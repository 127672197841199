// import { UTM_SOURCE_BLACKLIST } from '~/constants';

export default function setUtmSource(originUrl?: string) {
  if (!originUrl) return originUrl;
  // don't set utm_source if url is etherscan
  // const domainRegex = new RegExp(
  //   `^https?://(www\\.)?(${UTM_SOURCE_BLACKLIST.join('|')})`,
  //   'i'
  // );

  // if (domainRegex.test(originUrl)) return originUrl;
  const url = new URL(originUrl);
  url.searchParams.append('utm_source', 'catchmint');
  return url.href;
}
