export default function isProtocolHttps(url?: string) {
  if (!url) return false;

  try {
    const urlParsed = new URL(url);
    return urlParsed.protocol === 'https:';
  } catch (e) {
    return false;
  }
}
