import cn from 'classnames';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useRecoilValue } from 'recoil';

import { IcnPin, IcnPinned } from '~/assets';
import { LivePulse, Popover } from '~/components';
import {
  DEFAULT_MAIN_LAYOUT_ORDER,
  MAIN_LAYOUT,
  PLAY_STATUS,
} from '~/constants';
import useContractFilterList from '~/data/useContractFilterList';
import useLiveMints from '~/data/useLiveMints';
import { displayState } from '~/store/app';
import { checksumContractAddressState } from '~/store/contract';
import type { AvailableChain } from '~/types';
import toChecksumAddress from '~/utils/toChecksumAddress';

import styles from './LiveMints.module.scss';
import LiveMintsFilter from './LiveMintsFilter';
import LiveMintsItem from './LiveMintsItem';

interface LiveMintsProps {}

export default function LiveMints({}: LiveMintsProps) {
  const router = useRouter();

  const collectionAddress = useRecoilValue(checksumContractAddressState);
  const { contractFilterList } = useContractFilterList();
  const {
    filter,
    initialDataFetched,
    onPause,
    onPlay,
    playStatus,
    switchAddressPinned,
    transactionList,
  } = useLiveMints({
    collectionAddress,
    collectionFilterList: contractFilterList,
  });

  const mainLayoutOrder =
    useRecoilValue(displayState)?.main || DEFAULT_MAIN_LAYOUT_ORDER;

  const lastTransaction = transactionList[transactionList.length - 1];

  const handleClickItem = useCallback(
    (chain: Lowercase<AvailableChain>, address: string) => {
      const checksumAddress = toChecksumAddress(address);
      router.push(
        {
          pathname: `/collection/[chain]/[address]`,
          query: { address: checksumAddress, chain },
        },
        undefined,
        {
          shallow: true,
        }
      );
    },
    []
  );

  return (
    <section
      className={cn(styles.container, {
        [styles.is_center]: mainLayoutOrder[1] === MAIN_LAYOUT.LIVE_MINTS,
      })}
    >
      <div className={styles.section_header_container}>
        <div className={styles.header_left_container}>
          <span className={styles.section_header}>{'Live Mints'}</span>
          <LivePulse playStatus={playStatus} />
        </div>
        <div className={styles.header_right_container}>
          <Popover
            render={() => (
              <div className="default_popover">
                <span>{`Pin feed to the contract you're looking at`}</span>
              </div>
            )}
            animation
            placement="left"
          >
            <button className={styles.btn_pin} onClick={switchAddressPinned}>
              {filter.addressPinned ? (
                <div
                  className={cn(styles.pinned, {
                    [styles.pinned_playing]: playStatus === PLAY_STATUS.PLAYED,
                  })}
                >
                  {playStatus === PLAY_STATUS.PLAYED && (
                    <div className={styles.pinned_pulse} />
                  )}
                  <IcnPinned />
                </div>
              ) : (
                <IcnPin />
              )}
            </button>
          </Popover>
        </div>
      </div>
      <LiveMintsFilter />
      <div
        className={styles.section_body_container}
        onMouseEnter={onPause}
        onMouseLeave={onPlay}
      >
        {transactionList.length > 0 ? (
          <Flipper
            className={styles.transaction_item_list}
            flipKey={
              lastTransaction?.transactionHash +
              lastTransaction?.timestamp +
              filter.hiddenVisibility
            }
            element="ul"
            spring="noWobble"
            staggerConfig={{
              default: {
                speed: 15,
              },
            }}
          >
            {transactionList.map((tx, index) => {
              const isBlurred =
                filter.hiddenVisibility === 'blur' &&
                contractFilterList.some(
                  (hiddenContract) => hiddenContract === tx.address
                );
              const { address, transactionHash } = tx;

              return (
                <Flipped
                  key={transactionHash}
                  flipId={transactionHash}
                  translate
                >
                  <LiveMintsItem
                    blur={isBlurred}
                    data={tx}
                    enableBound={initialDataFetched && !!address}
                    index={transactionList.length - index}
                    onClickItem={() => handleClickItem('ethereum', address)}
                  />
                </Flipped>
              );
            })}
          </Flipper>
        ) : (
          <div className={styles.empty_data}>
            <span>{`No mints data`}</span>
          </div>
        )}
      </div>
    </section>
  );
}
