import type { JsonFragmentType } from '@ethersproject/abi';

export default function getAlignedParams(
  decodedData: any,
  inputs?: ReadonlyArray<JsonFragmentType>
) {
  if (!inputs) return null;
  const alignedParams =
    inputs?.reduce<{
      [key: string]: any;
    }>((prev, input) => {
      if (input.name)
        return Object.assign(prev, {
          [input.name]: decodedData[input.name],
        });
      return prev;
    }, {}) || null;
  return alignedParams;
}
