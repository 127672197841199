import { useContext } from 'react';

import { NotificationsContext } from './context';

export const useNotificationsContext = () => {
  const result = useContext(NotificationsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};

export default useNotificationsContext;
