import _isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { APP_SETTINGS_MODIFIED } from '~/constants';
import { failedTransactionsFilterState } from '~/store/app';
import type { FailedTransactionsFilter } from '~/types';

export default function useFailedTransactionsFilter() {
  const [failedTransactionsFilter, setFailedTransactionsFilter] =
    useRecoilState(failedTransactionsFilterState);

  const updateFailedTransactionsFilter = useCallback(
    (updatedFailedTransactionsFilter: Partial<FailedTransactionsFilter>) => {
      setFailedTransactionsFilter((prev) => {
        if (_isEqual(prev, updatedFailedTransactionsFilter)) {
          return prev;
        }
        const timestamp = new Date().getTime().toString();
        localStorage.setItem(APP_SETTINGS_MODIFIED, timestamp);

        return {
          ...prev,
          ...updatedFailedTransactionsFilter,
        };
      });
    },
    []
  );

  return [failedTransactionsFilter, updateFailedTransactionsFilter] as const;
}
