import { useCallback, useRef, useState } from 'react';

import type { ContractLinkedHolder } from '~/types';

import styles from './ClusteredView.module.scss';
import ClusterRow from './ClusterRow';

interface ClusteredViewProps {
  linkedHolders: ContractLinkedHolder[];
  deployer?: string | null;
  totalSupply?: string | number | null;
}

export default function ClusteredView({
  linkedHolders,
  deployer,
  totalSupply,
}: ClusteredViewProps) {
  const [nonCollapsedIndex, setNonCollapsedIndex] = useState<number | null>(
    null
  );
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const handleCollapse = useCallback(
    (index: number) => {
      if (nonCollapsedIndex === index) {
        setNonCollapsedIndex(null);
      } else {
        setNonCollapsedIndex(index);
        // for scrolling after unfolding cluster detail
        setTimeout(() => {
          scrollRef.current?.scrollTo({ top: index * 38, behavior: 'smooth' });
        }, 0);
      }
    },
    [nonCollapsedIndex]
  );

  return (
    <div className={styles.clustered_view_container} ref={scrollRef}>
      <div className={styles.view_header}>
        <div className={styles.clustered_view_column}>
          <span className={'number_column'}>{'Rank'}</span>
        </div>
        <div className={styles.clustered_view_column}>
          <span className={'text_column'}>{'Cluster'}</span>
        </div>
        <div className={styles.clustered_view_column}>
          <span className={'number_column'}>{'Quantity'}</span>
        </div>
      </div>
      {linkedHolders.length > 0 ? (
        <ol className={styles.clustered_view_list}>
          {linkedHolders.map(({ addresses, quantity }, index) => (
            <ClusterRow
              key={`clustered_row_${index}`}
              addresses={addresses.sort((prev, curr) =>
                prev.quantity === curr.quantity
                  ? prev.ens.length > 0
                    ? curr.ens.length > 0
                      ? prev.ens.localeCompare(curr.ens)
                      : -1
                    : 1
                  : curr.quantity - prev.quantity
              )}
              deployer={deployer}
              handleCollapse={() => handleCollapse(index)}
              index={index + 1}
              isCollapsed={nonCollapsedIndex !== index}
              quantity={quantity}
              totalSupply={totalSupply}
            />
          ))}
        </ol>
      ) : (
        <div className={styles.no_data}>{'No Data'}</div>
      )}
    </div>
  );
}
