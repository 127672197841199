import { setUser as setUserToSentry } from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTheme } from 'next-themes';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';
import {
  Connector,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
} from 'wagmi';

import API from '~/api';
import { Toaster } from '~/components';
import {
  APP_SETTINGS,
  APP_SETTINGS_MODIFIED,
  APP_USER_ID,
  AVAILABLE_CHAIN,
} from '~/constants';
import {
  contractsKeys,
  notificationsKeys,
  usersKeys,
} from '~/constants/queryKeys';
import { WALLET_CONNECTED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import useBeforeUnload from '~/hooks/useBeforeUnload';
import usePushNotification from '~/hooks/usePushNotification';
import {
  appConnectModalState,
  appSettingModalState,
  automationsState,
  displayState,
  failedTransactionsFilterState,
  liveMintsFilterState,
  mintsOverviewFilterState,
  settingsState,
  themeState,
  top100HoldersFilterState,
} from '~/store/app';
import { checksumContractAddressState } from '~/store/contract';
import { linkWalletModalState } from '~/store/user';
import type { AppSettings, User } from '~/types';
import formatAddress from '~/utils/formatAddress';
import showErrorToast from '~/utils/showErrorToast';

import { AuthContext } from './context';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const { address: walletAddress, isConnected: isWalletConnected } =
    useAccount();
  const analytics = useAnalyticsContext();
  const { connectAsync } = useConnect({ chainId: 1 });
  const { disconnectAsync } = useDisconnect();
  const { removeServiceWorker, setFcmToken } = usePushNotification();
  const queryClient = useQueryClient();
  const showingContractAddress = useRecoilValue(checksumContractAddressState);
  const setAppConnectModal = useSetRecoilState(appConnectModalState);
  const setAppSettingModal = useSetRecoilState(appSettingModalState);
  const setLinkWalletModal = useSetRecoilState(linkWalletModalState);
  // for updating app settings
  const setAutomations = useSetRecoilState(automationsState);
  const setDisplay = useSetRecoilState(displayState);
  const setFailedTransactionsFilter = useSetRecoilState(
    failedTransactionsFilterState
  );
  const setLiveMintsFilter = useSetRecoilState(liveMintsFilterState);
  const setMintsOverviewFilter = useSetRecoilState(mintsOverviewFilterState);
  const setSettings = useSetRecoilState(settingsState);
  const setStoredTheme = useSetRecoilState(themeState);
  const setTop100HoldersFilter = useSetRecoilState(top100HoldersFilterState);
  const { setTheme } = useTheme();
  // -------------------------
  const { signMessageAsync } = useSignMessage();
  const isSigning = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const checkAppSettings = useCallback(async (user: User) => {
    const appSettingsModified =
      typeof window !== 'undefined'
        ? (() => {
            return localStorage.getItem(APP_SETTINGS_MODIFIED) || '';
          })()
        : '';
    if (user.settingsModified !== appSettingsModified) {
      localStorage.setItem(APP_SETTINGS_MODIFIED, user.settingsModified);

      try {
        const {
          automations,
          display,
          failedTransactions,
          liveMints,
          mintsOverview,
          settings,
          theme,
          top100Holders,
        } = (await API.getAppSettings()).data;

        setAutomations(automations);
        setDisplay(display);
        setFailedTransactionsFilter(failedTransactions);
        setLiveMintsFilter(liveMints);
        setMintsOverviewFilter(mintsOverview);
        setSettings(settings);
        setStoredTheme(theme);
        setTheme(theme);
        setTop100HoldersFilter(top100Holders);
      } catch (err) {}
    }
  }, []);

  const checkAuth = async () => {
    try {
      setIsLoading(true);
      const user = await API.getMyInfo();
      setUserInfo(user);
      await Promise.allSettled([checkAppSettings(user), setFcmToken()]);
      return true;
    } catch (err) {
      const { response } = err as unknown as AxiosError;

      if (response) {
        const { status } = response;
        // the case that it is not matched access token and wallet address
        if (status === 403) {
          await signOut();
        } else {
          removeServiceWorker();
        }
      }
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const clearUserInfo = useCallback(() => {
    setUser(null);
    setUserToSentry(null);
  }, []);

  const connect = useCallback(
    (connector: Connector) => {
      connectAsync({ connector })
        .catch((err) => {
          // wallet connect modal error
          if (err?.message === 'Connection request reset. Please try again.')
            return;
          return showErrorToast(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [connectAsync]
  );

  const connectWallet = useCallback(
    (connector: Connector) => {
      if (isWalletConnected) {
        disconnectAsync()
          .then(() => {
            connect(connector);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        connect(connector);
      }
    },
    [connect, disconnectAsync, isWalletConnected]
  );

  const createNonce = async (from: string) => {
    const {
      data: { message },
    } = await API.generateNonce(from);

    return message;
  };

  const saveAppSettings = useCallback(async () => {
    try {
      if (user) {
        const settingsModified = localStorage.getItem(APP_SETTINGS_MODIFIED);
        if (settingsModified) {
          if (user.settingsModified !== settingsModified) {
            const appSettings = localStorage.getItem(APP_SETTINGS);
            if (appSettings) {
              const {
                automations,
                failedTransactions,
                liveMints,
                mintsOverview,
                top100Holders,
              } = JSON.parse(appSettings) as AppSettings;
              await API.setAppSettings(
                {
                  automations,
                  failedTransactions,
                  liveMints,
                  mintsOverview,
                  top100Holders,
                },
                settingsModified
              );
            }
          }
        }
      }
    } catch (err) {}
  }, [user]);

  const signMessage = useCallback(
    async (
      address: string,
      callback: (signature: string) => void,
      fallback?: () => void
    ) => {
      // Avoid duplicate calls to signMessage
      if (!isSigning.current) {
        try {
          isSigning.current = true;
          const message = await createNonce(address);
          const signature = await signMessageAsync({ message });
          callback(signature);
        } catch (err: any) {
          fallback?.();
          showErrorToast(err);
        } finally {
          isSigning.current = false;
          setIsLoading(false);
        }
      }
    },
    [signMessageAsync]
  );

  const linkWallet = useCallback(async () => {
    if (!walletAddress) return;
    signMessage(
      walletAddress,
      async (signature) => {
        try {
          const { data: linkedProfile } = await API.linkWallet(
            walletAddress,
            signature
          );
          setUserInfo(linkedProfile);
          // renew app settings timestamp
          const timestamp = new Date().getTime().toString();
          localStorage.setItem(APP_SETTINGS_MODIFIED, timestamp);

          queryClient.invalidateQueries({
            queryKey: usersKeys.linkedWallets(),
          });
          Toaster.toast({
            description: `Wallet ${formatAddress(
              walletAddress
            )} is linked to profile ${formatAddress(user?.address)}`,
            type: 'success',
          });
          await setFcmToken();
        } catch (err: any) {
          if (user) await signOut();
          showErrorToast(err);
        }
      },
      () => {
        signOut();
      }
    );
  }, [analytics, signMessage, user?.address, walletAddress]);

  const unlinkWallet = useCallback(
    async (address: string) => {
      if (!!user) {
        try {
          await API.unlinkWallet(address);
          if (user.address === address) await signOut();
          queryClient.invalidateQueries({
            queryKey: usersKeys.linkedWallets(),
          });
          Toaster.toast({
            description: `Your wallet is unlinked successfully`,
            type: 'success',
          });
        } catch (err: any) {
          showErrorToast(err);
        }
      }
    },
    [analytics, user]
  );

  const setUserInfo = useCallback(
    async (user: User) => {
      const { address, username, uuid } = user;
      setUser(user);
      setUserToSentry({
        id: uuid,
        address,
        username: username ?? '',
      });
      const segmentUser = await analytics.user();
      const aid = segmentUser.anonymousId();
      segmentUser.id(address);
      segmentUser.anonymousId(aid);
    },
    [analytics]
  );

  const signIn = useCallback(async () => {
    if (walletAddress) {
      signMessage(walletAddress, async (signature) => {
        try {
          const segmentUser = await analytics.user();
          const uid = segmentUser.id();
          const aid = uid ? null : segmentUser.anonymousId();
          const user = await API.login(walletAddress, signature, aid);
          setAppConnectModal({ isOpened: false });
          await Promise.allSettled([
            checkAppSettings(user),
            setFcmToken(),
            setUserInfo(user),
            // reload data that user affects them
            queryClient.invalidateQueries({
              queryKey: contractsKeys.information(
                'ethereum',
                showingContractAddress
              ),
            }),
            queryClient.invalidateQueries({
              queryKey: contractsKeys.comments(
                'ethereum',
                showingContractAddress
              ),
            }),
            queryClient.removeQueries({
              queryKey: notificationsKeys.all,
            }),
          ]);
        } catch (err) {
          showErrorToast(err);
        }
      });
    } else {
      setAppConnectModal({ isOpened: true });
    }
  }, [setUserInfo, showingContractAddress, signMessage, walletAddress]);

  const signOut = useCallback(async () => {
    try {
      setIsLoading(true);
      await saveAppSettings();
      await API.logout();
      setAppSettingModal({ history: [], isOpened: false });
      clearUserInfo();
      queryClient.removeQueries({ queryKey: usersKeys.me() });
      removeServiceWorker();
    } catch (err: any) {
      showErrorToast(err);
    } finally {
      setIsLoading(false);
    }
  }, [analytics, clearUserInfo, saveAppSettings]);

  useBeforeUnload(saveAppSettings);

  // check access token
  useIsomorphicLayoutEffect(() => {
    (async () => {
      try {
        await checkAuth();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useIsomorphicLayoutEffect(() => {
    const handlePermissionChange = (e: Event) => {
      if ((e.target as PermissionStatus).state !== 'granted') {
        removeServiceWorker();
      }
    };

    try {
      navigator.permissions
        ?.query({ name: 'notifications' })
        .then((status) => {
          status.addEventListener('change', handlePermissionChange);
        })
        .catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }

    return () => {
      try {
        navigator.permissions
          ?.query({ name: 'notifications' })
          .then((status) => {
            status.removeEventListener('change', handlePermissionChange);
          })
          .catch((err) => console.error(err));
      } catch (err) {
        console.error(err);
      }
    };
  }, []);

  useEffect(() => {
    if (walletAddress) {
      localStorage.setItem(APP_USER_ID, walletAddress);
      analytics.track(WALLET_CONNECTED, {
        address: walletAddress,
        chain: AVAILABLE_CHAIN.ETHEREUM,
      });
    }
  }, [analytics, walletAddress]);

  useEffect(() => {
    (async () => {
      if (user && !walletAddress) {
        await signOut();
      }
    })();
  }, [signOut, user, walletAddress]);

  // case of user change wallet
  useEffect(() => {
    if (!!user && !!walletAddress) {
      if (user.address !== walletAddress) {
        setIsLoading(true);
        API.changeWallet(walletAddress)
          .then(async ({ data }) => {
            await Promise.allSettled([
              setFcmToken(),
              setUserInfo({
                ...user,
                ...data,
              }),
              // reload data that user affects them
              queryClient.invalidateQueries({
                queryKey: contractsKeys.information(
                  'ethereum',
                  showingContractAddress
                ),
              }),
              queryClient.invalidateQueries({
                queryKey: contractsKeys.comments(
                  'ethereum',
                  showingContractAddress
                ),
              }),
              queryClient.invalidateQueries({
                queryKey: usersKeys.hideFilter(),
              }),
              queryClient.invalidateQueries({
                queryKey: usersKeys.followingGroups(),
              }),
              queryClient.removeQueries({
                queryKey: notificationsKeys.all,
              }),
            ]);
          })
          .catch(async (err) => {
            if (err?.response?.status === 403) {
              setLinkWalletModal({ isOpened: true });
            } else if (err?.response?.status === 406) {
              // address is not signed in if the status is 406
              await signOut();
              setAppConnectModal({ isOpened: true });
            } else {
              showErrorToast(err);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [showingContractAddress, user, walletAddress]);

  return (
    <AuthContext.Provider
      value={{
        connectWallet,
        isAuthenticated: isWalletConnected && !!user,
        isLoading,
        linkWallet,
        setUserInfo,
        signIn,
        signOut,
        unlinkWallet,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
