import { Toaster } from '~/components';
import { UNEXPECTED_ERROR_MESSAGE } from '~/constants';

export default function showErrorToast(err: any) {
  console.error(err);
  if (err?.code === 4001) return;
  if (err?.message === 'User rejected request') return;
  if (err?.message === 'Connector not found') {
    Toaster.toast({
      description: `The default wallet extension has not been set, please refresh the page and try again.`,
      type: 'error',
    });
    return;
  }
  // in the case of ethers.js error
  if (err?.reason) {
    Toaster.toast({
      description: err?.reason,
      type: 'error',
    });
    return;
  }

  const { detail = undefined } = { ...err?.response?.data?.error };

  if (!!detail) {
    if (typeof detail === 'string') {
      Toaster.toast({
        description: detail,
        type: 'error',
      });
    } else if (Array.isArray(detail)) {
      Toaster.toast({
        description: detail[0],
        type: 'error',
      });
    } else if (typeof detail === 'object') {
      const message = Object.values(detail)
        .map((message: any) => message[0])
        .join('');
      if (typeof message === 'string') {
        Toaster.toast({
          description: message,
          type: 'error',
        });
      } else {
        Toaster.toast({
          description: UNEXPECTED_ERROR_MESSAGE,
          type: 'error',
        });
      }
    } else {
      Toaster.toast({
        description: UNEXPECTED_ERROR_MESSAGE,
        type: 'error',
      });
    }
  } else {
    Toaster.toast({
      description: UNEXPECTED_ERROR_MESSAGE,
      type: 'error',
    });
  }
}
