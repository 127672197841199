import type { Collection } from '~/types';

import base from './base';

const Collections = {
  getCollections(address: string) {
    return base.get<Collection[]>(`/collections/?address=${address}`);
  },
};

export default Collections;
