import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

import { IcnArrowDown, IcnInputClear, IcnSmallHint } from '~/assets';
import { DropdownMenu } from '~/components';

import styles from './ParamAddressInput.module.scss';

export interface SelectableAddress {
  name: string;
  value: string;
  tooltip?: string;
}

interface ParamInputProps {
  contractAddress: string;
  defaultValue: string | null;
  label: string;
  myAddress: string;
  placeholder: string;
  resource: string | undefined;
  setParams: (value: string | null) => void;
}

export default function ParamAddressInput({
  contractAddress,
  defaultValue,
  label,
  myAddress,
  placeholder,
  resource,
  setParams,
}: ParamInputProps) {
  const [selectableAddresses] = useState<SelectableAddress[]>([
    {
      name: 'My address',
      value: myAddress,
    },
    {
      name: 'Contract address',
      value: contractAddress,
    },
  ]);
  const [value, setValue] = useState<string | null>(defaultValue);
  const [selectedAddress, setSelectedAddress] = useState<SelectableAddress>(
    selectableAddresses[0]
  );

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setValue(value);
  };

  const onClearValue = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (defaultValue === null) {
      setValue(null);
      return;
    }

    setValue('');
  };

  const onPasteSelectedAddress = () => {
    const { value } = selectedAddress;
    setValue(value);
  };

  const onSelectOption = (selectableAddress: SelectableAddress) => {
    setSelectedAddress(selectableAddress);
  };

  useEffect(() => {
    const params = value ? value.toString() : null;
    setParams(params);
  }, [value]);

  const mergedSelectableAddresses = resource
    ? [
        ...selectableAddresses,
        {
          name: `Value from last transaction`,
          tooltip: `Paste the value from the previous successful transaction. This may be the last minter's address, contract address, or another relevant value. Please do your own research (DYOR).`,
          value: resource,
        },
      ]
    : selectableAddresses;
  const isNotBlank = value !== undefined && value !== null && value.length > 0;

  return (
    <div className={styles.modal_address_input_container}>
      <div className={styles.address_input_label_container}>
        <label htmlFor={label} className={styles.address_input_label}>
          {label}
        </label>
        <div className={styles.address_input_btn_container}>
          <DropdownMenu.Menu
            label={
              <button className={styles.btn_address_input_arrow}>
                {`${selectedAddress.name}`}
                <IcnArrowDown />
              </button>
            }
          >
            {mergedSelectableAddresses.map((selectableAddress, i) => {
              const { name, tooltip } = selectableAddress;

              return (
                <DropdownMenu.MenuItem
                  key={i}
                  tooltip={tooltip}
                  label={
                    <div className={styles.address_input_select_option}>
                      {name}
                      {tooltip && <IcnSmallHint />}
                    </div>
                  }
                  onClick={() => {
                    onSelectOption(selectableAddress);
                    setValue(selectableAddress.value);
                  }}
                />
              );
            })}
          </DropdownMenu.Menu>
          <button
            className={styles.address_input_paste_btn}
            onClick={onPasteSelectedAddress}
          >
            {`Paste`}
          </button>
        </div>
      </div>
      <div className={styles.address_input_container}>
        <input
          id={label}
          autoComplete="off"
          type="text"
          onChange={onChangeValue}
          value={value ?? ''}
          className={styles.address_input}
          placeholder={placeholder}
        />
        {isNotBlank && (
          <button className={styles.btn_clear} onClick={onClearValue}>
            <IcnInputClear />
          </button>
        )}
      </div>
    </div>
  );
}
