import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import API from '~/api';
import { notificationsKeys } from '~/constants/queryKeys';
import { useAuth } from '~/contexts/AuthContext';
import type { Notification } from '~/types';
import getParamFromUrl from '~/utils/getParamFromUrl';

export default function useNotificationList() {
  const queryClient = useQueryClient();
  const queryKey = notificationsKeys.all;
  const { isAuthenticated } = useAuth();

  const getNotificationListWithCursor = async (cursor: string | null) => {
    const {
      data: { next, results },
    } = await API.getNotifications(cursor);

    return {
      results,
      next: getParamFromUrl(next, 'cursor'),
      isLast: !next,
    };
  };

  const { data, fetchNextPage, isFetched, isLoading } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => getNotificationListWithCursor(pageParam),
    {
      cacheTime: 5_000,
      enabled: isAuthenticated,
      getNextPageParam: ({ next }) => next,
      refetchOnMount: false,
      staleTime: 5_000,
      keepPreviousData: true,
    }
  );

  const notifications = useMemo(
    () => data?.pages.flatMap((page) => page.results) ?? [],
    [data?.pages]
  );

  const isItemLoaded: (index: number) => boolean = (index) =>
    data?.pages[data.pages.length - 1].isLast || index < notifications.length;

  const setAllNotificationToRead = () => {
    queryClient.setQueriesData<
      | InfiniteData<{
          results: Notification[];
          next: string | null;
          isLast: boolean;
        }>
      | undefined
    >({ exact: true, queryKey }, (prev) => {
      if (prev) {
        const hasUnread = prev.pages[0].results.find(
          (notification) => notification.unread
        );
        if (hasUnread) {
          API.markAllAsRead();
        }
        prev.pages = prev.pages.map((page) => {
          page.results = page.results.map((result) => {
            result = {
              ...result,
              unread: false,
            };
            return result;
          });
          return page;
        });
      }
      return prev;
    });
  };

  useEffect(() => {
    if (data && data.pages.length === 1) {
      const hasUnread = data.pages[0].results.find(
        (notification) => notification.unread
      );
      if (hasUnread) {
        API.markAllAsRead();
      }
    }
  }, [data]);

  return {
    isFetched,
    isItemLoaded,
    isLoading,
    itemCount: data?.pages[data.pages.length - 1].isLast
      ? notifications.length
      : notifications.length + 1,
    loadMore: () => {
      fetchNextPage();
    },
    notifications,
    setAllNotificationToRead,
  };
}
