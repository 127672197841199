import _isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { APP_SETTINGS_MODIFIED } from '~/constants';
import { automationsState } from '~/store/app';
import type { Automations } from '~/types';

export default function useAutomations() {
  const [automations, setAutomations] =
    useRecoilState<Automations>(automationsState);

  const updateAutomations = useCallback(
    (updatedAutomations: Partial<Automations>, shouldSetModified = true) => {
      setAutomations((prev) => {
        if (_isEqual(prev, updatedAutomations)) {
          return prev;
        }
        if (shouldSetModified) {
          const timestamp = new Date().getTime().toString();
          localStorage.setItem(APP_SETTINGS_MODIFIED, timestamp);
        }
        return {
          ...prev,
          ...updatedAutomations,
        };
      });
    },
    []
  );

  return [automations, updateAutomations] as const;
}
