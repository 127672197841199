export default function getDecimalPlaceLength(num: number | string) {
  if (Number.isInteger(num)) {
    return 0;
  }

  const numStr = num.toString();
  const isExponential = numStr.includes('e') || numStr.includes('E');

  if (isExponential) {
    const [mantissa, exponent] = numStr.split(/e|E/);
    const decimalStr = mantissa.split('.')[1] || '';
    const decimalLength = decimalStr.length - Number(exponent);
    return decimalLength > 0 ? decimalLength : 0;
  } else {
    const decimalStr = numStr.split('.')[1] || '';
    return decimalStr.length;
  }
}
