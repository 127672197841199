import type { ID } from '@segment/analytics-next';

import type { AccountInfo } from '~/types';

import base, { activeAuthenticateAPI, inactiveAuthenticateAPI } from './base';

const Accounts = {
  generateNonce(address: string) {
    return base.post<{ message: string }>(`/nonce/`, { address });
  },
  async login(address: string, signature: string, aid: ID) {
    const params: { [key: string]: any } = {
      address,
      signature,
    };
    if (!!aid) params['aid'] = aid;

    const {
      data: { access, user },
    } = await base.post<AccountInfo>(`/login/`, params);

    if (access && access.length > 0) {
      activeAuthenticateAPI(access);
    }

    return user;
  },
  async logout() {
    const {
      data: { detail },
    } = await base.get<{ detail: string }>(`/logout/`);
    inactiveAuthenticateAPI();

    return detail;
  },
};

export default Accounts;
