import { CSSProperties, memo } from 'react';

import { IcnMore } from '~/assets';
import { DropdownMenu } from '~/components';
import type { LinkedWallet } from '~/types';
import formatAddress from '~/utils/formatAddress';

import styles from './LinkedWalletItem.module.scss';

interface LinkedWalletItemProps extends LinkedWallet {
  isActive: boolean;
  unlinkWallet: (address: string) => Promise<void>;
  style?: CSSProperties;
}

export default memo(function LinkedWalletItem({
  address,
  isActive = false,
  isPrimary,
  style,
  unlinkWallet,
  username,
}: LinkedWalletItemProps) {
  const showingAddress = username ? `(${formatAddress(address)})` : address;
  return (
    <li className={styles.container} style={style}>
      <div className={styles.showing_name_container}>
        {username && <span className={styles.username}>{username}</span>}
        <p className={styles.address}>{showingAddress}</p>
        {isActive && <span className={styles.active}>{'Active'}</span>}
      </div>
      {!isPrimary && (
        <DropdownMenu.Menu
          label={
            <button className={styles.btn_more}>
              <IcnMore />
            </button>
          }
        >
          <DropdownMenu.MenuItem
            label={
              <div className={styles.btn_unlink}>
                <span>{'Unlink'}</span>
              </div>
            }
            onClick={() => unlinkWallet(address)}
          />
        </DropdownMenu.Menu>
      )}
    </li>
  );
});
