import cn from 'classnames';
import type { ReactNode } from 'react';

import styles from './DivComponent.module.scss';

interface DivColumnProps {
  children: ReactNode;
  className?: string;
  isText?: boolean;
}

export default function CustomColumn({
  children,
  className,
  isText = undefined,
}: DivColumnProps) {
  return (
    <div
      className={cn(
        styles.div_view_column,
        className,
        { '': isText === undefined },
        { [styles.number_column]: isText === false },
        { [styles.text_column]: isText === true }
      )}
    >
      {children}
    </div>
  );
}
