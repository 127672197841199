export default function isNewerVersion(oldVer: string, newVer: string) {
  if (typeof oldVer !== 'string' || typeof newVer !== 'string') return false;
  const oldParts = oldVer.split('.');
  const newParts = newVer.split('.');
  for (let i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i]; // parse int
    const b = ~~oldParts[i]; // parse int
    if (a > b) return true;
    if (a < b) return false;
  }
  return false;
}
