import cn from 'classnames';
import { Reorder } from 'framer-motion';
import { useState } from 'react';

import { IcnInvisible, IcnVisible } from '~/assets';
import type { DetailLayout, LayoutSetting } from '~/types';

import styles from './ContractDetailComponent.module.scss';

interface ContractDetailComponentProps {
  index: number;
  item: LayoutSetting<DetailLayout>;
  toggleVisible: (index: number) => void;
}

export default function ContractDetailComponent({
  index,
  item,
  toggleVisible,
}: ContractDetailComponentProps) {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <Reorder.Item
      className={cn(styles.contract_detail_component, {
        [styles.invisible]: !item.visible,
        [styles.isDragging]: isDragging,
      })}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      value={item}
    >
      {item.name}
      <button
        className={styles.visible_button}
        onClick={(e) => {
          toggleVisible(index);
          e.stopPropagation();
        }}
      >
        {item.visible ? <IcnVisible /> : <IcnInvisible />}
      </button>
    </Reorder.Item>
  );
}
