import Pusher from 'pusher-js';
import { FC, ReactNode, useEffect, useState } from 'react';

import config from '~/config';
import { CHANNEL_APP_HOOKS } from '~/constants';
import usePageVisibility from '~/hooks/usePageVisibility';

import { SocketContext } from './context';

interface SocketProviderProps {
  children: ReactNode;
}

export const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
  const isPageVisible = usePageVisibility();
  const [pusher, setPusher] = useState<Pusher | null>(null);

  useEffect(() => {
    const pusherInstance = new Pusher(config.SOCKETI_KEY, {
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      forceTLS: true,
      wsHost: config.SOCKETI_HOST,
      wsPort: 443,
      wssPort: 443,
    });
    setPusher(pusherInstance);

    return () => {
      pusherInstance.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isPageVisible && !!pusher) {
      pusher.subscribe(CHANNEL_APP_HOOKS);
      return () => {
        pusher.unsubscribe(CHANNEL_APP_HOOKS);
      };
    }
  }, [isPageVisible, pusher]);

  return (
    <SocketContext.Provider value={pusher}>{children}</SocketContext.Provider>
  );
};
