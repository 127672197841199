import type {
  CursorBasedPagination,
  Notification,
  NotificationProviderSettings,
  NotificationSetting,
  NotificationTrigger,
} from '~/types';

import base from './base';

const Notifications = {
  getNotificationSettings() {
    return base.get<NotificationProviderSettings>(`/notifications/social/`);
  },
  getNotificationTriggers() {
    return base.get<NotificationTrigger[]>(`/notifications/social/trigger/`);
  },
  getNotifications(cursor: string | null) {
    const url = `/notifications/${cursor ? `?cursor=${cursor}` : ''}`;
    return base.get<CursorBasedPagination<Notification>>(url);
  },
  markAllAsRead() {
    const url = '/notifications/mark_all_as_read/';

    return base.put(url);
  },
  removeDiscordWebhookNotification() {
    return base.delete(`/notifications/social/discord/webhook/register/`);
  },
  removeWebpushNotification() {
    return base.delete(`/notifications/social/webpush/register/`);
  },
  registerDiscordWebhookNotification(url: string) {
    return base.post(`/notifications/social/discord/webhook/register/`, {
      url,
    });
  },
  registerWebpushNotification() {
    return base.post(`/notifications/social/webpush/register/`);
  },
  updateNotificationSettings(settings: NotificationSetting) {
    return base.put(`/notifications/social/trigger/`, settings);
  },
};

export default Notifications;
