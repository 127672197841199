import { MouseEvent, useEffect, useState } from 'react';

import { IcnCopied, IcnCopy, IcnSmallCopied, IcnSmallCopy } from '~/assets';
import { Popover } from '~/components';
import { SHOW_COPIED } from '~/constants';
import copyToClipboard from '~/utils/copyToClipboard';
import noop from '~/utils/noop';

import styles from './Copy.module.scss';

interface CopyProps {
  content: string;
  onClick?: () => void;
  size?: 'small' | 'normal';
}

export default function Copy({
  content,
  onClick = noop,
  size = 'normal',
}: CopyProps) {
  const [showCopied, setShowCopied] = useState(false);

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();

    copyToClipboard(content);
    setShowCopied(true);
    onClick();
  };

  useEffect(() => {
    if (showCopied) {
      setTimeout(() => setShowCopied(false), SHOW_COPIED);
    }
  }, [showCopied]);

  if (showCopied) {
    return (
      <div className={styles.copied_container}>
        {size === 'normal' ? <IcnCopied /> : <IcnSmallCopied />}
      </div>
    );
  }
  return (
    <Popover
      render={({ labelId }) => (
        <div id={labelId} className={'brand_popover'}>
          <span>{'Copy address'}</span>
        </div>
      )}
      animation
    >
      <button className={styles.button_copy_container} onClick={handleClick}>
        {size === 'normal' ? <IcnCopy /> : <IcnSmallCopy />}
      </button>
    </Popover>
  );
}
