import cn from 'classnames';
import { useState } from 'react';

import { IcnAnnouncement } from '~/assets';
import { AnnouncementsModal, TimeAgo } from '~/components';
import { URGENT_TIME } from '~/constants';
import type { AvailableChain, ContractAnnouncement } from '~/types';

import styles from './Announcement.module.scss';

interface AnnouncementProps {
  announcements: ContractAnnouncement[];
  chain?: AvailableChain;
}

export default function Announcement({
  announcements,
  chain,
}: AnnouncementProps) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { created, title } = announcements[0];
  const createdTime = new Date(created);

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  return (
    <>
      <div className={styles.container} onClick={handleOpenModal}>
        <div className={styles.container_inner}>
          <div
            className={cn(styles.icn_announcement, {
              [styles.urgent]:
                new Date().getTime() - createdTime.getTime() < URGENT_TIME,
            })}
          >
            <IcnAnnouncement />
          </div>
          <span className={styles.time}>
            <TimeAgo date={createdTime} highlight />
          </span>
          <span className={styles.title}>{title}</span>
        </div>
        <button className={styles.btn_read}>Read</button>
      </div>
      {isOpenModal && (
        <AnnouncementsModal
          announcements={announcements}
          chain={chain}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}
