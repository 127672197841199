import { useMutation, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import API from '~/api';
import { IcnExternal } from '~/assets';
import {
  ExternalLink,
  Loading,
  NotableGroup,
  SubmitModal,
  Toaster,
} from '~/components';
import Table, {
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '~/components/Table';
import {
  AVAILABLE_CHAIN,
  NotableGroupsFilterList,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
  REQUEST_NOTABLE_GROUP_URL,
  UNEXPECTED_ERROR_MESSAGE,
} from '~/constants';
import { notableGroupsKeys, usersKeys } from '~/constants/queryKeys';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import useFollowedNotableGroups from '~/data/useFollowedNotableGroups';
import useNotableGroups from '~/data/useNotableGroups';
import styleVariables from '~/styles/variables.module.scss';
import type { NotableGroupsFilter } from '~/types';

import styles from './NotableGroupsModal.module.scss';
import commonStyles from './NotableGroupsSection.module.scss';

interface NotableGroupsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function NotableGroupsModal({
  isOpen,
  onClose,
}: NotableGroupsModalProps) {
  const analytics = useAnalyticsContext();
  const queryClient = useQueryClient();
  const [isFetching, setIsFetching] = useState(false);
  const {
    hasNew,
    notableGroups,
    isFetched: isNotableGroupsFetched,
  } = useNotableGroups();
  const { followingIds } = useFollowedNotableGroups();
  const [selectedIds, setSelectedIds] = useState<number[]>(followingIds);
  const [sortedNotableGroups, setSortedNotableGroups] = useState(notableGroups);
  const followingNotableGroupsMutation = useMutation((ids: number[]) =>
    API.updateFollowingNotableGroups(ids)
  );
  const [activeFilter, setActiveFilter] =
    useState<NotableGroupsFilter>('Popular');

  const notableGroupIds: number[] = notableGroups.map((group) => group.id);
  const allSelected =
    notableGroupIds.length === selectedIds.length &&
    notableGroupIds.every((id) => selectedIds.includes(id));

  const handleFilterData = (filter: NotableGroupsFilter) => {
    if (filter === 'New') {
      setSortedNotableGroups(
        [...notableGroups].sort((prev, curr) =>
          curr.isNew === prev.isNew
            ? prev.followCount === curr.followCount
              ? prev.name.toLowerCase().localeCompare(curr.name.toLowerCase())
              : curr.followCount - prev.followCount
            : prev.isNew
            ? -1
            : 1
        )
      );
    }
    if (filter === 'Popular') {
      setSortedNotableGroups(
        [...notableGroups].sort((prev, curr) =>
          prev.followCount === curr.followCount
            ? prev.name.toLowerCase().localeCompare(curr.name.toLowerCase())
            : curr.followCount - prev.followCount
        )
      );
    }
    if (filter === 'A to Z') {
      setSortedNotableGroups(
        [...notableGroups].sort((prev, curr) =>
          prev.name.toLowerCase().localeCompare(curr.name.toLowerCase())
        )
      );
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    setIsFetching(true);
    const sortedSelectedIds = selectedIds?.slice().sort();
    if (
      followingIds.length === selectedIds.length &&
      followingIds
        .slice()
        .sort()
        .every(function (value, index) {
          return value === sortedSelectedIds[index];
        })
    ) {
      setIsFetching(false);
      handleClose();
    } else {
      await followingNotableGroupsMutation.mutateAsync(selectedIds, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: notableGroupsKeys.all });
          queryClient.invalidateQueries({
            queryKey: usersKeys.followingGroups(),
          });
          handleClose();
        },
        onError: () => {
          Toaster.toast({
            description: UNEXPECTED_ERROR_MESSAGE,
            type: 'error',
          });
        },
        onSettled: () => {
          setIsFetching(false);
        },
      });
    }
  };

  const selectGroup = (id: number) => {
    const isExist = !!selectedIds.find((selectedId) => selectedId === id);
    if (isExist) {
      setSelectedIds((prev) => [
        ...prev.filter((selectedId) => selectedId !== id),
      ]);
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };

  const selectAllGroup = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds(notableGroupIds);
    }
  };

  useEffect(() => {
    if (isNotableGroupsFetched) {
      handleFilterData(activeFilter);
    }
  }, [activeFilter, isNotableGroupsFetched]);

  useEffect(() => {
    if (hasNew) {
      setActiveFilter('New');
    }
  }, [hasNew]);

  return (
    <SubmitModal
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={'Add Groups'}
      buttonText={
        isFetching ? (
          <Loading color={styleVariables.gray50} size={24} />
        ) : (
          'Save'
        )
      }
      filter={
        <ul className={styles.filter_container}>
          {(hasNew
            ? NotableGroupsFilterList
            : NotableGroupsFilterList.slice(1)
          ).map((filter) => (
            <li
              key={filter}
              className={cn(styles.filter, {
                [styles.active]: activeFilter === filter,
              })}
              onClick={(e) => {
                e.stopPropagation();
                setActiveFilter(filter);
              }}
            >
              {filter}
            </li>
          ))}
        </ul>
      }
    >
      <div className={styles.notable_groups_modal_body}>
        <span>
          <ExternalLink
            allowsUtmSource={false}
            className={styles.link_request_more_groups_to_be_added}
            label={'request_more_groups_to_be_added_link'}
            nofollow
            icon={<IcnExternal />}
            onClick={() => {
              analytics.track(REDIRECT_CLICKED, {
                chain: AVAILABLE_CHAIN.ETHEREUM,
                location: REDIRECT_CLICKED_LOCATION.NOTABLE_GROUPS,
                type: REDIRECT_CLICKED_TYPE.OTHER,
                url: REQUEST_NOTABLE_GROUP_URL,
              });
            }}
            role="link"
            url={REQUEST_NOTABLE_GROUP_URL}
          >
            <span>Request more groups to be added</span>
          </ExternalLink>
        </span>
        <Table summary={'Notable Group'}>
          <TableHeader>
            <TableRow>
              <TableCell
                isHeader={true}
                width={48}
                divClassName={commonStyles.table_checkbox}
              >
                <input
                  type="checkbox"
                  className={styles.popup_checkbox}
                  onChange={(e) => {
                    e.stopPropagation();
                    selectAllGroup();
                  }}
                  checked={allSelected}
                />
              </TableCell>
              <TableCell isText={true} isHeader={true} width={244}>
                {'Name'}
              </TableCell>
              <TableCell isText={true} isHeader={true}>
                {'Tags'}
              </TableCell>
              <TableCell isText={false} isHeader={true} width={86}>
                {'Followers'}
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedNotableGroups.map((group) => (
              <TableRow
                key={group.name}
                onClick={(e) => {
                  e.stopPropagation();
                  selectGroup(group.id);
                }}
              >
                <TableCell divClassName={commonStyles.table_checkbox}>
                  <input
                    className={styles.popup_checkbox}
                    type="checkbox"
                    checked={selectedIds.includes(group.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      selectGroup(group.id);
                    }}
                  />
                </TableCell>
                <TableCell isText={true}>
                  <NotableGroup {...group} />
                </TableCell>
                <TableCell isText={true}>
                  <div className={commonStyles.tags_container}>
                    {group.tags.map((tag) => (
                      <span
                        className={commonStyles.tag}
                        key={`${group.name}_tag_${tag}`}
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </TableCell>
                <TableCell isText={false}>{group.followCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </SubmitModal>
  );
}
