import { useTheme } from 'next-themes';
import { Toaster as RHToaster, ToasterProps } from 'react-hot-toast';

import styleVariables from '~/styles/variables.module.scss';

import Toast from './Toast';

export default function Toaster(props: ToasterProps) {
  const { systemTheme, theme } = useTheme();
  const isDarkMode =
    theme === 'system' ? systemTheme === 'dark' : theme === 'dark';
  const backgroundColor = isDarkMode
    ? styleVariables.gray100
    : styleVariables.white;

  return (
    <RHToaster
      {...props}
      containerStyle={{
        ...props.containerStyle,
        bottom: 64,
        left: 64,
      }}
      gutter={24}
      toastOptions={{
        ...props.toastOptions,
        position: 'bottom-left',
        style: {
          padding: '12px 6px',
          backgroundColor,
          color: styleVariables.gray850,
          width: '100%',
          maxWidth: 344,
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.15)',
          borderRadius: 4,
          overflow: 'hidden',
        },
        duration: 5_000,
      }}
    />
  );
}

Toaster.toast = Toast;
