import Accounts from './Accounts';
import Collections from './Collections';
import Comments from './Comments';
import Contracts from './Contracts';
import Etc from './Etc';
import Groups from './Groups';
import Mints from './Mints';
import Notifications from './Notifications';
import Timeseries from './Timeseries';
import Users from './Users';

const API = {
  ...Accounts,
  ...Collections,
  ...Comments,
  ...Contracts,
  ...Etc,
  ...Groups,
  ...Mints,
  ...Notifications,
  ...Timeseries,
  ...Users,
};

export default API;
