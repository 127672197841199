import type { ChangeEvent } from 'react';

import styles from './ToggleSwitch.module.scss';

interface ToggleSwitchProps {
  checked: boolean;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export default function ToggleSwitch({
  checked,
  disabled = false,
  name,
  onChange,
}: ToggleSwitchProps) {
  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        className={styles.switch_checkbox}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        name={name}
      />
      <span className={styles.slider}></span>
    </label>
  );
}
