import cn from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { MouseEvent, ReactNode, useCallback } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Popover, SelectButton, TimeAgo } from '~/components';
import {
  DEFAULT_MAIN_LAYOUT_ORDER,
  MAIN_LAYOUT,
  MINTS_OVERVIEW_FILTER_TIME_OPTION,
} from '~/constants';
import useContractFilterList from '~/data/useContractFilterList';
import useMintsOverview from '~/data/useMintsOverview';
import useMintsOverviewFilter from '~/hooks/useMintsOverviewFilter';
import { appSettingModalState, displayState } from '~/store/app';
import type {
  AvailableChain,
  HiddenVisibility,
  MintsOverviewData,
  MintsOverviewFilterTimeOption,
} from '~/types';
import toChecksumAddress from '~/utils/toChecksumAddress';

import styles from './MintsOverview.module.scss';
import MintsOverviewItem from './MintsOverviewItem';

interface MintsOverviewProps {}

export default function MintsOverview({}: MintsOverviewProps) {
  const [filter, setFilter] = useMintsOverviewFilter();
  const { initialDataFetched, lastUpdated, mintsOverviewData } =
    useMintsOverview(filter);
  const mainLayoutOrder =
    useRecoilValue(displayState)?.main || DEFAULT_MAIN_LAYOUT_ORDER;
  const router = useRouter();

  const { contractFilterList } = useContractFilterList();
  const setAppSettingModal = useSetRecoilState(appSettingModalState);

  const changeFilterOption = (timeframe: MintsOverviewFilterTimeOption) => {
    if (timeframe === filter.timeframe) return;
    setFilter({
      timeframe,
    });
  };

  const filterTimeOptions = useCallback(
    () =>
      filter.timeframe !== '0' && (
        <SelectButton.Group
          flat
          items={Object.entries(MINTS_OVERVIEW_FILTER_TIME_OPTION)
            .slice(1)
            .map((option) => ({
              label: option[1],
              key: option[0],
              value: option,
              selected: option[0] === filter.timeframe,
            }))}
          onItemClicked={(items) => {
            changeFilterOption(items[0].key as MintsOverviewFilterTimeOption);
          }}
        />
      ),
    [filter.timeframe]
  );

  const generateFlipKey = (
    hiddenOption: HiddenVisibility,
    mintableOption: boolean,
    data?: MintsOverviewData[]
  ) => {
    if (!data) return 0;
    const uniqueText = data.reduce(
      (acc, curr) => acc + curr.address + curr.totalCounts,
      ''
    );

    return getHash(uniqueText + hiddenOption + mintableOption);
  };

  const getHash = (text: string) => {
    let hash = 0,
      i,
      chr;
    if (text.length === 0) return hash;
    for (i = 0; i < text.length; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  const handleManageHiddenClicked = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAppSettingModal({ history: [1], isOpened: true });
    },
    []
  );

  const handleClickItem = useCallback(
    (chain: Lowercase<AvailableChain>, address: string) => {
      const checksumAddress = toChecksumAddress(address);
      router.push(
        {
          pathname: `/collection/[chain]/[address]`,
          query: { address: checksumAddress, chain },
        },
        undefined,
        {
          shallow: true,
        }
      );
    },
    [router]
  );

  const renderItem = (
    item: MintsOverviewData,
    index: number,
    isBlurred?: boolean
  ) => {
    return (
      <Flipped key={item.address ?? index} flipId={item.address} translate>
        <MintsOverviewItem
          blur={!!isBlurred}
          data={item}
          enableBound={initialDataFetched && Object.keys(item).length > 0}
          filter={filter.timeframe}
          onClickItem={() =>
            handleClickItem(
              item.chain.toLowerCase() as Lowercase<AvailableChain>,
              item.address
            )
          }
          rank={index + 1}
        />
      </Flipped>
    );
  };

  const renderList = (children: ReactNode) => {
    return (
      <Flipper
        flipKey={generateFlipKey(
          filter.hiddenVisibility ?? 'blur',
          filter.onlyMintable,
          mintsOverviewData[filter.timeframe].filter(
            (mintsOverviewItem) =>
              !contractFilterList.some(
                (hiddenContract) => hiddenContract === mintsOverviewItem.address
              )
          )
        )}
        spring="noWobble"
        staggerConfig={{
          default: {
            reverse: true,
            speed: 15,
          },
        }}
        element="ul"
      >
        {children}
      </Flipper>
    );
  };

  return (
    <section
      className={cn(styles.container, {
        [styles.is_center]: mainLayoutOrder[1] === MAIN_LAYOUT.MINTS_OVERVIEW,
      })}
    >
      <div className={styles.section_header_container}>
        <div className={styles.section_header_left}>
          <span className={styles.section_header}>{'Mints Overview'}</span>
          <div
            className={cn(styles.last_updated, {
              [styles.a_long_time_ago]:
                lastUpdated &&
                // turn color red if diff is more than 10 mins
                new Date().getTime() - lastUpdated.getTime() > 1_000 * 60 * 10,
            })}
          >
            {lastUpdated && <TimeAgo date={lastUpdated} init />}
          </div>
        </div>
        <div className={styles.section_header_right}>
          <Popover
            render={() => (
              <div className="default_popover">
                <span>
                  {'Show only mints that can be minted through CatchMint'}
                </span>
              </div>
            )}
            animation
            placement="left"
          >
            <button
              className={cn(styles.filter_button, {
                [styles.filter_active]: filter.onlyMintable,
              })}
              onClick={() => setFilter({ onlyMintable: !filter.onlyMintable })}
            >
              <span>{'Mintable'}</span>
            </button>
          </Popover>
        </div>
      </div>
      <div className={styles.time_filter_container}>{filterTimeOptions()}</div>
      <div className={styles.section_body_container}>
        {mintsOverviewData[filter.timeframe] &&
        mintsOverviewData[filter.timeframe].length > 0 ? (
          (filter.hiddenVisibility ?? 'blur') === 'blur' ? (
            renderList(
              mintsOverviewData[filter.timeframe]
                .filter((item) => {
                  if (
                    !_isEmpty(item) &&
                    filter.onlyMintable &&
                    !(
                      item.maxSupply !== item.totalSupply &&
                      item.simulationPassed
                    )
                  )
                    return false;
                  return true;
                })
                .map((item, index) =>
                  renderItem(
                    item,
                    index,
                    contractFilterList.some(
                      (hiddenContract) => hiddenContract === item.address
                    )
                  )
                )
            )
          ) : mintsOverviewData[filter.timeframe].filter((item) => {
              if (
                !_isEmpty(item) &&
                filter.onlyMintable &&
                !(item.maxSupply !== item.totalSupply && item.simulationPassed)
              )
                return false;
              return !contractFilterList.some(
                (hiddenContract) => hiddenContract === item.address
              );
            }).length > 0 ? (
            renderList(
              mintsOverviewData[filter.timeframe]
                .filter((item) => {
                  if (
                    !_isEmpty(item) &&
                    filter.onlyMintable &&
                    !(
                      item.maxSupply !== item.totalSupply &&
                      item.simulationPassed
                    )
                  )
                    return false;
                  return !contractFilterList.some(
                    (hiddenContract) => hiddenContract === item.address
                  );
                })
                .map((item, index) => renderItem(item, index))
            )
          ) : (
            <div className={styles.empty_data}>
              <span>{`You hid all contracts in this overview window`}</span>
              <button
                className={styles.btn_text_open_setting}
                onClick={handleManageHiddenClicked}
              >
                Manage hidden
              </button>
            </div>
          )
        ) : (
          <div className={styles.empty_data}>
            <span>
              {`No mints in the last ${
                MINTS_OVERVIEW_FILTER_TIME_OPTION[filter.timeframe]
              }`}
            </span>
          </div>
        )}
      </div>
    </section>
  );
}
