import getConfig from 'next/config';
import { useEffect } from 'react';

import {
  APP_SETTINGS,
  APP_VERSION,
  DEFAULT_APP_SETTINGS,
  DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT,
  DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT_ORDER,
  VERSION_SHOULD_RESET_QUICK_LINKS,
} from '~/constants';
import { AppSettings } from '~/types';
import isNewerVersion from '~/utils/isNewVersion';

import useDisplay from './useDisplay';
import useSettings from './useSettings';

const { publicRuntimeConfig } = getConfig();

export default function useCheckAppVersion() {
  const [, setDisplay] = useDisplay();
  const [, setSettings] = useSettings();

  useEffect(() => {
    // initialize user app configuration
    const appSettings = localStorage.getItem(APP_SETTINGS);

    if (appSettings) {
      const appVersion = localStorage.getItem(APP_VERSION);

      if (appVersion) {
        try {
          const prevAppSettings = JSON.parse(appSettings) as AppSettings;

          const shouldResetQuickLinks = isNewerVersion(
            appVersion,
            VERSION_SHOULD_RESET_QUICK_LINKS
          );

          if (shouldResetQuickLinks) {
            prevAppSettings.display.quickLinks =
              DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT_ORDER;
            prevAppSettings.settings.quickLinksLayout =
              DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT;
            localStorage.setItem(APP_SETTINGS, JSON.stringify(prevAppSettings));

            setDisplay(
              {
                quickLinks: prevAppSettings.display.quickLinks,
              },
              false
            );
            setSettings(prevAppSettings.settings, false);
          }
        } catch (err) {
          console.error(err);
        }
      }
    } else {
      localStorage.setItem(APP_SETTINGS, JSON.stringify(DEFAULT_APP_SETTINGS));
    }

    const currentAppVersion = publicRuntimeConfig.version;
    localStorage.setItem(APP_VERSION, currentAppVersion);
  }, []);
}
