import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import API from '~/api';
import { contractsKeys } from '~/constants/queryKeys';
import type { AvailableChain, Comment } from '~/types';
import getParamFromUrl from '~/utils/getParamFromUrl';
import showErrorToast from '~/utils/showErrorToast';

export default function useContractComments(
  chain: Lowercase<AvailableChain>,
  address: string
) {
  const [ref, inView] = useInView();
  const queryClient = useQueryClient();
  const queryKey = contractsKeys.comments(chain, address);

  const getContractCommentsWithCursor = async (cursor: string | null) => {
    const {
      data: { count, next, results },
    } = await API.getContractComments(address, cursor);

    return {
      isLast: !next,
      next: getParamFromUrl(next, 'cursor'),
      results,
      totalCount: count,
    };
  };

  const { data, isFetched, isLoading, fetchNextPage } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => getContractCommentsWithCursor(pageParam),
    {
      getNextPageParam: ({ next }) => next,
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const comments: Comment[] = useMemo(
    () => data?.pages.flatMap((page) => page.results) ?? [],
    [data?.pages]
  );

  const isItemLoaded: (index: number) => boolean = (index) =>
    data?.pages[data.pages.length - 1].isLast || index < comments.length;

  const refreshComment = (callback?: () => void) => {
    queryClient
      .invalidateQueries({ queryKey })
      .then(() => callback?.())
      .catch((err) => showErrorToast(err));
  };

  const updateComment = (modifiedData: Partial<Comment>) => {
    queryClient.setQueryData<
      | InfiniteData<{
          results: Comment[];
          next: string | null;
          isLast: boolean;
        }>
      | undefined
    >(queryKey, (prev) => {
      if (prev) {
        prev.pages = prev.pages.map((page) => {
          page.results = page.results.map((result) => {
            if (result.uuid === modifiedData.uuid) {
              result = {
                ...result,
                ...modifiedData,
              };
            }
            return result;
          });
          return page;
        });
      }
      return prev;
    });
  };

  useEffect(() => {
    if (!data) return;

    const pageLastIndex = data.pages.length - 1;
    const isLast = data.pages[pageLastIndex].isLast;

    if (!isLast && inView) fetchNextPage();
  }, [data, inView]);

  return {
    comments,
    isFetched,
    isItemLoaded,
    isLoading,
    itemCount: data?.pages[data.pages.length - 1].isLast
      ? comments.length
      : comments.length + 1,
    ref,
    refreshComment,
    totalCount: data?.pages[0]?.totalCount ?? 0,
    updateComment,
  };
}
