import cn from 'classnames';
import type { ReactNode } from 'react';

import customStyles from './Div/DivComponent.module.scss';
import styles from './Table/TableComponent.module.scss';

interface TableProps {
  children: ReactNode;
  className?: string;
  maxHeight?: string | number;
  summary?: string;
  tableClassName?: string;
  useCustomContainer?: boolean;
  useCustomTable?: boolean;
}

export default function Table({
  children,
  className,
  maxHeight = 'none',
  summary = '',
  tableClassName,
  useCustomContainer = false,
  useCustomTable = false,
}: TableProps) {
  return (
    <>
      {!useCustomTable ? (
        !useCustomContainer ? (
          <div
            className={cn(styles.table_container, className)}
            style={{
              maxHeight,
            }}
          >
            <table
              className={cn(styles.table, tableClassName)}
              summary={summary}
            >
              {children}
            </table>
          </div>
        ) : (
          <table className={cn(styles.table, tableClassName)} summary={summary}>
            {children}
          </table>
        )
      ) : (
        <div
          className={cn(customStyles.div_view_container, className)}
          style={{
            maxHeight,
          }}
        >
          {children}
        </div>
      )}
    </>
  );
}

export { default as TableBody } from './Table/TableBody';
export { default as TableCell } from './Table/TableCell';
export { default as TableHeader } from './Table/TableHeader';
export { default as TableNoData } from './Table/TableNoData';
export { default as TableRow } from './Table/TableRow';

export { default as CustomColumn } from './Div/CustomColumn';
export { default as CustomHeader } from './Div/CustomHeader';
export { default as CustomList } from './Div/CustomList';
