import { SelectButton, ToggleSwitch } from '~/components';

import styles from './NotificationTriggerSetting.module.scss';

interface NotificationTriggerProps {
  checked: boolean;
  description: string;
  name: string;
  onToggle: () => void;
  selectedValue: number | boolean;
  onOptionChange?: (value: number | boolean) => void;
  options?: [string, number | boolean][];
  optionPrefix?: string;
}

export default function NotificationTrigger({
  checked,
  description,
  name,
  onOptionChange,
  onToggle,
  optionPrefix,
  options,
  selectedValue,
}: NotificationTriggerProps) {
  return (
    <div className={styles.trigger_container}>
      <div className={styles.trigger_info_container}>
        <div className={styles.btn_toggle_container}>
          <ToggleSwitch checked={checked} name={name} onChange={onToggle} />
        </div>
        <div className={styles.trigger_info}>
          <span className={styles.trigger_name}>{name}</span>
          <span className={styles.trigger_description}>{description}</span>
        </div>
      </div>
      {options && (
        <div className={styles.trigger_option_container}>
          <SelectButton.Group
            key={`${name} ${selectedValue}`}
            flat
            items={options.map(([key, value]) => ({
              key,
              label: `${key}${optionPrefix ?? ''}`,
              selected: value === selectedValue,
              value,
            }))}
            onItemClicked={(_, clickedItem) => {
              if (!clickedItem) return;
              onOptionChange?.(clickedItem.value);
            }}
          />
        </div>
      )}
    </div>
  );
}
