import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import config from '~/config';

export const axiosConfig: AxiosRequestConfig = {
  baseURL: config.API_HOST,
  withCredentials: true,
  timeout: 9_000,
};

let authInterceptorID: number;
let requestQueue: any[] = [];

const axiosInstance = axios.create(axiosConfig);
const retryInstance = axios.create(axiosConfig);

export function activeAuthenticateAPI(token: string) {
  authInterceptorID = axiosInstance.interceptors.request.use((request) => {
    request.headers['Authorization'] = `Bearer ${token}`;
    return request;
  });
}

export function inactiveAuthenticateAPI() {
  axiosInstance.interceptors.request.eject(authInterceptorID);
  delete axiosInstance.defaults.headers.common['Authorization'];
}

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    const { config, response } = error;

    if (config?.url === '/token/refresh/') {
      if (response?.status === 401) {
        inactiveAuthenticateAPI();
      }
      return Promise.reject(error);
    }

    if (response?.status !== 401) {
      return Promise.reject(error);
    }

    return new Promise(async (resolve, reject) => {
      requestQueue.push({ request: config, resolve, reject });

      if (requestQueue.length === 1) {
        try {
          // clear last interceptor before token is changed
          inactiveAuthenticateAPI();
          const {
            data: { access },
          } = await axiosInstance.put('/token/refresh/', {});
          activeAuthenticateAPI(access);
          const size = requestQueue.length;
          for (let i = 0; i < size; i++) {
            const item = requestQueue[i];
            item.request.headers['Authorization'] = `Bearer ${access}`;
            item.resolve(retryInstance(item.request));
          }
        } catch (err) {
          const size = requestQueue.length;
          for (let i = 0; i < size; i++) {
            const item = requestQueue[i];
            item.reject(err);
          }
        } finally {
          requestQueue = [];
        }
      }
    });
  }
);

export default axiosInstance;
