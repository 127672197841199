import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import API from '~/api';
import { contractsKeys } from '~/constants/queryKeys';
import useStateCallback from '~/hooks/useStateCallback';
import type { MintingAggregates } from '~/types';

import useContractFilterList from './useContractFilterList';

interface MintingAggregatesQueryProps {
  address: string;
  ids: number[];
}

export default function useMintingAggregates({
  address,
  ids,
}: MintingAggregatesQueryProps) {
  const queryKey = contractsKeys.notableGroupsMintingAggregates(
    'ethereum',
    address,
    ids
  );
  const { contractFilterList } = useContractFilterList();
  const isHidden = contractFilterList.some(
    (hiddenContract) => hiddenContract === address
  );
  const [isFirstFetched, setIsFirstFetched] = useState(true);
  const [mintingAggregates, setMintingAggregates] = useStateCallback<
    MintingAggregates | undefined
  >(undefined);
  const result = useQuery(
    queryKey,
    () => API.getMintingAggregates(address, ids.toString()),
    {
      enabled: !!address && ids.length > 0 && !isHidden,
    }
  );

  useEffect(() => {
    // initialize if the address is changed
    setIsFirstFetched(true);
    setMintingAggregates(undefined);
  }, [address]);

  useEffect(() => {
    if (result.data) {
      setMintingAggregates(result.data.data, () => {
        setIsFirstFetched(false);
      });
    }
  }, [result.data]);

  useDeepCompareEffect(() => {
    if (ids.length === 0) {
      setMintingAggregates(undefined);
    }
  }, [ids]);

  return {
    ...result,
    isFirstFetched,
    mintingAggregates,
  };
}
