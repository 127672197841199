import cn from 'classnames';
import type { Dispatch, SetStateAction } from 'react';

import type { EditableContractInfo } from '~/types';

import Information from './Information';
import styles from './Overview.module.scss';
import RelatedLinks from './RelatedLinks';

interface OverviewProps {
  onChange: (changedData: Partial<EditableContractInfo>) => void;
  setFile: Dispatch<SetStateAction<File | undefined>>;
  show: boolean;
  contractInfo?: EditableContractInfo;
  file?: File;
}

export default function Overview({
  contractInfo,
  file,
  onChange,
  setFile,
  show,
}: OverviewProps) {
  const {
    discordGuildName = '',
    description = '',
    discordUrl = '',
    imageUrl = '',
    name = '',
    // primaryColor = null,
    twitterUrl = '',
    websiteUrl = '',
  } = {
    ...contractInfo,
  };
  return (
    <div className={cn(styles.container, { [styles.hidden]: !show })}>
      <Information
        description={description}
        file={file}
        imageUrl={imageUrl}
        name={name}
        onChange={onChange}
        setFile={setFile}
      />
      <RelatedLinks
        discordGuildName={discordGuildName}
        discordUrl={discordUrl}
        onChange={onChange}
        twitterUrl={twitterUrl}
        websiteUrl={websiteUrl}
      />
    </div>
  );
}
