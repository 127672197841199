import { parseUnits } from '@ethersproject/units';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import API from '~/api';
import {
  CHANNEL_GROUP_ACTIVITY_MINTS,
  EVENT_DATA,
  LIVE_MINTS_SHOWING_GAP_TIME,
  PLAY_STATUS,
} from '~/constants';
import { timeseriesKeys } from '~/constants/queryKeys';
import { useSocket } from '~/contexts/SocketContext';
import useNotableGroupActivityFilter from '~/hooks/useNotableGroupActivityFilter';
import usePageVisibility from '~/hooks/usePageVisibility';
import { usePrevious } from '~/hooks/usePrevious';
import { LiveMintsService } from '~/services';
import { groupActivitySelectedNotableGroupIdsState } from '~/store/app';
import type {
  NotableGroupActivity,
  NotableGroupActivityArgs,
  NotableGroupActivityFilter,
  PlayStatus,
} from '~/types';
import getParamFromUrl from '~/utils/getParamFromUrl';

const OVERSCAN = 8;
const groupActivityService = new LiveMintsService<NotableGroupActivity>();

export default function useNotableGroupActivity() {
  const [filter] = useNotableGroupActivityFilter();
  const isPageVisible = usePageVisibility();
  const queryClient = useQueryClient();
  const socket = useSocket();
  const selectedGroupIds = useRecoilValue(
    groupActivitySelectedNotableGroupIdsState
  );
  const dataFromSocketDuringPaused = useRef<NotableGroupActivity[]>([]);
  const lastSeenGroupActivityTime = useRef<Date | null>(null);
  const [clickedGroupActivity, setClickedGroupActivity] =
    useState<NotableGroupActivity | null>(null);
  const [dataFromSocket, setDataFromSocket] = useState<NotableGroupActivity[]>(
    []
  );
  const [lastGroupActivity, setLastGroupActivity] =
    useState<NotableGroupActivity | null>(null);
  const [playStatus, setPlayStatus] = useState<PlayStatus>(PLAY_STATUS.STOPPED);
  const [queryParams, setQueryParams] =
    useState<NotableGroupActivityArgs | null>(null);

  const onPause = useCallback(() => {
    setPlayStatus(PLAY_STATUS.PAUSED);
  }, []);

  const onPlay = useCallback(() => {
    setPlayStatus(PLAY_STATUS.PLAYED);
  }, []);

  /*
   * Group Activity filter rules
   * - if onlyFree is selected, priceRange is ignored.
   * - only show transactions from selected notable groups
   */
  const filterCondition: (tx: NotableGroupActivity) => boolean = useCallback(
    (tx) => {
      const excludeAirdropNotSelected = !filter.excludeAirdrop;
      const excludeAirdropSelected = filter.excludeAirdrop && !tx.isAirdrop;

      const includesNotableGroupSelected =
        selectedGroupIds.length === 0 ||
        tx.notableGroupIds.some((notableGroupId) =>
          selectedGroupIds.includes(notableGroupId)
        );

      const mintableNotSelected = !filter.onlyMintable;
      const mintableSelected =
        filter.onlyMintable &&
        tx.maxSupply !== tx.totalSupply &&
        tx.simulationPassed;

      const onlyFreeNotSelected =
        !filter.onlyFree &&
        +tx.value >= filter.priceRangeMin &&
        +tx.value <= filter.priceRangeMax;
      const onlyFreeSelected = filter.onlyFree && tx.value === '0';

      const onlyVerifiedNotSelected = !filter.onlyVerified;
      const onlyVerifiedSelected = filter.onlyVerified && !!tx.functionName;

      return (
        includesNotableGroupSelected &&
        (excludeAirdropNotSelected || excludeAirdropSelected) &&
        (mintableNotSelected || mintableSelected) &&
        (onlyFreeNotSelected || onlyFreeSelected) &&
        (onlyVerifiedNotSelected || onlyVerifiedSelected)
      );
    },
    [filter, selectedGroupIds]
  );

  const queryKey = queryParams
    ? timeseriesKeys.notableGroupsMints(queryParams)
    : [];
  const prevQueryKey = usePrevious(queryKey);

  const getNotableGroupsMintsWithCursor = async (cursor: string | null) => {
    if (!queryParams)
      return {
        isLast: true,
        next: null,
        results: [],
      };

    const {
      data: { next, results },
    } = await API.getNotableGroupsMints(queryParams, cursor);

    return {
      isLast: !next,
      next: getParamFromUrl(next, 'cursor'),
      results,
    };
  };

  const { data, hasNextPage, isFetching, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery(
      queryKey,
      ({ pageParam }) => getNotableGroupsMintsWithCursor(pageParam),
      {
        enabled: !!queryParams && queryKey.length > 0,
        getNextPageParam: ({ next }) => next,
        keepPreviousData: true,
        staleTime: 0,
      }
    );

  const isFirstFetching = isFetching && !isFetchingNextPage;

  const dataFromApi = useMemo(
    () => (data ? data.pages.flatMap((page) => page.results) : []),
    [data]
  );

  const groupActivities = useMemo(
    () => [...dataFromSocket, ...dataFromApi],
    [dataFromApi, dataFromSocket]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const flipKey =
    groupActivities.length > 0
      ? groupActivities[0].transactionHash + groupActivities[0].timestamp
      : '';
  const virtualizer = useVirtualizer({
    count: hasNextPage ? groupActivities.length + 1 : groupActivities.length,
    estimateSize: () => 58,
    getScrollElement: () => containerRef.current,
    overscan: OVERSCAN,
  });
  const totalSize = virtualizer.getTotalSize();
  const virtualItems = virtualizer.getVirtualItems();
  const [lastVirtualItem] = [...virtualItems].reverse();

  const onClickGroupActivity = useCallback(
    (groupActivity: NotableGroupActivity) => {
      const isMintable =
        groupActivity.simulationPassed &&
        groupActivity.maxSupply !== groupActivity.totalSupply;
      if (
        !isMintable ||
        clickedGroupActivity?.transactionHash === groupActivity.transactionHash
      ) {
        return setClickedGroupActivity(null);
      }
      return setClickedGroupActivity(groupActivity);
    },
    [clickedGroupActivity]
  );

  const resetCachingData = useCallback(() => {
    dataFromSocketDuringPaused.current = [];
    lastSeenGroupActivityTime.current = null;
    setClickedGroupActivity(null);
    setDataFromSocket([]);
    setLastGroupActivity(null);
  }, []);

  useEffect(() => {
    groupActivityService.loadTxs.subscribe(
      (groupActivity: NotableGroupActivity) => {
        const delayed =
          new Date().getTime() -
          new Date(groupActivity.timestamp).getTime() * 1_000;
        if (delayed < LIVE_MINTS_SHOWING_GAP_TIME) {
          setLastGroupActivity(groupActivity);
        }
      }
    );

    return () => {
      groupActivityService.reset();
    };
  }, []);

  // set last seen group activity time for bound animation
  useEffect(() => {
    if (virtualItems.length > 0 && groupActivities.length > 0) {
      const visibleFirstItemIndex = Math.max(
        virtualItems[0].index - OVERSCAN,
        0
      );
      const visibleFirstItemDate = new Date(
        groupActivities[visibleFirstItemIndex].timestamp
      );

      if (!!lastSeenGroupActivityTime.current) {
        if (lastSeenGroupActivityTime.current < visibleFirstItemDate) {
          lastSeenGroupActivityTime.current = visibleFirstItemDate;
        }
      } else {
        lastSeenGroupActivityTime.current = new Date(visibleFirstItemDate);
      }
    }
  }, [groupActivities, virtualItems]);

  useEffect(() => {
    const debouncedSetQueryParams = _debounce(
      (filter: NotableGroupActivityFilter, selectedGroupIds: number[]) => {
        setQueryParams({
          excludeAirdrop: filter.excludeAirdrop,
          ids: selectedGroupIds,
          maxValue: filter.onlyFree
            ? '0'
            : parseUnits(filter.priceRangeMax.toString()).toString(),
          minValue: filter.onlyFree
            ? '0'
            : parseUnits(filter.priceRangeMin.toString()).toString(),
          onlyMintable: filter.onlyMintable,
          onlyVerified: filter.onlyVerified,
        });
        containerRef.current?.scrollTo(0, 0);
      },
      500
    );
    debouncedSetQueryParams(filter, selectedGroupIds);
    return () => {
      debouncedSetQueryParams.cancel();
    };
  }, [filter, selectedGroupIds]);

  // initialize received data from socket when filter changed or selectedGroupIds changed
  useEffect(() => {
    if (JSON.stringify(prevQueryKey) !== JSON.stringify(queryKey)) {
      if (!!prevQueryKey && prevQueryKey.length > 0) {
        queryClient.removeQueries(prevQueryKey);
      }
      resetCachingData();
    }
  }, [prevQueryKey, queryKey, resetCachingData]);

  useEffect(() => {
    if (isPageVisible) {
      socket
        ?.subscribe(CHANNEL_GROUP_ACTIVITY_MINTS)
        ?.bind(EVENT_DATA, (data: NotableGroupActivity) =>
          groupActivityService.set(data)
        );
      setPlayStatus(PLAY_STATUS.LOADING);

      return () => {
        socket?.unsubscribe(CHANNEL_GROUP_ACTIVITY_MINTS);
        setPlayStatus(PLAY_STATUS.STOPPED);
      };
    }
  }, [isPageVisible, socket]);

  useEffect(() => {
    if (
      playStatus === PLAY_STATUS.PLAYED &&
      dataFromSocketDuringPaused.current.length > 0
    ) {
      setDataFromSocket((prev) => {
        const filteredPrev = [
          ...dataFromSocketDuringPaused.current,
          ...prev.filter(
            (prevData) =>
              !dataFromSocketDuringPaused.current.some(
                (data) => prevData.transactionHash === data.transactionHash
              )
          ),
        ];
        dataFromSocketDuringPaused.current = [];

        return filteredPrev;
      });
      setLastGroupActivity(null);
    }
  }, [playStatus]);

  useEffect(() => {
    if (lastGroupActivity && playStatus === PLAY_STATUS.LOADING) {
      setPlayStatus(PLAY_STATUS.PLAYED);
    }
  }, [lastGroupActivity, playStatus]);

  useEffect(() => {
    if (lastGroupActivity && filterCondition(lastGroupActivity)) {
      if (playStatus === PLAY_STATUS.PLAYED) {
        setDataFromSocket((prev) => {
          // change confirmations if transaction is already in the list
          const transaction = prev.find(
            (tx) => tx.transactionHash === lastGroupActivity.transactionHash
          );
          if (!!transaction) {
            if (transaction.confirmations !== lastGroupActivity.confirmations) {
              return prev.map((txData) =>
                txData.transactionHash === lastGroupActivity.transactionHash
                  ? lastGroupActivity
                  : txData
              );
            }
            return prev;
          } else {
            return [lastGroupActivity, ...prev];
          }
        });
      }
      if (playStatus === PLAY_STATUS.PAUSED) {
        // caching data when paused
        const duplicatedIndex = dataFromSocketDuringPaused.current.findIndex(
          (data) => data.transactionHash === lastGroupActivity.transactionHash
        );
        if (duplicatedIndex > -1) {
          dataFromSocketDuringPaused.current.splice(duplicatedIndex);
        }
        dataFromSocketDuringPaused.current.unshift(lastGroupActivity);
      }
      setLastGroupActivity(null);
    }
  }, [filterCondition, lastGroupActivity, playStatus]);

  useEffect(() => {
    if (!lastVirtualItem || lastVirtualItem.index === 0) return;
    if (
      lastVirtualItem.index >= groupActivities.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    fetchNextPage,
    groupActivities.length,
    hasNextPage,
    isFetchingNextPage,
    lastVirtualItem,
  ]);

  return {
    clickedGroupActivity,
    containerRef,
    flipKey,
    groupActivities,
    isFirstFetching,
    lastSeenGroupActivityTime: lastSeenGroupActivityTime.current,
    onClickGroupActivity,
    onPause,
    onPlay,
    playStatus,
    totalSize,
    virtualItems,
  };
}
