import type { MouseEvent } from 'react';
import ReactMarkdown from 'react-markdown';
import type { HeadingProps } from 'react-markdown/lib/ast-to-react';
import remarkGfm from 'remark-gfm';

import { REDIRECT_CLICKED_TYPE } from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import type { AvailableChain, RedirectClickedLocation } from '~/types';
import isValidUrl from '~/utils/isValidUrl';
import setUtmSource from '~/utils/setUtmSource';

import styles from './Markdown.module.scss';

interface MarkdownProps {
  children: string;
  chain?: AvailableChain;
  location?: RedirectClickedLocation;
}

export default function Markdown({ chain, children, location }: MarkdownProps) {
  const analytics = useAnalyticsContext();

  const setAnalyticsEvent = (
    e: MouseEvent<HTMLAnchorElement>,
    url?: string
  ) => {
    e.stopPropagation();
    if (!!url && !!location) {
      analytics.track(REDIRECT_CLICKED, {
        chain,
        location,
        type: REDIRECT_CLICKED_TYPE.OTHER,
        url,
      });
    }
  };

  const renderMarkdownHeadingComponent = (props: HeadingProps) => (
    <span className={styles.head_markdown}>{props.children}</span>
  );

  return (
    <ReactMarkdown
      components={{
        a: ({ children, href }) => {
          if (href && isValidUrl(href)) {
            return (
              <a
                className={styles.link}
                href={setUtmSource(href)}
                onClick={(e) => setAnalyticsEvent(e, href)}
                rel="noopener noreferrer"
                role="link"
                target="_blank"
              >
                {children}
              </a>
            );
          }
          return <>{children}</>;
        },
        h1: renderMarkdownHeadingComponent,
        h2: renderMarkdownHeadingComponent,
        h3: renderMarkdownHeadingComponent,
        h4: renderMarkdownHeadingComponent,
        h5: renderMarkdownHeadingComponent,
        h6: renderMarkdownHeadingComponent,
        p: ({ children }) => <p className={styles.paragraph}>{children}</p>,
        img: () => null,
      }}
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </ReactMarkdown>
  );
}
