import { useEffect } from 'react';
import type { SendTransactionResult } from 'wagmi/actions';

import { Loading } from '~/components';
import useWriteMint from '~/hooks/useWriteMint';
import styleVariables from '~/styles/variables.module.scss';
import type { TxGasOption } from '~/types';
import showErrorToast from '~/utils/showErrorToast';

import styles from './WriteButton.module.scss';

interface WriteButtonProps {
  argsOrder: string[];
  isAuthenticated: boolean;
  txGasOption: TxGasOption | null;
  writeMintOption: any;
  onSubmit: (sendTx: () => Promise<SendTransactionResult>) => void;
}

export default function WriteButton({
  argsOrder,
  isAuthenticated,
  txGasOption,
  writeMintOption,
  onSubmit,
}: WriteButtonProps) {
  const { isLoading, isPrepareError, prepareError, writeAsync } = useWriteMint({
    ...writeMintOption,
    argsOrder,
    txGasOption,
  });
  const isDisabled = !writeAsync;

  const handleSubmit = () => {
    if (isLoading || !isAuthenticated || isDisabled) return;
    onSubmit(writeAsync);
  };

  useEffect(() => {
    if (!txGasOption && isPrepareError) {
      showErrorToast(prepareError);
    }
  }, [isPrepareError, prepareError, txGasOption]);

  if (!txGasOption) {
    return (
      <button
        className={styles.btn}
        disabled={isDisabled}
        onClick={handleSubmit}
      >
        {isLoading ? (
          <Loading color={styleVariables.gray50} size={24} />
        ) : (
          <>
            <span className={styles.confidence}>
              {`Use wallet default gas setting`}
            </span>
            <strong className={styles.text_write}>{'Write'}</strong>
          </>
        )}
      </button>
    );
  }

  const { confidence, maxFee: fee, maxPrio } = txGasOption;
  const priorityFee = maxPrio.toFixed(2);
  const maxFee = fee.toFixed(2);

  return (
    <button className={styles.btn} disabled={isDisabled} onClick={handleSubmit}>
      {isLoading ? (
        <div className={styles.option_loading}>
          <Loading color={styleVariables.gray50} size={24} />
        </div>
      ) : (
        <>
          <span className={styles.confidence}>{`Target: ${confidence}`}</span>
          <span className={styles.label_option}>
            <span className={styles.label}>Priority Fee</span>
            <div className={styles.value_container}>
              <strong className={styles.value}>{priorityFee}</strong>
              <span className={styles.unit}>Gwei</span>
            </div>
          </span>
          <span className={styles.label_option}>
            <span className={styles.label}>Max Fee</span>
            <div className={styles.value_container}>
              <strong className={styles.value}>{maxFee}</strong>
              <span className={styles.unit}>Gwei</span>
            </div>
          </span>
        </>
      )}
    </button>
  );
}
