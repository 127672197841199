import { isAddress } from '@ethersproject/address';
import { Context, Plugin } from '@segment/analytics-next';
import getConfig from 'next/config';

import { APP_USER_ID } from '~/constants';

const { publicRuntimeConfig } = getConfig();

const AppFormat: () => Plugin = () => {
  const format = (ctx: Context) => {
    ctx.event.properties = {
      ...ctx.event.properties,
      version: publicRuntimeConfig.version,
    };
    ctx.event.context = {
      ...ctx.event.context,
      version: publicRuntimeConfig.version,
    };
    const userId = localStorage.getItem(APP_USER_ID);
    if (userId && isAddress(userId)) ctx.event.userId = userId;
    return ctx;
  };

  return {
    name: 'App Format',
    type: 'enrichment',
    version: '1.1.0',

    isLoaded: () => true,
    load: () => Promise.resolve(),
    track: format,
    identify: format,
    page: format,
    group: format,
    alias: format,
    screen: format,
  };
};

export default AppFormat();
