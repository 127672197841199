import type { MouseEvent } from 'react';

import { IcnGoogleLens, IcnSmallChainEthereum } from '~/assets';
import { ImageSource, Popover, VideoSource } from '~/components';
import config from '~/config';
import { AVAILABLE_VIDEO_EXTENSION } from '~/constants';
import type { AvailableVideoExtension, ContractArt } from '~/types';
import formatEthPrice from '~/utils/formatEthPrice';
import formatTimeAgo from '~/utils/formatTimeAgo';
import getExtension from '~/utils/getExtension';
import noExponents from '~/utils/noExponents';

import styles from './ContractArtItem.module.scss';

interface ContractArtItemProps {
  address: string;
  art: ContractArt;
  onClick: (url: string, position: number) => void;
  onClickGoogleLens: (
    e: MouseEvent<HTMLButtonElement>,
    url: string,
    position: number
  ) => void;
  position: number;
}

export default function ContractArtItem({
  address,
  art: { imageUrl, name, timestamp, tokenId, value },
  onClick: onClickItem,
  onClickGoogleLens,
  position,
}: ContractArtItemProps) {
  const diff = Date.now() - new Date(timestamp).getTime();
  const extension = getExtension(imageUrl);
  const isVideo = Object.values(AVAILABLE_VIDEO_EXTENSION).includes(
    extension as AvailableVideoExtension
  );
  const link = tokenId
    ? `https://opensea.io/assets/ethereum/${address}/${tokenId}`
    : undefined;

  const onClick = link ? () => onClickItem(link, position) : undefined;
  const onClickLens = imageUrl
    ? (e: MouseEvent<HTMLButtonElement>) =>
        onClickGoogleLens(e, `${config.ASSETS_HOST}/${imageUrl}`, position)
    : undefined;

  return (
    <div className={styles.container}>
      <a
        className={styles.art_item}
        aria-label={`${address} ${tokenId}`}
        href={link}
        onClick={onClick}
        role="link"
        rel="nofollow noreferrer noopener"
        target="_blank"
      >
        <div className={styles.art_container}>
          {isVideo ? (
            <VideoSource
              extension={extension}
              height="100%"
              width="auto"
              url={imageUrl ? `${config.ASSETS_HOST}/${imageUrl}` : ''}
            />
          ) : (
            <ImageSource
              alt={name}
              height="100%"
              width={250}
              url={imageUrl ? `${config.ASSETS_HOST}/${imageUrl}` : ''}
            />
          )}
        </div>
        <div className={styles.art_info}>
          {name && <span className={styles.name}>{name}</span>}
          {tokenId && <span className={styles.token_id}>{`#${tokenId}`}</span>}
        </div>
        {!!imageUrl && (
          <>
            <div className={styles.btn_lens_background} />
            <Popover
              render={() => (
                <div className="default_popover">
                  <span>{'Google Lens'}</span>
                </div>
              )}
              animation
              placement="top"
            >
              <button className={styles.btn_lens} onClick={onClickLens}>
                <IcnGoogleLens />
              </button>
            </Popover>
          </>
        )}
      </a>
      <div className={styles.etc_info}>
        <span className={styles.value}>
          {value.length > 6 && `≈`}
          {value !== '0' && <IcnSmallChainEthereum />}
          {value === '0' ? (
            'Free'
          ) : value.length > 6 ? (
            <Popover
              render={() => (
                <div className="price_popover_container">
                  <span className="price_popover_content">
                    <IcnSmallChainEthereum />
                    <span className="price_content">
                      {noExponents(+(value ?? '0'))}
                    </span>
                  </span>
                </div>
              )}
              delay={{ open: 1, close: 1 }}
              placement="top"
            >
              <p>{formatEthPrice(value)}</p>
            </Popover>
          ) : (
            value
          )}
        </span>
        <span className={styles.time}>{formatTimeAgo(diff)}</span>
      </div>
    </div>
  );
}
