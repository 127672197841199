import { MouseEvent, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useAccount } from 'wagmi';

import { IcnArrowNext } from '~/assets';
import { SelectButton } from '~/components';
import { useAuth } from '~/contexts/AuthContext';
import useLiveMintsFilter from '~/hooks/useLiveMintsFilter';
import useMintsOverviewFilter from '~/hooks/useMintsOverviewFilter';
import { appConnectModalState } from '~/store/app';
import type { HiddenVisibility } from '~/types';

import styles from './HiddenContractsSection.module.scss';
import commonStyles from '../SettingsModal.module.scss';

interface HiddenContractsSectionProps {
  pushTo: (index: number) => void;
}

export default function HiddenContractsSection({
  pushTo,
}: HiddenContractsSectionProps) {
  const { address: connectedAddress } = useAccount();
  const { signIn, user } = useAuth();
  const [liveMintsFilter, setLiveMintsFilter] = useLiveMintsFilter();
  const [mintsOverviewFilter, setMintsOverviewFilter] =
    useMintsOverviewFilter();
  const liveMintsHiddenVisibility = liveMintsFilter.hiddenVisibility;
  const mintsOverviewVisibility = mintsOverviewFilter.hiddenVisibility;
  const setAppConnectModal = useSetRecoilState(appConnectModalState);

  const handleHiddenContractsView = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (!connectedAddress) {
        return setAppConnectModal({ isOpened: true });
      }

      if (!user) {
        return signIn();
      }

      return pushTo(7);
    },
    [connectedAddress, signIn, user]
  );

  return (
    <section className={commonStyles.section}>
      <div className={commonStyles.section_container}>
        <div className={commonStyles.section_title_container}>
          <span className={commonStyles.section_title}>{'Visibility'}</span>
          <p className={commonStyles.section_description}>
            {
              'Hidden contracts can either be blurred out or removed completely from the feed.'
            }
          </p>
        </div>
        <div className={styles.options_container}>
          <div className={styles.hide_option}>
            <span className={styles.option_title}>Live Mints</span>
            <SelectButton.Group<HiddenVisibility>
              items={[
                {
                  key: 'live_mints_visibility_option_blur',
                  label: <span className={styles.option_icon}>Blur</span>,
                  selected: liveMintsHiddenVisibility === 'blur',
                  value: 'blur',
                },
                {
                  key: 'live_mints_visibility_option_remove',
                  label: <span className={styles.option_icon}>Remove</span>,
                  selected: liveMintsHiddenVisibility === 'remove',
                  value: 'remove',
                },
              ]}
              onItemClicked={(_, clickedItem) => {
                if (clickedItem) {
                  setLiveMintsFilter({
                    hiddenVisibility: clickedItem.value,
                  });
                }
              }}
            />
          </div>
          <div className={styles.hide_option}>
            <span className={styles.option_title}>Mints Overview</span>
            <SelectButton.Group<HiddenVisibility>
              items={[
                {
                  key: 'mints_overview_visibility_option_blur',
                  label: <span className={styles.option_icon}>Blur</span>,
                  selected: mintsOverviewVisibility === 'blur',
                  value: 'blur',
                },
                {
                  key: 'mints_overview_visibility_option_remove',
                  label: <span className={styles.option_icon}>Remove</span>,
                  selected: mintsOverviewVisibility === 'remove',
                  value: 'remove',
                },
              ]}
              onItemClicked={(_, clickedItem) => {
                if (clickedItem) {
                  setMintsOverviewFilter({
                    hiddenVisibility: clickedItem.value,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className={commonStyles.section_container}>
        <div className={commonStyles.section_header}>
          <div className={commonStyles.section_title_container}>
            <span className={commonStyles.section_title}>
              {'Hidden Contracts'}
            </span>
            <p className={commonStyles.section_description}>
              {
                'Here are the contracts you have hidden. You can add or remove them from this list.'
              }
            </p>
          </div>
          <button
            className={commonStyles.btn_next}
            onClick={handleHiddenContractsView}
          >
            <span>{'View'}</span>
            <IcnArrowNext />
          </button>
        </div>
      </div>
    </section>
  );
}
