import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { NotificationsProvider } from '~/contexts/NotificationsContext';
import { appNotificationModalState } from '~/store/app';

import NotificationHistory from './NotificationHistory';
import styles from './NotificationPopup.module.scss';

export default function NotificationPopup() {
  const setAppNotification = useSetRecoilState(appNotificationModalState);

  const onClose = () => {
    setAppNotification({
      isOpened: false,
      isUnread: false,
    });
  };

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      e.stopPropagation();
      const target = e.target as HTMLElement;
      const classList = [...target.classList];
      const len = classList.length;
      for (let i = 0; i < len; i++) {
        const className = classList[i].toLowerCase();
        if (!className.includes('notification')) {
          onClose();
          break;
        }
      }
    };
    setAppNotification((prev) => ({ ...prev, isUnread: false }));
    document.addEventListener('click', handleMouseDown);

    return () => {
      document.removeEventListener('click', handleMouseDown);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        <div className={styles.header}>Notifications</div>
        <NotificationsProvider>
          <NotificationHistory onClose={onClose} />
        </NotificationsProvider>
      </div>
    </div>
  );
}
