import styles from './TableData.module.scss';

interface TableNoDataProps {
  colSpan?: number;
  rowSpan?: number;
  text?: string;
}

export default function TableNoData({
  colSpan = 1,
  rowSpan = 1,
  text = 'No Data',
}: TableNoDataProps) {
  return (
    <tr className={styles.no_data}>
      <td colSpan={colSpan} rowSpan={rowSpan}>
        {text}
      </td>
    </tr>
  );
}
