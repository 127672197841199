import {
  IcnBlur,
  IcnCoinbase,
  IcnDiscord,
  IcnEtherscan,
  IcnLooksRare,
  IcnMagically,
  IcnMagicEden,
  IcnNFTFlip,
  IcnNftNerds,
  IcnOpensea,
  IcnOpenseaPro,
  IcnSudoswap,
  IcnUniswap,
  IcnWebsite,
  IcnX,
  IcnX2Y2,
} from '~/assets';
import { CONTRACT_QUICK_LINK, REDIRECT_CLICKED_TYPE } from '~/constants';
import type { ContractQuickLink, RedirectClickedType } from '~/types';

interface UseQuicklinksProps {
  platform: ContractQuickLink;
}

export default function useQuicklinks({ platform }: UseQuicklinksProps) {
  const icon = {
    [CONTRACT_QUICK_LINK.BLUR]: <IcnBlur />,
    [CONTRACT_QUICK_LINK.COINBASE]: <IcnCoinbase />,
    [CONTRACT_QUICK_LINK.DISCORD]: <IcnDiscord />,
    [CONTRACT_QUICK_LINK.ETHERSCAN]: <IcnEtherscan />,
    [CONTRACT_QUICK_LINK.LOOKSRARE]: <IcnLooksRare />,
    [CONTRACT_QUICK_LINK.MAGIC_EDEN]: <IcnMagicEden />,
    [CONTRACT_QUICK_LINK.MAGICALLY]: <IcnMagically />,
    [CONTRACT_QUICK_LINK.NFTFLIP]: <IcnNFTFlip />,
    [CONTRACT_QUICK_LINK.NFTNERDS]: <IcnNftNerds />,
    [CONTRACT_QUICK_LINK.OPENSEA]: <IcnOpensea />,
    [CONTRACT_QUICK_LINK.OPENSEA_PRO]: <IcnOpenseaPro />,
    [CONTRACT_QUICK_LINK.SUDOSWAP]: <IcnSudoswap />,
    [CONTRACT_QUICK_LINK.UNISWAP]: <IcnUniswap />,
    [CONTRACT_QUICK_LINK.WEBSITE]: <IcnWebsite />,
    [CONTRACT_QUICK_LINK.X]: <IcnX />,
    [CONTRACT_QUICK_LINK.X2Y2]: <IcnX2Y2 />,
  }[platform];

  const type: RedirectClickedType = {
    [CONTRACT_QUICK_LINK.BLUR]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
    [CONTRACT_QUICK_LINK.COINBASE]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
    [CONTRACT_QUICK_LINK.DISCORD]: REDIRECT_CLICKED_TYPE.OTHER,
    [CONTRACT_QUICK_LINK.ETHERSCAN]: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
    [CONTRACT_QUICK_LINK.LOOKSRARE]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
    [CONTRACT_QUICK_LINK.MAGIC_EDEN]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
    [CONTRACT_QUICK_LINK.MAGICALLY]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
    [CONTRACT_QUICK_LINK.NFTFLIP]: REDIRECT_CLICKED_TYPE.TOOL,
    [CONTRACT_QUICK_LINK.NFTNERDS]: REDIRECT_CLICKED_TYPE.TOOL,
    [CONTRACT_QUICK_LINK.OPENSEA]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
    [CONTRACT_QUICK_LINK.OPENSEA_PRO]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
    [CONTRACT_QUICK_LINK.SUDOSWAP]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
    [CONTRACT_QUICK_LINK.UNISWAP]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
    [CONTRACT_QUICK_LINK.WEBSITE]: REDIRECT_CLICKED_TYPE.COLLECTION_WEBSITE,
    [CONTRACT_QUICK_LINK.X]: REDIRECT_CLICKED_TYPE.OTHER,
    [CONTRACT_QUICK_LINK.X2Y2]: REDIRECT_CLICKED_TYPE.MARKETPLACE,
  }[platform];

  const getUrl = (address: string, url?: string) =>
    ({
      [CONTRACT_QUICK_LINK.BLUR]: url,
      [CONTRACT_QUICK_LINK.COINBASE]: `https://nft.coinbase.com/collection/ethereum/${address}`,
      [CONTRACT_QUICK_LINK.DISCORD]: url,
      [CONTRACT_QUICK_LINK.ETHERSCAN]: `https://etherscan.io/address/${address}`,
      [CONTRACT_QUICK_LINK.LOOKSRARE]: `https://looksrare.org/collections/${address}`,
      [CONTRACT_QUICK_LINK.MAGIC_EDEN]: `https://magiceden.io/collections/eth/${address.toLowerCase()}`,
      [CONTRACT_QUICK_LINK.MAGICALLY]: `https://magically.gg/collection/${address}`,
      [CONTRACT_QUICK_LINK.NFTFLIP]: `https://nftflip.ai/collection/${address.toLowerCase()}`,
      [CONTRACT_QUICK_LINK.NFTNERDS]: `https://nftnerds.ai/collection/${address}/liveview`,
      [CONTRACT_QUICK_LINK.OPENSEA]: url,
      [CONTRACT_QUICK_LINK.OPENSEA_PRO]: `https://pro.opensea.io/collection/${address.toLowerCase()}/`,
      [CONTRACT_QUICK_LINK.SUDOSWAP]: `https://sudoswap.xyz/#/browse/buy/${address}`,
      [CONTRACT_QUICK_LINK.UNISWAP]: `https://app.uniswap.org/#/nfts/collection/${address.toLowerCase()}`,
      [CONTRACT_QUICK_LINK.WEBSITE]: url,
      [CONTRACT_QUICK_LINK.X]: url,
      [CONTRACT_QUICK_LINK.X2Y2]: `https://x2y2.io/collection/${address}`,
    }[platform]);

  return {
    getUrl,
    icon,
    type,
  };
}
