import cn from 'classnames';

import { PLAY_STATUS } from '~/constants';
import type { PlayStatus } from '~/types';

import styles from './LivePulse.module.scss';

interface LivePulseProps {
  playStatus: PlayStatus;
}

export default function LivePulse({ playStatus }: LivePulseProps) {
  return (
    <div className={styles.container}>
      <div
        className={cn(styles.pulse, {
          [styles.pulse_loading]: playStatus === PLAY_STATUS.LOADING,
          [styles.pulse_playing]: playStatus === PLAY_STATUS.PLAYED,
        })}
      />
      <span
        className={cn(styles.live_text, {
          [styles.live_text_loading]: playStatus === PLAY_STATUS.LOADING,
          [styles.live_text_playing]: playStatus === PLAY_STATUS.PLAYED,
        })}
      >
        {'Live'}
      </span>
    </div>
  );
}
