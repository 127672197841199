import { useMutation } from '@tanstack/react-query';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { MouseEvent, useEffect, useState } from 'react';

import API from '~/api';
import { IcnAnnouncement } from '~/assets';
import { Loading, Toaster } from '~/components';
import useContractAnnouncements from '~/data/useContractAnnouncements';
import type { AvailableChain, ContractAnnouncement } from '~/types';
import showErrorToast from '~/utils/showErrorToast';

import AnnouncementInputForm from './AnnouncementInputForm';
import AnnouncementItem from './AnnouncementItem';
import styles from './Announcements.module.scss';

interface AnnouncementsProps {
  show: boolean;
}

export default function Announcements({ show }: AnnouncementsProps) {
  const router = useRouter();
  const { address, chain } = router.query as {
    address: string;
    chain: Lowercase<AvailableChain>;
  };
  const { announcements, isLoading, refreshAnnouncement } =
    useContractAnnouncements(chain, address);
  const [isAdding, setIsAdding] = useState(false);

  const addContractAnnouncementMutation = useMutation(
    ({
      description,
      title,
    }: Pick<ContractAnnouncement, 'description' | 'title'>) =>
      API.createAnnouncement(address, title, description),
    {
      onSuccess() {
        setIsAdding(false);
        refreshAnnouncement();
        Toaster.toast({
          description: 'Announcement created successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const deleteContractAnnouncementMutation = useMutation(
    (uuid: string) => API.deleteContractAnnouncement(uuid),
    {
      onSuccess() {
        refreshAnnouncement();
        Toaster.toast({
          description: 'Announcement deleted successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const handleAnnounce = async (
    announcement: Pick<ContractAnnouncement, 'description' | 'title'>
  ) => {
    await addContractAnnouncementMutation.mutateAsync(announcement);
  };

  const handleToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsAdding((prev) => !prev);
  };

  const handleRemove = async (uuid: string) => {
    await deleteContractAnnouncementMutation.mutateAsync(uuid);
  };

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (['Escape', 'Esc'].includes(e.key)) {
        setIsAdding(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <div className={cn(styles.container, { [styles.hidden]: !show })}>
      {isAdding ? (
        <AnnouncementInputForm
          onClose={handleToggle}
          onSubmit={handleAnnounce}
        />
      ) : (
        <button className={styles.btn_add_announcement} onClick={handleToggle}>
          <IcnAnnouncement />
          <span>Add Announcement</span>
        </button>
      )}
      <ol className={styles.announcements}>
        {isLoading ? (
          <Loading size={32} />
        ) : (
          announcements.map((announcement) => (
            <AnnouncementItem
              {...announcement}
              key={announcement.uuid}
              chain={
                (chain.slice(0, 1).toUpperCase() +
                  chain.slice(1)) as AvailableChain
              }
              onRemove={handleRemove}
            />
          ))
        )}
      </ol>
    </div>
  );
}
