import cn from 'classnames';
import { useEffect, useState } from 'react';

import { URGENT_TIME } from '~/constants';

import styles from './TimeAgo.module.scss';

interface TimeAgoProps {
  date: Date;
  highlight?: boolean;
  highlightDuring?: number;
  init?: boolean;
}

export default function TimeAgo({
  date,
  highlight = false,
  highlightDuring = URGENT_TIME,
  init = false,
}: TimeAgoProps) {
  const [diff, setDiff] = useState<number>(
    new Date().getTime() - date.getTime()
  );

  useEffect(() => {
    // initialize timer if date is changed
    if (init) {
      setDiff(0);
    }

    const secondTimer = setInterval(() => {
      setDiff(new Date().getTime() - date.getTime());
    }, 1_000);

    return () => clearInterval(secondTimer);
  }, [date, init]);

  const format = (diff: number) => {
    const seconds = Math.floor(diff * 0.001);

    if (seconds < 1) return `${seconds < 0 ? 0 : seconds} secs ago`;
    if (seconds < 2) return `${seconds} sec ago`;
    if (seconds < 60) return `${seconds} secs ago`;
    if (seconds < 60 * 2) return `1 min ago`;
    if (seconds < 60 * 60) return `${Math.floor(seconds / 60)} mins ago`;
    if (seconds < 60 * 60 * 2) return `1 hr ago`;
    if (seconds < 60 * 60 * 24)
      return `${Math.floor(seconds / (60 * 60))} hrs ago`;
    if (seconds < 60 * 60 * 24 * 2) return `1 day ago`;
    return `${Math.floor(seconds / (60 * 60 * 24))} days ago`;
  };

  return (
    <time
      dateTime={date.toUTCString()}
      className={cn({
        [styles.under_one_min]: !!highlight && diff < highlightDuring,
      })}
    >
      {format(diff)}
    </time>
  );
}
