import cn from 'classnames';
import { useAccount } from 'wagmi';

import { IcnClose } from '~/assets';
import { Modal } from '~/components';
import { useAuth } from '~/contexts/AuthContext';
import formatAddress from '~/utils/formatAddress';

import styles from './LinkWalletModal.module.scss';

interface LinkWalletModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LinkWalletModal({
  isOpen,
  onClose,
}: LinkWalletModalProps) {
  const { address: connectWalletAddress } = useAccount();
  const { linkWallet, signOut, user } = useAuth();

  const handleLinkWallet = () => {
    linkWallet();
    onClose();
  };

  const handleSignOut = () => {
    signOut();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleSignOut}>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <h2 className={styles.title}>{'Link wallet'}</h2>
          <button
            className={cn('btn_icon', styles.btn_close)}
            onClick={(e) => {
              handleSignOut();
              e.stopPropagation();
            }}
          >
            <IcnClose />
          </button>
        </div>
        <p className={styles.description}>
          {`Looks like wallet `}
          <span>{`${formatAddress(connectWalletAddress)}`}</span>
          {` is not linked to profile `}
          <span>{`${formatAddress(user?.address)}`}</span>
        </p>
        <div className={styles.buttons_container}>
          <button
            className={cn(styles.button, styles.btn_link)}
            onClick={handleLinkWallet}
          >
            {`Link wallet`}
          </button>
          <button
            className={cn(styles.button, styles.btn_sign_out)}
            onClick={handleSignOut}
          >
            {`Sign out of ${formatAddress(user?.address)}`}
          </button>
        </div>
      </div>
    </Modal>
  );
}
