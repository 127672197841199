import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAuth } from '~/contexts/AuthContext';

import Loading from './Loading';

interface PrivateRouteProps {
  children: JSX.Element;
  protectedRoutes: string[];
}

export default function PrivateRoute({
  children,
  protectedRoutes,
}: PrivateRouteProps) {
  const { isAuthenticated, isLoading } = useAuth();
  const router = useRouter();

  const pathIsProtected = protectedRoutes.indexOf(router.pathname) !== -1;

  useEffect(() => {
    if (!isLoading && !isAuthenticated && pathIsProtected) {
      router.push('/');
    }
  }, [isLoading, isAuthenticated, pathIsProtected]);

  if ((isLoading || !isAuthenticated) && pathIsProtected) {
    return <Loading size={24} />;
  }

  return children;
}
