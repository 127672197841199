import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  Messaging,
} from 'firebase/messaging';
import localforage from 'localforage';

import config from '~/config';

export class FirebaseService {
  firebaseApp: FirebaseApp;
  firebaseMessaging?: Messaging;

  constructor() {
    this.firebaseApp = initializeApp({
      apiKey: config.FIREBASE_API_KEY,
      projectId: config.FIREBASE_PROJECT_ID,
      messagingSenderId: config.FIREBASE_SENDER_ID,
      appId: config.FIREBASE_APP_ID,
    });
  }

  async init() {
    try {
      this.firebaseMessaging = getMessaging(this.firebaseApp);
      const tokenInLocalForage = (await localforage.getItem('fcm_token')) as
        | string
        | null;

      // Return the token if it is already in our local storage
      if (tokenInLocalForage !== null) {
        return tokenInLocalForage;
      }

      // Request the push notification permission from browser
      const status = await Notification.requestPermission();
      if (status && status === 'granted') {
        // Get new token from Firebase
        const fcm_token = await getToken(this.firebaseMessaging, {
          vapidKey: config.FIREBASE_VAPID_KEY,
        });

        // Set token in our local storage
        if (fcm_token) {
          localforage.setItem('fcm_token', fcm_token);
          return fcm_token;
        }
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async clear() {
    try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        this.firebaseMessaging = getMessaging(this.firebaseApp);
        const isDeleted = await deleteToken(this.firebaseMessaging);
        return isDeleted;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      localforage.removeItem('fcm_token');
    }
  }
}

export default FirebaseService;
