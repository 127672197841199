import cn from 'classnames';
import { useState } from 'react';

import { IcnArrowNext, IcnArrowPrev, IcnClose } from '~/assets';
import { ImageSource, Modal, VideoSource } from '~/components';
import { AVAILABLE_VIDEO_EXTENSION } from '~/constants';
import { AvailableVideoExtension, NewFeature } from '~/types';
import getExtension from '~/utils/getExtension';

import styles from './NewFeaturesModal.module.scss';

interface NewFeaturesModalProps {
  isOpen: boolean;
  newFeatures: NewFeature[];
  onClose: () => void;
}

export default function NewFeaturesModal({
  newFeatures,
  onClose,
}: NewFeaturesModalProps) {
  const [showingIndex, setShowingIndex] = useState(0);

  const scrollToIndex = (index: number) => {
    setShowingIndex(index);
  };

  return (
    <Modal open={true} onClose={onClose} style={{ width: 640 }}>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <span className={styles.title}>Feature Update</span>
          <button
            className={cn('btn_icon', styles.btn_close)}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <IcnClose />
          </button>
        </div>
        <div className={styles.body_container}>
          <div
            className={styles.new_feature_container}
            style={{
              width: `${newFeatures.length * 576}px`,
              transform: `translateX(${-576 * showingIndex}px)`,
            }}
          >
            {newFeatures.map(({ header, id, imageLink, subHeader }) => {
              const extension = getExtension(imageLink);
              const isVideo = Object.values(AVAILABLE_VIDEO_EXTENSION).includes(
                extension as AvailableVideoExtension
              );
              return (
                <div key={id} className={styles.new_feature}>
                  <div className={styles.header_container}>
                    <span className={styles.header}>{header}</span>
                    <span className={styles.sub_header}>{subHeader}</span>
                  </div>
                  <div className={styles.picture_container}>
                    {isVideo ? (
                      <VideoSource
                        extension={extension}
                        width={528}
                        height={300}
                        url={imageLink}
                      />
                    ) : (
                      <ImageSource
                        alt={header}
                        width={528}
                        height={300}
                        url={imageLink}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {showingIndex !== 0 && (
            <button
              className={cn(styles.btn_arrow, styles.btn_prev)}
              onClick={() => scrollToIndex(showingIndex - 1)}
            >
              <IcnArrowPrev />
            </button>
          )}
          {showingIndex !== newFeatures.length - 1 && (
            <button
              className={cn(styles.btn_arrow, styles.btn_next)}
              onClick={() => scrollToIndex(showingIndex + 1)}
            >
              <IcnArrowNext />
            </button>
          )}
          <div className={styles.breadcrumbs}>
            {Array.from({ length: newFeatures.length }).map((_, index) => (
              <button
                key={index}
                className={cn(styles.crumb, {
                  [styles.crumb_active]: showingIndex === index,
                })}
                onClick={() => scrollToIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
