import { memo } from 'react';

import { Copy, Thumbnail } from '~/components';
import type { HiddenContract } from '~/types';
import formatAddress from '~/utils/formatAddress';

import styles from './HiddenContractsList.module.scss';

interface HiddenContractItemProps {
  data: HiddenContract;
  handleClick: () => void;
  handleHide: (address: string) => void;
  handleUnhide: (address: string) => void;
  isTurnedUnhidden: boolean;
  style: any;
}

export default memo(function HiddenContractItem({
  data,
  handleClick,
  handleHide,
  handleUnhide,
  isTurnedUnhidden,
  style,
}: HiddenContractItemProps) {
  const { address, imageUrl, name } = data;
  return (
    <li
      className={styles.hidden_list_item}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
    >
      <div className={styles.hidden_contract_info}>
        <div>
          <Thumbnail url={imageUrl} />
        </div>
        <div className={styles.hidden_contract_info_text}>
          <span className={styles.name}>{name}</span>
          <div className={styles.address}>
            <p>{formatAddress(address)}</p>
            <Copy content={address} />
          </div>
        </div>
      </div>
      {isTurnedUnhidden ? (
        <button
          className={styles.btn_hide}
          onClick={(e) => {
            e.stopPropagation();
            handleHide(address);
          }}
        >
          {'Hide'}
        </button>
      ) : (
        <button
          className={styles.btn_unhide}
          onClick={(e) => {
            e.stopPropagation();
            handleUnhide(address);
          }}
        >
          {'Unhide'}
        </button>
      )}
    </li>
  );
});
