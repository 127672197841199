import * as d3 from 'd3';
import type { Simulation } from 'd3-force';
import _debounce from 'lodash/debounce';
import {
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import styleVariables from '~/styles/variables.module.scss';
import type { HoldersCluster, SimLink, SimNode } from '~/types';
import normalize from '~/utils/normalize';

import styles from './ForceGraph.module.scss';

interface ForceGraph<T> {
  focusedNode: string | null;
  hoveredNode: string | null;
  links: SimLink<T>[];
  nodes: SimNode<T>[];
  onClickNode: (event: any, address: string) => void;
  onHoverNode: (
    e: MouseEvent<HTMLLIElement | SVGCircleElement>,
    address: string | null
  ) => void;
  uniqueQuantities: number[];
}

export default function ForceGraph({
  focusedNode,
  hoveredNode,
  links,
  nodes,
  onClickNode,
  onHoverNode,
  uniqueQuantities,
}: ForceGraph<HoldersCluster>) {
  const minQuantity = Math.min(...uniqueQuantities);
  const maxQuantity = Math.max(...uniqueQuantities);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const drag = (simulation: Simulation<SimNode<HoldersCluster>, undefined>) => {
    const dragStarted = (event: any) => {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    };

    const dragged = (event: any) => {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    };

    const dragEnded = (event: any) => {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    };

    return d3
      .drag<SVGCircleElement, SimNode<HoldersCluster>>()
      .on('start', dragStarted)
      .on('drag', dragged)
      .on('end', dragEnded);
  };

  const nodeClick = useCallback((event: any) => {
    const clickedNode = d3.select(event.target);
    const clickedNodeData = (clickedNode.datum() as SimNode<HoldersCluster>)
      .data;

    onClickNode(event, clickedNodeData.address);
  }, []);

  useEffect(() => {
    const getContainerSize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setSize({ width, height });
      }
    };

    getContainerSize();
    const debounced = _debounce(getContainerSize, 250);
    window.addEventListener('resize', debounced);
    return () => window.removeEventListener('resize', debounced);
  }, []);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const { width, height } = size;
      const svg = d3
        .select(svgRef.current)
        .attr('viewBox', [-width / 2, -height / 2, width, height]);

      const simulation = d3
        .forceSimulation<SimNode<HoldersCluster>, SimLink<HoldersCluster>>(
          nodes
        )
        .force(
          'link',
          d3
            .forceLink<SimNode<HoldersCluster>, SimLink<HoldersCluster>>(links)
            .id((d) => d.id)
            .distance(64)
            .strength(1)
        )
        .force('center', d3.forceCenter(0, 0))
        .force('charge', d3.forceManyBody().strength(-200))
        .force('x', d3.forceX())
        .force('y', d3.forceY());

      const linkElements = svg
        .select(`.${styles.links}`)
        .attr('stroke', styleVariables.gray500)
        .attr('stroke-opacity', 0.6)
        .selectAll('line')
        .data(links)
        .join('line')
        .attr('stroke-width', 2);

      const nodeElements = svg
        .select(`.${styles.nodes}`)
        .selectAll<SVGCircleElement, SimNode<HoldersCluster>>('circle')
        .data<SimNode<HoldersCluster>>(nodes)
        .join<SVGCircleElement>('circle')
        .attr('class', styles.node)
        .attr('r', ({ data: { weight } }) => {
          // normalized to range 10 ~ 20
          return (1 + normalize(minQuantity, maxQuantity)(weight)) * 10;
        })
        .attr('fill', ({ data: { isHolder } }) =>
          isHolder ? styleVariables.gray500 : styleVariables.gray50
        )
        .call(drag(simulation));

      const nodeMouseOut = (event: any) => {
        onHoverNode(event, null);
      };

      const nodeMouseOver = (event: any) => {
        const targetNode = d3.select(event.target);
        const targetNodeData = (targetNode.datum() as SimNode<HoldersCluster>)
          .data;

        onHoverNode(event, targetNodeData.address);
      };

      nodeElements
        .on('click', nodeClick)
        .on('mouseout', nodeMouseOut)
        .on('mouseover', nodeMouseOver);

      simulation.on('tick', () => {
        //update link positions
        linkElements
          .attr(
            'x1',
            ({ source }) => (source as SimNode<HoldersCluster>).x || 0
          )
          .attr(
            'y1',
            ({ source }) => (source as SimNode<HoldersCluster>).y || 0
          )
          .attr(
            'x2',
            ({ target }) => (target as SimNode<HoldersCluster>).x || 0
          )
          .attr(
            'y2',
            ({ target }) => (target as SimNode<HoldersCluster>).y || 0
          );

        // update node positions
        nodeElements
          .attr('cx', (node) => node.x || 0)
          .attr('cy', (node) => node.y || 0);
      });
    }
  }, [size]);

  useLayoutEffect(() => {
    const svg = d3.select(svgRef.current);
    const linkElements = svg
      .select(`.${styles.links}`)
      .selectAll<SVGLineElement, SimLink<HoldersCluster>>('line');
    const nodeElements = svg
      .select(`.${styles.nodes}`)
      .selectAll<SVGCircleElement, SimNode<HoldersCluster>>('circle');

    if (focusedNode && hoveredNode) {
      const focused = nodes.find((node) => node.id === focusedNode);

      nodeElements
        .style('fill', (o) => {
          if (!o.data.isHolder) return styleVariables.gray50;

          const isFocused = o.id === focusedNode;
          const isHovered = o.id === hoveredNode;
          const isLinked =
            !!focused && focused.data.linkedNodes.includes(o.data.address);

          return isFocused
            ? isHovered
              ? styleVariables.brand600
              : styleVariables.brand500
            : isLinked
            ? isHovered
              ? styleVariables.brand400
              : styleVariables.brand300
            : isHovered
            ? styleVariables.gray800
            : styleVariables.gray500;
        })
        .style('opacity', ({ id, data: { linkedNodes } }) => {
          return id && (id === hoveredNode || linkedNodes.includes(hoveredNode))
            ? 1
            : 0.25;
        });

      linkElements
        .style('opacity', ({ source, target }) => {
          return (source as SimNode<HoldersCluster>).id === hoveredNode ||
            (target as SimNode<HoldersCluster>).id === hoveredNode
            ? 1
            : 0.25;
        })
        .style('stroke', ({ source, target }) => {
          return (source as SimNode<HoldersCluster>).id === focusedNode ||
            (target as SimNode<HoldersCluster>).id === focusedNode
            ? styleVariables.brand300
            : styleVariables.gray500;
        })
        .style('stroke-width', ({ source, target }) => {
          return (source as SimNode<HoldersCluster>).id === hoveredNode ||
            (target as SimNode<HoldersCluster>).id === hoveredNode
            ? 3.5
            : 2;
        });
    } else if (focusedNode && !hoveredNode) {
      const focused = nodes.find((node) => node.id === focusedNode);
      nodeElements
        .style('fill', ({ id, data: { address, isHolder } }) => {
          if (!isHolder) return styleVariables.gray50;

          const isFocused = id === focusedNode;
          const isLinked =
            !!focused && focused.data.linkedNodes.includes(address);

          if (isLinked) return styleVariables.brand300;

          return isFocused ? styleVariables.brand500 : styleVariables.gray500;
        })
        .style('opacity', ({ id, data: { linkedNodes } }) => {
          return id && (id === focusedNode || linkedNodes.includes(focusedNode))
            ? 1
            : 0.25;
        });

      linkElements
        .style('opacity', ({ source, target }) => {
          return (source as SimNode<HoldersCluster>).id === focusedNode ||
            (target as SimNode<HoldersCluster>).id === focusedNode
            ? 1
            : 0.25;
        })
        .style('stroke', ({ source, target }) => {
          return (source as SimNode<HoldersCluster>).id === focusedNode ||
            (target as SimNode<HoldersCluster>).id === focusedNode
            ? styleVariables.brand300
            : styleVariables.gray500;
        })
        .style('stroke-width', ({ source, target }) => {
          return (source as SimNode<HoldersCluster>).id === focusedNode ||
            (target as SimNode<HoldersCluster>).id === focusedNode
            ? 3.5
            : 2;
        });
    } else if (!focusedNode && hoveredNode) {
      nodeElements
        .style('fill', ({ id, data: { isHolder } }) => {
          if (!isHolder) return styleVariables.gray50;
          const isHovered = id === hoveredNode;
          return isHovered ? styleVariables.gray800 : styleVariables.gray500;
        })
        .style('opacity', ({ id, data: { linkedNodes } }) => {
          return id && (id === hoveredNode || linkedNodes.includes(hoveredNode))
            ? 1
            : 0.25;
        });
      linkElements
        .style('opacity', ({ source, target }) => {
          return (source as SimNode<HoldersCluster>).id === hoveredNode ||
            (target as SimNode<HoldersCluster>).id === hoveredNode
            ? 1
            : 0.25;
        })
        .style('stroke', styleVariables.gray500)
        .style('stroke-width', ({ source, target }) => {
          return (source as SimNode<HoldersCluster>).id === hoveredNode ||
            (target as SimNode<HoldersCluster>).id === hoveredNode
            ? 3.5
            : 2;
        });
    } else {
      nodeElements
        .style('fill', ({ data: { isHolder } }) =>
          isHolder ? styleVariables.gray500 : styleVariables.gray50
        )
        .style('opacity', 1);
      linkElements
        .style('opacity', 1)
        .style('stroke', styleVariables.gray500)
        .style('stroke-width', 2);
    }
  }, [focusedNode, hoveredNode, nodes]);

  return (
    <div className={styles.chart} ref={containerRef}>
      <svg ref={svgRef}>
        <g className={styles.links} />
        <g className={styles.nodes} />
      </svg>
    </div>
  );
}
