import { useQuery, useQueryClient } from '@tanstack/react-query';
import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Toaster } from 'components';
import API from '~/api';
import {
  API_DEBOUNCE_DELAY,
  DEFAULT_TRIGGER_SETTING,
  MINIMUM_LOADING_TIME,
  NOTIFICATION_TARGET_LIST,
} from '~/constants';
import { notificationsKeys } from '~/constants/queryKeys';
import type {
  NotificationProviderSettings,
  NotificationTriggerSettings,
  NotificationTriggerType,
} from '~/types';
import showErrorToast from '~/utils/showErrorToast';

export default function useNotificationSettings() {
  const queryClient = useQueryClient();
  const storedProviderSettings = useRef<NotificationProviderSettings | null>(
    null
  );
  const storedTriggerSettings = useRef<NotificationTriggerSettings | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [providerSettings, setProviderSettings] =
    useState<NotificationProviderSettings | null>(null);
  const [triggerSettings, setTriggerSettings] =
    useState<NotificationTriggerSettings>(DEFAULT_TRIGGER_SETTING);
  const socialQueryKey = notificationsKeys.social();
  const triggerQueryKeys = notificationsKeys.trigger();

  const { data: currentSettingsData, isFetched: isSettingsFetched } = useQuery(
    socialQueryKey,
    () => API.getNotificationSettings(),
    {
      cacheTime: Infinity,
      refetchOnMount: false,
      staleTime: Infinity,
    }
  );
  const { data: currentTriggersData, isFetched: isTriggersFetched } = useQuery(
    triggerQueryKeys,
    () => API.getNotificationTriggers(),
    {
      cacheTime: Infinity,
      refetchOnMount: false,
      staleTime: Infinity,
    }
  );

  const canSave = useMemo(
    () =>
      !!storedProviderSettings.current &&
      !!providerSettings &&
      (JSON.stringify(storedProviderSettings.current) !==
        JSON.stringify(providerSettings) ||
        JSON.stringify(storedTriggerSettings.current) !==
          JSON.stringify(triggerSettings)),
    [providerSettings, triggerSettings]
  );

  const changeTriggerSettings = useCallback(
    (type: NotificationTriggerType, value: number | boolean) => {
      setTriggerSettings((prev) => ({
        ...prev,
        [type]: value,
      }));
    },
    []
  );

  const isFetched = isSettingsFetched && isTriggersFetched;

  const toggleDiscordWebhookNotification = useCallback(() => {
    if (!providerSettings) return;
    setProviderSettings({
      ...providerSettings,
      notification: {
        ...providerSettings.notification,
        DiscordWebhook: !providerSettings.notification.DiscordWebhook,
      },
    });
  }, [providerSettings]);

  const toggleWebPushNotification = useCallback(() => {
    if (!providerSettings) return;
    setProviderSettings({
      ...providerSettings,
      notification: {
        ...providerSettings.notification,
        WebPush: !providerSettings.notification.WebPush,
      },
    });
  }, [providerSettings]);

  const updateNotificationSettings = useCallback(
    _debounce(async () => {
      if (!providerSettings) return;
      try {
        setIsLoading(true);
        const startTime = Date.now();
        await API.updateNotificationSettings({
          notification: providerSettings.notification,
          trigger: triggerSettings,
        });
        queryClient.invalidateQueries({
          queryKey: socialQueryKey,
        });

        const elapsedTime = Date.now() - startTime;
        const remainingTime = MINIMUM_LOADING_TIME - elapsedTime;

        if (remainingTime > 0) {
          setTimeout(() => {
            setIsLoading(false);
            Toaster.toast({
              description: 'The notification settings saved successfully.',
              type: 'success',
            });
          }, remainingTime);
        } else {
          setIsLoading(false);
          Toaster.toast({
            description: 'The notification settings saved successfully.',
            type: 'success',
          });
        }
      } catch (err) {
        setIsLoading(true);
        showErrorToast(err);
      }
    }, API_DEBOUNCE_DELAY),
    [providerSettings, triggerSettings]
  );

  useEffect(() => {
    if (currentSettingsData) {
      const { data } = currentSettingsData;
      storedProviderSettings.current = data;
      setProviderSettings(data);
    }
  }, [currentSettingsData]);

  useEffect(() => {
    if (currentTriggersData) {
      const { data = [] } = currentTriggersData;
      const converted = {
        ...DEFAULT_TRIGGER_SETTING,
        ...data.reduce((acc, curr) => {
          acc[curr.triggerType] = curr.triggerPoint;
          return acc;
        }, {} as NotificationTriggerSettings),
      };
      storedTriggerSettings.current = converted;
      setTriggerSettings(converted);
    }
  }, [currentTriggersData]);

  return {
    canSave,
    discordWebhook: providerSettings?.providers.find(
      (provider) =>
        provider.provider === NOTIFICATION_TARGET_LIST.DISCORD_WEBHOOK
    ),
    isFetched,
    isLoading,
    notification: providerSettings?.notification,
    providerSettings,
    saveSettings: updateNotificationSettings,
    setProviderSettings,
    setTriggerSettings: changeTriggerSettings,
    toggleDiscordWebhookNotification,
    toggleWebPushNotification,
    triggerSettings,
  };
}
