import { useRecoilState } from 'recoil';

import { GlobalMintButton, LivePulse, MintModal } from '~/components';
import { WRITE_INFORMATION_FROM } from '~/constants';
import useGasSuggestion from '~/data/useGasSuggestion';
import useGroupActivityMintInfo from '~/data/useGroupActivityMintInfo';
import useNotableGroupActivity from '~/data/useNotableGroupActivity';
import useIsMounted from '~/hooks/useIsMounted';
import { writeInformationState } from '~/store/contract';

import styles from './ActivityPanel.module.scss';
import NotableGroupActivityFilter from './NotableGroupActivityFilter';
import NotableGroupActivityList from './NotableGroupActivityList';

export default function ActivityPanel() {
  const gasSuggestion = useGasSuggestion();
  const isMounted = useIsMounted();

  const [writeInformation, setWriteInformation] = useRecoilState(
    writeInformationState
  );

  const {
    containerRef,
    clickedGroupActivity,
    flipKey,
    groupActivities,
    isFirstFetching,
    lastSeenGroupActivityTime,
    onClickGroupActivity,
    onPause,
    onPlay,
    playStatus,
    totalSize,
    virtualItems,
  } = useNotableGroupActivity();

  const { isLoading, isMintedOut, mintInfo, selectedAddress, getDeployer } =
    useGroupActivityMintInfo({
      groupActivity: clickedGroupActivity,
    });

  return (
    <>
      <section className={styles.container}>
        <div className={styles.header_container}>
          <div className={styles.header_title_container}>
            <span className={styles.header_title}>Notable Group Activity</span>
            <LivePulse playStatus={playStatus} />
          </div>
          <div className={styles.header_filter_container}>
            {isMounted && <NotableGroupActivityFilter />}
          </div>
        </div>
        <div className={styles.body_container}>
          <div className={styles.activity_columns}>
            <div className={styles.activity_column}>
              <span>Collection</span>
            </div>
            <div className={styles.activity_column}>
              <span>Price</span>
            </div>
            <div className={styles.activity_column}>
              <span>Gas</span>
            </div>
            <div className={styles.activity_column}>
              <span>Mint Function</span>
            </div>
            <div className={styles.activity_column}>
              <span>Groups</span>
            </div>
            <div className={styles.activity_column}>
              <span>Minter</span>
            </div>
            <div className={styles.activity_column}>
              <span>Time</span>
            </div>
          </div>
          <NotableGroupActivityList
            clickedGroupActivity={clickedGroupActivity}
            containerRef={containerRef}
            flipKey={flipKey}
            isLoading={isFirstFetching}
            groupActivities={groupActivities}
            lastSeenGroupActivityTime={lastSeenGroupActivityTime}
            onClickGroupActivity={onClickGroupActivity}
            onPause={onPause}
            onPlay={onPlay}
            totalSize={totalSize}
            virtualItems={virtualItems}
          />
          <div className={styles.mint_container}>
            {clickedGroupActivity?.address === selectedAddress && (
              <GlobalMintButton
                mintInfo={mintInfo}
                isLoading={isLoading}
                isMintedOut={isMintedOut}
                getDeployer={getDeployer}
              />
            )}
          </div>
        </div>
      </section>
      {writeInformation.mintInfo.mintableFunction && (
        <MintModal
          address={writeInformation.address}
          gasSuggestion={gasSuggestion}
          isOpen={!!writeInformation.mintInfo.mintableFunction}
          mintInfo={writeInformation.mintInfo}
          onClose={() => {
            setWriteInformation({
              address: '',
              from: null,
              mintInfo: {
                mintableFunction: null,
                params: null,
                price: '0',
                transactionTo: '',
                deployer: null,
              },
            });
          }}
          shouldClearAddresses={
            writeInformation.from === WRITE_INFORMATION_FROM.SUCCESSFUL_TX
          }
        />
      )}
    </>
  );
}
