import { useQuery, useQueryClient } from '@tanstack/react-query';
import _isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import MintsOverviewService from 'services/MintsOverviewService';
import API from '~/api';
import {
  CHANNEL_MINTS_OVERVIEW,
  EVENT_DATA,
  MINTS_OVERVIEW_SKELETON_ITEM_NUMBER,
  RENDERING_BOUND_TIME,
} from '~/constants';
import { timeseriesKeys } from '~/constants/queryKeys';
import { useSocket } from '~/contexts/SocketContext';
import usePageVisibility from '~/hooks/usePageVisibility';
import { usePrevious } from '~/hooks/usePrevious';
import { checksumContractAddressState } from '~/store/contract';
import { contractAddressState } from '~/store/contract';
import type {
  MintsOverviewData,
  MintsOverviewFilter,
  MintsOverviewFilterTimeOption,
} from '~/types';

import useContractFilterList from './useContractFilterList';

interface UseMintsOverviewProps extends MintsOverviewFilter {}

const mintsOverviewService = new MintsOverviewService();

export default function useMintsOverview({
  hiddenVisibility,
  onlyMintable,
  timeframe,
}: UseMintsOverviewProps) {
  const { contractFilterList } = useContractFilterList();
  const setContractAddress = useSetRecoilState(contractAddressState);
  const contractAddress = useRecoilValue(checksumContractAddressState);
  const router = useRouter();
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());
  const [mintsOverviewData, setMintsOverviewData] = useState<{
    [key in MintsOverviewFilterTimeOption]: MintsOverviewData[];
  }>({
    '0': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '60': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '180': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '300': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '600': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '1800': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '3600': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '10800': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '21600': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '43200': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
    '86400': Array(MINTS_OVERVIEW_SKELETON_ITEM_NUMBER).fill({}),
  });
  const isPageVisible = usePageVisibility();
  const prevMintsOverviewData = usePrevious(mintsOverviewData)?.[
    timeframe
  ].filter((item) =>
    onlyMintable
      ? item.maxSupply !== item.totalSupply && item.simulationPassed
      : true
  );
  const queryClient = useQueryClient();
  const socket = useSocket();
  const showingMintsOverviewData = mintsOverviewData[timeframe].filter((item) =>
    onlyMintable
      ? item.maxSupply !== item.totalSupply && item.simulationPassed
      : true
  );
  const isUpdated = useMemo(() => {
    return (
      JSON.stringify(showingMintsOverviewData) !==
      JSON.stringify(prevMintsOverviewData)
    );
  }, [prevMintsOverviewData, showingMintsOverviewData]);

  const queryKey = timeseriesKeys.mintsOverview(timeframe);

  useQuery(queryKey, () => API.getMintsOverview(timeframe), {
    onSuccess: ({ data }) => {
      setMintsOverviewData({
        ...mintsOverviewData,
        [timeframe]: data,
      });

      if (!initialDataFetched) {
        setTimeout(() => setInitialDataFetched(true), RENDERING_BOUND_TIME);
      }
    },
  });

  useEffect(() => {
    mintsOverviewService.loadTxs.subscribe(
      (
        data: Partial<{
          [key in MintsOverviewFilterTimeOption]: MintsOverviewData[];
        }>
      ) => {
        setMintsOverviewData((prev) => ({ ...prev, ...data }));
      }
    );

    return () => {
      mintsOverviewService.reset();
    };
  }, []);

  useEffect(() => {
    if (isPageVisible) {
      queryClient.invalidateQueries({ queryKey });
      const channelName = CHANNEL_MINTS_OVERVIEW + timeframe;
      socket
        ?.subscribe(channelName)
        ?.bind(EVENT_DATA, (data: MintsOverviewData[]) =>
          mintsOverviewService.set({ [timeframe]: data } as {
            [key in MintsOverviewFilterTimeOption]: MintsOverviewData[];
          })
        );

      return () => {
        socket?.unsubscribe(channelName);
      };
    }
  }, [isPageVisible, socket, timeframe]);

  useEffect(() => {
    if (initialDataFetched && isUpdated) {
      setLastUpdated(new Date());
    }
  }, [initialDataFetched, isUpdated]);

  // show the first contract detail of mints overview if there is no address query
  // it is automatically changed to first place
  useEffect(() => {
    if (
      initialDataFetched &&
      router.isReady &&
      !router.query.address &&
      showingMintsOverviewData &&
      !_isEmpty(showingMintsOverviewData[0]) &&
      showingMintsOverviewData[0].address !== contractAddress
    ) {
      const filteredData = showingMintsOverviewData.filter(
        (mintsOverviewItem) =>
          !contractFilterList.some(
            (hiddenContract) => hiddenContract === mintsOverviewItem.address
          )
      );
      if (filteredData.length > 0) {
        setContractAddress(filteredData[0].address);
      }
    }
  }, [
    contractAddress,
    contractFilterList,
    hiddenVisibility,
    initialDataFetched,
    showingMintsOverviewData,
    router.isReady,
    router.query,
  ]);

  return {
    initialDataFetched,
    lastUpdated,
    mintsOverviewData,
  };
}
