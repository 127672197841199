import { parseEther, parseUnits } from '@ethersproject/units';
import type { Abi } from 'abitype';
import { BigNumber, PopulatedTransaction } from 'ethers';
import { useEffect } from 'react';
import {
  useContractWrite,
  usePrepareContractWrite,
  useTransaction,
  useWaitForTransaction,
} from 'wagmi';
import { mainnet } from 'wagmi/chains';

import API from '~/api';
import type { MintableFunction, TxGasOption } from '~/types';
import IsNumeric from '~/utils/isNumeric';

interface UseWriteMintProps {
  argsOrder: string[];
  from: string;
  to: string;
  abiItem: MintableFunction;
  methodName: string;
  params: any;
  value: string;
  enabled: boolean;
  txGasOption: TxGasOption | null;
}

export default function useWriteMint({
  argsOrder,
  from,
  to,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  abiItem: { signature, ...abi },
  methodName,
  params,
  value,
  enabled,
  txGasOption,
}: UseWriteMintProps) {
  const overrides: { [key: string]: string | BigNumber } = { from };
  if (value.length > 0 && IsNumeric(value)) {
    overrides['value'] = parseEther(value);
  }

  // arrange parameters order
  const args: readonly unknown[] | undefined = params
    ? Object.values(
        argsOrder.reduce((acc: { [key: string]: any }, key) => {
          acc[key] = params[key];
          return acc;
        }, {})
      )
    : undefined;

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
    isLoading: isPreparing,
  } = usePrepareContractWrite({
    abi: [abi] as Abi,
    address: to as `0x${string}`,
    args,
    chainId: mainnet.id,
    enabled,
    functionName: methodName,
    overrides,
  });

  const requestParams: Pick<
    PopulatedTransaction,
    'maxFeePerGas' | 'maxPriorityFeePerGas' | 'gasLimit'
  > = {};
  const { maxFee, maxPrio } = { ...txGasOption };
  if (maxFee)
    requestParams['maxFeePerGas'] = parseUnits(maxFee.toString(), 'gwei');
  if (maxPrio)
    requestParams['maxPriorityFeePerGas'] = parseUnits(
      maxPrio.toString(),
      'gwei'
    );
  if (config.request?.gasLimit) {
    // add 20% buffer
    requestParams['gasLimit'] = config.request.gasLimit.add(
      // div 5 not mul 0.2 because of underflow error
      config.request.gasLimit.div(5)
    );
  }

  const {
    data,
    error: writeError,
    isLoading: isWriteLoading,
    isError: isWriteError,
    writeAsync,
  } = useContractWrite<'prepared', Abi, string>({
    ...config,
    request: config.request
      ? {
          ...config.request,
          ...requestParams,
        }
      : undefined,
  });

  const { isLoading: isConfirming, isSuccess } = useWaitForTransaction({
    chainId: mainnet.id,
    hash: data?.hash,
    enabled: !!data?.hash,
  });

  const { data: txResponse } = useTransaction({
    chainId: mainnet.id,
    hash: data?.hash,
    enabled: !!data?.hash,
  });

  useEffect(() => {
    if (txResponse) {
      const sendMintConfirmation = async () => {
        try {
          await API.sendMintConfirmation(txResponse.hash, txResponse);
        } catch (err) {
          console.error(err);
        }
      };

      sendMintConfirmation();
    }
  }, [txResponse]);

  return {
    data,
    config,
    isConfirming,
    isLoading: isPreparing || isWriteLoading || isConfirming,
    isPrepareError,
    isSuccess,
    isWriteError,
    prepareError,
    writeError,
    writeAsync,
  };
}
