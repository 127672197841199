import cn from 'classnames';
import { AriaAttributes, AriaRole, forwardRef, ReactNode } from 'react';

import { IcnExternal } from '~/assets';
import noop from '~/utils/noop';
import setUtmSource from '~/utils/setUtmSource';

import styles from './ExternalLink.module.scss';

interface ExternalLinkProps {
  children: ReactNode;
  label: AriaAttributes['aria-label'];
  allowsUtmSource?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: ReactNode;
  nofollow?: boolean;
  onClick?: () => void;
  role?: AriaRole;
  url?: string;
}

export default forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  function ExternalLink(
    {
      allowsUtmSource = true,
      children,
      className,
      disabled,
      icon,
      label,
      nofollow = false,
      onClick = noop,
      role,
      url,
    },
    ref
  ) {
    let rel = 'noopener noreferrer';
    if (nofollow) rel += ' nofollow';

    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        aria-disabled={disabled}
        aria-label={label}
        className={cn(styles.container, className, {
          [styles.disabled]: disabled,
        })}
        href={allowsUtmSource ? setUtmSource(url) : url}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        ref={ref}
        rel={rel}
        role={role}
        target="_blank"
      >
        {children}
        {icon && (
          <div className={styles.icon_container}>
            <IcnExternal />
          </div>
        )}
      </a>
    );
  }
);
