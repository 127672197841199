import { getAddress } from '@ethersproject/address';

export default function toChecksumAddress(address: string | undefined) {
  if (!address) return '';

  try {
    return getAddress(address);
  } catch (e) {
    return address;
  }
}
