import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef } from 'react';

import type { ContractHolder } from '~/types';

import WalletRow from './WalletRow';
import styles from './WalletsView.module.scss';

interface WalletsViewProps {
  holders: ContractHolder[];
  deployer?: string | null;
  totalSupply?: string | number | null;
}

export default function WalletsView({
  holders,
  deployer,
  totalSupply,
}: WalletsViewProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: holders.length,
    estimateSize: () => 38,
    getScrollElement: () => containerRef.current,
    overscan: 5,
  });

  return (
    <div
      ref={containerRef}
      className={styles.wallets_view_container}
      style={{
        height:
          holders.length > 10
            ? '434px'
            : `${holders.length === 0 ? 70 : holders.length * 38 + 34}px`,
      }}
    >
      <div className={styles.view_header}>
        <div className={styles.wallets_view_column}>
          <span className={'number_column'}>{'Rank'}</span>
        </div>
        <div className={styles.wallets_view_column}>
          <span className={'text_column'}>{'Wallet'}</span>
        </div>
        <div className={styles.wallets_view_column}>
          <span className={'number_column'}>{'Quantity'}</span>
        </div>
      </div>
      {holders.length > 0 ? (
        <ol
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {virtualizer.getVirtualItems().map((row) => (
            <li
              key={row.key}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${row.size}px`,
                transform: `translateY(${row.start}px)`,
              }}
            >
              <WalletRow
                {...holders[row.index]}
                index={row.index + 1}
                deployer={deployer}
                totalSupply={totalSupply}
              />
            </li>
          ))}
        </ol>
      ) : (
        <div className={styles.no_data}>{'No Data'}</div>
      )}
    </div>
  );
}
