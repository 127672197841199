import { isAddress } from '@ethersproject/address';
import { configureScope } from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import API from '~/api';
import { IcnFire } from '~/assets';
import {
  AutomationModal,
  Copy,
  GlobalMintButton,
  Head,
  MintModal,
  Thumbnail,
  Toaster,
} from '~/components';
import config from '~/config';
import {
  AVAILABLE_CHAIN,
  DEFAULT_DETAIL_LAYOUT_ORDER,
  DEFAULT_TOP_100_HOLDERS,
  DETAIL_LAYOUT,
  EVENT_CONTRACT_INFO,
  IMAGE_SIZE,
  PAGE_VIEW,
  TOP_100_HOLDERS_VIEW,
  UNEXPECTED_ERROR_MESSAGE,
  WRITE_INFORMATION_FROM,
} from '~/constants';
import { contractsKeys } from '~/constants/queryKeys';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import { useSocket } from '~/contexts/SocketContext';
import useCollection from '~/data/useCollection';
import useContractDetail from '~/data/useContractDetail';
import useContractFilterList from '~/data/useContractFilterList';
import useContractInfo from '~/data/useContractInfo';
import useFollowedNotableGroups from '~/data/useFollowedNotableGroups';
import useGasSuggestion from '~/data/useGasSuggestion';
import useCheckAppVersion from '~/hooks/useCheckAppVersion';
import usePageVisibility from '~/hooks/usePageVisibility';
import { displayState, top100HoldersFilterState } from '~/store/app';
import {
  automationModalState,
  checksumContractAddressState,
  writeInformationState,
} from '~/store/contract';
import type { ContractInfo } from '~/types';
import extractXHandle from '~/utils/extractXHandle';

import ArtSection from './ArtSection';
import AverageEntryPointSection from './AverageEntryPointSection';
import CodeSnippetSection from './CodeSnippetSection';
import styles from './ContractDetail.module.scss';
import ContractInfoSection from './ContractInfoSection';
import DiscussionsSection from './DiscussionsSection';
import FailedTransactionsSection from './FailedTransactionsSection';
import MarketplaceComparisonSection from './MarketplaceComparisonSection';
import NotableGroupsSection from './NotableGroupsSection';
import ScamAlertSection from './ScamAlertSection';
import SuccessfulTransactionsSection from './SuccessfulTransactionsSection';
import Top100HoldersSection from './Top100HoldersSection';

interface ContractDetailProps {}

export default function ContractDetail({}: ContractDetailProps) {
  useCheckAppVersion();
  const analytics = useAnalyticsContext();
  const isPageVisible = usePageVisibility();
  const queryClient = useQueryClient();
  const router = useRouter();
  const address = useRecoilValue(checksumContractAddressState);
  const [contractInfo, setContractInfoData] = useState<ContractInfo | null>(
    null
  );
  const contractDetailOrder =
    useRecoilValue(displayState)?.detail || DEFAULT_DETAIL_LAYOUT_ORDER;
  const { view: top100HoldersView } =
    useRecoilValue(top100HoldersFilterState) || DEFAULT_TOP_100_HOLDERS;
  const { contractFilterList, unhideContract } = useContractFilterList();
  const collectionsResult = useCollection(address);
  const contractInfoResult = useContractInfo('ethereum', address);
  const { followingIds } = useFollowedNotableGroups();
  const {
    entryResult,
    failedTransactionsResult,
    holdersResult,
    linkedHoldersResult,
    linkedXContractsResult,
    mintsResult,
    notableGroupsMintedResult,
    similarContractsResult,
    successfulTransactionsResult,
  } = useContractDetail(address, followingIds);
  const isHidden = contractFilterList.some(
    (hiddenContract) => hiddenContract === address
  );
  const [writeInformation, setWriteInformation] = useRecoilState(
    writeInformationState
  );
  const automationModal = useRecoilValue(automationModalState);
  const gasSuggestion = useGasSuggestion();

  const socket = useSocket();

  const failedTxs =
    contractInfo?.failedTransactions ??
    failedTransactionsResult.failedTransactions;
  const receivedMaxSupply =
    contractInfo?.maxSupply ?? contractInfoResult.contractInfo.maxSupply;
  const maxSupply =
    receivedMaxSupply.length > 0 ? receivedMaxSupply[0].supply : undefined;
  const successfulTxs =
    contractInfo?.successfulTransactions ??
    successfulTransactionsResult.successfulTransactions;
  const totalSupply =
    contractInfo?.totalSupply ??
    contractInfo?.entry?.totalSupply ??
    contractInfoResult.contractInfo?.totalSupply ??
    entryResult?.data?.data.totalSupply;
  const uniqueWallets =
    contractInfo?.uniqueWallets ??
    contractInfoResult.contractInfo?.uniqueWallets;
  const isMintedOut =
    (maxSupply || maxSupply === 0) && (totalSupply || totalSupply === 0)
      ? maxSupply === totalSupply
      : false;

  const refetchCollection = useCallback(() => {
    collectionsResult.refetch();
  }, [collectionsResult]);

  const unhide = () => {
    API.unhideContract(address)
      .then((res) => {
        if (res.status === 204) {
          Toaster.toast({
            description: 'This contract has been unhidden.',
            type: 'success',
          });
          unhideContract(address);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error?.detail) {
          Toaster.toast({
            description: err.response.data.error.detail,
            type: 'error',
          });
        } else {
          Toaster.toast({
            description: UNEXPECTED_ERROR_MESSAGE,
            type: 'error',
          });
        }
      });
  };

  const setContractInfo = useCallback(
    (data: ContractInfo) => {
      // polling below queries if the contract info data is received through socketi
      queryClient.invalidateQueries({
        queryKey: contractsKeys.holders('ethereum', address),
      });
      queryClient.invalidateQueries({
        queryKey: contractsKeys.mints('ethereum', address),
      });
      queryClient.invalidateQueries({
        queryKey: contractsKeys.notableGroups('ethereum', address),
      });
      queryClient.invalidateQueries({
        queryKey: contractsKeys.notableGroupsMintingAggregate(
          'ethereum',
          address
        ),
      });
      queryClient.invalidateQueries({
        queryKey: contractsKeys.similar('ethereum', address),
      });
      setContractInfoData(data);
    },
    [address]
  );

  useEffect(() => {
    configureScope((scope) => {
      scope.setContext('Contract', {
        address,
        name: contractInfoResult.contractInfo?.name,
        standard: contractInfoResult.contractInfo?.standard,
        verified: contractInfoResult.contractInfo?.isVerified,
      });
    });
    setContractInfoData(null);
  }, [address]);

  useEffect(() => {
    if (isPageVisible && address?.startsWith('0x') && address.length === 42) {
      socket
        ?.subscribe(address)
        ?.bind(EVENT_CONTRACT_INFO, (data: ContractInfo) =>
          setContractInfo(data)
        );

      return () => {
        socket?.unsubscribe(address);
      };
    }
  }, [address, isPageVisible, socket]);

  useEffect(() => {
    if (contractInfoResult.contractInfo) {
      const { address, chain, name } = contractInfoResult.contractInfo;
      const properties: { [key: string]: any } = {
        chain,
        contract_address: address,
      };
      if (!!name) {
        properties['collection_name'] = name;
      }
      if (router.asPath !== '/') {
        analytics.page(PAGE_VIEW.COLLECTION_DETAIL);
      }
    }
  }, [contractInfoResult.contractInfo?.address]);

  if (!address) {
    return (
      <div className={styles.error_body_container}>
        <IcnFire />
        <span className={styles.contract_detail_error_description}>
          {'probably nothing'}
        </span>
      </div>
    );
  }

  if (!isAddress(address)) {
    return (
      <div className={styles.error_body_container}>
        <span className={styles.contract_detail_error_description}>
          {'Oops! This is not a valid address.'}
        </span>
      </div>
    );
  }

  if (contractInfoResult.isError) {
    return (
      <div className={styles.error_body_container}>
        <span className={styles.contract_detail_error_description}>
          {'There are no matching entries.'}
        </span>
      </div>
    );
  }

  if (isHidden) {
    if (contractInfoResult.contractInfo) {
      return (
        <div className={styles.hidden_body_container}>
          <Thumbnail
            url={contractInfoResult.contractInfo?.imageUrl}
            size={IMAGE_SIZE.HUGE}
          />
          <div className={styles.hidden_address}>
            {address}
            <Copy content={address} />
          </div>
          <p className={styles.hidden_description}>
            <span>{`${contractInfoResult.contractInfo?.name}`}</span>
            {` is hidden.`}
          </p>
          <button
            className={styles.btn_unhide}
            onClick={(e) => {
              e.stopPropagation();
              unhide();
            }}
          >
            Unhide
          </button>
        </div>
      );
    }
  }

  return (
    <>
      {router.asPath === '/' ? (
        <Head />
      ) : (
        <Head
          description={contractInfoResult.contractInfo?.description}
          title={contractInfoResult.contractInfo?.name}
          url={
            address
              ? `${config.APP_HOST}/collection/ethereum/${address}`
              : undefined
          }
        >
          {contractInfoResult.contractInfo?.twitterUrl && (
            <meta
              name="twitter:creator"
              content={
                extractXHandle(contractInfoResult.contractInfo.twitterUrl) ??
                undefined
              }
            />
          )}
        </Head>
      )}
      <div className={styles.body_container}>
        {!similarContractsResult.isError &&
          similarContractsResult.similarContracts.length > 1 && (
            <ScamAlertSection
              id="similar"
              similarContracts={similarContractsResult.similarContracts}
            />
          )}
        {!linkedXContractsResult.isError &&
          linkedXContractsResult.linkedXContracts.length > 1 && (
            <ScamAlertSection
              id="linked_x"
              similarContracts={linkedXContractsResult.linkedXContracts}
            />
          )}
        {contractDetailOrder.map(
          (name, index) =>
            ({
              [DETAIL_LAYOUT.ART]: (
                <Fragment key={`${name}_${index}`}>
                  {!contractInfoResult.isError && <ArtSection />}
                </Fragment>
              ),
              [DETAIL_LAYOUT.AVERAGE_ENTRY_POINT]: (
                <Fragment key={`${name}_${index}`}>
                  {!entryResult.isError && (
                    <AverageEntryPointSection
                      entry={contractInfo?.entry ?? entryResult.entry}
                      isFetched={
                        entryResult.isFetched &&
                        entryResult.isFetchedAfterMount &&
                        !contractInfoResult.isLoading
                      }
                      isLoading={
                        contractInfoResult.isLoading || entryResult.isLoading
                      }
                    />
                  )}
                </Fragment>
              ),
              [DETAIL_LAYOUT.CODE_SNIPPET]: (
                <Fragment key={`${name}_${index}`}>
                  {!contractInfoResult.isError &&
                    contractInfoResult.contractInfo &&
                    ((contractInfoResult.contractInfo.mintFunctions ?? [])
                      .length > 0 ||
                      (contractInfoResult.contractInfo.miscellaneous ?? '')
                        .length > 0) && (
                      <CodeSnippetSection
                        address={address}
                        chain={AVAILABLE_CHAIN.ETHEREUM}
                        isLoading={contractInfoResult.isLoading}
                        mintFunctions={
                          contractInfoResult.contractInfo.mintFunctions
                        }
                        miscellaneous={
                          contractInfoResult.contractInfo.miscellaneous ?? ''
                        }
                      />
                    )}
                </Fragment>
              ),
              [DETAIL_LAYOUT.CONTRACT_INFORMATION]: (
                <Fragment key={`${name}_${index}`}>
                  <ContractInfoSection
                    collectionsResult={collectionsResult}
                    contractInfo={contractInfo}
                    entryResult={entryResult}
                  />
                </Fragment>
              ),
              [DETAIL_LAYOUT.DISCUSSIONS]: (
                <Fragment key={`${name}_${index}`}>
                  {!contractInfoResult.isError && (
                    <DiscussionsSection
                      address={address}
                      chain={AVAILABLE_CHAIN.ETHEREUM}
                      deployer={contractInfoResult.contractInfo?.deployer}
                      isLoading={contractInfoResult.isLoading}
                    />
                  )}
                </Fragment>
              ),
              [DETAIL_LAYOUT.FAILED_TRANSACTIONS]: (
                <Fragment key={`${name}_${index}`}>
                  {!failedTransactionsResult.isError && (
                    <FailedTransactionsSection
                      failedTransactions={failedTxs ?? []}
                      isLoading={
                        contractInfoResult.isLoading ||
                        failedTransactionsResult.isLoading
                      }
                      mintFunctions={contractInfoResult.data?.abi ?? {}}
                    />
                  )}
                </Fragment>
              ),
              [DETAIL_LAYOUT.MARKETPLACE_COMPARISON]: (
                <Fragment key={`${name}_${index}`}>
                  {!(collectionsResult.isError || entryResult.isError) && (
                    <MarketplaceComparisonSection
                      collections={collectionsResult.collections}
                      oldestModified={collectionsResult.oldestModified}
                      price={+(entryResult.entry.averageEntry ?? '0')}
                      isFetched={
                        collectionsResult.isFetched &&
                        collectionsResult.isFetchedAfterMount &&
                        entryResult.isFetched &&
                        entryResult.isFetchedAfterMount &&
                        !contractInfoResult.isLoading
                      }
                      isLoading={
                        contractInfoResult.isLoading ||
                        collectionsResult.isLoading ||
                        entryResult.isLoading
                      }
                      refetch={refetchCollection}
                    />
                  )}
                </Fragment>
              ),
              [DETAIL_LAYOUT.NOTABLE_GROUPS_MINTED]: (
                <Fragment key={`${name}_${index}`}>
                  {!notableGroupsMintedResult.isError && (
                    <NotableGroupsSection
                      isFetched={
                        notableGroupsMintedResult.isFetched &&
                        notableGroupsMintedResult.isFetchedAfterMount &&
                        !contractInfoResult.isLoading
                      }
                      isFirstFetched={notableGroupsMintedResult.isFirstFetched}
                      isLoading={
                        notableGroupsMintedResult.isFirstFetched &&
                        (contractInfoResult.isLoading ||
                          (followingIds.length > 0 &&
                            notableGroupsMintedResult.isLoading))
                      }
                      notableGroupsMinted={
                        notableGroupsMintedResult.notableGroupsMinted
                      }
                      tags={notableGroupsMintedResult.tags}
                    />
                  )}
                </Fragment>
              ),
              [DETAIL_LAYOUT.SUCCESSFUL_TRANSACTIONS]: (
                <Fragment key={`${name}_${index}`}>
                  {!successfulTransactionsResult.isError && (
                    <SuccessfulTransactionsSection
                      deployer={contractInfoResult.contractInfo?.deployer}
                      isFetched={
                        successfulTransactionsResult.isFetched &&
                        successfulTransactionsResult.isFetchedAfterMount &&
                        !contractInfoResult.isLoading
                      }
                      isFirstFetched={
                        successfulTransactionsResult.isFirstFetched
                      }
                      isLoading={
                        successfulTransactionsResult.isFirstFetched &&
                        (contractInfoResult.isLoading ||
                          successfulTransactionsResult.isLoading)
                      }
                      isMintedOut={isMintedOut}
                      mintFunctions={contractInfoResult.data?.abi ?? {}}
                      successfulTransactions={successfulTxs}
                    />
                  )}
                </Fragment>
              ),
              [DETAIL_LAYOUT.TOP_100_HOLDERS]: (
                <Fragment key={`${name}_${index}`}>
                  {(top100HoldersView === TOP_100_HOLDERS_VIEW.WALLETS
                    ? !holdersResult.isError
                    : !linkedHoldersResult.isError) && (
                    <Top100HoldersSection
                      deployer={contractInfoResult.contractInfo?.deployer}
                      isFetched={
                        !contractInfoResult.isLoading &&
                        (top100HoldersView === TOP_100_HOLDERS_VIEW.WALLETS
                          ? holdersResult.isFetched &&
                            holdersResult.isFetchedAfterMount
                          : linkedHoldersResult.isFetched &&
                            linkedHoldersResult.isFetchedAfterMount)
                      }
                      isLoading={
                        contractInfoResult.isLoading ||
                        (top100HoldersView === TOP_100_HOLDERS_VIEW.WALLETS
                          ? holdersResult.isLoading
                          : linkedHoldersResult.isLoading)
                      }
                      isOtherOptionFetched={
                        !contractInfoResult.isLoading &&
                        (top100HoldersView === TOP_100_HOLDERS_VIEW.WALLETS
                          ? linkedHoldersResult.isFetchedAfterMount
                          : holdersResult.isFetchedAfterMount)
                      }
                      holders={holdersResult.holders}
                      linkedHolders={linkedHoldersResult.linkedHolders}
                      totalSupply={totalSupply}
                      uniqueWallets={uniqueWallets}
                    />
                  )}
                </Fragment>
              ),
            }[name])
        )}
      </div>
      <GlobalMintButton
        mintInfo={mintsResult.mintInfo}
        isLoading={contractInfoResult.isLoading && mintsResult.isLoading}
        isMintedOut={isMintedOut}
        getDeployer={async () => contractInfoResult.contractInfo?.deployer}
      />
      {writeInformation.mintInfo.mintableFunction && (
        <MintModal
          address={writeInformation.address}
          gasSuggestion={gasSuggestion}
          isOpen={!!writeInformation.mintInfo.mintableFunction}
          mintInfo={writeInformation.mintInfo}
          onClose={() => {
            setWriteInformation({
              address: '',
              from: null,
              mintInfo: {
                mintableFunction: null,
                params: null,
                price: '0',
                transactionTo: '',
              },
            });
          }}
          shouldClearAddresses={
            writeInformation.from === WRITE_INFORMATION_FROM.SUCCESSFUL_TX
          }
        />
      )}
      {automationModal.isOpen && (
        <AutomationModal gasSuggestion={gasSuggestion} />
      )}
    </>
  );
}
