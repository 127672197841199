import { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';

import { NotificationsContext } from './context';

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider: FC<NotificationsProviderProps> = ({
  children,
}) => {
  const listRef = useRef<InfiniteLoader | null>(null);
  const getSize = useCallback(
    (index: number) => sizeMap.current?.[index] || 101,
    []
  );
  const sizeMap = useRef<{ [key: number]: number } | null>(null);
  const setSize = useCallback((index: number, size: number) => {
    // @ts-ignore
    listRef.current?._listRef.resetAfterIndex(0);
    sizeMap.current = { ...sizeMap.current, [index]: size };
  }, []);

  useEffect(() => {
    return () => {
      listRef.current = null;
      sizeMap.current = null;
    };
  }, []);

  return (
    <NotificationsContext.Provider
      value={{ getSize, listRef, setSize, sizeMap }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
