import { useQuery } from '@tanstack/react-query';

import API from '~/api';
import { usersKeys } from '~/constants/queryKeys';
import { useAuth } from '~/contexts/AuthContext';

export default function useFollowedNotableGroups() {
  const queryKey = usersKeys.followingGroups();
  const { isAuthenticated } = useAuth();
  const result = useQuery(queryKey, () => API.getFollowedNotableGroups(), {
    cacheTime: 1_000 * 60 * 60 * 24,
    enabled: isAuthenticated,
  });

  return {
    ...result,
    followingIds: result.data?.data ?? [],
  };
}
