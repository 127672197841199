import cn from 'classnames';
import { useRouter } from 'next/router';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import API from '~/api';
import { IcnDiscord, IcnWebsite, IcnX } from '~/assets';
import { AddDiscordLinkModal, SocialProfile, Toaster } from '~/components';
import config from '~/config';
import {
  UNEXPECTED_ERROR_MESSAGE,
  X_CONNECTION_FAILED_STATEMENT,
} from '~/constants';
import useContractInfo from '~/data/useContractInfo';
import type { AvailableChain, EditableContractInfo } from '~/types';
import extractXHandle from '~/utils/extractXHandle';
import isValidURL from '~/utils/isValidUrl';

import commonStyles from './Overview.module.scss';
import styles from './RelatedLinks.module.scss';

interface RelatedLinksProps
  extends Pick<
    EditableContractInfo,
    'discordGuildName' | 'discordUrl' | 'twitterUrl' | 'websiteUrl'
  > {
  onChange: (changedData: Partial<EditableContractInfo>) => void;
}

export default function RelatedLinks({
  discordGuildName,
  discordUrl,
  twitterUrl,
  websiteUrl,
  onChange,
}: RelatedLinksProps) {
  const router = useRouter();
  const { address, chain, discord, twitter } = router.query as {
    address: string;
    chain: Lowercase<AvailableChain>;
    discord?: 'true' | 'false';
    twitter?: 'true' | 'false';
  };
  const { refresh } = useContractInfo(chain, address);
  const [showAddDiscordLinkModal, setShowAddDiscordLinkModal] = useState(false);

  const handleDisconnectDiscord = async () => {
    await API.disconnectDiscordToContract(address);
    refresh();
    Toaster.toast({
      description: 'Discord disconnected successfully.',
      type: 'success',
    });
  };

  const handleDisconnectX = async () => {
    await API.disconnectXToContract(address);
    refresh();
    Toaster.toast({
      description: 'X disconnected successfully.',
      type: 'success',
    });
  };

  const handleValidateDiscord = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!address) {
      return Toaster.toast({
        description: UNEXPECTED_ERROR_MESSAGE,
        type: 'error',
      });
    }
    window.location.replace(`${config.API_HOST}/contracts/${address}/discord/`);
  };

  const handleValidateX = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!address) {
      return Toaster.toast({
        description: UNEXPECTED_ERROR_MESSAGE,
        type: 'error',
      });
    }
    window.location.replace(`${config.API_HOST}/contracts/${address}/twitter/`);
  };

  const handleTypeLink = useCallback(
    (
      e: ChangeEvent<HTMLInputElement>,
      link: 'discordUrl' | 'twitterUrl' | 'websiteUrl'
    ) => {
      e.stopPropagation();
      onChange({ [link]: e.target.value });
    },
    []
  );

  const xHandle = extractXHandle(twitterUrl);

  const isValidatedWebsiteUrl = isValidURL(websiteUrl);

  useEffect(() => {
    setTimeout(() => {
      if (discord === 'true') {
        setShowAddDiscordLinkModal(true);
      } else if (discord === 'false') {
        Toaster.toast({
          description:
            'The resource owner or authorization server denied the request',
          type: 'error',
        });
      }
      if (twitter === 'false') {
        Toaster.toast({
          description: X_CONNECTION_FAILED_STATEMENT,
          type: 'error',
        });
      }
    });
  }, []);

  return (
    <>
      <div className={commonStyles.container}>
        <div className={commonStyles.field_container}>
          <div className={styles.link_name_container}>
            <IcnWebsite />
            <label
              className={commonStyles.field_name}
              htmlFor="contract_website"
            >
              Website
            </label>
          </div>
          <input
            id="contract_website"
            className={cn(commonStyles.input, {
              [commonStyles.input_warning]:
                websiteUrl.length > 0 && !isValidatedWebsiteUrl,
            })}
            value={websiteUrl}
            onChange={(e) => handleTypeLink(e, 'websiteUrl')}
            placeholder="https://catchmint.xyz"
          />
          <div className={commonStyles.field_error_container}>
            {websiteUrl.length > 0 && !isValidatedWebsiteUrl && (
              <span className={commonStyles.field_error}>
                {'Only HTTPS-formatted URLs are allowed.'}
              </span>
            )}
          </div>
        </div>
        <div className={commonStyles.field_container}>
          <div className={styles.link_name_container}>
            <IcnX />
            <span className={commonStyles.field_name}>X</span>
          </div>
          {xHandle ? (
            <SocialProfile
              name={xHandle}
              provider="twitter"
              onDisconnect={handleDisconnectX}
            />
          ) : (
            <button className={styles.btn_connect} onClick={handleValidateX}>
              Connect
            </button>
          )}
        </div>
        <div className={commonStyles.field_container}>
          <div className={styles.link_name_container}>
            <IcnDiscord />
            <span className={commonStyles.field_name}>Discord</span>
          </div>
          {discordUrl ? (
            <SocialProfile
              name={discordGuildName || discordUrl}
              provider="discord"
              onDisconnect={handleDisconnectDiscord}
            />
          ) : (
            <button
              className={styles.btn_connect}
              onClick={handleValidateDiscord}
            >
              Connect
            </button>
          )}
        </div>
      </div>
      {showAddDiscordLinkModal && (
        <AddDiscordLinkModal
          address={address}
          chain={chain}
          onClose={() => {
            setShowAddDiscordLinkModal(false);
          }}
        />
      )}
    </>
  );
}
