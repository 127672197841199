import { forwardRef } from 'react';

import styles from './ToastProgressBar.module.scss';

interface ToastProgressBarProps {
  progressBarColor: string;
  progressColor: string;
  value: number;
}

export default forwardRef<HTMLDivElement, ToastProgressBarProps>(
  function ToastProgressBar({ progressBarColor, progressColor, value }, ref) {
    return (
      <div
        className={styles.container}
        ref={ref}
        style={{ backgroundColor: progressBarColor }}
      >
        <div
          role="progressbar"
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={value}
          style={{
            width: `${100 - value}%`,
            height: 4,
            backgroundColor: progressColor,
            transition: 'width .1s',
          }}
        />
      </div>
    );
  }
);
