import ActivityPanel from './ActivityPanel';
import MintsVolumePanel from './MintsVolumePanel';
import styles from './NotableGroupActivity.module.scss';

interface NotableGroupActivityProps {}

export default function NotableGroupActivity({}: NotableGroupActivityProps) {
  return (
    <div className={styles.container}>
      <MintsVolumePanel />
      <ActivityPanel />
    </div>
  );
}
