import cn from 'classnames';
import type { ReactNode } from 'react';

import styles from './DivComponent.module.scss';

interface divHeaderProps {
  children: ReactNode;
  className?: string;
  height?: string | number;
}

export default function CustomHeader({
  children,
  className,
  height,
}: divHeaderProps) {
  return (
    <div
      className={cn(styles.div_view_header, className)}
      style={{
        height,
      }}
    >
      {children}
    </div>
  );
}
