import { useCallback, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';

import type { SearchSuggestion } from '~/types';

import SuggestionHeader from './SuggestionHeader';
import SuggestionItem from './SuggestionItem';
import styles from './Suggestions.module.scss';

interface SuggestionsProps {
  focusedIndex: number;
  onSearch: (suggestion: SearchSuggestion, position: number) => void;
  suggestions: SearchSuggestion[];
}

export default function Suggestions({
  focusedIndex,
  onSearch,
  suggestions,
}: SuggestionsProps) {
  const suggestionListRef = useRef<HTMLUListElement | null>(null);
  const onClickItem = useCallback(
    (suggestion: SearchSuggestion, position: number) => {
      onSearch(suggestion, position);
    },
    [onSearch]
  );

  useEffect(() => {
    if (suggestionListRef.current) {
      const focusedElTop = 48 * focusedIndex;
      const viewTop = suggestionListRef.current.scrollTop;
      const viewBottom = Math.min(
        // the bottom of fully visible list
        48 * 9 + viewTop,
        suggestionListRef.current.scrollHeight
      );

      if (focusedElTop < viewTop || focusedElTop > viewBottom) {
        suggestionListRef.current.scrollTo({ top: focusedElTop });
      }
    }
  }, [focusedIndex]);

  return (
    <div className={styles.container}>
      <SuggestionHeader />
      <SimpleBar
        forceVisible="y"
        style={{ maxHeight: 504 }}
        scrollableNodeProps={{ ref: suggestionListRef }}
      >
        <ul className={styles.item_container}>
          {suggestions.map((suggestion, index) => (
            <SuggestionItem
              key={suggestion.address}
              focused={index === focusedIndex}
              index={index}
              suggestion={suggestion}
              onClickItem={onClickItem}
            />
          ))}
        </ul>
      </SimpleBar>
    </div>
  );
}
