import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import API from '~/api';
import { contractsKeys } from '~/constants/queryKeys';
import type { AvailableChain, ContractAnnouncement } from '~/types';
import getParamFromUrl from '~/utils/getParamFromUrl';

export default function useContractAnnouncements(
  chain: Lowercase<AvailableChain>,
  address: string
) {
  const queryClient = useQueryClient();
  const queryKey = contractsKeys.announcements(chain, address);

  const getContractAnnouncementsWithCursor = async (cursor: string | null) => {
    const {
      data: { next, results },
    } = await API.getContractAnnouncements(address, cursor);

    return {
      results,
      next: getParamFromUrl(next, 'cursor'),
      isLast: !next,
    };
  };

  const { data, isFetched, isLoading, fetchNextPage } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => getContractAnnouncementsWithCursor(pageParam),
    {
      getNextPageParam: ({ next }) => next,
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const announcements: ContractAnnouncement[] = useMemo(
    () => data?.pages.flatMap((page) => page.results) ?? [],
    [data?.pages]
  );

  const isItemLoaded: (index: number) => boolean = (index) =>
    data?.pages[data.pages.length - 1].isLast || index < announcements.length;

  const refreshAnnouncement = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    announcements,
    isFetched,
    isItemLoaded,
    isLoading,
    itemCount: data?.pages[data.pages.length - 1].isLast
      ? announcements.length
      : announcements.length + 1,
    loadMore: () => {
      fetchNextPage();
    },
    refreshAnnouncement,
  };
}
