import { ToggleSwitch } from '~/components';
import useDiscordWebhookSetting from '~/data/useDiscordWebhookSetting';
import type { NotificationProvider } from '~/types';

import Registered from './Registered';
import RegisterUrl from './RegisterUrl';
import styles from '../NotificationsSection.module.scss';

interface DiscordWebhookSettingProps {
  notificationEnabled: boolean;
  toggleNotification: () => void;
  provider?: NotificationProvider;
}

export default function DiscordWebhookSetting({
  notificationEnabled,
  provider,
  toggleNotification,
}: DiscordWebhookSettingProps) {
  const registeredUrl = provider?.key?.url as string | undefined;

  const {
    checkUrlValidation,
    isDeleting,
    isLoading,
    registerDiscordWebhook,
    removeDiscordWebhookNotification,
    setIsDeleting,
    setUrl,
    step,
    url,
  } = useDiscordWebhookSetting({
    registeredUrl,
  });

  return (
    <div className={styles.provider_container}>
      <span className={styles.provider_title}>Discord Webhook</span>
      <div className={styles.provider_setting_container}>
        <div className={styles.toggle_switch_container}>
          <ToggleSwitch
            checked={!!registeredUrl && notificationEnabled}
            disabled={!registeredUrl}
            name="Discord Webhook Notification Toggle Switch"
            onChange={toggleNotification}
          />
        </div>
        {step === 'RegisterUrl' && (
          <RegisterUrl
            canSubmit={checkUrlValidation(url) && !isLoading}
            isLoading={isLoading}
            onNext={registerDiscordWebhook}
            setUrl={setUrl}
            url={url}
          />
        )}
        {step === 'Registered' && (
          <Registered
            isDeleting={isDeleting}
            isLoading={isLoading}
            onNext={removeDiscordWebhookNotification}
            registeredUrl={registeredUrl}
            setIsDeleting={setIsDeleting}
          />
        )}
      </div>
      {step === 'RegisterUrl' && (
        <div className={styles.information_container}>
          <span className={styles.information_title}>
            {`Getting your webhook URL for a server`}
          </span>
          <div className={styles.information}>
            {`1. Right-click the channel you want to add alerts to (ensure you have admin permissions).`}
          </div>
          <div className={styles.information}>
            {`2. Go to `}
            <span>{`Edit Channel, then click Integrations`}</span>
          </div>
          <div className={styles.information}>
            {`3. Create a new webhook and click Copy Webhook URL.`}
          </div>
        </div>
      )}
    </div>
  );
}
