import { createContext } from 'react';
import { Connector } from 'wagmi';

import type { User } from '~/types';

export type AuthContextProps = {
  connectWallet: (connector: Connector) => void;
  isAuthenticated: boolean;
  isLoading: boolean;
  linkWallet: () => Promise<void>;
  setUserInfo: (user: User, shouldUpdateTrackingInfo?: boolean) => void;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  unlinkWallet: (address: string) => Promise<void>;
  user: User | null;
} | null;

const defaultAuthContext = null;

export const AuthContext = createContext<AuthContextProps>(defaultAuthContext);
