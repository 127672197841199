import { createContext, MutableRefObject } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';

export type NotificationsContextProps = {
  getSize: (index: number) => number;
  listRef: MutableRefObject<InfiniteLoader | null>;
  setSize: (index: number, size: number) => void;
  sizeMap: MutableRefObject<{
    [key: number]: number;
  } | null>;
} | null;

export const NotificationsContext =
  createContext<NotificationsContextProps>(null);
