export default function formatTimeAgo(diff: number) {
  const seconds = Math.floor(diff * 0.001);

  if (seconds < 1) return `${seconds < 0 ? 0 : seconds} secs ago`;
  if (seconds < 2) return `${seconds} sec ago`;
  if (seconds < 60) return `${seconds} secs ago`;
  if (seconds < 60 * 2) return `1 min ago`;
  if (seconds < 60 * 60) return `${Math.floor(seconds / 60)} mins ago`;
  if (seconds < 60 * 60 * 2) return `1 hr ago`;
  if (seconds < 60 * 60 * 24)
    return `${Math.floor(seconds / (60 * 60))} hrs ago`;
  if (seconds < 60 * 60 * 24 * 2) return `1 day ago`;
  return `${Math.floor(seconds / (60 * 60 * 24))} days ago`;
}
