import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { CONNECT_X_LOCATION } from '~/constants';
import type { ConnectXLocation } from '~/types';

const { persistAtom } = recoilPersist({ key: CONNECT_X_LOCATION });

const linkWalletModalState = atom<{ isOpened: boolean }>({
  key: 'linkWalletModal',
  default: {
    isOpened: false,
  },
});

const locationConnectXClickedState = atom<ConnectXLocation>({
  key: 'locationConnectXClicked',
  default: 'profile',
  effects_UNSTABLE: [persistAtom],
});

const usernameRequiredModalState = atom<{ isOpened: boolean; reason?: string }>(
  {
    key: 'usernameRequiredModal',
    default: {
      isOpened: false,
      reason: '',
    },
  }
);

export {
  linkWalletModalState,
  locationConnectXClickedState,
  usernameRequiredModalState,
};
