import { useQuery, useQueryClient } from '@tanstack/react-query';

import API from '~/api';
import { usersKeys } from '~/constants/queryKeys';
import showErrorToast from '~/utils/showErrorToast';

export default function useSocialAccounts() {
  const queryClient = useQueryClient();
  const queryKey = usersKeys.socialAccounts();
  const result = useQuery(queryKey, () => API.getSocialAccounts());

  const socialAccounts = result.data?.data.results ?? [];
  const xAccount = socialAccounts.find(
    (socialAccount) => socialAccount.provider === 'twitter'
  );

  const disconnect: (id: number) => Promise<boolean> = async (id: number) => {
    try {
      await API.disconnectSocialAccount(id);
      refresh();
      return true;
    } catch (e) {
      showErrorToast(e);
      return false;
    }
  };

  const refresh = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    ...result,
    disconnect,
    refresh,
    socialAccounts,
    xAccount,
  };
}
