import config from '~/config';
import type {
  AppSettings,
  AutomationPartner,
  ContractQuickLink,
  DetailLayout,
  Flag,
  LayoutSetting,
  MainLayout,
  MintsOverviewFilterTimeOption,
  NotableGroupsFilter,
  NotificationTriggerSettings,
} from '~/types';

export const firebaseConfig = new URLSearchParams({
  apiKey: config.FIREBASE_API_KEY,
  projectId: config.FIREBASE_PROJECT_ID,
  messagingSenderId: config.FIREBASE_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
});
// chains
export const AVAILABLE_CHAIN = {
  ETHEREUM: 'Ethereum',
} as const;
// contract actions
export const CONTRACT_ACTION = {
  FLAG: 'Flag',
  HIDE: 'Hide',
  UNFLAG: 'Unflag',
} as const;
export const COLLECTION_CLICKED_LOCATION = {
  COLLECTION_CUSTOMIZE: 'collection_customize',
  COMMENTS: 'comments',
  HIDDEN_CONTRACTS: 'hidden_contracts',
  LIVE_MINTS: 'live_mints',
  MINTS_OVERVIEW: 'mints_overview',
  MY_COLLECTIONS: 'my_collections',
  NOTIFICATION_PANEL: 'notification_panel',
  SCAM_ALERT: 'scam_alert',
  SEARCH_BAR: 'search_bar',
  X_HISTORY: 'x_history',
} as const;
export const PAGE_VIEW = {
  COLLECTION_CUSTOMIZE: 'Collection Customize',
  COLLECTION_DETAIL: 'Collection Detail',
  COMMENTS: 'Comments',
  GROUP_ACTIVITY: 'Group Activity',
  HOME: 'Home',
  MY_COLLECTIONS: 'My Collections',
} as const;
export const REDIRECT_CLICKED_TYPE = {
  BLOCK_EXPLORER: 'block_explorer',
  COLLECTION_WEBSITE: 'collection_website',
  MARKETPLACE: 'marketplace',
  OTHER: 'other',
  TOOL: 'tool',
} as const;
export const REDIRECT_CLICKED_LOCATION = {
  ANNOUNCEMENTS: 'announcements',
  ART: 'art',
  CODE_SNIPPET: 'code_snippet',
  COMMENTS: 'comments',
  CONTRACT_INFO: 'contract_info',
  DISCUSSIONS: 'discussions',
  LIVE_MINTS: 'live_mints',
  MARKETPLACE_COMPARISON: 'marketplace_comparison',
  MINTS_OVERVIEW: 'mints_overview',
  NOTABLE_GROUPS: 'notable_groups',
  NOTABLE_GROUP_ACTIVITY: 'notable_group_activity',
  OTHER: 'other',
  TOP_100_HOLDERS: 'top_100_holders',
} as const;
// app settings
export const THEME = {
  DARK: 'dark',
  LIGHT: 'light',
  SYSTEM: 'system',
} as const;
export const CONTRACT_QUICK_LINK = {
  BLUR: 'Blur',
  DISCORD: 'Discord',
  COINBASE: 'Coinbase',
  ETHERSCAN: 'Etherscan',
  LOOKSRARE: 'LooksRare',
  MAGICALLY: 'Magically',
  MAGIC_EDEN: 'Magic Eden',
  NFTFLIP: 'NFTFlip',
  NFTNERDS: 'NFTNerds',
  OPENSEA: 'OpenSea',
  OPENSEA_PRO: 'OpenSea Pro',
  SUDOSWAP: 'Sudoswap',
  UNISWAP: 'Uniswap',
  WEBSITE: 'Website',
  X: 'X',
  X2Y2: 'X2Y2',
} as const;
export const FAILED_TRANSACTIONS_GROUP_BY = {
  METHOD: 'Method',
  TYPE: 'Type',
} as const;
export const HIDDEN_VISIBILITY = {
  BLUR: 'blur',
  REMOVE: 'remove',
} as const;
export const TOP_100_HOLDERS_VIEW = {
  CLUSTERED: 'Clustered',
  WALLETS: 'Wallets',
} as const;
export const DEFAULT_THEME = THEME.SYSTEM;
export const DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT_ORDER: ContractQuickLink[] = [
  CONTRACT_QUICK_LINK.BLUR,
  CONTRACT_QUICK_LINK.OPENSEA,
  CONTRACT_QUICK_LINK.ETHERSCAN,
  CONTRACT_QUICK_LINK.X,
  CONTRACT_QUICK_LINK.WEBSITE,
  CONTRACT_QUICK_LINK.NFTNERDS,
];
export const QUICK_LINKS: ContractQuickLink[] = [
  CONTRACT_QUICK_LINK.BLUR,
  CONTRACT_QUICK_LINK.OPENSEA,
  CONTRACT_QUICK_LINK.ETHERSCAN,
  CONTRACT_QUICK_LINK.X,
  CONTRACT_QUICK_LINK.WEBSITE,
  CONTRACT_QUICK_LINK.NFTNERDS,
  CONTRACT_QUICK_LINK.DISCORD,
  CONTRACT_QUICK_LINK.OPENSEA_PRO,
  CONTRACT_QUICK_LINK.MAGICALLY,
  CONTRACT_QUICK_LINK.MAGIC_EDEN,
  CONTRACT_QUICK_LINK.NFTFLIP,
  CONTRACT_QUICK_LINK.X2Y2,
  CONTRACT_QUICK_LINK.LOOKSRARE,
  CONTRACT_QUICK_LINK.COINBASE,
  CONTRACT_QUICK_LINK.UNISWAP,
  CONTRACT_QUICK_LINK.SUDOSWAP,
];
export const DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT: LayoutSetting<ContractQuickLink>[] =
  QUICK_LINKS.map((name) => ({
    name,
    visible: DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT_ORDER.includes(name),
  }));
export const QUICKLINKS_ORDERING_EXAMPLE_INFO = {
  address: '0xd774557b647330C91Bf44cfEAB205095f7E6c367',
  imageUrl:
    'https://i.seadn.io/gcs/files/1619b033c453fe36c5d9e2ac451379a7.png?w=500&auto=format',
  name: 'Nakamigos',
};
export const DETAIL_LAYOUT = {
  ART: 'Art',
  AVERAGE_ENTRY_POINT: 'Average Entry Point',
  CODE_SNIPPET: 'Code Snippet',
  CONTRACT_INFORMATION: 'Contract Information',
  DISCUSSIONS: 'Discussions',
  FAILED_TRANSACTIONS: 'Failed Transactions',
  MARKETPLACE_COMPARISON: 'Marketplace Comparison',
  NOTABLE_GROUPS_MINTED: 'Notable Groups Minted',
  SUCCESSFUL_TRANSACTIONS: 'Successful Transactions',
  TOP_100_HOLDERS: 'Top 100 Holders',
} as const;
export const DEFAULT_GROUP_ACTIVITY = {
  excludeAirdrop: false,
  hiddenVisibility: HIDDEN_VISIBILITY.REMOVE,
  onlyFree: false,
  onlyMintable: false,
  onlyVerified: false,
  priceRangeMax: 1000,
  priceRangeMin: 0,
} as const;
export const DEFAULT_LIVE_MINTS = {
  addressPinned: false,
  excludeAirdrop: false,
  hiddenVisibility: HIDDEN_VISIBILITY.REMOVE,
  onlyFree: false,
  onlyVerified: false,
  priceRangeMax: 1000,
  priceRangeMin: 0,
} as const;
export const DEFAULT_MINTS_OVERViEW = {
  hiddenVisibility: HIDDEN_VISIBILITY.BLUR,
  onlyMintable: false,
  timeframe: '3600',
} as const;
export const DEFAULT_FAILED_TRANSACTIONS = {
  groupBy: FAILED_TRANSACTIONS_GROUP_BY.METHOD,
} as const;
export const DEFAULT_TOP_100_HOLDERS = {
  view: TOP_100_HOLDERS_VIEW.WALLETS,
} as const;
export const DEFAULT_DETAIL_LAYOUT_ORDER: DetailLayout[] = [
  DETAIL_LAYOUT.CONTRACT_INFORMATION,
  DETAIL_LAYOUT.DISCUSSIONS,
  DETAIL_LAYOUT.ART,
  DETAIL_LAYOUT.NOTABLE_GROUPS_MINTED,
  DETAIL_LAYOUT.SUCCESSFUL_TRANSACTIONS,
  DETAIL_LAYOUT.FAILED_TRANSACTIONS,
  DETAIL_LAYOUT.CODE_SNIPPET,
  DETAIL_LAYOUT.AVERAGE_ENTRY_POINT,
  DETAIL_LAYOUT.TOP_100_HOLDERS,
  DETAIL_LAYOUT.MARKETPLACE_COMPARISON,
];
export const DEFAULT_DETAIL_LAYOUT: LayoutSetting<DetailLayout>[] =
  DEFAULT_DETAIL_LAYOUT_ORDER.map((name) => ({ name, visible: true }));
export const MAIN_LAYOUT = {
  CONTRACT_DETAIL: 'Contract Detail',
  LIVE_MINTS: 'Live Mints',
  MINTS_OVERVIEW: 'Mints Overview',
} as const;
export const DEFAULT_MAIN_LAYOUT_ORDER: MainLayout[] = [
  MAIN_LAYOUT.MINTS_OVERVIEW,
  MAIN_LAYOUT.CONTRACT_DETAIL,
  MAIN_LAYOUT.LIVE_MINTS,
];
export const DEFAULT_MAIN_LAYOUT: LayoutSetting<MainLayout>[] =
  DEFAULT_MAIN_LAYOUT_ORDER.map((name) => ({
    name,
    visible: true,
  }));
export const SETTINGS_SECTIONS = [
  { id: 0, name: 'Appearance', children: [] },
  { id: 1, name: 'Hidden Contracts', children: [] },
  { id: 2, name: 'Mint Automation', children: [] },
  { id: 3, name: 'Quicklinks', children: [] },
  { id: 4, name: 'Notifications', children: [] },
  { id: 5, name: 'Profile', children: [] },
  { id: 6, name: 'Wallets', children: [] },
];
export const SETTINGS_SUB_SECTIONS = {
  1: [{ id: 0, name: 'Hidden Contracts' }],
};
export const AUTOMATION_PARTNERS: AutomationPartner[] = [
  'alphahq',
  'breezeaio',
  'fenixlabs',
  'hangout',
  'jpegalerts',
  'lucidlabs',
  'metasniper',
  'mintech',
  'nfthunder',
  'nftsensei',
  'tenx',
  'timith',
  'waifu',
];
export const DEFAULT_DISPLAY = {
  detail: DEFAULT_DETAIL_LAYOUT_ORDER,
  main: DEFAULT_MAIN_LAYOUT_ORDER,
  quickLinks: DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT_ORDER,
} as const;
export const DEFAULT_SETTINGS = {
  detailLayout: DEFAULT_DETAIL_LAYOUT,
  mainLayout: DEFAULT_MAIN_LAYOUT,
  quickLinksLayout: DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT,
} as const;
export const DEFAULT_AUTOMATIONS = { partners: [], default: null };
export const DEFAULT_APP_SETTINGS: AppSettings = {
  automations: DEFAULT_AUTOMATIONS,
  display: DEFAULT_DISPLAY,
  failedTransactions: DEFAULT_FAILED_TRANSACTIONS,
  liveMints: DEFAULT_LIVE_MINTS,
  mintsOverview: DEFAULT_MINTS_OVERViEW,
  theme: DEFAULT_THEME,
  top100Holders: DEFAULT_TOP_100_HOLDERS,
  settings: DEFAULT_SETTINGS,
} as const;
// durations
export const AVERAGE_BLOCK_TIME = 12_000;
export const LIVE_MINTS_NEXT_RENDERING_TIME = 250;
export const LIVE_MINTS_SHOWING_GAP_TIME = 120_000;
export const NOTIFY_SOCKET_CONNECTED = 1_000;
export const RENDERING_BOUND_TIME = 500;
export const TRANSITION_TIME = 600;
export const REFETCH_DURATION = 3_000;
export const SHOW_COPIED = 800;
export const API_DEBOUNCE_DELAY = 350;
export const HUMAN_MEDIAN_REACTION_TIME = 250;
export const URGENT_TIME = 180_000;
export const MINIMUM_LOADING_TIME = 750;
// socket_channels
export const CHANNEL_APP_HOOKS = 'appHooks';
export const CHANNEL_GROUP_ACTIVITY_MINTS = 'groupActivityMints';
export const CHANNEL_GROUP_ACTIVITY_MINTS_VOLUME = 'groupActivityMintsVolume';
export const CHANNEL_LIVE_MINTS = 'liveMints';
export const CHANNEL_MINTS_OVERVIEW = 'mintsOverview';
export const CHANNEL_PENDINGS = 'pendings';
// socket_events
export const EVENT_ACTIVE_VIEWERS = 'activeViewers';
export const EVENT_ART = 'art';
export const EVENT_CONTRACT_INFO = 'contractInfo';
export const EVENT_DATA = 'data';
export const EVENT_GAS_PRICE = 'gasPrice';
export const EVENT_GAS_SUGGESTION = 'gasSuggestion';
export const EVENT_PENDINGS_LATEST = 'latest';
export const EVENT_SHOULD_UPDATE_HIDE_LIST = 'shouldUpdateHideList';
// flag reason
export const FLAG_TYPE = {
  SCAM: 'Scam',
  DRAIN: 'Drain',
  COPY: 'Copy',
  HONEYPOT: 'Honeypot',
  OTHER: 'Other',
} as const;
export const FLAG_REASON: { [key in Flag]: string } = {
  [FLAG_TYPE.SCAM]: 'Fake/Rug/Scam',
  [FLAG_TYPE.DRAIN]: 'Wallet Drain',
  [FLAG_TYPE.COPY]: 'Copy/Stolen',
  [FLAG_TYPE.HONEYPOT]: 'Honeypot',
  [FLAG_TYPE.OTHER]: 'Other',
} as const;
// live mints
export const PRICE_MIN_VALUE = 0;
export const PRICE_MAX_VALUE = 1000;
// notable groups
export const NotableGroupsFilterList: NotableGroupsFilter[] = [
  'New',
  'Popular',
  'A to Z',
];
export const REQUEST_NOTABLE_GROUP_URL = 'https://forms.gle/k8ApiPwfzagwRuwJA';
// certain addresses
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
// successful transactions
export const FILTER_CLEAR_ALL = 'Clear All';
export const MINTS_OVERVIEW_SKELETON_ITEM_NUMBER = 50;
export const LIVE_MINTS_VISIBLE_ITEM_NUMBER = 20;
export const MANIFOLD_CONTRACT_ADDRESSES = [
  '0xa46f952645D4DeEc07A7Cd98D1Ec9EC888d4b61E',
  '0x44e94034AFcE2Dd3CD5Eb62528f239686Fc8f162',
];
export const MANIFOLD_CREATOR_CONTRACT_ADDRESS_ARGUMENT =
  'creatorContractAddress';
// sentence
export const TOTAL_MINTED_HINT_TOTAL_SUPPLY_NOT_SUPPORTED =
  'This contract doesn’t support IERC721Enumerable interface that has total_supply() function. Might differ from the real supply but should be close.';
export const TOTAL_MINTED_HINT_OLD_COLLECTIONS =
  'The number might be off for old collections.';
// local storage
export const APP_SETTINGS = 'catchmint.settings';
export const APP_SETTINGS_MODIFIED = 'catchmint.settings_modified';
export const APP_THEME = 'catchmint.theme';
export const APP_NEW_FEATURES_LAST_ID = 'catchmint.new_features.last_id';
export const APP_USER_ID = 'catchmint.user_id';
export const APP_VERSION = 'catchmint.version';
export const CONNECT_X_LOCATION = 'catchmint.connect_x_location';
// response messages
export const UNEXPECTED_ERROR_MESSAGE =
  'An unexpected error has occurred. Please try again.';
// tooltip
export const NOTABLE_GROUPS_AGGREGATES =
  'Counted without duplicates if the minter is in multiple groups';
export const AGGREGATE_AIRDROPS = 'Airdrops';
export const AGGREGATE_MINTS = 'Mints';
export const AGGREGATE_WALLETS = 'Wallets';
// external links
export const APP_DISCLAIMER =
  'https://catchmint.notion.site/Disclaimer-6304b379a4c64b6ab94cd715cab8c7e5';
export const APP_DISCORD_LINK = 'https://discord.gg/KgNSsdZcja';
export const APP_PRIVACY_LINK =
  'https://catchmint.notion.site/Privacy-Policy-5e41492c9f7b4d258d5e16bb11a43eca';
export const APP_TERMS_LINK =
  'https://catchmint.notion.site/Terms-of-Service-d6f251913f144928b09934ce9d9d44a6';
export const APP_X_LINK = 'https://x.com/catchmintxyz';
export const BLUR_ROYALTY_DESCRIPTION_LINK =
  'https://mirror.xyz/blurdao.eth/2nba-2j0zHPrBX0iPSNGquZ9s_WotNH6B4e5usz85mM';
export const LOOKSRARE_ROYALTY_DESCRIPTION_LINK =
  'https://docs.looksrare.org/blog/looksrare-offers-zero-royalty-trading-shares-protocol-fees-with-creators-instead';
export const UNDER_CONSTRUCTION = 'Under Construction';
export const UNIDENTIFIED_CONTRACT = 'Unidentified contract';
// automations
export const ALPHAHQ_QT_URL = 'https://app.alphahq.io/mintbot';
export const BREEZEAIO_QT_URL = 'http://localhost:7683/catchmint';
export const FENIXLABS_QT_URL = 'https://automations.fenixlabs.io/tasks';
export const HANGOUT_QT_URL = 'http://localhost:61223/quick_tasks';
export const JPEGALERTS_QT_URL = 'http://localhost:42099/catchmint';
export const LUCIDLABS_QT_URL = 'http://localhost:11111/quicktask';
export const METASNIPER_QT_URL = 'http://localhost:3333/catchmint';
export const MINTECH_QT_URL = 'http://localhost:4562/quicktask';
export const NFTHUNDER_QT_URL = 'http://localhost:7777/quickTask';
export const NFTSENSEI_QT_URL =
  'https://app.sensei.win/mintbot?txData=CATCHMINT';
export const TENX_QT_URL = 'http://localhost:1739/qt';
export const TIMITH_QT_URL = 'http://localhost:1738/catchmint';
export const WAIFU_QT_URL = 'http://localhost:7780/invoke';
// comments
export const DEFAULT_SHOWING_COMMENTS = 3;
export const ENABLE_TO_DOWNVOTE_KARMA = 500;
export const DISCUSSIONS_GUIDELINES_LINK =
  'https://catchmint.notion.site/Guidelines-1e45d999c78b47f6a187e52755b75217';
export const DEAD_SCORE_COUNT = -20;
export const DEFAULT_FOLDED_FLAG_SCORE = -5;
export const TOWARDS_DEATH_FIRST_SCORE_COUNT = -5;
export const TOWARDS_DEATH_SECOND_SCORE_COUNT = -10;
export const TOWARDS_DEATH_THIRD_SCORE_COUNT = -15;
export const X_CONNECTION_FAILED_STATEMENT =
  'There was an error connecting your X account. Please open a ticket in our discord.';
export const X_LOGIN_URL = `${config.API_HOST}/accounts/twitter/login/`;
// contract customization
export const ANNOUNCEMENT_TITLE_CHARACTER_LIMITS = 80;
export const ANNOUNCEMENT_DESCRIPTION_CHARACTER_LIMITS = 1000;
export const CONTRACT_NAME_CHARACTER_LIMITS = 100;
export const CONTRACT_DESCRIPTION_CHARACTER_LIMITS = 1000;
export const CONTRACT_CUSTOMIZATION_TABS = {
  OVERVIEW: 'Overview',
  ANNOUNCEMENTS: 'Announcements',
} as const;
export const FILE_UPLOAD_SIZE_MAX_LIMIT = 10 * 1024 * 1024;
export const AVAILABLE_IMAGE_EXTENSION = {
  GIF: 'gif',
  JPEG: 'jpeg',
  JPG: 'jpg',
  PNG: 'png',
  SVG: 'svg',
  TIFF: 'tiff',
  WEBP: 'webp',
} as const;
export const AVAILABLE_VIDEO_EXTENSION = {
  MOV: 'mov',
  MP4: 'mp4',
  OGG: 'ogg',
  WEBM: 'webm',
} as const;
export const UTM_SOURCE_BLACKLIST = [
  'app.uniswap.org',
  'blur.io',
  'etherscan.io',
  'looksrare.org',
  'magically.gg',
  'nftnerds.ai',
  'nft.coinbase.com',
  'magiceden.io',
  'opensea.io',
  'pro.opensea.io',
  'review.nftflip.ai',
  'sudoswap.xyz',
] as const;
export const EXTERNAL_LINK: { [key: string]: string } = {
  BLUR: 'Blur',
  DISCORD: 'Discord',
  COINBASE: 'Coinbase',
  ETHERSCAN: 'Etherscan',
  LOOKSRARE: 'LooksRare',
  NFTNERDS: 'NFTNerds',
  OPENSEA: 'OpenSea',
  UNISWAP: 'Uniswap',
  WEBSITE: 'Website',
  X: 'X',
  X2Y2: 'X2Y2',
} as const;
export const IMAGE_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  HUGE: 'huge',
} as const;
export const PLAY_STATUS = {
  LOADING: 'loading',
  PAUSED: 'paused',
  PLAYED: 'played',
  STOPPED: 'stopped',
} as const;
export const MARKETPLACE_NAME = {
  BLUR: 'blur',
  LOOKSRARE: 'looksrare',
  OPENSEA: 'opensea',
  X2Y2: 'x2y2',
} as const;
export const MINT_OPTION: {
  [key in Uppercase<AutomationPartner>]: string;
} = {
  ALPHAHQ: 'Alpha HQ',
  BREEZEAIO: 'BreezeAIO',
  FENIXLABS: 'Fenix Labs',
  HANGOUT: 'Hangout',
  JPEGALERTS: 'Jpeg Alerts',
  LUCIDLABS: 'Lucid Labs',
  METASNIPER: 'MetaSniper',
  MINTECH: 'MinTech',
  NFTHUNDER: 'NFThunder',
  NFTSENSEI: 'NFT Sensei',
  TENX: 'TenX',
  TIMITH: 'Timith',
  WAIFU: 'Waifu',
} as const;
export const MINTS_OVERVIEW_FILTER_TIME_OPTION: {
  [key in MintsOverviewFilterTimeOption]: string;
} = {
  '0': '',
  '60': '1m',
  '180': '3m',
  '300': '5m',
  '600': '10m',
  '1800': '30m',
  '3600': '1h',
  '10800': '3h',
  '21600': '6h',
  '43200': '12h',
  '86400': '24h',
} as const;
// notable wallets
export const SAFE_ADDRESS = '0x32469531686D921b4D9cA6DAA2306a5ef730de2A';
export const GLOBAL_COMMENTS_FILTER = {
  NEW: 'new',
  VOTE: 'vote',
} as const;
export const GLOBAL_COMMENTS_FILTER_VOTE_OPTION = {
  TODAY: 'today',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  ALL: 'all',
} as const;
export const GLOBAL_COMMENTS_FILTER_VOTE_OPTION_DISPLAY: {
  [key in keyof typeof GLOBAL_COMMENTS_FILTER_VOTE_OPTION]: string;
} = {
  TODAY: 'Today',
  WEEK: 'This Week',
  MONTH: 'This Month',
  YEAR: 'This Year',
  ALL: 'All Time',
} as const;
// mint separator
export const AMOUNT_SEPARATOR = '${amount}';
export const FROM_SEPARATOR = '${from}';
export const WRITE_INFORMATION_FROM = {
  GLOBAL: 'global',
  SUCCESSFUL_TX: 'successful_tx',
} as const;
// notifications
export const NOTIFICATION_TRIGGER_FIVE_MINUTE_MINTING_SPIKE = {
  '300': 300,
  '350': 350,
  '400': 400,
  '450': 450,
  '500': 500,
  FALSE: false,
} as const;
export const NOTIFICATION_TRIGGER_INITIAL_MINT_ALERT = {
  FALSE: false,
  TRUE: true,
} as const;
export const NOTIFICATION_TRIGGER_MINT_PROGRESS_ALERT = {
  '50': 50,
  '60': 60,
  '70': 70,
  '80': 80,
  '90': 90,
  FALSE: false,
} as const;
export const NOTIFICATION_TRIGGER_ONE_MINUTE_MINTING_SPIKE = {
  '100': 100,
  '125': 125,
  '150': 150,
  '175': 175,
  '200': 200,
  FALSE: false,
} as const;
export const NOTIFICATION_TRIGGER_PENDING_TRANSACTIONS_ALERT = {
  '50': 50,
  '75': 75,
  '100': 100,
  '125': 125,
  '150': 150,
  '175': 175,
  '200': 200,
  FALSE: false,
} as const;
export const NOTIFICATION_TRIGGER_REPLY_TO_COMMENT = {
  FALSE: false,
  TRUE: true,
} as const;
export const NOTIFICATION_TRIGGER_VIEWER_SPIKE_ALERT = {
  '100': 100,
  '125': 125,
  '150': 150,
  '175': 175,
  '200': 200,
  FALSE: false,
} as const;
export const NOTIFICATION_TRIGGER_DESCRIPTION = {
  FIVE_MINUTE_MINTING_SPIKE:
    'Get notified when the number of mints for a collection exceeds a specific threshold within a 5-minute duration',
  INITIAL_MINT_ALERT:
    'Get notified when the first NFT in a collection is minted',
  MINT_PROGRESS_ALERT:
    'Get notified when a collection is minted over a set percentage threshold',
  ONE_MINUTE_MINTING_SPIKE:
    'Get notified when the number of mints for a collection exceeds a specific threshold within a 1-minute duration',
  PENDING_TRANSACTIONS_ALERT:
    'Get notified when pending transactions for a collection exceed a specific threshold',
  REPLY_TO_COMMENT: '(Web push notifications only)',
  VIEWER_SPIKE_ALERT:
    'Get notified when active viewers on a collection exceed a specified threshold',
} as const;
export const NOTIFICATION_TRIGGER_TYPE = {
  FIVE_MINUTE_MINTING_SPIKE: '5-Minute Minting Spike',
  INITIAL_MINT_ALERT: 'Initial Mint Alert',
  MINT_PROGRESS_ALERT: 'Mint Progress Alert',
  ONE_MINUTE_MINTING_SPIKE: '1-Minute Minting Spike',
  PENDING_TRANSACTIONS_ALERT: 'Pending Transactions Alert',
  REPLY_TO_COMMENT: 'Reply to Comment',
  VIEWER_SPIKE_ALERT: 'Viewer Spike Alert',
} as const;
export const DEFAULT_TRIGGER_SETTING: NotificationTriggerSettings = {
  [NOTIFICATION_TRIGGER_TYPE.FIVE_MINUTE_MINTING_SPIKE]:
    NOTIFICATION_TRIGGER_FIVE_MINUTE_MINTING_SPIKE.FALSE,
  [NOTIFICATION_TRIGGER_TYPE.INITIAL_MINT_ALERT]:
    NOTIFICATION_TRIGGER_INITIAL_MINT_ALERT.FALSE,
  [NOTIFICATION_TRIGGER_TYPE.MINT_PROGRESS_ALERT]:
    NOTIFICATION_TRIGGER_MINT_PROGRESS_ALERT.FALSE,
  [NOTIFICATION_TRIGGER_TYPE.ONE_MINUTE_MINTING_SPIKE]:
    NOTIFICATION_TRIGGER_ONE_MINUTE_MINTING_SPIKE.FALSE,
  [NOTIFICATION_TRIGGER_TYPE.PENDING_TRANSACTIONS_ALERT]:
    NOTIFICATION_TRIGGER_PENDING_TRANSACTIONS_ALERT.FALSE,
  [NOTIFICATION_TRIGGER_TYPE.REPLY_TO_COMMENT]:
    NOTIFICATION_TRIGGER_REPLY_TO_COMMENT.FALSE,
  [NOTIFICATION_TRIGGER_TYPE.VIEWER_SPIKE_ALERT]:
    NOTIFICATION_TRIGGER_VIEWER_SPIKE_ALERT.FALSE,
};
export const NOTIFICATION_TARGET_LIST = {
  DISCORD_WEBHOOK: 'DiscordWebhook',
  WEB_PUSH: 'WebPush',
} as const;
// app versions
export const VERSION_SHOULD_RESET_QUICK_LINKS = '0.64.7';
export const VERSION_NEW_FEATURES_ID = '3';
