import _isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { APP_SETTINGS_MODIFIED } from '~/constants';
import { top100HoldersFilterState } from '~/store/app';
import type { Top100HoldersFilter } from '~/types';

export default function useTop100HoldersFilter() {
  const [top100HoldersFilter, setTop100HoldersFilter] = useRecoilState(
    top100HoldersFilterState
  );

  const updateTop100HoldersFilter = useCallback(
    (updatedTop100HoldersFilter: Partial<Top100HoldersFilter>) => {
      setTop100HoldersFilter((prev) => {
        if (_isEqual(prev, updatedTop100HoldersFilter)) {
          return prev;
        }
        const timestamp = new Date().getTime().toString();
        localStorage.setItem(APP_SETTINGS_MODIFIED, timestamp);

        return {
          ...prev,
          ...updatedTop100HoldersFilter,
        };
      });
    },
    []
  );

  return [top100HoldersFilter, updateTop100HoldersFilter] as const;
}
