import { useMutation } from '@tanstack/react-query';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useReCaptcha } from 'next-recaptcha-v3';
import { memo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSetRecoilState } from 'recoil';

import API from '~/api';
import { IcnSmallExternal, IcnSmallRefresh } from '~/assets';
import {
  CommentInput,
  CommentList,
  ExternalLink,
  Loading,
  Toaster,
} from '~/components';
import {
  DETAIL_LAYOUT,
  DISCUSSIONS_GUIDELINES_LINK,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import { useAuth } from '~/contexts/AuthContext';
import useContractComments from '~/data/useContractComments';
import { usernameRequiredModalState } from '~/store/user';
import type { AvailableChain, FlagParams, VoteParams } from '~/types';
import showErrorToast from '~/utils/showErrorToast';

import styles from './DiscussionsSection.module.scss';
import SectionLayout from './SectionLayout';

interface DiscussionsSectionProps {
  address: string;
  chain: AvailableChain;
  isLoading: boolean;
  deployer?: string | null;
}

export default memo(function DiscussionsSection({
  address,
  chain,
  deployer,
  isLoading,
}: DiscussionsSectionProps) {
  const analytics = useAnalyticsContext();
  const { isAuthenticated, setUserInfo, signIn, user } = useAuth();
  const router = useRouter();
  const {
    comments,
    isLoading: isLoadingContractComments,
    ref,
    refreshComment,
    totalCount,
    updateComment,
  } = useContractComments(
    chain.toLowerCase() as Lowercase<AvailableChain>,
    address
  );
  const { executeRecaptcha } = useReCaptcha();
  const setUsernameRequiredModal = useSetRecoilState(
    usernameRequiredModalState
  );
  const [isRefreshing, setIsRefreshing] = useState(false);
  const hash = router.asPath.split('#')?.[1];

  const showSkeleton = isLoading || isLoadingContractComments;

  const addCommentMutation = useMutation(
    ({
      reCaptchaToken,
      text,
      parentId,
    }: {
      reCaptchaToken: string;
      text: string;
      parentId?: string;
    }) => API.addCommentToContract(address, reCaptchaToken, text, parentId),
    {
      onSuccess() {
        refreshComment();
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const deleteCommentMutation = useMutation(
    (uuid: string) => API.deleteComment(uuid),
    {
      onSuccess() {
        refreshComment();
        Toaster.toast({
          description: 'Comment deleted successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const downvoteCommentMutation = useMutation(
    (uuid: string) => API.downvoteComment(uuid),
    {
      onSuccess({ data: { karma } }, uuid) {
        if (!!user) setUserInfo({ ...user, karma });
        updateComment({ isDownvote: true, isUpvote: false, uuid });
        Toaster.toast({
          description: 'Comment downvoted successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const editCommentMutation = useMutation(
    ({ comment, uuid }: { comment: string; uuid: string }) =>
      API.editComment(uuid, comment),
    {
      onSuccess(_, { comment, uuid }) {
        updateComment({ comment, uuid });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const flagCommentMutation = useMutation(
    ({ uuid }: FlagParams) => API.flagComment(uuid),
    {
      onSuccess({ data: { karma } }, { flagScore, uuid }) {
        if (!!user) setUserInfo({ ...user, karma });
        updateComment({
          flagScore: flagScore + 1,
          isFlagged: true,
          isFlaggedItem: true,
          uuid,
        });
        Toaster.toast({
          description: 'Comment flagged successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const pinCommentMutation = useMutation(
    (uuid: string) => API.pinComment(uuid),
    {
      onSuccess() {
        refreshComment();
        Toaster.toast({
          description: 'Comment pinned successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const unflagCommentMutation = useMutation(
    ({ uuid }: FlagParams) => API.unflagComment(uuid),
    {
      onSuccess({ data: { karma } }, { flagScore, uuid }) {
        if (!!user) setUserInfo({ ...user, karma });
        updateComment({
          flagScore: flagScore - 1,
          isFlagged: false,
          isFlaggedItem: flagScore > 1,
          uuid,
        });
        Toaster.toast({
          description: 'Comment unflagged successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const unpinCommentMutation = useMutation(
    (uuid: string) => API.unpinComment(uuid),
    {
      onSuccess() {
        refreshComment();
        Toaster.toast({
          description: 'Comment unpinned successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const unvoteCommentMutation = useMutation(
    ({ isDownvote, isUpvote, uuid }: VoteParams) => {
      if (isDownvote) return API.cancelDownvoteComment(uuid);
      if (isUpvote) return API.cancelUpvoteComment(uuid);
      throw new Error();
    },
    {
      onSuccess({ data: { karma } }, { uuid }) {
        if (!!user) setUserInfo({ ...user, karma });
        updateComment({ isDownvote: false, isUpvote: false, uuid });
        Toaster.toast({
          description: 'Comment unvoted successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const unvouchCommentMutation = useMutation(
    (uuid: string) => API.unvouchComment(uuid),
    {
      onSuccess({ data: { karma } }, uuid) {
        if (!!user) setUserInfo({ ...user, karma });
        updateComment({
          isVouched: false,
          uuid,
        });
        Toaster.toast({
          description: 'Comment unvouched successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const upvoteCommentMutation = useMutation(
    (uuid: string) => API.upvoteComment(uuid),
    {
      onSuccess({ data: { karma } }, uuid) {
        if (!!user) setUserInfo({ ...user, karma });
        updateComment({ isDownvote: false, isUpvote: true, uuid });
        Toaster.toast({
          description: 'Comment upvoted successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const vouchCommentMutation = useMutation(
    (uuid: string) => API.vouchComment(uuid),
    {
      onSuccess({ data: { karma } }, uuid) {
        if (!!user) setUserInfo({ ...user, karma });
        updateComment({
          isVouched: true,
          uuid,
        });
        Toaster.toast({
          description: 'Comment vouched successfully.',
          type: 'success',
        });
      },
      onError(err: any) {
        showErrorToast(err);
      },
    }
  );

  const handleAddComment = async (text: string) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To add a comment',
        });
        return false;
      }
      const reCaptchaToken = await executeRecaptcha('add_comment');
      await addCommentMutation.mutateAsync({ reCaptchaToken, text });
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleAddReply = async (
    text: string,
    parentId: string,
    callback: () => void
  ) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To add a comment',
        });
        callback();
        return false;
      }
      const reCaptchaToken = await executeRecaptcha('add_comment');
      await addCommentMutation.mutateAsync({ parentId, reCaptchaToken, text });
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleDelete = async (uuid: string, callback: () => void) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To delete a comment',
        });
        callback();
        return false;
      }
      await deleteCommentMutation.mutateAsync(uuid);
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleDownvote = async (uuid: string, callback: () => void) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To downvote a comment',
        });
        callback();
        return false;
      }
      await downvoteCommentMutation.mutateAsync(uuid);
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleEdit = async (
    uuid: string,
    comment: string,
    callback: () => void
  ) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To edit a comment',
        });
        callback();
        return false;
      }
      await editCommentMutation.mutateAsync({ uuid, comment });
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleFlag = async (params: FlagParams, callback: () => void) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To flag a comment',
        });
        callback();
        return false;
      }
      await flagCommentMutation.mutateAsync(params);
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handlePin = async (uuid: string, callback: () => void) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To pin a comment',
        });
        callback();
        return false;
      }
      await pinCommentMutation.mutateAsync(uuid);
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    refreshComment(() => setIsRefreshing(false));
  };

  const handleUnflag = async (params: FlagParams, callback: () => void) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To unflag a comment',
        });
        callback();
        return false;
      }
      await unflagCommentMutation.mutateAsync(params);
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleUnpin = async (uuid: string, callback: () => void) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To unpin a comment',
        });
        callback();
        return false;
      }
      await unpinCommentMutation.mutateAsync(uuid);
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleUnvote = async (
    { isDownvote, isUpvote, uuid }: VoteParams,
    callback: () => void
  ) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To unvote a comment',
        });
        callback();
        return false;
      }
      await unvoteCommentMutation.mutateAsync({ isDownvote, isUpvote, uuid });
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleUnvouch = async (uuid: string, callback: () => void) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To unvouch a comment',
        });
        callback();
        return false;
      }
      await unvouchCommentMutation.mutateAsync(uuid);
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleUpvote = async (uuid: string, callback: () => void) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To upvote a comment',
        });
        callback();
        return false;
      }
      await upvoteCommentMutation.mutateAsync(uuid);
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  const handleVouch = async (uuid: string, callback: () => void) => {
    try {
      if (!isAuthenticated) {
        await signIn();
        callback();
        return false;
      }
      if (!user?.username) {
        setUsernameRequiredModal({
          isOpened: true,
          reason: 'To vouch a comment',
        });
        callback();
        return false;
      }
      await vouchCommentMutation.mutateAsync(uuid);
      callback();
      return true;
    } catch (err) {
      callback();
      return false;
    }
  };

  return (
    <SectionLayout boundTrigger={''} enableBound={false} id={'discussions'}>
      <div className={styles.section_header}>
        <div className={styles.section_title_container}>
          <h2 className={styles.section_title}>
            {showSkeleton ? (
              <Skeleton width={108} />
            ) : (
              DETAIL_LAYOUT.DISCUSSIONS
            )}
          </h2>
          {!showSkeleton &&
            (isRefreshing ? (
              <div className={styles.loading_container}>
                <Loading size={18} />
              </div>
            ) : (
              <button
                className={cn('btn_icon', styles.btn_refresh)}
                onClick={handleRefresh}
              >
                <IcnSmallRefresh />
              </button>
            ))}
        </div>
        {!showSkeleton && (
          <div className={styles.section_header_inner_right}>
            <span className={styles.total_count}>
              {`${totalCount} Comments`}
            </span>
            <ExternalLink
              allowsUtmSource={false}
              className={styles.guidelines_link}
              label="Discussions Guidelines"
              onClick={() => {
                analytics.track(REDIRECT_CLICKED, {
                  chain,
                  location: REDIRECT_CLICKED_LOCATION.DISCUSSIONS,
                  type: REDIRECT_CLICKED_TYPE.OTHER,
                  url: DISCUSSIONS_GUIDELINES_LINK,
                });
              }}
              role="link"
              url={DISCUSSIONS_GUIDELINES_LINK}
            >
              Guidelines
              <IcnSmallExternal />
            </ExternalLink>
          </div>
        )}
      </div>
      {showSkeleton ? (
        <Skeleton height={112} />
      ) : (
        <div className={styles.inner_container}>
          <CommentInput
            isLoading={isLoadingContractComments}
            submit={handleAddComment}
          />
          {comments.length > 0 && (
            <div className={styles.comment_list}>
              <CommentList
                comments={comments}
                handleAddReply={handleAddReply}
                handleDelete={handleDelete}
                handleDownvote={handleDownvote}
                handleEdit={handleEdit}
                handleFlag={handleFlag}
                handlePin={handlePin}
                handleUnflag={handleUnflag}
                handleUnpin={handleUnpin}
                handleUnvote={handleUnvote}
                handleUnvouch={handleUnvouch}
                handleUpvote={handleUpvote}
                handleVouch={handleVouch}
                hash={hash}
                ref={ref}
                totalCount={totalCount}
                isUserDeployer={!!deployer && deployer === user?.address}
              />
            </div>
          )}
        </div>
      )}
    </SectionLayout>
  );
});
