import cn from 'classnames';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { IcnEdit, IcnSmallHint } from '~/assets';
import { Popover, Thumbnail } from '~/components';
import {
  AVAILABLE_IMAGE_EXTENSION,
  AVAILABLE_VIDEO_EXTENSION,
  CONTRACT_DESCRIPTION_CHARACTER_LIMITS,
  CONTRACT_NAME_CHARACTER_LIMITS,
  IMAGE_SIZE,
} from '~/constants';
import type { EditableContractInfo } from '~/types';
import validateFileUpload from '~/utils/validateFileUpload';

import styles from './Information.module.scss';
import commonStyles from './Overview.module.scss';

interface InformationProps
  extends Pick<EditableContractInfo, 'description' | 'imageUrl' | 'name'> {
  onChange: (changedData: Partial<EditableContractInfo>) => void;
  setFile: Dispatch<SetStateAction<File | undefined>>;
  file?: File;
}

export default function Information({
  description,
  file,
  imageUrl,
  name,
  onChange,
  setFile,
}: InformationProps) {
  const [thumbnailPreview, setThumbnailPreview] = useState<string | undefined>(
    undefined
  );

  const handleName = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ name: e.target.value });
  };

  const handleDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange({ description: e.target.value });
  };

  const handleSetFile = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const file = validateFileUpload(target);

    setFile(file);

    if (thumbnailPreview) {
      URL.revokeObjectURL(thumbnailPreview);
    }

    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setThumbnailPreview(objectUrl);
      onChange({ imageUrl: file.name });
    }
  };

  const getAcceptExtensions = useCallback(() => {
    const extensions = [
      ...Object.values(AVAILABLE_IMAGE_EXTENSION),
      ...Object.values(AVAILABLE_VIDEO_EXTENSION),
    ];
    return extensions.map((ext) => `.${ext}`).toString();
  }, []);

  useEffect(() => {
    if (!file && thumbnailPreview) {
      setThumbnailPreview(undefined);
    }
  }, [file, thumbnailPreview]);

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.field_container}>
        <div className={commonStyles.label_container}>
          <label className={commonStyles.field_name} htmlFor="contract_name">
            Name *
          </label>
          <div className={styles.character_limits_guide}>
            <span
              className={cn({
                [commonStyles.warning_info]:
                  !name?.trim() ||
                  name.trim().length > CONTRACT_NAME_CHARACTER_LIMITS,
              })}
            >
              {name?.trim().length}
            </span>
            {` / ${CONTRACT_NAME_CHARACTER_LIMITS}`}
          </div>
        </div>
        <input
          id="contract_name"
          className={cn(commonStyles.input, {
            [commonStyles.input_warning]: name?.trim().length === 0,
          })}
          value={name}
          onChange={handleName}
        />
        <div className={commonStyles.field_error_container}>
          {name?.trim().length === 0 && (
            <span className={commonStyles.field_error}>
              {'Required field.'}
            </span>
          )}
        </div>
      </div>
      <div className={commonStyles.field_container}>
        <div className={commonStyles.label_container}>
          <label
            className={commonStyles.field_name}
            htmlFor="contract_description"
          >
            Description
            <Popover
              render={() => (
                <div className={styles.hint_container}>
                  <span>{'Markdown syntax is supported'}</span>
                </div>
              )}
              animation
              placement="top"
            >
              <div className="center">
                <IcnSmallHint />
              </div>
            </Popover>
          </label>
          <div className={styles.character_limits_guide}>
            <span
              className={cn({
                [commonStyles.warning_info]:
                  description.length > CONTRACT_DESCRIPTION_CHARACTER_LIMITS,
              })}
            >
              {description.length}
            </span>
            {` / ${CONTRACT_DESCRIPTION_CHARACTER_LIMITS}`}
          </div>
        </div>
        <textarea
          id="contract_description"
          className={cn(commonStyles.textarea, {
            [commonStyles.textarea_warning]:
              description.length > CONTRACT_DESCRIPTION_CHARACTER_LIMITS,
          })}
          value={description ?? undefined}
          onChange={handleDescription}
        />
      </div>
      <div className={commonStyles.field_container}>
        <div className={commonStyles.field_name_container}>
          <span className={commonStyles.field_name}>Image</span>
          <Popover
            render={() => (
              <div className={styles.hint_container}>
                <span>{'Recommended: 350px x 350px'}</span>
                <span>{'Max Size: 10 MB'}</span>
              </div>
            )}
            animation
            placement="top"
          >
            <div className="center">
              <IcnSmallHint />
            </div>
          </Popover>
        </div>
        <div className={styles.thumbnail_container}>
          <div className={styles.upload_background} />
          <label className={styles.upload_label} htmlFor="image_upload">
            <IcnEdit />
          </label>
          <input
            id="image_upload"
            accept={getAcceptExtensions()}
            type="file"
            onChange={handleSetFile}
          />
          <Thumbnail
            key={thumbnailPreview ?? 'thumbnail'}
            ext={file?.type?.split('/')[1]}
            name="thumbnail"
            size={IMAGE_SIZE.HUGE}
            url={thumbnailPreview ?? imageUrl}
          />
        </div>
      </div>
    </div>
  );
}
