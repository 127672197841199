import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import API from '~/api';
import { CHANNEL_APP_HOOKS, EVENT_SHOULD_UPDATE_HIDE_LIST } from '~/constants';
import { usersKeys } from '~/constants/queryKeys';
import { useAuth } from '~/contexts/AuthContext';
import { useSocket } from '~/contexts/SocketContext';
import usePageVisibility from '~/hooks/usePageVisibility';
import { hiddenContractsState } from '~/store/contract';

export default function useContractFilterList() {
  const queryClient = useQueryClient();
  const isPageVisible = usePageVisibility();
  const { isAuthenticated } = useAuth();
  const socket = useSocket();
  const [hiddenContracts, setHiddenContracts] =
    useRecoilState(hiddenContractsState);
  const [contractFilterList, setContractFilterList] = useState<string[]>([]);

  const queryKey = usersKeys.hideFilter();

  const unhideContract = (address: string) => {
    setContractFilterList((prev) =>
      prev.filter((hiddenContract) => hiddenContract !== address)
    );
    setHiddenContracts((prev) =>
      prev.filter((hiddenContract) => hiddenContract !== address)
    );
  };

  useQuery(queryKey, () => API.getContractFilterList(), {
    enabled: isAuthenticated,
    onSuccess: ({ data }) => setContractFilterList(data),
  });

  useEffect(() => {
    if (isPageVisible) {
      const channel = socket?.subscribe(CHANNEL_APP_HOOKS);

      channel?.bind(EVENT_SHOULD_UPDATE_HIDE_LIST, () =>
        queryClient.invalidateQueries({ queryKey })
      );

      return () => {
        channel?.unbind(EVENT_SHOULD_UPDATE_HIDE_LIST);
      };
    }
  }, [isPageVisible, socket]);

  return {
    contractFilterList: isAuthenticated
      ? [...new Set([...contractFilterList, ...hiddenContracts])]
      : [],
    unhideContract,
  };
}
