import type { JsonFragmentType } from '@ethersproject/abi';
import { parseUnits } from '@ethersproject/units';
import cn from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ExternalLink, Modal } from '~/components';
import {
  APP_DISCLAIMER,
  AVAILABLE_CHAIN,
  MANIFOLD_CONTRACT_ADDRESSES,
  MANIFOLD_CREATOR_CONTRACT_ADDRESS_ARGUMENT,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import { useAuth } from '~/contexts/AuthContext';
import useContractInfo from '~/data/useContractInfo';
import useScrollWithShadow from '~/hooks/useScrollWithShadow';
import {
  automationModalState,
  checksumContractAddressState,
} from '~/store/contract';
import type {
  ContractSuccessfulTransaction,
  GasSuggestionResource,
  TxGasOption,
} from '~/types';
import toChecksumAddress from '~/utils/toChecksumAddress';

import styles from './AutomationModal.module.scss';
import ParamAddressInput from './ParamAddressInput';
import ParamInput from './ParamInput';

interface AutomationModalProps {
  gasSuggestion: GasSuggestionResource | null;
}

interface Forms {
  [k: string]: any;
}

export default memo(function AutomationModal({
  gasSuggestion,
}: AutomationModalProps) {
  const analytics = useAnalyticsContext();
  const { user } = useAuth();
  const address = useRecoilValue(checksumContractAddressState);
  const [automationModal, setAutomationModal] =
    useRecoilState(automationModalState);
  const contractInfoResult = useContractInfo('ethereum', address);
  const { bottomOpacity, onScrollHandler, topOpacity } = useScrollWithShadow();
  const [forms, setForms] = useState<Forms>({});
  const [isValid, setValid] = useState<boolean>(false);
  const [savedAddress, setSavedAddress] = useState<string | null>(null);
  const [txGasOptions, setTxGasOptions] = useState<Array<TxGasOption>>([]);
  const { execute, isOpen, mintFunction, successfulTx } = automationModal;
  const [value, setValue] = useState<string>(successfulTx?.value || '0');
  const { isVerified = false } = {
    ...contractInfoResult.contractInfo,
  };
  const modalBodyRef = useRef<HTMLDivElement | null>(null);
  const hasScroll = modalBodyRef.current
    ? modalBodyRef.current.scrollHeight - modalBodyRef.current.clientHeight > 0
    : false;

  const convertValueByType = (forms: Forms, name: string, type: string) => {
    try {
      const value = forms[name];
      const isNullOrBlank = value === null || value.length < 1;
      if (type.toLowerCase() === 'tuple' || type.slice(-1) === ']') {
        if (isNullOrBlank) {
          return null;
        }

        return JSON.parse(value);
      }

      if (isAddressType(type)) {
        return toChecksumAddress(value);
      }

      if (type.startsWith('bytes') && isNullOrBlank) {
        return '0x';
      }

      return value;
    } catch (e) {
      return null;
    }
  };

  const getDefaultValue = (
    name: string,
    type: string,
    value: string | null | undefined,
    toAddress: string | null
  ) => {
    if (value === undefined) {
      return '';
    }

    if (value === null) {
      return null;
    }

    if (
      type.toLowerCase() === 'tuple' ||
      (!isAddressType(type) && type.slice(-1) === ']')
    ) {
      return JSON.stringify(value);
    }

    if (isAddressType(type)) {
      if (
        MANIFOLD_CONTRACT_ADDRESSES.includes(toAddress ?? '') &&
        name === MANIFOLD_CREATOR_CONTRACT_ADDRESS_ARGUMENT
      ) {
        return address;
      }

      return '';
    }

    return value;
  };

  const getFunctionParams = (
    forms: Forms,
    inputs: ReadonlyArray<JsonFragmentType>,
    replaceValue: string,
    sender: string | undefined
  ) => {
    const functionParams = inputs
      .map((input: JsonFragmentType) => {
        const { name, type } = input;
        if (!name || !type) {
          return null;
        }

        if (name === sender) {
          const params = `0x${replaceValue}`;
          const value = type === 'address[]' ? `[${params}]` : params;
          return {
            name,
            type,
            value,
          };
        }

        const value = convertValueByType(forms, name, type);
        return {
          name,
          type,
          value,
        };
      })
      .filter((data) => data !== null);

    return JSON.stringify(functionParams);
  };

  const getLatestMinter = (
    decodedData: Forms | null,
    fromAddress: string | null,
    isVerified: boolean,
    regex: RegExp,
    sender: string | undefined
  ) => {
    const replaceValue = '';
    if (!isVerified) {
      if (!fromAddress) {
        return null;
      }

      return fromAddress.toLowerCase().replace(regex, replaceValue);
    }

    if (!sender) {
      return null;
    }

    const value = decodedData?.[sender];
    if (!value) {
      return null;
    }

    return value.toString().toLowerCase().replace(regex, replaceValue);
  };

  const hasBytes = (
    inputs: ReadonlyArray<JsonFragmentType>,
    types: Array<'bytes' | 'bytes32[]' | 'bytes32[][]'> & Array<string>
  ) => {
    const input = inputs.find((input: JsonFragmentType) => {
      const type = input.type ?? '';

      return types.includes(type);
    });

    return input !== undefined;
  };

  const isAddressType = (type: string | undefined) => {
    if (!type) {
      return false;
    }

    return type.startsWith('address');
  };

  const setParams = (
    inputs: ReadonlyArray<JsonFragmentType>,
    name: string,
    value: string | null
  ) => {
    setForms((forms) => {
      const isValid = inputs
        .map((input) => input.name)
        .every((key) => {
          if (!key) {
            return true;
          }

          return key === name ? value : forms[key];
        });
      setValid(isValid);

      return {
        ...forms,
        [name]: value,
      };
    });
  };

  const onQuickTask = (
    contractAddress: string,
    inputs: ReadonlyArray<JsonFragmentType>,
    isVerified: boolean,
    maxFee: number,
    maxPrio: number,
    minter: string,
    name: string | undefined,
    successfulTx: ContractSuccessfulTransaction,
    execute: (urlSearchParams: URLSearchParams) => void,
    value: string
  ) => {
    const addressRegex = /[^0-9a-fA-Fx]/g;
    const prefixRegex = /^0x/;
    const replaceValue = '${walletAddress}';
    const { data, fromAddress, decodedData } = successfulTx;
    const maxFeePerGas = parseUnits(maxFee.toString(), 'gwei').toString();
    const maxPriorityFeePerGas = parseUnits(
      maxPrio.toString(),
      'gwei'
    ).toString();
    const sender = Object.keys(forms).find((key: string) => {
      const senderAddress = forms[key]
        ?.replace(addressRegex, '')
        ?.toLowerCase();
      return senderAddress === minter.toLowerCase();
    });
    const latestMinter = getLatestMinter(
      decodedData,
      fromAddress,
      isVerified,
      prefixRegex,
      sender
    );
    const hexData = data.replace(latestMinter, replaceValue);
    const minterAddress = minter.replace(prefixRegex, '');
    const isNotEmpty = inputs.length > 0;
    const params: { [key: string]: any } = {
      contractAddress,
      fromAddress,
      hexData,
      isVerified,
      maxFeePerGas,
      maxPriorityFeePerGas,
      minterAddress,
      origin: 'catchmint',
      value,
    };
    if (isVerified && name) {
      params['functionName'] = name;
    }

    if (isVerified && isNotEmpty) {
      params['hasBytes'] = hasBytes(inputs, ['bytes']);
      params['hasBytes32'] = hasBytes(inputs, ['bytes32[]', 'bytes32[][]']);
      params['functionParams'] = getFunctionParams(
        forms,
        inputs,
        replaceValue,
        sender
      );
    }

    const urlSearchParams = new URLSearchParams(params);
    execute(urlSearchParams);
  };

  const onClose = () => {
    setAutomationModal({
      execute: null,
      isOpen: false,
      mintFunction: null,
      successfulTx: null,
    });
  };

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (savedAddress) {
      return;
    }

    setSavedAddress(address);
  }, [address]);

  useEffect(() => {
    const txGasOptions = gasSuggestion
      ? [
          {
            ...gasSuggestion.nextBlock,
            confidence: 'next block',
          },
          {
            ...gasSuggestion.threeBlocks,
            confidence: 'next 2-3 blocks',
          },
        ]
      : [];

    setTxGasOptions(txGasOptions);
  }, [gasSuggestion]);

  if (!execute || !mintFunction || !successfulTx || !savedAddress || !user) {
    return <></>;
  }

  const { stateMutability, name } = mintFunction;
  const { decodedData, toAddress } = successfulTx;
  const { address: minter } = user;
  const inputs = mintFunction.inputs ?? [];
  const addressInputs = inputs.filter((input) => isAddressType(input.type));
  const orderedInputs = [
    ...addressInputs,
    ...inputs.filter((input) => !isAddressType(input.type)),
  ];

  return (
    <Modal open={isOpen} onClose={onClose} style={{ width: 640 }}>
      <div className={styles.automation_modal_container}>
        <div className={styles.automation_modal_title_container}>
          <h1 className={styles.automation_modal_title}>{name}</h1>
        </div>
        {hasScroll && (
          <>
            <div
              className={cn(styles.shadow, styles.shadow_bottom)}
              style={{ opacity: bottomOpacity }}
            />
            <div
              className={cn(styles.shadow, styles.shadow_top)}
              style={{ opacity: topOpacity }}
            />
          </>
        )}
        <div
          className={styles.automation_modal_body}
          ref={modalBodyRef}
          onScroll={onScrollHandler}
        >
          {stateMutability === 'payable' && (
            <ParamInput
              label={'payableAmount (ether)'}
              defaultValue={successfulTx.value}
              placeholder={'payableAmount (ether)'}
              setParams={(value) => setValue(value ?? '0')}
            />
          )}
          {orderedInputs.map((input: JsonFragmentType) => {
            const { name, type } = input;
            if (!name || !type) {
              return <></>;
            }

            const combineProperties = `${name} (${type})`;
            const isAddress = isAddressType(type);
            const value = decodedData?.[name];
            const defaultValue = getDefaultValue(name, type, value, toAddress);
            if (isAddress) {
              return (
                <ParamAddressInput
                  key={name}
                  contractAddress={savedAddress}
                  defaultValue={defaultValue}
                  label={combineProperties}
                  myAddress={minter}
                  placeholder={combineProperties}
                  resource={value}
                  setParams={(value) => setParams(addressInputs, name, value)}
                />
              );
            }

            return (
              <ParamInput
                key={name}
                defaultValue={defaultValue}
                label={combineProperties}
                placeholder={combineProperties}
                setParams={(value) => setParams(addressInputs, name, value)}
              />
            );
          })}
          <div className={styles.alert_text_container}>
            <span className={styles.alert_text}>
              {`Please use with caution. It may be unstable, and you may lose gas fee unless you know what you’re doing. By clicking on one of the three buttons right below, you agree to our `}
              <ExternalLink
                allowsUtmSource={false}
                className={styles.disclaimer_link}
                label="catchmint_disclaimer"
                onClick={() => {
                  analytics.track(REDIRECT_CLICKED, {
                    chain: AVAILABLE_CHAIN.ETHEREUM,
                    location: REDIRECT_CLICKED_LOCATION.OTHER,
                    type: REDIRECT_CLICKED_TYPE.OTHER,
                    url: APP_DISCLAIMER,
                  });
                }}
                role="link"
                url={APP_DISCLAIMER}
              >
                {'Disclaimer'}
              </ExternalLink>
              {`.`}
            </span>
          </div>
        </div>
        <div className={styles.buttons_container}>
          <div className={styles.submit_button_container}>
            {txGasOptions.map((txGasOption, i) => {
              const { confidence, maxFee, maxPrio } = txGasOption;
              const priorityFee = maxPrio.toFixed(2);
              const fee = maxFee.toFixed(2);

              return (
                <button
                  key={i}
                  className={cn(styles.btn, styles.option)}
                  disabled={!isValid}
                  onClick={() =>
                    onQuickTask(
                      savedAddress,
                      inputs,
                      isVerified,
                      maxFee,
                      maxPrio,
                      minter,
                      name,
                      successfulTx,
                      execute,
                      value
                    )
                  }
                >
                  <span
                    className={styles.confidence}
                  >{`Target: ${confidence}`}</span>
                  <span className={styles.label_option}>
                    <span className={styles.label}>Priority Fee</span>
                    <div className={styles.value_container}>
                      <strong className={styles.value}>{priorityFee}</strong>
                      <span className={styles.unit}>Gwei</span>
                    </div>
                  </span>
                  <span className={styles.label_option}>
                    <span className={styles.label}>Max Fee</span>
                    <div className={styles.value_container}>
                      <strong className={styles.value}>{fee}</strong>
                      <span className={styles.unit}>Gwei</span>
                    </div>
                  </span>
                </button>
              );
            })}
          </div>
          <button className={styles.cancel_button} onClick={onClose}>
            {'Cancel'}
          </button>
        </div>
      </div>
    </Modal>
  );
});
