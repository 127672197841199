import { atom, selector } from 'recoil';

import type {
  ContractSuccessfulTransaction,
  MintableFunction,
  MintInformation,
  WriteInformationFrom,
} from '~/types';
import toChecksumAddress from '~/utils/toChecksumAddress';

const automationModalState = atom<{
  execute: ((urlSearchParams: URLSearchParams) => void) | null;
  isOpen: boolean;
  mintFunction: MintableFunction | null;
  successfulTx: ContractSuccessfulTransaction | null;
  deployer?: string | null;
}>({
  key: 'automationModal',
  default: {
    deployer: null,
    execute: null,
    isOpen: false,
    mintFunction: null,
    successfulTx: null,
  },
});

const checksumContractAddressState = selector({
  key: 'checksumContractAddress',
  get: ({ get }) => toChecksumAddress(get(contractAddressState)),
});

const contractAddressState = atom({
  key: 'contractAddress',
  default: '',
});

const hiddenContractsState = atom<string[]>({
  key: 'hiddenContracts',
  default: [],
});

const writeInformationState = atom<{
  address: string;
  from: WriteInformationFrom | null;
  mintInfo: MintInformation;
}>({
  key: 'writeInformation',
  default: {
    address: '',
    from: null,
    mintInfo: {
      mintableFunction: null,
      params: null,
      price: '0',
      transactionTo: '',
    },
  },
});

export {
  automationModalState,
  checksumContractAddressState,
  contractAddressState,
  hiddenContractsState,
  writeInformationState,
};
