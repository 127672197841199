import { useQuery } from '@tanstack/react-query';

import API from '~/api';
import { contractsKeys } from '~/constants/queryKeys';
import type { AvailableChain } from '~/types';

import useContractFilterList from './useContractFilterList';

export default function useContractFlags(
  chain: Lowercase<AvailableChain>,
  address: string
) {
  const queryKey = contractsKeys.flags(chain, address);
  const { contractFilterList } = useContractFilterList();
  const isHidden = contractFilterList.some(
    (hiddenContract) => hiddenContract === address
  );
  const result = useQuery(queryKey, () => API.getContractFlags(address), {
    enabled: !!address && !isHidden,
  });

  return {
    ...result,
    flags: result.data?.data ?? [],
  };
}
