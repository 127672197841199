import cn from 'classnames';

import { IcnRemove } from '~/assets';
import { Markdown, Popover, TimeAgo } from '~/components';
import { REDIRECT_CLICKED_LOCATION } from '~/constants';
import type { AvailableChain, ContractAnnouncement } from '~/types';

import styles from './AnnouncementItem.module.scss';

interface AnnouncementItemProps extends ContractAnnouncement {
  chain: AvailableChain;
  onRemove: (uuid: string) => Promise<void>;
}

export default function AnnouncementItem({
  chain,
  description,
  created,
  title,
  uuid,
  onRemove,
}: AnnouncementItemProps) {
  const handleRemove = () => {
    onRemove(uuid);
  };

  return (
    <li className={styles.container}>
      <span className={styles.time}>
        <TimeAgo date={new Date(created)} highlight />
      </span>
      <div className={styles.buttons_container}>
        <Popover
          render={() => (
            <div className="default_popover">
              <span>{'Remove'}</span>
            </div>
          )}
          animation
          placement="top"
        >
          <button
            className={cn('btn_icon', styles.btn_remove)}
            onClick={handleRemove}
          >
            <IcnRemove />
          </button>
        </Popover>
      </div>
      <div className={styles.announcement}>
        <span className={styles.title}>{title}</span>
        <div className={styles.description}>
          <Markdown
            chain={chain}
            location={REDIRECT_CLICKED_LOCATION.ANNOUNCEMENTS}
          >
            {description}
          </Markdown>
        </div>
      </div>
    </li>
  );
}
