import { useState } from 'react';

import { SubmitModal } from '~/components';
import { FLAG_TYPE } from '~/constants';
import type { Flag } from '~/types';

import styles from './FlagReasonInputModal.module.scss';

interface FlagReasonInputModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (label: Flag, text: string) => void;
}

export default function FlagReasonInputModal({
  isOpen,
  onClose,
  onSubmit,
}: FlagReasonInputModalProps) {
  const [reason, setReason] = useState('');

  return (
    <SubmitModal
      isOpen={isOpen}
      title={'Report this contract'}
      onClose={() => {
        onClose();
        setReason('');
      }}
      onSubmit={() => {
        onSubmit(FLAG_TYPE.OTHER, reason);
        setReason('');
      }}
    >
      <textarea
        className={styles.flag_reason}
        placeholder={`Please explain why you're reporting this contract`}
        style={{
          width: '100%',
        }}
        maxLength={1000}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />
    </SubmitModal>
  );
}
