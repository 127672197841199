import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import API from '~/api';
import {
  AVERAGE_BLOCK_TIME,
  CHANNEL_GROUP_ACTIVITY_MINTS_VOLUME,
  EVENT_DATA,
} from '~/constants';
import { timeseriesKeys } from '~/constants/queryKeys';
import { useSocket } from '~/contexts/SocketContext';
import usePageVisibility from '~/hooks/usePageVisibility';
import { groupActivitySelectedNotableGroupIdsState } from '~/store/app';
import type { MintsVolume, NotableGroupWithMintsVolume } from '~/types';
import formatEthPrice from '~/utils/formatEthPrice';

import useNotableGroups from './useNotableGroups';

interface useNotableGroupsMintsVolumeProps {
  searchKeyword: string;
}

export default function useNotableGroupsMintsVolume({
  searchKeyword,
}: useNotableGroupsMintsVolumeProps) {
  const [selectedNotableGroupIds, setSelectedNotableGroupIds] = useRecoilState<
    number[]
  >(groupActivitySelectedNotableGroupIdsState);
  const { isLoading: isNotableGroupsLoading, notableGroups } =
    useNotableGroups();

  const isPageVisible = usePageVisibility();
  const socket = useSocket();
  const [mintsVolume, setMintsVolume] = useState<MintsVolume[]>([]);

  const queryKey = timeseriesKeys.notableGroupsMintsVolume();
  const { data, isLoading: isNotableGroupsMintsOverviewLoading } = useQuery(
    queryKey,
    () => API.getNotableGroupsMintsVolume(),
    {
      cacheTime: AVERAGE_BLOCK_TIME,
      staleTime: AVERAGE_BLOCK_TIME,
    }
  );

  const isLoading =
    isNotableGroupsLoading || isNotableGroupsMintsOverviewLoading;

  const notableGroupsWithMintsVolume: NotableGroupWithMintsVolume[] =
    useMemo(() => {
      if (mintsVolume.length === 0 || notableGroups.length === 0) {
        return [];
      }

      return notableGroups
        .map((group) => {
          const mintsVolumeInfo = mintsVolume.find(({ id }) => group.id === id);
          return {
            ...group,
            volume: mintsVolumeInfo
              ? formatEthPrice(mintsVolumeInfo.volume).toString()
              : '0',
          };
        })
        .sort((a, b) => {
          if (a.volume === b.volume) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          }
          return +b.volume - +a.volume;
        });
    }, [mintsVolume, notableGroups]);

  const [selectedNotableGroups, notSelectedNotableGroups] = useMemo(() => {
    if (notableGroupsWithMintsVolume.length === 0) return [[], []];
    return notableGroupsWithMintsVolume.reduce<
      [NotableGroupWithMintsVolume[], NotableGroupWithMintsVolume[]]
    >(
      (acc, notableGroupWithMintsVolume) => {
        if (selectedNotableGroupIds.includes(notableGroupWithMintsVolume.id)) {
          acc[0].push(notableGroupWithMintsVolume);
        } else if (
          // keyword filtering is only applied to not selected notable groups
          notableGroupWithMintsVolume.name
            .toLowerCase()
            .includes(searchKeyword.toLowerCase())
        ) {
          acc[1].push(notableGroupWithMintsVolume);
        }
        return acc;
      },
      [[], []]
    );
  }, [notableGroupsWithMintsVolume, searchKeyword, selectedNotableGroupIds]);

  const flipKey = mintsVolume.map(({ id }) => id).join('');

  const resetSelectedNotableGroupIds = useCallback(() => {
    setSelectedNotableGroupIds([]);
  }, []);

  useEffect(() => {
    if (!!data) {
      setMintsVolume(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (isPageVisible) {
      socket
        ?.subscribe(CHANNEL_GROUP_ACTIVITY_MINTS_VOLUME)
        ?.bind(EVENT_DATA, (data: MintsVolume[]) => setMintsVolume(data));

      return () => {
        socket?.unsubscribe(CHANNEL_GROUP_ACTIVITY_MINTS_VOLUME);
      };
    }
  }, [isPageVisible, socket]);

  return {
    flipKey,
    isLoading,
    notSelectedNotableGroups,
    resetSelectedNotableGroupIds,
    selectedNotableGroups,
    selectedNotableGroupIds,
  };
}
