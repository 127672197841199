import type { Hit, SearchOptions } from '@algolia/client-search';
import type { RequestOptions } from '@algolia/transporter';
import algoliasearch from 'algoliasearch/lite';
import cn from 'classnames';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import {
  ChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IcnSearch } from '~/assets';
import { Loading } from '~/components';
import config from '~/config';
import { AVAILABLE_CHAIN, COLLECTION_CLICKED_LOCATION } from '~/constants';
import type { SearchResult, SearchSuggestion } from '~/types';
import showErrorToast from '~/utils/showErrorToast';
import toChecksumAddress from '~/utils/toChecksumAddress';

import styles from './SearchBar.module.scss';
import Suggestions from './Suggestions';

interface SearchBarProps {}

/**
 * https://www.algolia.com/doc/api-client/getting-started/install/javascript/?client=javascript
 */
const searchClient = algoliasearch(
  config.ALGOLIA_APP_ID,
  config.ALGOLIA_API_KEY
);
const index = searchClient.initIndex('contracts');
const requestOptions: RequestOptions & SearchOptions = {
  cacheable: false,
  timeout: 10,
  attributesToRetrieve: [
    'address',
    'deployedAt',
    'firstMint',
    'flagCount',
    'hideCount',
    'imageUrl',
    'name',
    'notableMints',
    'totalMints',
  ],
  hitsPerPage: 30,
};

export default function SearchBar({}: SearchBarProps) {
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focused, setFocused] = useState(false);
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([]);
  const search = useMemo(
    () =>
      debounce(async (query: string) => {
        try {
          if (isEmpty(query)) {
            return;
          }
          const response = await index.search<SearchResult>(
            query,
            requestOptions
          );
          setFocusedSuggestionIndex(0);
          setIsLoading(false);
          const { hits } = response;
          const suggestions = hits.map((hit: Hit<SearchResult>) => {
            const {
              address,
              chain = AVAILABLE_CHAIN.ETHEREUM,
              deployedAt,
              firstMint,
              flagCount,
              hideCount,
              imageUrl,
              name,
              notableMints,
              totalMints,
              _highlightResult,
            } = hit;

            return {
              address,
              chain,
              deployedAt,
              firstMint,
              flagCount,
              hideCount,
              highlights: _highlightResult,
              imageUrl,
              name,
              notableMints,
              totalMints,
            };
          });

          setSuggestions(suggestions);
        } catch (err) {
          showErrorToast(err);
          setIsLoading(false);
        }
      }, 250),
    []
  );

  const handleSearchItem = useCallback(
    (
      { address, chain = AVAILABLE_CHAIN.ETHEREUM, name }: SearchSuggestion,
      position: number
    ) => {
      const checksumAddress = toChecksumAddress(address);
      const params: { [key: string]: any } = {
        chain,
        contract_address: checksumAddress,
        location: COLLECTION_CLICKED_LOCATION.SEARCH_BAR,
        position,
      };
      if (!!name) {
        params['collection_name'] = name;
      }
      router.push(
        {
          pathname: `/collection/[chain]/[address]`,
          query: { address: checksumAddress, chain: 'ethereum' },
        },
        undefined,
        {
          shallow: true,
        }
      );
    },
    []
  );

  const isEmpty = (text: string) => {
    return text.trim().length < 1;
  };

  const onBlur = useCallback(() => {
    setFocused(false);
    setFocusedSuggestionIndex(0);
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setKeyword(value);

    if (isEmpty(value)) {
      setFocusedSuggestionIndex(0);
      setSuggestions([]);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }

    search(value);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onSearch = useCallback(
    (suggestion: SearchSuggestion, position: number) => {
      if (!suggestion.address) {
        return;
      }

      handleSearchItem(suggestion, position);
      setKeyword(suggestion.name);
      setSuggestions([suggestion]);
      onBlur();
    },
    [handleSearchItem, onBlur]
  );

  const onKeyDown = (e: ReactKeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowUp':
        setFocusedSuggestionIndex(
          focusedSuggestionIndex === 0
            ? suggestions.length - 1
            : focusedSuggestionIndex - 1
        );
        e.preventDefault();
        break;
      case 'ArrowDown':
        setFocusedSuggestionIndex(
          (focusedSuggestionIndex + 1) % suggestions.length
        );
        e.preventDefault();
        break;
      case 'Enter':
        const suggestion = suggestions[focusedSuggestionIndex];
        if (suggestion) {
          onSearch(suggestion, focusedSuggestionIndex + 1);
          e.currentTarget.blur();
          e.preventDefault();
        }
        break;
      case 'Esc':
      case 'Escape':
        onBlur();
        e.currentTarget.blur();
        e.preventDefault();
        break;
    }
  };

  useEffect(() => {
    function keydownEventListener(e: KeyboardEvent) {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      )
        return true;
      if (e.key === '/') {
        if (inputRef.current) {
          e.preventDefault();
          inputRef.current.focus();
        } else {
          return true;
        }
      }
    }
    window.addEventListener('keydown', keydownEventListener, true);
    return () => {
      window.removeEventListener('keydown', keydownEventListener, true);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.input_container, {
          [styles.focus]: focused,
          [styles.typing]: focused && keyword.length > 0,
        })}
      >
        {isLoading ? (
          <div className={styles.loading_container}>
            <Loading size={20} />
          </div>
        ) : (
          <IcnSearch />
        )}
        <input
          ref={inputRef}
          className={styles.input}
          type="text"
          placeholder={'Search by collection Name or Address'}
          name="q"
          autoComplete="off"
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          value={keyword}
        />
        <div className={styles.loading_container}>
          {!focused && <div className={styles.shortcut_key}>{'/'}</div>}
        </div>
      </div>
      {focused && suggestions.length > 0 && (
        <Suggestions
          focusedIndex={focusedSuggestionIndex}
          onSearch={onSearch}
          suggestions={suggestions}
        />
      )}
    </div>
  );
}
