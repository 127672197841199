import { useTheme } from 'next-themes';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { ImageSource, VideoSource } from '~/components';
import {
  AVAILABLE_VIDEO_EXTENSION,
  IMAGE_SIZE,
  UNIDENTIFIED_CONTRACT,
} from '~/constants';
import type { AvailableVideoExtension, ImageSize } from '~/types';
import getExtension from '~/utils/getExtension';
import isProtocolHttps from '~/utils/isProtocolHttps';

import styles from './Thumbnail.module.scss';

interface ThumbnailProps {
  isBlurred?: boolean;
  isLoading?: boolean;
  name?: string;
  ext?: string;
  size?: ImageSize;
  url?: string;
}

export default memo(function Thumbnail({
  ext,
  isBlurred = false,
  isLoading = false,
  name = UNIDENTIFIED_CONTRACT,
  size = IMAGE_SIZE.MEDIUM,
  url,
}: ThumbnailProps) {
  const { systemTheme, theme } = useTheme();

  const baseImageUrl =
    theme === 'system'
      ? systemTheme === 'dark'
        ? '/default_img_dark.png'
        : '/default_img_light.png'
      : theme === 'dark'
      ? '/default_img_dark.png'
      : '/default_img_light.png';
  const extension = ext ?? getExtension(url);
  const isVideo = Object.values(AVAILABLE_VIDEO_EXTENSION).includes(
    extension as AvailableVideoExtension
  );
  const diameter = {
    [IMAGE_SIZE.SMALL]: 24,
    [IMAGE_SIZE.MEDIUM]: 42,
    [IMAGE_SIZE.LARGE]: 64,
    [IMAGE_SIZE.HUGE]: 96,
  }[size];

  if (isBlurred) {
    return (
      <div
        className={styles.blurred_thumbnail}
        style={{
          width: diameter,
          height: diameter,
        }}
      />
    );
  }

  return (
    <div
      className={styles.container}
      style={{
        width: diameter,
        height: diameter,
      }}
    >
      {isLoading ? (
        <Skeleton circle width={diameter} height={diameter} />
      ) : url?.startsWith('blob') || isProtocolHttps(url) ? (
        isVideo ? (
          <VideoSource
            extension={extension}
            poster={baseImageUrl}
            radius
            size={diameter}
            url={url}
          />
        ) : (
          <ImageSource
            alt={name}
            radius
            size={diameter}
            url={url || baseImageUrl}
          />
        )
      ) : (
        /*
         * show default image if the protocol is not https
         * https://web.dev/i18n/en/fixing-mixed-content/
         */
        <ImageSource alt={name} radius size={diameter} url={baseImageUrl} />
      )}
    </div>
  );
});
