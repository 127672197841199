import cn from 'classnames';
import type { MouseEvent } from 'react';
import { useRecoilValue } from 'recoil';

import { IcnFlagOff } from '~/assets';
import { Copy, Thumbnail } from '~/components';
import { IMAGE_SIZE } from '~/constants';
import { checksumContractAddressState } from '~/store/contract';
import type { SimilarContract } from '~/types';
import formatAddress from '~/utils/formatAddress';
import formatTimeAgo from '~/utils/formatTimeAgo';

import styles from './SimilarContractsTable.module.scss';

interface SimilarContractsTableProps {
  onClick: (contract: SimilarContract, position: number) => void;
  similarContracts: SimilarContract[];
}

export default function SimilarContractsTable({
  onClick,
  similarContracts,
}: SimilarContractsTableProps) {
  const currentAddress = useRecoilValue(checksumContractAddressState);
  const handleContractClick = (
    e: MouseEvent,
    similarContract: SimilarContract,
    index: number
  ) => {
    e.stopPropagation();
    onClick(similarContract, index);
  };

  const renderRow = (similarContract: SimilarContract, index: number) => {
    const {
      address,
      deployedAt,
      firstMint,
      flagCount,
      imageUrl,
      name,
      notableMints,
      totalMints,
    } = similarContract;
    const isCurrentContract = address == currentAddress;
    const deployedAtDiff = deployedAt
      ? Date.now() - new Date(deployedAt).getTime()
      : 0;
    const firstMintDiff = firstMint
      ? Date.now() - new Date(firstMint).getTime()
      : 0;

    return (
      <tr
        key={`similar_contract_${address}`}
        className={cn({
          [styles.current]: isCurrentContract,
        })}
      >
        <td>
          <div className={styles.similar_contract}>
            <div
              className={cn(styles.similar_contract_thumbnail, {
                [styles.current_contract_name]: isCurrentContract,
              })}
              onClick={(e) =>
                handleContractClick(e, similarContract, index + 1)
              }
            >
              <Thumbnail size={IMAGE_SIZE.MEDIUM} url={imageUrl} />
            </div>
            <div className={styles.similar_contract_info_container}>
              <span
                className={cn(styles.similar_contract_name, {
                  [styles.current_contract_name]: isCurrentContract,
                })}
                onClick={(e) =>
                  handleContractClick(e, similarContract, index + 1)
                }
              >
                {name}
              </span>
              {!isCurrentContract && (
                <div className={styles.similar_contract_address_container}>
                  <span className={styles.similar_contract_address}>
                    {formatAddress(address)}
                  </span>
                  <Copy content={address} size="small" />
                </div>
              )}
            </div>
          </div>
        </td>
        <td className="number_column">
          {totalMints ? totalMints.toLocaleString() : ''}
        </td>
        <td className="number_column">
          {notableMints ? notableMints.toLocaleString() : ''}
        </td>
        <td className="text_column">
          {deployedAtDiff ? formatTimeAgo(deployedAtDiff) : ''}
        </td>
        <td className="text_column">
          {firstMintDiff ? formatTimeAgo(firstMintDiff) : ''}
        </td>
        <td className="number_column">
          <div
            className={cn(styles.flag, {
              [styles.flag_active]: flagCount > 0,
            })}
          >
            <IcnFlagOff />
            {flagCount.toLocaleString()}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className="text_column">{'Collection'}</th>
          <th className="number_column">{'Total Mints'}</th>
          <th className="number_column">{'Notable Mints'}</th>
          <th className="text_column">{'Deployed'}</th>
          <th className="text_column">{'First Mint'}</th>
          <th className="number_column">{'Flags'}</th>
        </tr>
      </thead>
      <tbody>
        {similarContracts.map((similarContract, index) =>
          renderRow(similarContract, index)
        )}
      </tbody>
    </table>
  );
}
