import {
  NOTIFICATION_TRIGGER_DESCRIPTION,
  NOTIFICATION_TRIGGER_FIVE_MINUTE_MINTING_SPIKE,
  NOTIFICATION_TRIGGER_MINT_PROGRESS_ALERT,
  NOTIFICATION_TRIGGER_ONE_MINUTE_MINTING_SPIKE,
  NOTIFICATION_TRIGGER_PENDING_TRANSACTIONS_ALERT,
  NOTIFICATION_TRIGGER_TYPE,
  NOTIFICATION_TRIGGER_VIEWER_SPIKE_ALERT,
} from '~/constants';
import type {
  NotificationTriggerSettings,
  NotificationTriggerType,
} from '~/types';

import NotificationTrigger from './NotificationTrigger';
import styles from './NotificationTriggerSetting.module.scss';

interface NotificationTriggerSettingProps {
  setTriggerSettings: (
    type: NotificationTriggerType,
    value: number | boolean
  ) => void;
  triggerSettings: NotificationTriggerSettings;
}

export default function NotificationTriggerSetting({
  setTriggerSettings,
  triggerSettings,
}: NotificationTriggerSettingProps) {
  const fiveMinuteMintingSpikeTriggerValue =
    triggerSettings[NOTIFICATION_TRIGGER_TYPE.FIVE_MINUTE_MINTING_SPIKE];
  const initialMintAlertTriggerValue =
    triggerSettings[NOTIFICATION_TRIGGER_TYPE.INITIAL_MINT_ALERT];
  const mintProgressAlertTriggerValue =
    triggerSettings[NOTIFICATION_TRIGGER_TYPE.MINT_PROGRESS_ALERT];
  const oneMinuteMintingSpikeTriggerValue =
    triggerSettings[NOTIFICATION_TRIGGER_TYPE.ONE_MINUTE_MINTING_SPIKE];
  const pendingTransactionsAlertTriggerValue =
    triggerSettings[NOTIFICATION_TRIGGER_TYPE.PENDING_TRANSACTIONS_ALERT];
  const replyToCommentTriggerValue =
    triggerSettings[NOTIFICATION_TRIGGER_TYPE.REPLY_TO_COMMENT];
  const viewerSpikeAlertTriggerValue =
    triggerSettings[NOTIFICATION_TRIGGER_TYPE.VIEWER_SPIKE_ALERT];

  const isFiveMinuteMintingSpikeTriggerEnabled = Boolean(
    fiveMinuteMintingSpikeTriggerValue
  );
  const isInitialMintAlertTriggerEnabled = Boolean(
    initialMintAlertTriggerValue
  );
  const isMintProgressAlertTriggerEnabled = Boolean(
    mintProgressAlertTriggerValue
  );
  const isOneMinuteMintingSpikeTriggerEnabled = Boolean(
    oneMinuteMintingSpikeTriggerValue
  );
  const isPendingTriggerEnabled = Boolean(pendingTransactionsAlertTriggerValue);
  const isReplyToCommentTriggerEnabled = Boolean(replyToCommentTriggerValue);
  const isViewerSpikeAlertTriggerEnabled = Boolean(
    viewerSpikeAlertTriggerValue
  );

  return (
    <div className={styles.container}>
      <NotificationTrigger
        checked={isReplyToCommentTriggerEnabled}
        description={NOTIFICATION_TRIGGER_DESCRIPTION.REPLY_TO_COMMENT}
        name={NOTIFICATION_TRIGGER_TYPE.REPLY_TO_COMMENT}
        onToggle={() =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.REPLY_TO_COMMENT,
            !isReplyToCommentTriggerEnabled
          )
        }
        selectedValue={replyToCommentTriggerValue}
      />
      <NotificationTrigger
        checked={isInitialMintAlertTriggerEnabled}
        description={NOTIFICATION_TRIGGER_DESCRIPTION.INITIAL_MINT_ALERT}
        name={NOTIFICATION_TRIGGER_TYPE.INITIAL_MINT_ALERT}
        onToggle={() =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.INITIAL_MINT_ALERT,
            !isInitialMintAlertTriggerEnabled
          )
        }
        selectedValue={initialMintAlertTriggerValue}
      />
      <NotificationTrigger
        checked={isViewerSpikeAlertTriggerEnabled}
        description={NOTIFICATION_TRIGGER_DESCRIPTION.VIEWER_SPIKE_ALERT}
        name={NOTIFICATION_TRIGGER_TYPE.VIEWER_SPIKE_ALERT}
        onOptionChange={(value: number | boolean) =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.VIEWER_SPIKE_ALERT,
            value
          )
        }
        onToggle={() =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.VIEWER_SPIKE_ALERT,
            isViewerSpikeAlertTriggerEnabled
              ? NOTIFICATION_TRIGGER_VIEWER_SPIKE_ALERT.FALSE
              : NOTIFICATION_TRIGGER_VIEWER_SPIKE_ALERT['100']
          )
        }
        options={Object.entries(NOTIFICATION_TRIGGER_VIEWER_SPIKE_ALERT).slice(
          0,
          -1
        )}
        selectedValue={viewerSpikeAlertTriggerValue}
      />
      <NotificationTrigger
        checked={isMintProgressAlertTriggerEnabled}
        description={NOTIFICATION_TRIGGER_DESCRIPTION.MINT_PROGRESS_ALERT}
        name={NOTIFICATION_TRIGGER_TYPE.MINT_PROGRESS_ALERT}
        onOptionChange={(value: number | boolean) =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.MINT_PROGRESS_ALERT,
            value
          )
        }
        onToggle={() =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.MINT_PROGRESS_ALERT,
            isMintProgressAlertTriggerEnabled
              ? NOTIFICATION_TRIGGER_MINT_PROGRESS_ALERT.FALSE
              : NOTIFICATION_TRIGGER_MINT_PROGRESS_ALERT['50']
          )
        }
        optionPrefix="%"
        options={Object.entries(NOTIFICATION_TRIGGER_MINT_PROGRESS_ALERT).slice(
          0,
          -1
        )}
        selectedValue={mintProgressAlertTriggerValue}
      />
      <NotificationTrigger
        checked={isOneMinuteMintingSpikeTriggerEnabled}
        description={NOTIFICATION_TRIGGER_DESCRIPTION.ONE_MINUTE_MINTING_SPIKE}
        name={NOTIFICATION_TRIGGER_TYPE.ONE_MINUTE_MINTING_SPIKE}
        onOptionChange={(value: number | boolean) =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.ONE_MINUTE_MINTING_SPIKE,
            value
          )
        }
        onToggle={() =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.ONE_MINUTE_MINTING_SPIKE,
            isOneMinuteMintingSpikeTriggerEnabled
              ? NOTIFICATION_TRIGGER_ONE_MINUTE_MINTING_SPIKE.FALSE
              : NOTIFICATION_TRIGGER_ONE_MINUTE_MINTING_SPIKE['100']
          )
        }
        options={Object.entries(
          NOTIFICATION_TRIGGER_ONE_MINUTE_MINTING_SPIKE
        ).slice(0, -1)}
        selectedValue={oneMinuteMintingSpikeTriggerValue}
      />
      <NotificationTrigger
        checked={isFiveMinuteMintingSpikeTriggerEnabled}
        description={NOTIFICATION_TRIGGER_DESCRIPTION.FIVE_MINUTE_MINTING_SPIKE}
        name={NOTIFICATION_TRIGGER_TYPE.FIVE_MINUTE_MINTING_SPIKE}
        onOptionChange={(value: number | boolean) =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.FIVE_MINUTE_MINTING_SPIKE,
            value
          )
        }
        onToggle={() =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.FIVE_MINUTE_MINTING_SPIKE,
            isFiveMinuteMintingSpikeTriggerEnabled
              ? NOTIFICATION_TRIGGER_FIVE_MINUTE_MINTING_SPIKE.FALSE
              : NOTIFICATION_TRIGGER_FIVE_MINUTE_MINTING_SPIKE['300']
          )
        }
        options={Object.entries(
          NOTIFICATION_TRIGGER_FIVE_MINUTE_MINTING_SPIKE
        ).slice(0, -1)}
        selectedValue={fiveMinuteMintingSpikeTriggerValue}
      />
      <NotificationTrigger
        checked={isPendingTriggerEnabled}
        description={
          NOTIFICATION_TRIGGER_DESCRIPTION.PENDING_TRANSACTIONS_ALERT
        }
        name={NOTIFICATION_TRIGGER_TYPE.PENDING_TRANSACTIONS_ALERT}
        onOptionChange={(value: number | boolean) =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.PENDING_TRANSACTIONS_ALERT,
            value
          )
        }
        onToggle={() =>
          setTriggerSettings(
            NOTIFICATION_TRIGGER_TYPE.PENDING_TRANSACTIONS_ALERT,
            isPendingTriggerEnabled
              ? NOTIFICATION_TRIGGER_PENDING_TRANSACTIONS_ALERT.FALSE
              : NOTIFICATION_TRIGGER_PENDING_TRANSACTIONS_ALERT['50']
          )
        }
        options={Object.entries(
          NOTIFICATION_TRIGGER_PENDING_TRANSACTIONS_ALERT
        ).slice(0, -1)}
        selectedValue={pendingTransactionsAlertTriggerValue}
      />
    </div>
  );
}
