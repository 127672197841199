import cn from 'classnames';
import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';
import { useAccount } from 'wagmi';

import {
  IcnArrowDown,
  IcnCollections,
  IcnProfile,
  IcnRefresh,
  IcnSignIn,
  IcnSignOut,
  IcnWallet,
} from '~/assets';
import { DropdownMenu, Loading } from '~/components';
import { useAuth } from '~/contexts/AuthContext';
import useIsMounted from '~/hooks/useIsMounted';
import { appConnectModalState, appSettingModalState } from '~/store/app';
import styleVariables from '~/styles/variables.module.scss';
import formatAddress from '~/utils/formatAddress';

import styles from './Account.module.scss';

interface AccountProps {}

export default function Account({}: AccountProps) {
  const router = useRouter();
  const setAppSettingModal = useSetRecoilState(appSettingModalState);
  const { address, isConnected: isWalletConnected } = useAccount();
  const { isLoading, signIn, signOut, user } = useAuth();
  const isMounted = useIsMounted();
  const setAppConnectModal = useSetRecoilState(appConnectModalState);

  const handleConnect = () => {
    setAppConnectModal({ isOpened: true });
  };

  const handleConnectWithDifferentWallet = async () => {
    setAppConnectModal({ isOpened: true });
  };

  const handleProfile = () => {
    setAppSettingModal({ history: [5], isOpened: true });
  };

  const handleManageWallet = () => {
    setAppSettingModal({ history: [6], isOpened: true });
  };

  const handleMyCollections = () => {
    router.push('/collections', undefined, { shallow: true });
  };

  if (!isMounted || isLoading) {
    return (
      <div className={styles.loading_container}>
        <Loading color={styleVariables.brand500} />
      </div>
    );
  }

  if (!isWalletConnected) {
    return (
      <button className={styles.btn_connect} onClick={handleConnect}>
        <IcnWallet />
        <span>{'Connect Wallet'}</span>
      </button>
    );
  }

  if (!user) {
    return (
      <button
        className={cn(styles.account_container, styles.not_signed_in)}
        onClick={signIn}
      >
        <div className={styles.wallet_container}>
          <span className={cn(styles.connect_info, styles.sign_in_text)}>
            {`Sign in`}
          </span>
          <span className={styles.wallet}>{formatAddress(address)}</span>
        </div>
        <IcnSignIn />
      </button>
    );
  }

  return (
    <DropdownMenu.Menu
      label={
        <div className={styles.account_container}>
          <div className={styles.wallet_container}>
            <span className={styles.connect_info}>
              {user.username || 'No username'}
            </span>
            <span className={styles.wallet}>{formatAddress(user.address)}</span>
          </div>
          <IcnArrowDown />
        </div>
      }
    >
      <DropdownMenu.MenuItem
        label={
          <div className={styles.account_menu}>
            <IcnProfile />
            <span>{'Profile'}</span>
          </div>
        }
        onClick={handleProfile}
      />
      <DropdownMenu.MenuItem
        label={
          <div className={styles.account_menu}>
            <IcnCollections />
            <span>{'My Collections'}</span>
          </div>
        }
        onClick={handleMyCollections}
      />
      <DropdownMenu.MenuItem
        label={
          <div className={styles.account_menu}>
            <IcnWallet />
            <span>{'Manage Wallets'}</span>
          </div>
        }
        onClick={handleManageWallet}
      />
      <DropdownMenu.MenuItem
        label={
          <div className={styles.account_menu}>
            <IcnRefresh />
            <span>{'Connect a different wallet'}</span>
          </div>
        }
        onClick={handleConnectWithDifferentWallet}
      />
      <DropdownMenu.MenuItem
        label={
          <div className={styles.account_menu}>
            <IcnSignOut />
            <span>{'Sign out'}</span>
          </div>
        }
        onClick={signOut}
      />
    </DropdownMenu.Menu>
  );
}
