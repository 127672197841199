import cn from 'classnames';

import { Thumbnail } from '~/components';
import { IMAGE_SIZE } from '~/constants';
import type { SearchSuggestion } from '~/types';
import formatAddress from '~/utils/formatAddress';
import formatTimeAgo from '~/utils/formatTimeAgo';

import styles from './SuggestionItem.module.scss';

interface SuggestionItemProps {
  focused: boolean;
  index: number;
  onClickItem: (suggestion: SearchSuggestion, index: number) => void;
  suggestion: SearchSuggestion;
}

export default function SuggestionItem({
  focused = false,
  index,
  onClickItem,
  suggestion,
}: SuggestionItemProps) {
  const {
    address,
    deployedAt,
    firstMint,
    flagCount,
    hideCount,
    highlights,
    imageUrl,
    name,
    notableMints,
    totalMints,
  } = { ...suggestion };
  const deployedAtDiff = deployedAt
    ? Date.now() - new Date(deployedAt * 1000).getTime()
    : 0;
  const firstMintDiff = firstMint
    ? Date.now() - new Date(firstMint * 1000).getTime()
    : 0;

  // Use onMouseDown because onClick event blur input
  const onMouseDown = () => {
    onClickItem(suggestion, index + 1);
  };

  return (
    <li
      className={cn(styles.container, {
        [styles.focused]: focused,
      })}
      onMouseDown={onMouseDown}
    >
      <div className={styles.inner_container}>
        <div className={styles.left_container}>
          <div className={styles.thumbnail}>
            <Thumbnail name={name} size={IMAGE_SIZE.SMALL} url={imageUrl} />
          </div>
          <div className={styles.text_box}>
            {highlights?.name?.value ? (
              <p
                className={styles.name}
                dangerouslySetInnerHTML={{ __html: highlights?.name?.value }}
              />
            ) : (
              <p className={styles.name}>{name}</p>
            )}
            <span className={styles.address}>{formatAddress(address)}</span>
          </div>
        </div>
        <div className={styles.right_container}>
          <ul className={styles.columns}>
            <li className={cn('number_column', styles.column)}>
              {totalMints ? totalMints.toLocaleString() : ''}
            </li>
            <li className={cn('number_column', styles.column)}>
              {notableMints ? notableMints.toLocaleString() : ''}
            </li>
            <li className={cn('text_column', styles.column)}>
              {deployedAtDiff ? formatTimeAgo(deployedAtDiff) : ''}
            </li>
            <li className={cn('text_column', styles.column)}>
              {firstMintDiff ? formatTimeAgo(firstMintDiff) : ''}
            </li>
            <li className={cn('number_column', styles.column)}>
              {flagCount ? flagCount.toLocaleString() : ''}
            </li>
            <li className={cn('number_column', styles.column)}>
              {hideCount ? hideCount.toLocaleString() : ''}
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
}
