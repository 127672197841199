import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { IcnSmallChainEthereum } from '~/assets';
import Thumbnail from '~/components/Thumbnail';
import { IMAGE_SIZE, RENDERING_BOUND_TIME, TRANSITION_TIME } from '~/constants';
import { usePrevious } from '~/hooks/usePrevious';
import type { NotableGroupWithMintsVolume } from '~/types';

import styles from './NotableGroupMintsVolumeItem.module.scss';

interface NotableGroupMintsVolumeItemProps extends NotableGroupWithMintsVolume {
  onClick: () => void;
  selected?: boolean;
}

export default function NotableGroupMintsVolumeItem({
  imageUrl,
  name,
  onClick,
  selected,
  volume,
}: NotableGroupMintsVolumeItemProps) {
  const boundTimeoutRef = useRef<undefined | number>(undefined);
  const noTransitionTimeoutRef = useRef<undefined | number>(undefined);
  const prevVolume = usePrevious(volume);
  const [isBound, setIsBound] = useState(false);
  const [noTransition, setNoTransition] = useState(true);

  useEffect(() => {
    if (!noTransition) {
      setIsBound(true);
    }
  }, [noTransition]);

  useEffect(() => {
    if (!!prevVolume && prevVolume !== volume) {
      setNoTransition(false);
    }
  }, [prevVolume, volume]);

  useEffect(() => {
    if (isBound) {
      boundTimeoutRef.current = window.setTimeout(
        () => setIsBound(false),
        RENDERING_BOUND_TIME
      );
      return () => window.clearTimeout(boundTimeoutRef.current);
    } else {
      noTransitionTimeoutRef.current = window.setTimeout(
        () => setNoTransition(true),
        TRANSITION_TIME - RENDERING_BOUND_TIME
      );
      return () => window.clearTimeout(noTransitionTimeoutRef.current);
    }
  }, [isBound]);

  return (
    <div
      className={cn(styles.group_item, {
        [styles.bound]: isBound,
        [styles.no_transition]: noTransition,
        [styles.selected]: selected,
      })}
      onClick={onClick}
    >
      <div className={styles.group_info}>
        <Thumbnail url={imageUrl} size={IMAGE_SIZE.SMALL} name={name} />
        <span className={styles.group_name}>{name}</span>
      </div>
      <div className={styles.volume_info}>
        <span className={styles.volume}>{volume === '0' ? '-' : volume}</span>
        {volume !== '0' && (
          <div className={styles.icn_ethereum}>
            <IcnSmallChainEthereum />
          </div>
        )}
      </div>
    </div>
  );
}
