import cn from 'classnames';

import styles from './SuggestionHeader.module.scss';

interface SuggestionHeaderProps {}

export default function SuggestionHeader({}: SuggestionHeaderProps) {
  return (
    <div className={cn(styles.container)}>
      <div className={styles.inner_container}>
        <div className={styles.left_container}>
          <div className={styles.text_box}>
            <p className={styles.name}>{'Collection'}</p>
          </div>
        </div>
        <div className={styles.right_container}>
          <ul className={styles.columns}>
            <li className={cn('number_column', styles.column)}>
              {'Total Mints'}
            </li>
            <li className={cn('number_column', styles.column)}>
              {'Notable Mints'}
            </li>
            <li className={cn('text_column', styles.column)}>{'Deployed'}</li>
            <li className={cn('text_column', styles.column)}>{'First Mint'}</li>
            <li className={cn('number_column', styles.column)}>{'Flags'}</li>
            <li className={cn('number_column', styles.column)}>{'Hidden'}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
