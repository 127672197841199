import type { NewFeature } from '~/types';

import base from './base';

const Etc = {
  getGasPrice() {
    return base.get<{ gasPrice: number | null }>(`/gas_price/`);
  },
  getNewFeatures() {
    return base.get<NewFeature[]>(`/new_features/`);
  },
  getPreSignedURL(filename: string) {
    return base.get<{ preSignedUrl: string }>(`/image/?filename=${filename}`);
  },
};

export default Etc;
