import NextHead from 'next/head';
import type { ReactNode } from 'react';

import config from '~/config';

interface HeadProps {
  children?: ReactNode;
  description?: string | null;
  imageUrl?: string | null;
  title?: string | null;
  url?: string | null;
}

export default function Head({
  children,
  description,
  imageUrl,
  title,
  url,
}: HeadProps) {
  const descriptionContent =
    description ?? 'Discover the best new NFTs, every day';
  const imageUrlContent =
    imageUrl ?? `${config.ASSETS_HOST}/images/common/og.png`;
  const metaUrl = url ?? config.APP_HOST;
  const titleContent = title
    ? `${title} | CatchMint`
    : 'CatchMint - NFT mint tracker';

  return (
    <NextHead>
      <title>{titleContent}</title>
      <meta name="title" content={titleContent} key="title" />
      <meta name="description" content={descriptionContent} key="description" />
      <meta
        property="og:description"
        content={descriptionContent}
        key="og:description"
      />
      <meta property="og:title" content={titleContent} key="og:title" />
      <meta property="og:url" content={metaUrl} key="og:url" />
      <meta property="og:image" content={imageUrlContent} key="og:image" />
      <meta property="og:image:width" content="1200" key="og:image:width" />
      <meta property="og:image:height" content="630" key="og:image:height" />
      <meta
        name="twitter:description"
        content={descriptionContent}
        key="twitter:description"
      />
      <meta
        name="twitter:image"
        content={imageUrlContent}
        key="twitter:image"
      />
      <meta
        name="twitter:image:alt"
        content={title ?? 'CatchMint'}
        key="twitter:image:alt"
      />
      <meta name="twitter:title" content={titleContent} key="twitter:title" />
      {url && <link rel="canonical" href={url} key="canonical" />}
      {children}
    </NextHead>
  );
}
