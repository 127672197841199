import { useEffect, useState } from 'react';

export default function useWebPushSetting() {
  const [notificationPermission, setNotificationPermission] =
    useState<NotificationPermission>('default');

  useEffect(() => {
    const checkNotificationPermission = async () => {
      const status = await Notification.requestPermission();

      setNotificationPermission(status);
    };

    checkNotificationPermission();
  }, []);

  return {
    notificationPermission,
  };
}
