import cn from 'classnames';

import { IcnClose } from '~/assets';
import { Modal } from '~/components';
import type { AvailableChain, ContractAnnouncement } from '~/types';

import AnnouncementItem from './AnnouncementItem';
import styles from './AnnouncementsModal.module.scss';

interface AnnouncementsModalProps {
  announcements: ContractAnnouncement[];
  onClose: () => void;
  chain?: AvailableChain;
}

export default function AnnouncementsModal({
  announcements,
  chain = 'Ethereum',
  onClose,
}: AnnouncementsModalProps) {
  return (
    <Modal open={true} onClose={onClose} style={{ width: 720 }}>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <span className={styles.title}>Announcements</span>
          <button
            className={cn('btn_icon', styles.btn_close)}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <IcnClose />
          </button>
        </div>
        <ol className={styles.announcements}>
          {announcements.map((announcement) => (
            <AnnouncementItem
              {...announcement}
              key={announcement.uuid}
              chain={chain}
            />
          ))}
        </ol>
      </div>
    </Modal>
  );
}
