import { useVirtualizer } from '@tanstack/react-virtual';
import { useCallback, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { groupActivitySelectedNotableGroupIdsState } from '~/store/app';
import type { NotableGroupWithMintsVolume } from '~/types';

interface UseNotableGroupMintsVolumeList {
  notSelectedItems: NotableGroupWithMintsVolume[];
  selectedItems: NotableGroupWithMintsVolume[];
}

export default function useNotableGroupMintsVolumeList({
  notSelectedItems,
  selectedItems,
}: UseNotableGroupMintsVolumeList) {
  const setSelectedNotableGroupIds = useSetRecoilState<number[]>(
    groupActivitySelectedNotableGroupIdsState
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: notSelectedItems.length + selectedItems.length,
    estimateSize: () => 36,
    getScrollElement: () => containerRef.current,
    overscan: 5,
  });

  const totalSize = virtualizer.getTotalSize();
  const virtualItems = virtualizer.getVirtualItems();

  const handleClickItem = useCallback((id: number, isSelected: boolean) => {
    if (isSelected) {
      return setSelectedNotableGroupIds((prev) =>
        prev.filter((selectedId) => selectedId !== id)
      );
    }
    return setSelectedNotableGroupIds((prev) => [...prev, id]);
  }, []);

  return {
    containerRef,
    handleClickItem,
    totalSize,
    virtualItems,
  };
}
