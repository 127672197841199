import cn from 'classnames';
import { useRecoilValue } from 'recoil';

import { IcnSmallHint } from '~/assets';
import {
  GoogleReCAPTCHAGuide,
  Loading,
  NotableGroup,
  Popover,
} from '~/components';
import { DEFAULT_MAIN_LAYOUT_ORDER, MAIN_LAYOUT } from '~/constants';
import useContractNotableFlags from '~/data/useContractNotableFlags';
import useNotableGroups from '~/data/useNotableGroups';
import { displayState } from '~/store/app';
import { checksumContractAddressState } from '~/store/contract';

import styles from './FlagDetailsModal.module.scss';

interface FlagDetailsModal {}

export default function FlagDetailsModal({}: FlagDetailsModal) {
  const address = useRecoilValue(checksumContractAddressState);
  const mainLayoutOrder =
    useRecoilValue(displayState)?.main || DEFAULT_MAIN_LAYOUT_ORDER;
  const { notableGroups } = useNotableGroups();
  const { notableFlags, isLoading } = useContractNotableFlags(address);

  return (
    <div
      className={cn(styles.flag_details_container, {
        [styles.left]: mainLayoutOrder[0] !== MAIN_LAYOUT.CONTRACT_DETAIL,
        [styles.right]: mainLayoutOrder[0] === MAIN_LAYOUT.CONTRACT_DETAIL,
      })}
    >
      <div className={styles.notable_flag_table}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className="text_column">
                <div className={styles.table_header_cell}>
                  Name
                  <Popover
                    render={() => (
                      <div className="hint_popover">
                        <span>
                          {
                            'Duplicates can exist if the flagger is in multiple groups.'
                          }
                        </span>
                      </div>
                    )}
                    animation
                    placement="top"
                    showArrow
                  >
                    <div className={styles.column_hint}>
                      <IcnSmallHint />
                    </div>
                  </Popover>
                </div>
              </th>
              <th className="number_column">Fake/Rug/Scam</th>
              <th className="number_column">Wallet Drain</th>
              <th className="number_column">Copy/Stolen</th>
              <th className="number_column">Honeypot</th>
              <th className="number_column">Other</th>
              <th className="number_column">Total</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className={styles.no_data}>
                <td colSpan={6} className={styles.no_data}>
                  <Loading />
                </td>
              </tr>
            ) : notableFlags.length > 0 ? (
              notableFlags.map((notableFlag, index) => {
                const { copy, drain, honeypot, id, other, scam, total } =
                  notableFlag;
                const notableGroup = notableGroups.find(
                  (group) => group.id === id
                );

                if (id !== 0 && !notableGroup) return;
                return (
                  <tr key={`row_${index}`}>
                    <td className="text_column">
                      {id === 0 ? 'Others' : <NotableGroup {...notableGroup} />}
                    </td>
                    <td className="number_column">
                      {scam ? scam.toLocaleString() : ''}
                    </td>
                    <td className="number_column">
                      {drain ? drain.toLocaleString() : ''}
                    </td>
                    <td className="number_column">
                      {copy ? copy.toLocaleString() : ''}
                    </td>
                    <td className="number_column">
                      {honeypot ? honeypot.toLocaleString() : ''}
                    </td>
                    <td className="number_column">
                      {other ? other.toLocaleString() : ''}
                    </td>
                    <td className="number_column">
                      {total ? total.toLocaleString() : ''}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className={styles.no_data}>
                <td colSpan={6} className={styles.no_data}>
                  {'This collection has not been reported by community.'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={styles.recaptcha_guide_container}>
        <GoogleReCAPTCHAGuide />
      </div>
    </div>
  );
}
