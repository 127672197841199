import type { NotificationPayload } from 'firebase/messaging';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import {
  APP_SETTINGS,
  DEFAULT_AUTOMATIONS,
  DEFAULT_DISPLAY,
  DEFAULT_FAILED_TRANSACTIONS,
  DEFAULT_GROUP_ACTIVITY,
  DEFAULT_LIVE_MINTS,
  DEFAULT_MINTS_OVERViEW,
  DEFAULT_SETTINGS,
  DEFAULT_THEME,
  DEFAULT_TOP_100_HOLDERS,
} from '~/constants';
import type {
  Automations,
  Display,
  FailedTransactionsFilter,
  LiveMintsFilter,
  MintsOverviewFilter,
  NotableGroupActivityFilter,
  Settings,
  Theme,
  Top100HoldersFilter,
} from '~/types';

const { persistAtom } = recoilPersist({
  key: APP_SETTINGS,
});

const appConnectModalState = atom<{ isOpened: boolean }>({
  key: 'appConnectModal',
  default: {
    isOpened: false,
  },
});

const appNewFeaturesModalState = atom<{
  isOpened: boolean;
}>({
  key: 'appNewFeaturesModal',
  default: {
    isOpened: true,
  },
});

const appNotificationState = atom<
  | {
      data:
        | {
            [key: string]: string;
          }
        | undefined;
      notification: NotificationPayload;
    }
  | undefined
>({
  key: 'appNotification',
  default: undefined,
});

const appNotificationModalState = atom<{
  isOpened: boolean;
  isUnread: boolean;
}>({
  key: 'appNotificationModal',
  default: {
    isOpened: false,
    isUnread: false,
  },
});

const appSettingModalState = atom<{ history: number[]; isOpened: boolean }>({
  key: 'appSettingModal',
  default: {
    history: [0],
    isOpened: false,
  },
});

const automationsState = atom<Automations>({
  key: 'automations',
  default: DEFAULT_AUTOMATIONS,
  effects_UNSTABLE: [persistAtom],
});

const displayState = atom<Display>({
  key: 'display',
  default: DEFAULT_DISPLAY,
  effects_UNSTABLE: [persistAtom],
});

const failedTransactionsFilterState = atom<FailedTransactionsFilter>({
  key: 'failedTransactions',
  default: DEFAULT_FAILED_TRANSACTIONS,
  effects_UNSTABLE: [persistAtom],
});

const groupActivityFilterState = atom<NotableGroupActivityFilter>({
  key: 'groupActivity',
  default: DEFAULT_GROUP_ACTIVITY,
  effects_UNSTABLE: [persistAtom],
});

const groupActivitySelectedNotableGroupIdsState = atom<number[]>({
  key: 'groupActivitySelectedIds',
  default: [],
});

const liveMintsFilterState = atom<LiveMintsFilter>({
  key: 'liveMints',
  default: DEFAULT_LIVE_MINTS,
  effects_UNSTABLE: [persistAtom],
});

const mintsOverviewFilterState = atom<MintsOverviewFilter>({
  key: 'mintsOverview',
  default: DEFAULT_MINTS_OVERViEW,
  effects_UNSTABLE: [persistAtom],
});

const settingsState = atom<Settings>({
  key: 'settings',
  default: DEFAULT_SETTINGS,
  effects_UNSTABLE: [persistAtom],
});

const themeState = atom<Theme>({
  key: 'theme',
  default: DEFAULT_THEME,
  effects_UNSTABLE: [persistAtom],
});

const top100HoldersFilterState = atom<Top100HoldersFilter>({
  key: 'top100Holders',
  default: DEFAULT_TOP_100_HOLDERS,
  effects_UNSTABLE: [persistAtom],
});

export {
  appConnectModalState,
  appNewFeaturesModalState,
  appNotificationState,
  appNotificationModalState,
  appSettingModalState,
  automationsState,
  displayState,
  failedTransactionsFilterState,
  groupActivityFilterState,
  groupActivitySelectedNotableGroupIdsState,
  liveMintsFilterState,
  mintsOverviewFilterState,
  settingsState,
  themeState,
  top100HoldersFilterState,
};
