import type {
  Comment,
  Contract,
  ContractAnnouncement,
  ContractArt,
  ContractEntry,
  ContractFailedTransaction,
  ContractFlag,
  ContractHolder,
  ContractHolderNetworkLink,
  ContractLinkedHolder,
  ContractNotableFlag,
  ContractSuccessfulTransaction,
  CursorBasedPagination,
  CursorBasedPaginationWithCount,
  EditableContractInfo,
  FlagReason,
  MintInfo,
  MintingAggregates,
  MyContract,
  NotableGroupMinted,
  SimilarContract,
} from '~/types';

import base from './base';

const Contracts = {
  async addCommentToContract(
    address: string,
    reCaptchaToken: string,
    comment: string,
    parent?: string
  ) {
    const response = await base.post('/comments/', {
      contentObject: `/contracts/${address}/`,
      comment,
      parent,
      reCaptchaToken,
    });
    return response;
  },
  cancelFlagContract(address: string) {
    return base.delete(`/contracts/${address}/flag/`);
  },
  changeFlagContract(address: string, reason: FlagReason) {
    return base.patch(`/contracts/${address}/flag/`, reason);
  },
  connectDiscordInviteUrlToContract(address: string, discordUrl: string) {
    return base.post<{ discordGuildName: string }>(
      `/contracts/${address}/discord_invite/`,
      { discordUrl }
    );
  },
  createAnnouncement(address: string, title: string, description: string) {
    return base.post<ContractAnnouncement>(`/contracts/announcements/`, {
      description,
      contract: address,
      title,
    });
  },
  deleteContractAnnouncement(uuid: string) {
    return base.delete(`/contracts/announcements/${uuid}`);
  },
  disconnectDiscordToContract(address: string) {
    return base.delete(`/contracts/${address}/discord/`);
  },
  disconnectXToContract(address: string) {
    return base.delete(`/contracts/${address}/twitter/`);
  },
  flagContract(address: string, reCaptchaToken: string, reason: FlagReason) {
    return base.post(`/contracts/${address}/flag/`, {
      ...reason,
      reCaptchaToken,
    });
  },
  getContractAnnouncements(address: string, cursor: string | null) {
    const url = `/contracts/${address}/announcements/${
      cursor ? `?cursor=${cursor}` : ''
    }`;
    return base.get<CursorBasedPagination<ContractAnnouncement>>(url);
  },
  getContractArt(address: string) {
    return base.get<ContractArt[]>(`/contracts/${address}/art/`);
  },
  getContractComments(address: string, cursor: string | null) {
    const url = `/contracts/${address}/comments/${
      cursor ? `?cursor=${cursor}` : ''
    }`;
    return base.get<CursorBasedPaginationWithCount<Comment>>(url);
  },
  getContractDetail(address: string) {
    return base.get<Contract>(`/contracts/${address}/`);
  },
  getContractEntry(address: string) {
    return base.get<ContractEntry>(`/contracts/${address}/entry/`);
  },
  getContractFailedTransactions(address: string) {
    return base.get<ContractFailedTransaction[]>(
      `/contracts/${address}/failed_txs/`
    );
  },
  getContractFlags(address: string) {
    return base.get<ContractFlag[]>(`/contracts/${address}/flags/`);
  },
  getContractHolders(address: string) {
    return base.get<ContractHolder[]>(`/contracts/${address}/holders/`);
  },
  getContractLinkedHolders(address: string) {
    return base.get<ContractLinkedHolder[]>(
      `/contracts/${address}/linked_holders/`
    );
  },
  getContractHoldersNetwork(addresses: string[]) {
    return base.post<ContractHolderNetworkLink[]>(
      `/contracts/holders_network/`,
      {
        addresses,
      }
    );
  },
  getContractNotableFlags(address: string) {
    return base.get<ContractNotableFlag[]>(
      `/contracts/${address}/notable_flags/`
    );
  },
  getContractSuccessfulTxs(address: string) {
    return base.get<ContractSuccessfulTransaction[]>(
      `/contracts/${address}/successful_txs/`
    );
  },
  getSimilarContracts(address: string) {
    return base.get<SimilarContract[]>(`/contracts/${address}/similar/`);
  },
  getLinkedXContracts(address: string) {
    return base.get<SimilarContract[]>(
      `/contracts/${address}/twitter_accounts/`
    );
  },
  getMintInfo(address: string) {
    return base.get<MintInfo[]>(`/contracts/${address}/mints/`);
  },
  getMintingAggregates(address: string, ids: string) {
    return base.get<MintingAggregates>(
      `/contracts/${address}/notable_groups_aggregate/`,
      { params: { ids } }
    );
  },
  getMyContracts(cursor: string | null) {
    const url = `/contracts/my${cursor ? `?cursor=${cursor}` : ''}`;
    return base.get<CursorBasedPagination<MyContract>>(url);
  },
  getNotableGroupsMinted(address: string, ids: string) {
    return base.get<NotableGroupMinted[]>(
      `/contracts/${address}/notable_groups/`,
      { params: { ids } }
    );
  },
  hideContract(address: string) {
    return base.post(`/contracts/${address}/hide/`);
  },
  unhideContract(address: string) {
    return base.delete(`/contracts/${address}/hide/`);
  },
  updateContractInfo(
    address: string,
    contractInfo: Partial<EditableContractInfo>
  ) {
    return base.patch(`/contracts/${address}/`, contractInfo);
  },
};

export default Contracts;
