import dayjs from 'dayjs';
import { memo, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  IcnMarketplaceBlur,
  IcnMarketplaceLooksRare,
  IcnMarketplaceOpensea,
  IcnMarketplaceX2Y2,
  IcnSmallExternal,
} from '~/assets';
import { ExternalLink, Popover } from '~/components';
import Table, {
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '~/components/Table';
import {
  AVAILABLE_CHAIN,
  BLUR_ROYALTY_DESCRIPTION_LINK,
  DETAIL_LAYOUT,
  EXTERNAL_LINK,
  LOOKSRARE_ROYALTY_DESCRIPTION_LINK,
  MARKETPLACE_NAME,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
  REFETCH_DURATION,
  RENDERING_BOUND_TIME,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import type { Collection, Marketplace } from '~/types';
import capitalizeFirstLetter from '~/utils/capitalizeFirstLetter';
import formatEthPrice from '~/utils/formatEthPrice';
import noExponents from '~/utils/noExponents';

import styles from './MarketplaceComparisonSection.module.scss';
import SectionLayout from './SectionLayout';

interface MarketplaceComparisonSectionProps {
  collections: Collection[] | undefined;
  isFetched: boolean;
  isLoading: boolean;
  oldestModified: string;
  price: number;
  refetch: () => void;
}

export default memo(function MarketplaceComparisonSection({
  collections,
  isFetched,
  isLoading,
  oldestModified,
  price,
  refetch,
}: MarketplaceComparisonSectionProps) {
  const analytics = useAnalyticsContext();

  const refetchTimer = useRef<null | NodeJS.Timeout>(null);
  const [enableBound, setEnableBound] = useState(false);
  const [refetchCount, setRefetchCount] = useState(0);

  const icon = (marketplace: Marketplace) =>
    ({
      [MARKETPLACE_NAME.BLUR]: <IcnMarketplaceBlur />,
      [MARKETPLACE_NAME.LOOKSRARE]: <IcnMarketplaceLooksRare />,
      [MARKETPLACE_NAME.OPENSEA]: <IcnMarketplaceOpensea />,
      [MARKETPLACE_NAME.X2Y2]: <IcnMarketplaceX2Y2 />,
    }[marketplace]);

  useEffect(() => {
    const boundTimer = () =>
      setTimeout(() => {
        setEnableBound(true);
      }, RENDERING_BOUND_TIME);

    if (isFetched) {
      boundTimer();
      setRefetchCount(0);
    } else {
      clearTimeout(boundTimer());
      setEnableBound(false);
    }
  }, [isFetched]);

  useEffect(() => {
    if (oldestModified.length > 0) {
      const hourDiff = dayjs().diff(dayjs(oldestModified), 'hour', true);

      if (hourDiff > 1 && refetchCount < 3) {
        refetchTimer.current = setTimeout(() => {
          refetch();
          setRefetchCount((count) => count + 1);
        }, REFETCH_DURATION);
      } else {
        refetchTimer.current && clearTimeout(refetchTimer.current);
      }
    }
    // refetch method is too often called. so remove it in dependencies
  }, [oldestModified, refetchCount]);

  return (
    <SectionLayout
      boundTrigger={''}
      enableBound={enableBound}
      id={'marketplace_comparison'}
    >
      <div className={styles.section_title_container}>
        <span className={styles.section_title}>
          {isLoading ? (
            <Skeleton width={250} />
          ) : (
            DETAIL_LAYOUT.MARKETPLACE_COMPARISON
          )}
        </span>
        {!isLoading && (
          <span className={styles.last_updated}>
            {oldestModified.length > 0
              ? `Last updated ${dayjs().to(dayjs(oldestModified))}`
              : ''}
          </span>
        )}
      </div>
      {isLoading ? (
        <Skeleton height={157} />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell isText={true} isHeader={true} width={130}>
                {'Marketplace'}
              </TableCell>
              <TableCell isText={false} isHeader={true} width={174}>
                {'Royalty'}
              </TableCell>
              <TableCell isText={false} isHeader={true} width={100}>
                {'Fee'}
              </TableCell>
              <TableCell isText={false} isHeader={true} width={130}>
                {'Break-even Listing'}
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {collections?.map((collection) =>
              collection.url === '' ? (
                <TableRow key={collection.marketplace}>
                  <TableCell isText={true} colSpan={4}>
                    {`${
                      collection.marketplace === MARKETPLACE_NAME.X2Y2
                        ? 'X2Y2'
                        : capitalizeFirstLetter(collection.marketplace)
                    } API is unavailable. Please try again in a few minutes.`}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={collection.marketplace}>
                  <TableCell className={styles.line_height_zero} isText={true}>
                    <div className={styles.market}>
                      <ExternalLink
                        label={`collection_${collection.marketplace}_link`}
                        onClick={() => {
                          analytics.track(REDIRECT_CLICKED, {
                            chain: AVAILABLE_CHAIN.ETHEREUM,
                            location:
                              REDIRECT_CLICKED_LOCATION.MARKETPLACE_COMPARISON,
                            type: REDIRECT_CLICKED_TYPE.MARKETPLACE,
                            url: collection.url,
                          });
                        }}
                        role="link"
                        url={collection.url}
                      >
                        <div className={styles.icn_market}>
                          {icon(collection.marketplace)}
                        </div>
                        <span>
                          {EXTERNAL_LINK[collection.marketplace.toUpperCase()]}
                        </span>
                      </ExternalLink>
                    </div>
                  </TableCell>
                  <TableCell isText={false}>
                    {collection.marketplace === MARKETPLACE_NAME.BLUR ? (
                      <div className={styles.royalty_description_link}>
                        <ExternalLink
                          label={`blur_royalty_description_link`}
                          onClick={() => {
                            analytics.track(REDIRECT_CLICKED, {
                              chain: AVAILABLE_CHAIN.ETHEREUM,
                              location:
                                REDIRECT_CLICKED_LOCATION.MARKETPLACE_COMPARISON,
                              type: REDIRECT_CLICKED_TYPE.OTHER,
                              url: BLUR_ROYALTY_DESCRIPTION_LINK,
                            });
                          }}
                          role="link"
                          url={BLUR_ROYALTY_DESCRIPTION_LINK}
                        >
                          <span>{`incentivized`}</span>
                          <div className={styles.icn_external}>
                            <IcnSmallExternal />
                          </div>
                        </ExternalLink>
                      </div>
                    ) : collection.marketplace ===
                      MARKETPLACE_NAME.LOOKSRARE ? (
                      <div className={styles.royalty_description_link}>
                        <ExternalLink
                          label={`looksrare_royalty_description_link`}
                          onClick={() => {
                            analytics.track(REDIRECT_CLICKED, {
                              chain: AVAILABLE_CHAIN.ETHEREUM,
                              location:
                                REDIRECT_CLICKED_LOCATION.MARKETPLACE_COMPARISON,
                              type: REDIRECT_CLICKED_TYPE.OTHER,
                              url: LOOKSRARE_ROYALTY_DESCRIPTION_LINK,
                            });
                          }}
                          role="link"
                          url={LOOKSRARE_ROYALTY_DESCRIPTION_LINK}
                        >
                          <span>{`optional`}</span>
                          <div className={styles.icn_external}>
                            <IcnSmallExternal />
                          </div>
                        </ExternalLink>
                      </div>
                    ) : (
                      `${collection.royalty / 100}%`
                    )}
                  </TableCell>
                  <TableCell isText={false}>
                    {`${collection.fee / 100}%`}
                  </TableCell>
                  <TableCell isText={false}>
                    <Popover
                      render={() => (
                        <div className="price_popover_container">
                          <span className="price_popover_content">
                            {`Ξ ${noExponents(
                              (10000 * price) /
                                (10000 - (collection.fee + collection.royalty))
                            )}`}
                          </span>
                        </div>
                      )}
                      placement="right-end"
                    >
                      <span>
                        {`Ξ ${formatEthPrice(
                          (10000 * price) /
                            (10000 - (collection.fee + collection.royalty))
                        )}`}
                      </span>
                    </Popover>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      )}
    </SectionLayout>
  );
});
