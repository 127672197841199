import cn from 'classnames';
import { useTheme } from 'next-themes';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import styleDark from 'react-syntax-highlighter/dist/cjs/styles/prism/dracula';
import styleLight from 'react-syntax-highlighter/dist/cjs/styles/prism/prism';

import { IcnExternal } from '~/assets';
import { ExternalLink } from '~/components';
import {
  DETAIL_LAYOUT,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import styleVariables from '~/styles/variables.module.scss';
import type { AvailableChain } from '~/types';
import noop from '~/utils/noop';

import styles from './CodeSnippetSection.module.scss';
import SectionLayout from './SectionLayout';

interface CodeSnippetSectionProps {
  address: string;
  chain: AvailableChain;
  isLoading: boolean;
  mintFunctions: string[];
  miscellaneous: string;
}

const customStyle = {
  margin: '0px',
  padding: '16px 12px',
  fontFamily: 'Poppins',
  fontSize: '12px',
  border: `1px solid ${styleVariables.gray100}`,
  borderRadius: '4px',
};

export default memo(function CodeSnippetSection({
  address,
  chain,
  isLoading,
  mintFunctions = [],
  miscellaneous = '',
}: CodeSnippetSectionProps) {
  const analytics = useAnalyticsContext();
  const { theme } = useTheme();
  const style = theme === 'light' ? styleLight : styleDark;

  return (
    <SectionLayout boundTrigger="" enableBound={false} id={'code_snippet'}>
      <div className={styles.section_title_container}>
        <ExternalLink
          className={cn(styles.section_title, {
            [styles.clickable]: !isLoading,
          })}
          label={`etherscan_${address}_code`}
          onClick={
            isLoading
              ? noop
              : () => {
                  analytics.track(REDIRECT_CLICKED, {
                    chain,
                    location: REDIRECT_CLICKED_LOCATION.CODE_SNIPPET,
                    type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
                    url: `https://etherscan.io/address/${address}#code`,
                  });
                }
          }
          role="link"
          url={`https://etherscan.io/address/${address}#code`}
        >
          <span>
            {isLoading ? <Skeleton width={135} /> : DETAIL_LAYOUT.CODE_SNIPPET}
          </span>
          {!isLoading && (
            <div className={styles.icn_external}>
              <IcnExternal />
            </div>
          )}
        </ExternalLink>
      </div>
      <div className={styles.code_snippets_container}>
        {miscellaneous.length > 0 && (
          <div className={styles.code_snippet_container}>
            <span className={styles.code_snippet_title}>{'miscellaneous'}</span>
            <div className={styles.code_snippet_body}>
              <SyntaxHighlighter
                language="solidity"
                style={style}
                customStyle={customStyle}
              >
                {miscellaneous}
              </SyntaxHighlighter>
            </div>
          </div>
        )}
        {mintFunctions.length > 0 && (
          <div className={styles.code_snippet_container}>
            <span className={styles.code_snippet_title}>{`mint function${
              mintFunctions.length > 1 ? 's' : ''
            }`}</span>
            <div className={styles.code_snippet_body}>
              {mintFunctions.map((mintFunction, index) => (
                <SyntaxHighlighter
                  language="solidity"
                  style={style}
                  customStyle={customStyle}
                  key={`mint_function_${index}`}
                >
                  {mintFunction}
                </SyntaxHighlighter>
              ))}
            </div>
          </div>
        )}
      </div>
    </SectionLayout>
  );
});
