import { AnalyticsBrowser } from '@segment/analytics-next';
import { FC, ReactNode, useMemo } from 'react';

import config from '~/config';

import { AnalyticsContext } from './context';
import { AppFormat } from './plugins';

interface AnalyticsProviderProps {
  children: ReactNode;
  writeKey: string;
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({
  children,
  writeKey,
}) => {
  const analytics = useMemo(
    () =>
      AnalyticsBrowser.load(
        {
          cdnURL: config.SEGMENT_CDN_URL,
          plugins: [AppFormat],
          writeKey,
        },
        {
          obfuscate: true,
        }
      ),
    [writeKey]
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
