import { CSSProperties, forwardRef, memo } from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useAccount } from 'wagmi';

import { Loading } from '~/components';
import { useAuth } from '~/contexts/AuthContext';
import useLinkedWallets from '~/data/useLinkedWallets';

import LinkedWalletItem from './LinkedWalletItem';
import styles from './WalletsSection.module.scss';
import commonStyles from '../SettingsModal.module.scss';

interface WalletsSectionProps {}

export default memo(function WalletsSection({}: WalletsSectionProps) {
  const { address: connectedWalletAddress } = useAccount();
  const { unlinkWallet } = useAuth();
  const { isItemLoaded, isLoading, itemCount, linkedWallets, loadMore } =
    useLinkedWallets();

  const innerElementType = forwardRef<HTMLUListElement, any>(
    function InnerElement({ style, ...rest }, ref) {
      return <ul {...rest} ref={ref} style={{ ...style, marginBottom: 34 }} />;
    }
  );

  const renderLinkedWalletItem = ({
    index,
    style,
  }: {
    index: number;
    style: CSSProperties;
  }) => {
    if (!isItemLoaded(index)) {
      return (
        <div className={styles.loading_container} style={style}>
          <Loading size={32} />
        </div>
      );
    }

    const linkedWallet = linkedWallets[index];

    return (
      <LinkedWalletItem
        {...linkedWallet}
        isActive={connectedWalletAddress === linkedWallet.address}
        style={style}
        unlinkWallet={unlinkWallet}
      />
    );
  };

  return (
    <section className={commonStyles.section}>
      <div className={commonStyles.section_container}>
        <div className={commonStyles.section_title_container}>
          <span className={commonStyles.section_title}>{'Manage Wallets'}</span>
          <p className={commonStyles.section_description}>
            {
              'Link your wallets to change to other wallets without having to sign in every time. Wallets linked to this profile are only visible to you.'
            }
          </p>
        </div>
        <div className={styles.link_wallet_container}>
          <div className={styles.how_to_link_container}>
            <span className={styles.how_to_link_title}>
              {'How to link wallet'}
            </span>
            <p className={styles.how_to_link_description}>
              {
                'Go to your wallet extension and switch to the wallet you wish to link to this profile.'
              }
            </p>
          </div>
        </div>
        <div className={styles.linked_wallets_container}>
          {isLoading ? (
            <Loading size={32} />
          ) : (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMore}
              threshold={12}
            >
              {({ onItemsRendered, ref }: any) => (
                <List
                  height={484}
                  innerElementType={innerElementType}
                  itemCount={itemCount}
                  itemSize={32}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={456}
                >
                  {renderLinkedWalletItem}
                </List>
              )}
            </InfiniteLoader>
          )}
        </div>
      </div>
    </section>
  );
});
