import { useContext } from 'react';

import { AuthContext } from './context';

// Create an analytics hook that we can use with other components.
export const useAuth = () => {
  const result = useContext(AuthContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};

export default useAuth;
