import cn from 'classnames';
import { useState } from 'react';

import { IcnClose, IcnWarning } from '~/assets';

import styles from './InstantAlert.module.scss';

interface InstantAlertProps {
  message: string;
  visible?: boolean;
}

export default function InstantAlert({
  message,
  visible = false,
}: InstantAlertProps) {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.hidden]: !show || !visible,
      })}
    >
      <div className={styles.message_container}>
        <div className={styles.warning_container}>
          <IcnWarning />
        </div>
        <p className={styles.message}>{message}</p>
      </div>
      <button className={styles.btn_close} onClick={handleClose}>
        <IcnClose />
      </button>
    </div>
  );
}
