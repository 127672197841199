import type {
  CursorBasedPagination,
  GlobalComment,
  GlobalCommentsFilter,
  GlobalCommentsFilterVoteOption,
} from '~/types';

import base from './base';

const Comments = {
  async cancelDownvoteComment(uuid: string) {
    const response = await base.delete<{ karma: number }>(
      `/comments/${uuid}/downvote/`
    );
    return response;
  },
  async cancelUpvoteComment(uuid: string) {
    const response = await base.delete<{ karma: number }>(
      `/comments/${uuid}/upvote/`
    );
    return response;
  },
  async deleteComment(uuid: string) {
    const response = await base.delete(`/comments/${uuid}/`);
    return response;
  },
  async downvoteComment(uuid: string) {
    const response = await base.post<{ karma: number }>(
      `/comments/${uuid}/downvote/`
    );
    return response;
  },
  async editComment(uuid: string, comment: string) {
    const response = await base.patch(`/comments/${uuid}/`, { comment });
    return response;
  },
  async flagComment(uuid: string) {
    const response = await base.post<{ karma: number }>(
      `/comments/${uuid}/flag/`
    );
    return response;
  },
  getComments(
    cursor: string | null,
    sort: GlobalCommentsFilter,
    duration?: GlobalCommentsFilterVoteOption
  ) {
    const params: {
      sort: GlobalCommentsFilter;
      duration?: GlobalCommentsFilterVoteOption;
    } = {
      sort,
    };
    if (sort === 'vote') {
      params.duration = duration;
    }
    const url = `/comments/?${new URLSearchParams(params).toString()}${
      cursor ? `&cursor=${cursor}` : ''
    }`;

    return base.get<CursorBasedPagination<GlobalComment>>(url);
  },
  async pinComment(uuid: string) {
    const response = await base.post(`/comments/${uuid}/pin/`);
    return response;
  },
  async unflagComment(uuid: string) {
    const response = await base.delete<{ karma: number }>(
      `/comments/${uuid}/flag/`
    );
    return response;
  },
  async unpinComment(uuid: string) {
    const response = await base.delete(`/comments/${uuid}/pin/`);
    return response;
  },
  async unvouchComment(uuid: string) {
    const response = await base.delete<{ karma: number }>(
      `/comments/${uuid}/vouch/`
    );
    return response;
  },
  async upvoteComment(uuid: string) {
    const response = await base.post<{ karma: number }>(
      `/comments/${uuid}/upvote/`
    );
    return response;
  },
  async vouchComment(uuid: string) {
    const response = await base.post<{ karma: number }>(
      `/comments/${uuid}/vouch/`
    );
    return response;
  },
};

export default Comments;
