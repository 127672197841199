import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { useReCaptcha } from 'next-recaptcha-v3';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import API from '~/api';
import { IcnFlagOff, IcnFlagOn } from '~/assets';
import { DropdownMenu, Popover, Toaster } from '~/components';
import {
  AVAILABLE_CHAIN,
  FLAG_REASON,
  FLAG_TYPE,
  UNEXPECTED_ERROR_MESSAGE,
} from '~/constants';
import { contractsKeys } from '~/constants/queryKeys';
import { useAuth } from '~/contexts/AuthContext';
import useContractFlags from '~/data/useContractFlags';
import { checksumContractAddressState } from '~/store/contract';
import type { AvailableChain, Contract, Flag } from '~/types';

import styles from './ContractFlagAction.module.scss';
import FlagDetailsModal from './FlagDetailsModal';
import FlagReasonInputModal from './FlagReasonInputModal';

interface ContractFlagActionProps {
  contractInfo: Contract;
}

export default function ContractFlagAction({
  contractInfo,
}: ContractFlagActionProps) {
  const { flagCount, notableFlagCount, hasFlagged = [] } = { ...contractInfo };
  const { isAuthenticated, signIn } = useAuth();
  const address = useRecoilValue(checksumContractAddressState);
  const chain =
    AVAILABLE_CHAIN.ETHEREUM.toLowerCase() as Lowercase<AvailableChain>;
  const queryClient = useQueryClient();
  const { flags, refetch: flagsRefetch } = useContractFlags(chain, address);
  const { executeRecaptcha } = useReCaptcha();
  const [isOpenFlagReasonModal, setIsOpenFlagReasonModal] = useState(false);

  const updateContractInfo = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: contractsKeys.information(chain, address),
    });
    queryClient.invalidateQueries({
      queryKey: contractsKeys.flags(chain, address),
    });
    queryClient.invalidateQueries({
      queryKey: contractsKeys.notableFlags(chain, address),
    });
  }, [address, chain]);

  const closeFlagReasonModal = () => {
    setIsOpenFlagReasonModal(false);
  };

  const setContractFlag = (label: Flag, text = '') => {
    if (hasFlagged.length > 0) {
      if (hasFlagged[0].label === label) {
        cancelFlag();
      } else {
        changeFlag(label, text);
      }
    } else {
      flag(label, text);
    }

    closeFlagReasonModal();
  };

  const cancelFlag = () => {
    API.cancelFlagContract(address)
      .then(() => {
        updateContractInfo();
        Toaster.toast({
          description: 'Canceled report for this contract',
          type: 'success',
        });
      })
      .catch((err: any) => {
        if (err?.response?.data?.error?.detail) {
          Toaster.toast({
            description: err.response.data.error.detail,
            type: 'error',
          });
        } else {
          Toaster.toast({
            description: UNEXPECTED_ERROR_MESSAGE,
            type: 'error',
          });
        }
      });
  };

  const changeFlag = (label: Flag, text = '') => {
    API.changeFlagContract(address, { label, text })
      .then(() => {
        updateContractInfo();
        Toaster.toast({
          description: 'This contract has been reported',
          type: 'success',
        });
      })
      .catch((err: any) => {
        if (err?.response?.data?.error?.detail) {
          Toaster.toast({
            description: err.response.data.error.detail,
            type: 'error',
          });
          flagsRefetch();
        } else {
          Toaster.toast({
            description: UNEXPECTED_ERROR_MESSAGE,
            type: 'error',
          });
        }
      });
  };

  const flag = async (label: Flag, text = '') => {
    try {
      const reCaptchaToken = await executeRecaptcha('add_flag');

      const { status } = await API.flagContract(address, reCaptchaToken, {
        label,
        text,
      });
      if (status === 201) {
        updateContractInfo();
        Toaster.toast({
          description: 'This contract has been reported',
          type: 'success',
        });
      } else {
        throw new Error();
      }
    } catch (err: any) {
      if (err?.response?.data?.error?.detail) {
        Toaster.toast({
          description: err.response.data.error.detail,
          type: 'error',
        });
        flagsRefetch();
      } else {
        Toaster.toast({
          description: UNEXPECTED_ERROR_MESSAGE,
          type: 'error',
        });
      }
    }
  };

  return (
    <>
      <DropdownMenu.Menu
        label={
          <Popover
            render={() => (
              <div className="default_popover">
                <span>{`Flag count: total flag count & notable flag count in brackets`}</span>
              </div>
            )}
            animation
          >
            <button
              className={cn(styles.flag_button, {
                [styles.flagged]: flagCount > 0,
              })}
            >
              {hasFlagged.length > 0 ? <IcnFlagOn /> : <IcnFlagOff />}
              <span>{`${flagCount.toLocaleString()} (${notableFlagCount.toLocaleString()})`}</span>
            </button>
          </Popover>
        }
        showDimmer
      >
        {[
          FLAG_TYPE.SCAM,
          FLAG_TYPE.DRAIN,
          FLAG_TYPE.COPY,
          FLAG_TYPE.HONEYPOT,
          FLAG_TYPE.OTHER,
        ].map((flagType) => {
          const flagInfo = flags?.find((flag) => flag.label === flagType);
          return (
            <DropdownMenu.MenuItem
              key={flagType}
              label={
                <div
                  className={cn(styles.flag_type_container, {
                    [styles.flagged_type]:
                      hasFlagged.length > 0 && hasFlagged[0].label === flagType,
                  })}
                >
                  <span className={styles.flag_label}>
                    {FLAG_REASON[flagType]}
                  </span>
                  <span className={styles.flag_count}>
                    {(flagInfo?.count ?? 0).toLocaleString()}
                  </span>
                </div>
              }
              onClick={() => {
                if (isAuthenticated) {
                  if (
                    flagType === FLAG_TYPE.OTHER &&
                    (hasFlagged.length === 0 ||
                      hasFlagged[0].label !== FLAG_TYPE.OTHER)
                  ) {
                    setIsOpenFlagReasonModal(true);
                  } else {
                    setContractFlag(flagType);
                  }
                } else {
                  closeFlagReasonModal();
                  signIn();
                }
              }}
            />
          );
        })}
        {flagCount > 0 && <FlagDetailsModal />}
      </DropdownMenu.Menu>
      <FlagReasonInputModal
        isOpen={isOpenFlagReasonModal}
        onClose={() => closeFlagReasonModal()}
        onSubmit={(label: Flag, text: string) => setContractFlag(label, text)}
      />
    </>
  );
}
