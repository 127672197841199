import { useQuery } from '@tanstack/react-query';

import API from '~/api';
import { notableGroupsKeys } from '~/constants/queryKeys';

export default function useNotableGroups() {
  const queryKey = notableGroupsKeys.all;
  const { data, isFetched, isLoading } = useQuery(
    queryKey,
    () => API.getNotableGroups(),
    {
      cacheTime: 1_000 * 60 * 60 * 24,
      staleTime: 1_000 * 60 * 60 * 24,
    }
  );

  const notableGroups = data?.data ?? [];
  const hasNew = notableGroups.some((group) => group.isNew);

  return {
    hasNew,
    isFetched,
    isLoading,
    notableGroups,
  };
}
