import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import API from '~/api';
import { IcnComments, IcnHide, IcnViewer } from '~/assets';
import { CountUp, Popover, Toaster } from '~/components';
import { EVENT_ACTIVE_VIEWERS, UNEXPECTED_ERROR_MESSAGE } from '~/constants';
import { useAuth } from '~/contexts/AuthContext';
import { useSocket } from '~/contexts/SocketContext';
import usePageVisibility from '~/hooks/usePageVisibility';
import {
  checksumContractAddressState,
  hiddenContractsState,
} from '~/store/contract';
import type { Contract } from '~/types';
import scrollIntoViewById from '~/utils/scrollIntoViewById';

import ContractFlagAction from './ContractFlagAction';
import styles from './ContractStatus.module.scss';

interface ContractStatusProps {
  contractInfo: Contract;
}

export default function ContractStatus({ contractInfo }: ContractStatusProps) {
  const { activeViewers, commentCount, hideCount } = contractInfo;
  const isPageVisible = usePageVisibility();
  const { isAuthenticated, signIn } = useAuth();
  const address = useRecoilValue(checksumContractAddressState);
  const [viewerCount, setViewerCount] = useState<number>(activeViewers + 1);
  const setHiddenContracts = useSetRecoilState(hiddenContractsState);
  const socket = useSocket();

  const hideContract = () => {
    if (isAuthenticated) {
      API.hideContract(address)
        .then((res) => {
          if (res.status === 204) {
            Toaster.toast({
              description: 'This contract has been hidden.',
              type: 'success',
            });
            setHiddenContracts((prev) => [...prev, address]);
          }
        })
        .catch((err: any) => {
          if (err?.response?.data?.error?.detail) {
            Toaster.toast({
              description: err.response.data.error.detail,
              type: 'error',
            });
          } else {
            Toaster.toast({
              description: UNEXPECTED_ERROR_MESSAGE,
              type: 'error',
            });
          }
        });
    } else {
      signIn();
    }
  };

  useEffect(() => {
    if (isPageVisible && !!address.startsWith('0x') && address.length === 42) {
      const channel = socket?.subscribe(address);

      channel?.bind(EVENT_ACTIVE_VIEWERS, (viewerCount: number) =>
        setViewerCount(viewerCount)
      );

      return () => {
        channel?.unbind(EVENT_ACTIVE_VIEWERS);
      };
    }
  }, [address, isPageVisible, socket]);

  return (
    <>
      <div className={styles.contract_status_container}>
        <Popover
          render={() => (
            <div className="default_popover">
              <span>{`Active viewers`}</span>
            </div>
          )}
          animation
          placement="top"
        >
          <div className={styles.status}>
            <div className={styles.status_icon_container}>
              <IcnViewer />
            </div>
            <CountUp end={viewerCount} duration={0.5} separator="," start={1} />
          </div>
        </Popover>
        <Popover
          render={() => (
            <div className="default_popover">
              <span>{`Discussions`}</span>
            </div>
          )}
          animation
          placement="top"
        >
          <button
            className={styles.btn_comments}
            onClick={() => {
              scrollIntoViewById('discussions');
            }}
          >
            <div className={styles.status}>
              <div className={styles.status_icon_container}>
                <IcnComments />
              </div>
              <CountUp end={commentCount} duration={0.5} separator="," />
            </div>
          </button>
        </Popover>
        <Popover
          render={() => (
            <div className="default_popover">
              <span>{`Number of people who have hidden this contract`}</span>
            </div>
          )}
          animation
          placement="top"
        >
          <button
            className={styles.btn_hide}
            onClick={(e) => {
              e.stopPropagation();
              hideContract();
            }}
          >
            <div className={styles.status}>
              <div className={styles.status_icon_container}>
                <IcnHide />
              </div>
              <span>{hideCount}</span>
            </div>
          </button>
        </Popover>
        <ContractFlagAction contractInfo={contractInfo} />
      </div>
    </>
  );
}
