import { APP_VERSION } from '~/constants';
import type {
  AppSettings,
  CursorBasedPagination,
  HiddenContract,
  LinkedWallet,
  SocialAccount,
  User,
} from '~/types';

import base from './base';

const Users = {
  changeWallet(address: string) {
    return base.patch<User>(`/users/change_wallet/`, {
      address,
    });
  },
  checkUsername(username: string) {
    const encodedUsername = encodeURI(username);
    return base.get<{ username: string }>(
      `/users/?username=${encodedUsername}`
    );
  },
  disconnectSocialAccount(socialAccountId: number) {
    return base.post(`/socialaccounts/${socialAccountId}/disconnect/`);
  },
  getHiddenContracts(cursor: string | null) {
    const url = `/users/hidden/${cursor ? `?cursor=${cursor}` : ''}`;
    return base.get<CursorBasedPagination<HiddenContract>>(url);
  },
  getContractFilterList() {
    return base.get<string[]>(`/users/hide_filter/`);
  },
  getFollowedNotableGroups() {
    return base.get<number[]>(`/users/me/following/groups/`);
  },
  getLinkedWallets(cursor: string | null) {
    const url = `/users/linked_wallets/${cursor ? `?cursor=${cursor}` : ''}`;
    return base.get<CursorBasedPagination<LinkedWallet>>(url);
  },
  async getMyInfo() {
    const { data: user } = await base.get<User>('/users/me/');

    return user;
  },
  getAppSettings() {
    return base.get<AppSettings>(`/users/setting/`);
  },
  getSocialAccounts() {
    return base.get<CursorBasedPagination<SocialAccount>>(`/socialaccounts/`);
  },
  async getUserInfo(address: string) {
    return base.get<User>(`/users/?address=${address}`);
  },
  linkWallet(address: string, signature: string) {
    return base.patch<User>(`/users/link_wallet/`, {
      address,
      signature,
    });
  },
  setAppSettings(appSettings: Partial<AppSettings>, settingsModified: string) {
    const appVersion = localStorage.getItem(APP_VERSION);
    return base.patch(`/users/setting/`, {
      ...appSettings,
      settingsModified,
      version: appVersion,
    });
  },
  setPushToken(token: string) {
    return base.post(`/users/devices/`, {
      type: 'web',
      registrationId: token,
      active: true,
    });
  },
  setUsername(username: string) {
    return base.patch<{ username: string }>(`/users/me/`, {
      username,
    });
  },
  unlinkWallet(address: string) {
    return base.patch(`/users/unlink_wallet/`, { address });
  },
};

export default Users;
