import { useQuery } from '@tanstack/react-query';

import API from '~/api';
import { MARKETPLACE_NAME } from '~/constants';
import { collectionsKeys } from '~/constants/queryKeys';
import type { Collection } from '~/types';

import useContractFilterList from './useContractFilterList';

export default function useCollection(address: string) {
  const { contractFilterList } = useContractFilterList();
  const isHidden = contractFilterList.some(
    (hiddenContract) => hiddenContract === address
  );
  const result = useQuery(
    collectionsKeys.collection('ethereum', address),
    () => API.getCollections(address),
    {
      cacheTime: 1_000 * 60 * 60,
      enabled: !!address && !isHidden,
    }
  );

  const collections: Collection[] | undefined = result.data?.data
    ? [
        result.data?.data.find(
          (collection) => collection.marketplace === MARKETPLACE_NAME.OPENSEA
        ) ?? {
          fee: 0,
          royalty: 0,
          modified: '',
          url: '',
          marketplace: MARKETPLACE_NAME.OPENSEA,
        },
        {
          fee: 0,
          royalty: 0,
          modified: '',
          url: `https://blur.io/collection/${address}`,
          marketplace: MARKETPLACE_NAME.BLUR,
        },
        result.data?.data.find(
          (collection) => collection.marketplace === MARKETPLACE_NAME.LOOKSRARE
        ) ?? {
          fee: 200,
          royalty: 0,
          modified: '',
          url: `https://looksrare.org/collections/${address}`,
          marketplace: MARKETPLACE_NAME.LOOKSRARE,
        },
        result.data?.data.find(
          (collection) => collection.marketplace === MARKETPLACE_NAME.X2Y2
        ) ?? {
          fee: 0,
          royalty: 0,
          modified: '',
          url: '',
          marketplace: MARKETPLACE_NAME.X2Y2,
        },
      ]
    : undefined;

  let oldestModified = '';

  const modifiedTimes =
    collections
      ?.map((collection) => collection.modified)
      .filter((x): x is string => x !== '') ?? [];

  if (modifiedTimes.length > 0) {
    oldestModified = modifiedTimes[0];

    for (let i = 0; i < modifiedTimes.length; i++) {
      if (oldestModified.localeCompare(modifiedTimes[i]) === 1) {
        oldestModified = modifiedTimes[i];
      }
    }
  }

  return {
    ...result,
    collections,
    oldestModified,
  };
}
