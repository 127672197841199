import { from, of } from 'rxjs';
import { bufferTime, concatMap, delay, filter } from 'rxjs/operators';

import {
  LIVE_MINTS_NEXT_RENDERING_TIME,
  LIVE_MINTS_SHOWING_GAP_TIME,
} from '~/constants';

import ResettableSubject from './ResettableSubject';

export class LiveMintsService<T extends { timestamp: string }> {
  private txSubject = new ResettableSubject<T>();
  private tx = this.txSubject.asObservable();
  loadTxs = this.tx
    .pipe(bufferTime(1_000))
    .pipe(filter((data) => data.length > 0))
    .pipe(
      concatMap((data) =>
        from(data).pipe(
          concatMap((item) => of(item).pipe(delay(this.setDelay(data.length))))
        )
      )
    );

  constructor() {
    this.reset = this.reset.bind(this);
    this.set = this.set.bind(this);
  }

  private setDelay(count: number): number {
    if (count < 5) return LIVE_MINTS_NEXT_RENDERING_TIME;
    if (count < 15) return 340 - 18 * count;
    return 70;
  }

  reset(): void {
    this.txSubject.reset();
  }

  set(data: T): void {
    const timeDiff =
      new Date().getTime() - new Date(data.timestamp).getTime() * 1_000;

    if (timeDiff < LIVE_MINTS_SHOWING_GAP_TIME) {
      this.txSubject.next(data);
    }
  }
}

export default LiveMintsService;
