import cn from 'classnames';
import _isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import { memo, useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';

import API from '~/api';
import { IcnAppDiscord, IcnAppX, IcnBack, IcnClose } from '~/assets';
import { ExternalLink, Modal } from '~/components';
import {
  APP_DISCORD_LINK,
  APP_SETTINGS_MODIFIED,
  APP_X_LINK,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
  SETTINGS_SECTIONS,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import { useAuth } from '~/contexts/AuthContext';
import useAutomations from '~/hooks/useAutomations';
import useDisplay from '~/hooks/useDisplay';
import useSettings from '~/hooks/useSettings';
import { appSettingModalState } from '~/store/app';
import styleVariables from '~/styles/variables.module.scss';
import type {
  AvailableChain,
  ContractQuickLink,
  DetailLayout,
  LayoutSetting,
  MainLayout,
} from '~/types';
import showErrorToast from '~/utils/showErrorToast';
import toChecksumAddress from '~/utils/toChecksumAddress';

import AppearanceSection from './AppearanceSection';
import HiddenContractsSection from './HiddenContractsSection';
import HiddenContractsList from './HiddenContractsSection/HiddenContractsList';
import MintAutomationSection from './MintAutomationSection';
import NotificationsSection from './NotificationsSection';
import ProfileSection from './ProfileSection';
import QuicklinksSection from './QuicklinksSection';
import styles from './SettingsModal.module.scss';
import WalletsSection from './WalletsSection';

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default memo(function SettingsModal({
  isOpen,
  onClose,
}: SettingsModalProps) {
  const analytics = useAnalyticsContext();
  const [automations, setAutomations] = useAutomations();
  const [, setDisplay] = useDisplay();
  const [settings, setSettings] = useSettings();
  const router = useRouter();

  const { isAuthenticated, user } = useAuth();
  const [appSettingModal, setAppSettingModal] =
    useRecoilState(appSettingModalState);
  const [automationPartners, setAutomationPartners] = useState(
    automations.partners
  );
  const [tempQuickLinksLayout, setTempQuickLinksLayout] = useState<
    LayoutSetting<ContractQuickLink>[]
  >(settings.quickLinksLayout);
  const [tempMainLayout, setTempMainLayout] = useState<
    LayoutSetting<MainLayout>[]
  >(settings.mainLayout);
  const [tempDetailLayout, setTempDetailLayout] = useState<
    LayoutSetting<DetailLayout>[]
  >(settings.detailLayout);

  const changeSection = (index: number) => {
    setAppSettingModal({ history: [index], isOpened: true });
  };

  const pushTo = (index: number) => {
    setAppSettingModal((prev) => ({
      history: [...prev.history, index],
      isOpened: true,
    }));
  };

  const handleBack = () => {
    setAppSettingModal((prev) => ({
      history: prev.history.slice(0, -1),
      isOpened: true,
    }));
  };

  const closeModal = async () => {
    if (
      !_isEqual(automations.partners, automationPartners) ||
      !_isEqual(settings.detailLayout, tempDetailLayout) ||
      !_isEqual(settings.mainLayout, tempMainLayout) ||
      !_isEqual(settings.quickLinksLayout, tempQuickLinksLayout)
    ) {
      try {
        const timestamp = new Date().getTime().toString();
        const detail = tempDetailLayout
          .filter(({ visible }) => visible)
          .map(({ name }) => name);
        const main = tempMainLayout
          .filter(({ visible }) => visible)
          .map(({ name }) => name);
        const quickLinks = tempQuickLinksLayout
          .filter(({ visible }) => visible)
          .map(({ name }) => name);

        if (!!user) {
          await API.setAppSettings(
            {
              automations: {
                ...automations,
                partners: automationPartners,
              },
              display: {
                detail,
                main,
                quickLinks,
              },
              settings: {
                detailLayout: tempDetailLayout,
                mainLayout: tempMainLayout,
                quickLinksLayout: tempQuickLinksLayout,
              },
            },
            timestamp
          );
        }
        localStorage.setItem(APP_SETTINGS_MODIFIED, timestamp);

        setAutomations(
          {
            partners: automationPartners,
          },
          false
        );
        setDisplay(
          {
            detail,
            main,
            quickLinks,
          },
          false
        );
        setSettings(
          {
            detailLayout: tempDetailLayout,
            mainLayout: tempMainLayout,
            quickLinksLayout: tempQuickLinksLayout,
          },
          false
        );
      } catch (err) {
        showErrorToast(err);
      }
    }
    onClose();
  };

  const handleClickContract = (
    chain: Lowercase<AvailableChain>,
    address: string
  ) => {
    const checksumAddress = toChecksumAddress(address);
    router.push(
      {
        pathname: `/collection/[chain]/[address]`,
        query: { address: checksumAddress, chain },
      },
      undefined,
      {
        shallow: true,
      }
    );
    setAppSettingModal({
      history: [0],
      isOpened: false,
    });
  };

  const renderSection = useCallback(() => {
    return {
      0: (
        <AppearanceSection
          detailLayout={tempDetailLayout}
          mainLayout={tempMainLayout}
          setDetailLayout={setTempDetailLayout}
          setMainLayout={setTempMainLayout}
        />
      ),
      1: <HiddenContractsSection pushTo={pushTo} />,
      2: (
        <MintAutomationSection
          automationPartners={automationPartners}
          setAutomationPartners={setAutomationPartners}
        />
      ),
      3: (
        <QuicklinksSection
          quickLinksLayout={tempQuickLinksLayout}
          setQuickLinksLayout={setTempQuickLinksLayout}
        />
      ),
      4: <NotificationsSection />,
      5: <ProfileSection />,
      6: <WalletsSection />,
      7: <HiddenContractsList handleClickContract={handleClickContract} />,
    }[appSettingModal.history[appSettingModal.history.length - 1]];
  }, [
    appSettingModal,
    automationPartners,
    tempDetailLayout,
    tempMainLayout,
    tempQuickLinksLayout,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      style={{ backgroundColor: styleVariables.gray50, width: 968 }}
    >
      <div className={styles.settings_modal}>
        <aside className={styles.left_side_container}>
          <div className={styles.modal_title_container}>
            <span className={styles.modal_title}>{'Settings'}</span>
          </div>
          <ul className={styles.section_list}>
            {(isAuthenticated
              ? SETTINGS_SECTIONS
              : SETTINGS_SECTIONS.slice(0, 4)
            ).map((section) => (
              <li
                key={`settings_section_${section.id}`}
                className={cn(styles.section_list_item, {
                  [styles.selected]: section.id === appSettingModal.history[0],
                })}
                onClick={(e) => {
                  changeSection(section.id);
                  e.stopPropagation();
                }}
              >
                <span>{section.name}</span>
              </li>
            ))}
          </ul>
          <div className={styles.app_social_container}>
            <ExternalLink
              allowsUtmSource={false}
              className={cn('btn_icon', styles.btn_app_social)}
              label="app_x_link"
              onClick={() => {
                analytics.track(REDIRECT_CLICKED, {
                  location: REDIRECT_CLICKED_LOCATION.OTHER,
                  type: REDIRECT_CLICKED_TYPE.OTHER,
                  url: APP_X_LINK,
                });
              }}
              role="button"
              url={APP_X_LINK}
            >
              <IcnAppX />
            </ExternalLink>
            <ExternalLink
              allowsUtmSource={false}
              className={cn('btn_icon', styles.btn_app_social)}
              label="app_discord_link"
              onClick={() => {
                analytics.track(REDIRECT_CLICKED, {
                  location: REDIRECT_CLICKED_LOCATION.OTHER,
                  type: REDIRECT_CLICKED_TYPE.OTHER,
                  url: APP_DISCORD_LINK,
                });
              }}
              role="button"
              url={APP_DISCORD_LINK}
            >
              <IcnAppDiscord />
            </ExternalLink>
          </div>
        </aside>
        <div className={styles.right_side_container}>
          <div className={styles.right_side_nav_container}>
            <button
              className={cn('btn_icon', styles.btn_close)}
              onClick={(e) => {
                closeModal();
                e.stopPropagation();
              }}
            >
              <IcnClose />
            </button>
            {appSettingModal.history.length > 1 && (
              <button
                className={styles.btn_prev}
                onClick={(e) => {
                  handleBack();
                  e.stopPropagation();
                }}
              >
                <IcnBack />
                <span>
                  {SETTINGS_SECTIONS.find(
                    (section) => section.id === appSettingModal.history[0]
                  )?.name ?? ''}
                </span>
              </button>
            )}
          </div>
          {renderSection()}
        </div>
      </div>
    </Modal>
  );
});
