import { useQuery, useQueryClient } from '@tanstack/react-query';

import API from '~/api';
import { contractsKeys } from '~/constants/queryKeys';
import type { AvailableChain, Contract } from '~/types';

export default function useContractInfo(
  chain: Lowercase<AvailableChain>,
  address: string
) {
  const queryClient = useQueryClient();
  const queryKey = contractsKeys.information(chain, address);
  const queryFn = async () => {
    try {
      const { data } = await API.getContractDetail(address);
      return data;
    } catch (e) {
      console.error(e);
      return;
    }
  };
  const result = useQuery(queryKey, queryFn, {
    enabled: !!address,
  });
  const refresh = () => {
    queryClient.invalidateQueries({ queryKey });
  };
  const update = (modifiedData: Partial<Contract>) => {
    queryClient.setQueryData<Contract | undefined>(queryKey, (prev) => {
      if (prev) {
        prev = {
          ...prev,
          ...modifiedData,
        };
      }
      return prev;
    });
  };

  return {
    ...result,
    contractInfo: {
      ...result.data,
      maxSupply: result.data?.maxSupply ?? [],
    } as Contract,
    refresh,
    update,
  };
}
