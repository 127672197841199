import styleVariables from '~/styles/variables.module.scss';

import styles from './Loading.module.scss';

interface LoadingProps {
  color?: string;
  size?: number | string;
  strokeWidth?: number | string;
}

export default function Loading({
  color = styleVariables.brand500,
  size = '1em',
  strokeWidth = '6',
}: LoadingProps) {
  return (
    <div className={styles.loading}>
      <svg viewBox="25 25 50 50" style={{ width: size }}>
        <circle
          cx="50"
          cy="50"
          r="20"
          stroke={color}
          strokeWidth={strokeWidth}
        ></circle>
      </svg>
    </div>
  );
}
