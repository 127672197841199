import cn from 'classnames';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';

import { Popover } from '~/components';

import styles from './SelectButton.module.scss';
import SelectButtonGroup from './SelectButtonGroup';

interface SelectButtonProps {
  children: ReactNode;
  onClick: (selected: boolean) => void;
  active?: boolean;
  popover?: string;
}

function SelectButton({
  children,
  onClick,
  active = false,
  popover,
}: SelectButtonProps) {
  const [isActive, setIsActive] = useState(active);

  const handleClick = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    onClick(!isActive);
  };

  const renderButton = () => {
    return (
      <li
        className={cn(styles.select_button, {
          [styles.active]: isActive,
        })}
        onClick={handleClick}
      >
        {children}
      </li>
    );
  };

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  if (popover) {
    return (
      <Popover
        render={() => (
          <div className="default_popover">
            <span>{popover}</span>
          </div>
        )}
        animation
        placement="top"
      >
        {renderButton()}
      </Popover>
    );
  }

  return renderButton();
}

SelectButton.Group = SelectButtonGroup;

export default SelectButton;
