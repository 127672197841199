import cn from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTimeout } from 'usehooks-ts';

import {
  IcnAirdrop,
  IcnConfirmed,
  IcnEtherscan,
  IcnGasPrice,
  IcnSmallChainEthereum,
  IcnVerified,
} from '~/assets';
import { ExternalLink, Loading, Thumbnail } from '~/components';
import {
  AVAILABLE_CHAIN,
  // COLLECTION_CLICKED_LOCATION,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
  RENDERING_BOUND_TIME,
  TRANSITION_TIME,
  UNIDENTIFIED_CONTRACT,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import styleVariables from '~/styles/variables.module.scss';
import type { MintData } from '~/types';
import formatEthPrice from '~/utils/formatEthPrice';

import styles from './LiveMintsItem.module.scss';

interface LiveMintsItemProps {
  data: MintData;
  enableBound: boolean;
  index: number;
  onClickItem: () => void;
  blur?: boolean;
}

export default function LiveMintsItem({
  data,
  enableBound,
  index,
  onClickItem,
  blur = false,
  // For using react-flip-toolkit
  ...rest
}: LiveMintsItemProps) {
  const analytics = useAnalyticsContext();
  const {
    address,
    confirmations,
    explorerUrl = '',
    functionName,
    gasPrice,
    imageUrl,
    isAirdrop,
    mintQuantity,
    name = UNIDENTIFIED_CONTRACT,
    timestamp,
    value,
  } = { ...data };

  const [diff, setDiff] = useState<number>(
    new Date().getTime() - new Date(timestamp).getTime() * 1_000
  );
  const [isBound, setIsBound] = useState(enableBound);
  const [noTransition, setNoTransition] = useState(false);

  // const onClick = () => {
  //   if (enableBound) {
  //     const params: { [key: string]: any } = {
  //       chain,
  //       contract_address: address,
  //       location: COLLECTION_CLICKED_LOCATION.LIVE_MINTS,
  //       position: index,
  //     };
  //     if (!!name) {
  //       params['collection_name'] = name;
  //     }
  //     analytics.track(COLLECTION_CLICKED, params);
  //     onClickItem();
  //   }
  // };

  const handleLinkClicked = (url: string) => {
    if (url.length !== 0) {
      analytics.track(REDIRECT_CLICKED, {
        chain: AVAILABLE_CHAIN.ETHEREUM,
        location: REDIRECT_CLICKED_LOCATION.LIVE_MINTS,
        position: index,
        type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
        url,
      });
    }
  };

  const format = useCallback((diff: number) => {
    if (typeof diff !== 'number') return [0, ''];
    const seconds = Math.floor(diff * 0.001);
    let sentence = '< ';
    if (seconds < 10) sentence += '10s';
    else if (seconds < 20) sentence += '20s';
    else if (seconds < 30) sentence += '30s';
    else if (seconds < 40) sentence += '40s';
    else if (seconds < 50) sentence += '50s';
    else if (seconds < 60) sentence += '1m';
    else sentence += `${Math.floor(seconds / 60)}m`;
    return [seconds, sentence];
  }, []);

  useTimeout(() => {
    setIsBound(false);
  }, RENDERING_BOUND_TIME);

  useTimeout(() => {
    setNoTransition(true);
  }, TRANSITION_TIME);

  useEffect(() => {
    const secondTimer = setInterval(() => {
      setDiff(new Date().getTime() - new Date(timestamp).getTime() * 1_000);
    }, 1_000);

    return () => clearInterval(secondTimer);
  }, [timestamp]);

  const [seconds, sentence] = format(diff);

  return (
    <li
      {...rest}
      className={cn(styles.container, {
        [styles.bound]: !blur && isBound,
        [styles.data_received]: enableBound,
        [styles.no_transition]: noTransition,
      })}
      onClick={onClickItem}
    >
      <div className={styles.thumbnail_container}>
        <Thumbnail
          isBlurred={blur}
          isLoading={!enableBound}
          name={name}
          url={imageUrl}
        />
      </div>
      <div
        className={cn(styles.contract_info_container, {
          [styles.blur]: blur,
        })}
      >
        <div className={styles.contract_name_container}>
          {address ? (
            <span className={styles.contract_name}>
              {name.trim().length > 0 ? name : UNIDENTIFIED_CONTRACT}
            </span>
          ) : (
            <Skeleton width={120} />
          )}
          {(mintQuantity?.length > 19 ? BigInt(mintQuantity) : +mintQuantity) >
            1 && (
            <div className={styles.quantity_container}>
              <span className={styles.quantity}>{`x${mintQuantity}`}</span>
            </div>
          )}
        </div>
        <div className={styles.contract_extra_container}>
          {typeof value === 'undefined' ? (
            <Skeleton width={20} style={{ marginRight: 8 }} />
          ) : (
            <div className={styles.price_container}>
              {value !== '0' && (
                <div className={styles.currency}>
                  <IcnSmallChainEthereum />
                </div>
              )}
              <span>
                {value === '0' ? 'Free' : formatEthPrice(+(value ?? '0'))}
              </span>
            </div>
          )}
          {address ? (
            gasPrice && (
              <>
                <div className={styles.extra_divider} />
                <div className={styles.gas_price_container}>
                  <IcnGasPrice />
                  <span>{`${Math.ceil(+gasPrice)} Gwei`}</span>
                </div>
              </>
            )
          ) : (
            <Skeleton width={60} />
          )}
          {isAirdrop && value === '0' && (
            <>
              <div className={styles.extra_divider} />
              <div className={styles.airdrop_container}>
                <IcnAirdrop />
              </div>
            </>
          )}
          {!!functionName && (
            <>
              <div className={styles.extra_divider} />
              <div className={styles.verified_container}>
                <IcnVerified />
              </div>
              <span className={styles.function_name}>{functionName}</span>
            </>
          )}
        </div>
      </div>
      {!blur && !_isEmpty(data) && (
        <div className={styles.end_container}>
          <div className={styles.row}>
            <div className={styles.loading_container}>
              {confirmations === 0 ? (
                <Loading color={styleVariables.orange500} size={12} />
              ) : (
                <div className={styles.icn_copied}>
                  <IcnConfirmed />
                </div>
              )}
            </div>
            <div className={styles.button_container}>
              <ExternalLink
                className={cn(styles.button, {
                  button_disabled: explorerUrl.length === 0,
                })}
                label="live_mints_tx_hash_link"
                nofollow
                onClick={() => handleLinkClicked(explorerUrl)}
                role="link"
                url={explorerUrl}
              >
                <IcnEtherscan />
              </ExternalLink>
            </div>
          </div>
          <div className={styles.row}>
            {diff && (
              <span
                className={cn(styles.tx_timestamp, {
                  [styles.urgent]: +seconds < 10,
                  [styles.normal]: +seconds < 30 && +seconds >= 10,
                })}
              >
                {sentence}
              </span>
            )}
          </div>
        </div>
      )}
    </li>
  );
}
