import { useQuery } from '@tanstack/react-query';

import API from '~/api';
import { contractsKeys } from '~/constants/queryKeys';

import useContractFilterList from './useContractFilterList';

export default function useContractNotableFlags(address: string) {
  const queryKey = contractsKeys.notableFlags('ethereum', address);
  const { contractFilterList } = useContractFilterList();
  const isHidden = contractFilterList.some(
    (hiddenContract) => hiddenContract === address
  );
  const result = useQuery(
    queryKey,
    () => API.getContractNotableFlags(address),
    {
      enabled: !!address && !isHidden,
    }
  );

  return {
    ...result,
    notableFlags: result.data?.data ?? [],
  };
}
