import cn from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { RENDERING_BOUND_TIME } from '~/constants';
import { usePrevious } from '~/hooks/usePrevious';

import styles from './SectionLayout.module.scss';

interface SectionLayoutProps {
  boundTrigger: string;
  children: ReactNode;
  enableBound: boolean;
  id?: string;
}

export default function SectionLayout({
  boundTrigger,
  children,
  enableBound,
  id,
}: SectionLayoutProps) {
  const prevBoundTrigger = usePrevious(boundTrigger) ?? '';
  const timeoutRef = useRef<undefined | number>(undefined);
  const [isBound, setIsBound] = useState(enableBound);

  useEffect(() => {
    if (enableBound && prevBoundTrigger.localeCompare(boundTrigger) !== 0) {
      setIsBound(true);
    }
  }, [boundTrigger, enableBound, prevBoundTrigger]);

  useEffect(() => {
    if (isBound) {
      timeoutRef.current = window.setTimeout(
        () => setIsBound(false),
        RENDERING_BOUND_TIME
      );
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [isBound]);

  useEffect(() => {
    if (!enableBound) {
      setIsBound(false);
      window.clearTimeout(timeoutRef.current);
    }
  }, [enableBound]);

  return (
    <section
      id={id}
      className={cn(styles.container, {
        [`${styles.bound}`]: isBound,
      })}
    >
      {children}
    </section>
  );
}
