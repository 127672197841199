import cn from 'classnames';
import type { ReactNode } from 'react';
import toast, { DefaultToastOptions, ToastType } from 'react-hot-toast';

import { IcnClose, IcnToastError, IcnToastSuccess } from '~/assets';
import styleVariables from '~/styles/variables.module.scss';

import styles from './Toast.module.scss';
import ToastProgress from './ToastProgress';

interface ToastProps {
  description?: ReactNode;
  icon?: string;
  title?: string;
  type?: ToastType;
}

export default function Toast(
  { description, icon, title, type = 'blank' }: ToastProps,
  options?: DefaultToastOptions
) {
  const renderIcon =
    icon ?? type === 'error' ? <IcnToastError /> : <IcnToastSuccess />;

  toast(
    (t) => (
      <>
        <div className={styles.toast_container}>
          <div className={cn(styles.icon, styles[type])}>{renderIcon}</div>
          <div className={styles.contents}>
            {title && <span className={styles.title}>{title}</span>}
            {description &&
            (typeof description === 'string' ||
              typeof description === 'number') ? (
              <p className={styles.description}>{description}</p>
            ) : (
              <>{description}</>
            )}
          </div>
          <button
            className={styles.btn_close}
            onClick={() => toast.dismiss(t.id)}
          >
            <IcnClose />
          </button>
        </div>
        {t.type !== 'loading' && type !== 'loading' && (
          <ToastProgress
            duration={t.duration}
            progressBarColor={
              type === 'error' ? styleVariables.red50 : styleVariables.green50
            }
            progressColor={
              type === 'error' ? styleVariables.red500 : styleVariables.green500
            }
          />
        )}
      </>
    ),
    { ...options }
  );
}
