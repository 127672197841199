import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext } from 'react';

export type AnalyticsContextProps = AnalyticsBrowser | null;

const defaultAnalyticsContext = null;

export const AnalyticsContext = createContext<AnalyticsContextProps>(
  defaultAnalyticsContext
);
