import _isEqual from 'lodash/isEqual';
import type { Dispatch, SetStateAction } from 'react';

import { DEFAULT_DETAIL_LAYOUT, DEFAULT_MAIN_LAYOUT } from '~/constants';
import type { DetailLayout, LayoutSetting, MainLayout } from '~/types';

import styles from './AppearanceSection.module.scss';
import LayoutOrder from './LayoutOrder';
import ThemeSwitch from './ThemeSwitch';
import commonStyles from '../SettingsModal.module.scss';

interface AppearanceSectionProps {
  detailLayout: LayoutSetting<DetailLayout>[];
  mainLayout: LayoutSetting<MainLayout>[];
  setDetailLayout: Dispatch<SetStateAction<LayoutSetting<DetailLayout>[]>>;
  setMainLayout: Dispatch<SetStateAction<LayoutSetting<MainLayout>[]>>;
}

export default function AppearanceSection(props: AppearanceSectionProps) {
  const resetToDefault = () => {
    props.setDetailLayout(DEFAULT_DETAIL_LAYOUT);
    props.setMainLayout(DEFAULT_MAIN_LAYOUT);
  };

  return (
    <section className={commonStyles.section}>
      <div className={commonStyles.section_container}>
        <div className={commonStyles.section_title_container}>
          <span className={commonStyles.section_title}>{'Theme'}</span>
        </div>
        <div className={styles.theme_container}>
          <span className={styles.theme_title}>{'Light / Dark'}</span>
          <ThemeSwitch />
        </div>
      </div>
      <div className={commonStyles.section_container}>
        <div className={commonStyles.section_header}>
          <div className={commonStyles.section_title_container}>
            <div className={commonStyles.section_title}>
              <span>{'Change layout'}</span>
              <button
                className={commonStyles.btn_reset}
                onClick={resetToDefault}
                disabled={
                  _isEqual(props.mainLayout, DEFAULT_MAIN_LAYOUT) &&
                  _isEqual(props.detailLayout, DEFAULT_DETAIL_LAYOUT)
                }
              >
                {'Reset to Default'}
              </button>
            </div>
            <p className={commonStyles.section_description}>
              {
                'Re-order the components in mint detail view. Drag your favorite sections to the top and move others to the bottom. Or toggle the eye icon to show/hide them.'
              }
            </p>
          </div>
        </div>
        <div className={styles.layout_order_container}>
          <LayoutOrder {...props} />
        </div>
      </div>
    </section>
  );
}
