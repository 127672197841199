import cn from 'classnames';
import type { MouseEvent } from 'react';

import { IcnRemove } from '~/assets';
import { Popover, Thumbnail } from '~/components';
import { IMAGE_SIZE } from '~/constants';
import type { SocialAccount } from '~/types';

import styles from './SocialProfile.module.scss';

interface SocialProfileProps extends Omit<SocialAccount, 'id' | 'uid'> {
  onDisconnect?: () => void;
}

export default function SocialProfile({
  avatar,
  name,
  provider,
  onDisconnect,
}: SocialProfileProps) {
  const handleDisconnect = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDisconnect?.();
  };

  return (
    <div className={styles.container}>
      {avatar && (
        <Thumbnail
          size={IMAGE_SIZE.SMALL}
          url={avatar.replace(/^http:\/\//i, 'https://')}
          name={`${provider === 'twitter' ? 'x' : provider} name ${name}`}
        />
      )}
      <span className={styles.name}>
        {`${provider === 'twitter' ? '@' : ''}${name}`}
      </span>
      {onDisconnect && (
        <Popover
          render={() => (
            <div className="default_popover">
              <span>{'Disconnect'}</span>
            </div>
          )}
          animation
          placement="top"
        >
          <button
            className={cn('btn_icon', styles.btn_disconnect)}
            onClick={handleDisconnect}
          >
            <IcnRemove />
          </button>
        </Popover>
      )}
    </div>
  );
}
