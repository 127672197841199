import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import API from '~/api';
import {
  CHANNEL_LIVE_MINTS,
  EVENT_DATA,
  LIVE_MINTS_SHOWING_GAP_TIME,
  LIVE_MINTS_VISIBLE_ITEM_NUMBER,
  PLAY_STATUS,
  RENDERING_BOUND_TIME,
} from '~/constants';
import { timeseriesKeys } from '~/constants/queryKeys';
import { useSocket } from '~/contexts/SocketContext';
import useLiveMintsFilter from '~/hooks/useLiveMintsFilter';
import usePageVisibility from '~/hooks/usePageVisibility';
import { LiveMintsService } from '~/services';
import type { MintData, PlayStatus } from '~/types';

interface UseLiveMintsProps {
  collectionAddress: string;
  collectionFilterList: string[];
}

const liveMintsService = new LiveMintsService<MintData>();

export default function useLiveMints({
  collectionAddress,
  collectionFilterList,
}: UseLiveMintsProps) {
  const queryKey = timeseriesKeys.liveMints();
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [playStatus, setPlayStatus] = useState<PlayStatus>(PLAY_STATUS.STOPPED);
  const [newTransaction, setNewTransaction] = useState<MintData | null>(null);
  const [transactionList, setTransactionList] = useState<MintData[]>(
    Array(LIVE_MINTS_VISIBLE_ITEM_NUMBER).fill({})
  );
  const [filter, setFilter] = useLiveMintsFilter();
  const isPageVisible = usePageVisibility();
  const socket = useSocket();
  const hiddenVisibility = filter.hiddenVisibility ?? 'remove';

  const onPause = () => {
    if (newTransaction) {
      setPlayStatus(PLAY_STATUS.PAUSED);
    }
  };

  const onPlay = useCallback(() => {
    setPlayStatus(PLAY_STATUS.PLAYED);
  }, []);

  const switchAddressPinned = useCallback(() => {
    setFilter({
      addressPinned: !filter.addressPinned,
    });
  }, [filter]);

  /*
   * Live Mints filter rules
   * - if addressPinned is selected, onlyVerified is ignored.
   * - if onlyFree is selected, priceRange is ignored.
   */
  const filterCondition: (tx: MintData) => boolean[] = useCallback(
    (tx) => {
      const addressPinnedNotSelected = !filter.addressPinned;
      const addressPinnedSelected =
        filter.addressPinned && tx.address === collectionAddress;

      const excludeAirdropNotSelected = !filter.excludeAirdrop;
      const excludeAirdropSelected = filter.excludeAirdrop && !tx.isAirdrop;

      const onlyFreeNotSelected =
        !filter.onlyFree &&
        +tx.value >= filter.priceRangeMin &&
        +tx.value <= filter.priceRangeMax;
      const onlyFreeSelected = filter.onlyFree && tx.value === '0';

      const onlyVerifiedNotSelected = !filter.onlyVerified;
      const onlyVerifiedSelected = filter.onlyVerified && !!tx.functionName;

      return [
        addressPinnedSelected &&
          (excludeAirdropNotSelected || excludeAirdropSelected) &&
          (onlyFreeSelected || onlyFreeNotSelected),
        addressPinnedNotSelected &&
          (excludeAirdropSelected || excludeAirdropNotSelected) &&
          (onlyFreeSelected || onlyFreeNotSelected) &&
          (onlyVerifiedSelected || onlyVerifiedNotSelected),
      ];
    },
    [collectionAddress, filter]
  );

  useQuery(queryKey, () => API.getLiveMints(), {
    onSuccess: ({ data }) => {
      const filtered = data
        .filter(
          ({ transactionHash }, index, self) =>
            index ===
            self.findIndex((tx) => tx.transactionHash === transactionHash)
        )
        .reverse();
      setTransactionList(filtered);
      setTimeout(() => setInitialDataFetched(true), RENDERING_BOUND_TIME);
    },
  });

  useEffect(() => {
    liveMintsService.loadTxs.subscribe((transaction: MintData) => {
      const delayed =
        new Date().getTime() -
        new Date(transaction.timestamp).getTime() * 1_000;
      if (delayed < LIVE_MINTS_SHOWING_GAP_TIME) {
        setNewTransaction(transaction);
      }
    });

    return () => {
      liveMintsService.reset();
    };
  }, []);

  useEffect(() => {
    if (isPageVisible) {
      socket
        ?.subscribe(CHANNEL_LIVE_MINTS)
        ?.bind(EVENT_DATA, (data: MintData) => liveMintsService.set(data));
      setPlayStatus(PLAY_STATUS.LOADING);

      return () => {
        socket?.unsubscribe(CHANNEL_LIVE_MINTS);
        setPlayStatus(PLAY_STATUS.STOPPED);
      };
    }
  }, [isPageVisible, socket]);

  useEffect(() => {
    if (newTransaction && playStatus === PLAY_STATUS.LOADING) {
      setPlayStatus(PLAY_STATUS.PLAYED);
    }
  }, [newTransaction, playStatus]);

  useEffect(() => {
    if (
      playStatus === PLAY_STATUS.PLAYED &&
      newTransaction &&
      !(
        hiddenVisibility === 'remove' &&
        collectionFilterList.some(
          (hiddenContract) => hiddenContract === newTransaction.address
        )
      ) &&
      filterCondition(newTransaction).includes(true)
    ) {
      setTransactionList((prev) => {
        // change confirmations if transaction is already in the list
        const existingTx = prev.find(
          (tx) => tx.transactionHash === newTransaction.transactionHash
        );
        if (!!existingTx) {
          if (existingTx.confirmations !== newTransaction.confirmations) {
            return prev.map((txData) =>
              txData.transactionHash === newTransaction.transactionHash
                ? newTransaction
                : txData
            );
          }
          return prev;
        } else {
          return [...prev, newTransaction].splice(-30);
        }
      });
    }
  }, [
    collectionFilterList,
    filterCondition,
    hiddenVisibility,
    newTransaction,
    playStatus,
  ]);

  return {
    filter: {
      ...filter,
      hiddenVisibility,
    },
    initialDataFetched,
    onPause,
    onPlay,
    playStatus,
    switchAddressPinned,
    transactionList,
  };
}
