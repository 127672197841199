import type {
  AvailableChain,
  GlobalCommentsFilter,
  GlobalCommentsFilterVoteOption,
  MintsOverviewFilterTimeOption,
  NotableGroupActivityArgs,
} from '~/types';

const clusteredKeys = {
  all: ['clustered'],
  wallets: (chain: Lowercase<AvailableChain>, addresses: string[]) => [
    ...clusteredKeys.all,
    chain,
    addresses.toString(),
  ],
} as const;

const collectionsKeys = {
  all: ['collections'],
  collection: (chain: Lowercase<AvailableChain>, address: string) => [
    ...collectionsKeys.all,
    chain,
    address,
  ],
} as const;

const commentsKeys = {
  all: ['comments'],
  duration: (duration: GlobalCommentsFilterVoteOption) => [
    ...commentsKeys.all,
    'vote',
    duration,
  ],
  sort: (sort: GlobalCommentsFilter) => [...commentsKeys.all, sort],
} as const;

const contractsKeys = {
  all: ['contracts'],
  announcements: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'announcements',
  ],
  art: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'art',
  ],
  collection: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.all,
    chain,
    address,
  ],
  comments: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'comments',
  ],
  entry: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'entry',
  ],
  failedTxs: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'failed_txs',
  ],
  flags: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'flags',
  ],
  holders: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'holders',
  ],
  information: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'information',
  ],
  linkedHolders: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'linked_holders',
  ],
  linkedXContracts: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'x_accounts',
  ],
  mints: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'mints',
  ],
  myContracts: () => [...contractsKeys.all, 'my'],
  notableFlags: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'notable_flags',
  ],
  notableGroups: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'notable_groups',
  ],
  notableGroupsMintingAggregate: (
    chain: Lowercase<AvailableChain>,
    address: string
  ) => [
    ...contractsKeys.collection(chain, address),
    'notable_groups_aggregate',
  ],
  notableGroupsMintingAggregates: (
    chain: Lowercase<AvailableChain>,
    address: string,
    ids: number[]
  ) => [
    ...contractsKeys.notableGroupsMintingAggregate(chain, address),
    ids.toString(),
  ],
  notableGroupsFollowing: (
    chain: Lowercase<AvailableChain>,
    address: string,
    followingIds: number[]
  ) => [
    ...contractsKeys.notableGroups(chain, address),
    followingIds.toString(),
  ],
  similar: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'similar',
  ],
  successfulTxs: (chain: Lowercase<AvailableChain>, address: string) => [
    ...contractsKeys.collection(chain, address),
    'successful_txs',
  ],
} as const;

const gasPriceKeys = {
  all: ['gas_price'],
} as const;

const newFeaturesKeys = {
  all: ['new_features'],
} as const;

const notableGroupsKeys = {
  all: ['notable_groups'],
} as const;

const notificationsKeys = {
  all: ['notifications'],
  social: () => [...notificationsKeys.all, 'social'],
  trigger: () => [...notificationsKeys.social(), 'trigger'],
} as const;

const timeseriesKeys = {
  all: ['timeseries', 'mints'],
  liveMints: () => [...timeseriesKeys.all, 'live'],
  mintsOverview: (timeframe: MintsOverviewFilterTimeOption) => [
    ...timeseriesKeys.all,
    'overview',
    timeframe,
  ],
  notableGroups: () => [...timeseriesKeys.all, 'notable'],
  notableGroupsMints: ({
    excludeAirdrop,
    ids,
    minValue,
    maxValue,
    onlyMintable,
    onlyVerified,
  }: NotableGroupActivityArgs) => [
    ...timeseriesKeys.notableGroups(),
    ids.toString(),
    excludeAirdrop.toString(),
    minValue.toString(),
    maxValue.toString(),
    onlyMintable,
    onlyVerified,
  ],
  notableGroupsMintsVolume: () => [...timeseriesKeys.notableGroups(), 'volume'],
} as const;

const usersKeys = {
  all: ['users'],
  hideFilter: () => [...usersKeys.all, 'hide_filter'],
  me: () => [...usersKeys.all, 'me'],
  setting: () => [...usersKeys.all, 'setting'],
  followingGroups: () => [...usersKeys.me(), 'following_groups'],
  hiddenContracts: () => [...usersKeys.me(), 'hidden_contracts'],
  linkedWallets: () => [...usersKeys.me(), 'linked_Wallets'],
  socialAccounts: () => [...usersKeys.me(), 'social_accounts'],
} as const;

export {
  clusteredKeys,
  collectionsKeys,
  commentsKeys,
  contractsKeys,
  gasPriceKeys,
  newFeaturesKeys,
  notableGroupsKeys,
  notificationsKeys,
  timeseriesKeys,
  usersKeys,
};
