import _debounce from 'lodash/debounce';
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { API_DEBOUNCE_DELAY } from '~/constants';

interface UseSearchProps {
  callback?: (keyword: string) => void;
  defaultValue?: string;
  delay?: number;
}

export default function useSearch({
  callback,
  defaultValue = '',
  delay = API_DEBOUNCE_DELAY,
}: UseSearchProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focused, setFocused] = useState(false);
  const [keyword, setKeyword] = useState(defaultValue);

  const onBlur = () => {
    setFocused(false);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onSearch = (keyword: string) => {
    setKeyword(keyword);
    onBlur();
  };

  const debouncedChange = useMemo(() => {
    return _debounce(onChange, delay);
  }, [delay]);

  useEffect(() => {
    return () => {
      debouncedChange.cancel();
    };
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue;
    }
  }, []);

  useEffect(() => {
    callback?.(keyword);
  }, [callback, keyword]);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        break;
      case 'ArrowDown':
        e.preventDefault();
        break;
      case 'Enter':
        onSearch(keyword);
        e.currentTarget.blur();
        e.preventDefault();
        break;
      case 'Esc':
      case 'Escape':
        onBlur();
        e.currentTarget.blur();
        e.preventDefault();
        break;
    }
  };

  return {
    focused,
    keyword,
    ref: inputRef,
    onBlur,
    onChange: debouncedChange,
    onFocus,
    onKeyDown,
  };
}
