import { VirtualItem } from '@tanstack/react-virtual';
import { RefObject } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';

import { Loading } from '~/components';
// import useNotableGroupActivityList from '~/data/useNotableGroupActivityList';
import useNotableGroups from '~/data/useNotableGroups';
import type { NotableGroupActivity } from '~/types';

import NotableGroupActivityItem from './NotableGroupActivityItem';
import styles from './NotableGroupActivityList.module.scss';

interface NotableGroupActivityListProps {
  clickedGroupActivity: NotableGroupActivity | null;
  containerRef: RefObject<HTMLDivElement>;
  flipKey: string;
  groupActivities: NotableGroupActivity[];
  isLoading: boolean;
  lastSeenGroupActivityTime: Date | null;
  onClickGroupActivity: (groupActivity: NotableGroupActivity) => void;
  onPause: () => void;
  onPlay: () => void;
  totalSize: number;
  virtualItems: VirtualItem[];
}

export default function NotableGroupActivityList({
  clickedGroupActivity,
  containerRef,
  flipKey,
  groupActivities,
  isLoading,
  lastSeenGroupActivityTime,
  onClickGroupActivity,
  onPause,
  onPlay,
  totalSize,
  virtualItems,
}: NotableGroupActivityListProps) {
  const { notableGroups } = useNotableGroups();

  if (isLoading || notableGroups.length === 0) {
    return (
      <div className={styles.empty_container}>
        <Loading size={32} />
      </div>
    );
  }

  if (virtualItems.length === 0) {
    return <div className={styles.empty_container}>{'No data found'}</div>;
  }

  return (
    <Flipper
      className={styles.container}
      flipKey={flipKey}
      spring="noWobble"
      staggerConfig={{
        default: {
          speed: 15,
        },
      }}
    >
      <div
        className={styles.list_container}
        onMouseEnter={onPause}
        onMouseLeave={onPlay}
        ref={containerRef}
      >
        <ul
          style={{
            position: 'relative',
            width: '100%',
            height: `${totalSize}px`,
          }}
        >
          {virtualItems.map(({ key, index, size, start }) => {
            const isLoader = index > groupActivities.length - 1;
            if (isLoader) {
              return (
                <div
                  key={key}
                  data-index={index}
                  style={{
                    position: 'absolute',
                    top: `${start}px`,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                  }}
                >
                  <Loading size={32} />
                </div>
              );
            }

            const groupActivity = groupActivities[index];
            const groupsInfo = !!groupActivity
              ? notableGroups.filter(({ id }) =>
                  groupActivity.notableGroupIds.includes(id)
                )
              : [];
            const flipId =
              groupActivity.transactionHash +
              groupActivity.timestamp +
              groupActivity.confirmations;

            return (
              <Flipped key={flipId} flipId={flipId} translate>
                <li
                  key={key}
                  style={{
                    position: 'absolute',
                    top: `${start}px`,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                  }}
                >
                  <NotableGroupActivityItem
                    {...groupActivity}
                    clicked={
                      !!clickedGroupActivity &&
                      clickedGroupActivity.transactionHash ===
                        groupActivity.transactionHash
                    }
                    enableBound={
                      !!lastSeenGroupActivityTime &&
                      lastSeenGroupActivityTime <
                        new Date(groupActivity.timestamp)
                    }
                    groupsInfo={groupsInfo}
                    index={index}
                    onClick={() => onClickGroupActivity(groupActivity)}
                  />
                </li>
              </Flipped>
            );
          })}
        </ul>
      </div>
    </Flipper>
  );
}
