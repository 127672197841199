import { useRef, useState } from 'react';
import { useToasterStore } from 'react-hot-toast';
import { useInterval } from 'usehooks-ts';

import ToastProgressBar from './ToastProgressBar';

interface ToastProgressProps {
  progressBarColor: string;
  progressColor: string;
  duration?: number;
}

const delay = 50;

export default function ToastProgress({
  progressBarColor,
  progressColor,
  duration = 5_000,
}: ToastProgressProps) {
  const timer = useRef(0);
  const [progression, setProgression] = useState(0);

  const { pausedAt } = useToasterStore();

  useInterval(
    () => {
      timer.current += delay;
      setProgression((timer.current * 100) / duration);
    },
    !!!pausedAt && progression < 100 ? delay : null
  );

  return (
    <ToastProgressBar
      progressBarColor={progressBarColor}
      progressColor={progressColor}
      value={progression}
    />
  );
}
