import cn from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { IcnClose } from '~/assets';
import { Modal } from '~/components';
import { appSettingModalState } from '~/store/app';
import { usernameRequiredModalState } from '~/store/user';

import styles from './UsernameRequiredModal.module.scss';

interface UsernameRequiredModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function UsernameRequiredModal({
  isOpen,
  onClose,
}: UsernameRequiredModalProps) {
  const { reason } = useRecoilValue(usernameRequiredModalState);
  const setAppSettingsModal = useSetRecoilState(appSettingModalState);
  const handleGoToProfile = () => {
    onClose();
    setAppSettingsModal({ isOpened: true, history: [5] });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <h2 className={styles.title}>{'Username Required'}</h2>
          <button
            className={cn('btn_icon', styles.btn_close)}
            onClick={(e) => {
              onClose();
              e.stopPropagation();
            }}
          >
            <IcnClose />
          </button>
        </div>
        <p className={styles.description}>
          {`${reason}, you must set a `}
          <span>{'username'}</span>
          {`.`}
        </p>
        <div className={styles.buttons_container}>
          <button
            className={cn(styles.button, styles.btn_link)}
            onClick={handleGoToProfile}
          >
            {`Set a username`}
          </button>
          <button
            className={cn(styles.button, styles.btn_sign_out)}
            onClick={onClose}
          >
            {`No, thanks`}
          </button>
        </div>
      </div>
    </Modal>
  );
}
