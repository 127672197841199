import { Reorder } from 'framer-motion';
import { Dispatch, SetStateAction, useCallback } from 'react';

import { MAIN_LAYOUT } from '~/constants';
import type { DetailLayout, LayoutSetting, MainLayout } from '~/types';

import ContractDetailComponent from './ContractDetailComponent';
import LayoutComponent from './LayoutComponent';
import styles from './LayoutOrder.module.scss';

interface LayoutOrderProps {
  detailLayout: LayoutSetting<DetailLayout>[];
  mainLayout: LayoutSetting<MainLayout>[];
  setDetailLayout: Dispatch<SetStateAction<LayoutSetting<DetailLayout>[]>>;
  setMainLayout: Dispatch<SetStateAction<LayoutSetting<MainLayout>[]>>;
}

export default function LayoutOrder({
  detailLayout,
  mainLayout,
  setDetailLayout,
  setMainLayout,
}: LayoutOrderProps) {
  const toggleContractDetailComponentVisible = useCallback(
    (index: number) => {
      setDetailLayout((prev) => {
        const updated = [...prev];
        updated[index] = {
          ...updated[index],
          visible: !updated[index].visible,
        };
        return updated;
      });
    },
    [detailLayout]
  );

  return (
    <Reorder.Group
      id={'layoutGroup'}
      axis="x"
      className={styles.layout_order_container}
      onReorder={setMainLayout}
      values={mainLayout}
    >
      {mainLayout.map((item) => (
        <LayoutComponent key={item.name} item={item}>
          {item.name === MAIN_LAYOUT.CONTRACT_DETAIL && (
            <Reorder.Group
              id={'contractDetailGroup'}
              axis="y"
              className={styles.contract_detail_component_list}
              onReorder={setDetailLayout}
              values={detailLayout}
            >
              {detailLayout.map((detailItem, index) => (
                <ContractDetailComponent
                  key={detailItem.name}
                  item={detailItem}
                  index={index}
                  toggleVisible={toggleContractDetailComponentVisible}
                />
              ))}
            </Reorder.Group>
          )}
        </LayoutComponent>
      ))}
    </Reorder.Group>
  );
}
