import cn from 'classnames';
import _debounce from 'lodash/debounce';
import { memo, MouseEvent, useEffect, useRef, useState } from 'react';

import { Markdown } from '~/components';
import {
  HUMAN_MEDIAN_REACTION_TIME,
  REDIRECT_CLICKED_LOCATION,
} from '~/constants';
import type { AvailableChain } from '~/types';

import styles from './Description.module.scss';

interface DescriptionProps {
  description: string;
  chain?: AvailableChain;
}

export default memo(function Description({
  chain,
  description,
}: DescriptionProps) {
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMultiline, setIsMultiline] = useState(false);

  const toggleCollapse = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const updateCollapse = () => {
      const text = textRef.current;
      if (!!text) {
        const lineHeight = parseInt(getComputedStyle(text).lineHeight);
        const textHeight = text.scrollHeight;
        const numLines = Math.round(textHeight / lineHeight);
        setIsMultiline(numLines > 1);
      } else {
        setIsMultiline(false);
      }
    };
    const debouncedUpdateCollapse = _debounce(
      updateCollapse,
      HUMAN_MEDIAN_REACTION_TIME
    );
    updateCollapse();
    window.addEventListener('resize', debouncedUpdateCollapse);
    return () => window.removeEventListener('resize', debouncedUpdateCollapse);
  }, [description]);

  return (
    <div
      className={cn(styles.container, {
        [styles.collapsed_container]: isCollapsed,
      })}
    >
      <div
        ref={textRef}
        className={cn(styles.description, {
          [styles.collapsed]: isCollapsed,
        })}
      >
        <Markdown
          chain={chain}
          location={REDIRECT_CLICKED_LOCATION.CONTRACT_INFO}
        >
          {description}
        </Markdown>
      </div>
      {isMultiline && (
        <div className={styles.btn_container}>
          <button className={styles.btn_toggle} onClick={toggleCollapse}>
            Read {isCollapsed ? 'less' : 'more'}
          </button>
        </div>
      )}
    </div>
  );
});
