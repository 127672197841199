import { ExternalLink, Thumbnail } from '~/components';
import {
  AVAILABLE_CHAIN,
  IMAGE_SIZE,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import type { NotableGroup as NotableGroupType } from '~/types';

import styles from './NotableGroup.module.scss';

interface NotableGroupProps extends Partial<NotableGroupType> {}

export default function NotableGroup({
  imageUrl,
  isNew,
  name,
  websiteUrl,
}: NotableGroupProps) {
  const analytics = useAnalyticsContext();

  return (
    <div className={styles.group_info_container}>
      {isNew && <label className={styles.new}>New</label>}
      <ExternalLink
        className={styles.group_link}
        label={name}
        nofollow
        onClick={() => {
          analytics.track(REDIRECT_CLICKED, {
            chain: AVAILABLE_CHAIN.ETHEREUM,
            location: REDIRECT_CLICKED_LOCATION.NOTABLE_GROUPS,
            type: REDIRECT_CLICKED_TYPE.COLLECTION_WEBSITE,
            url: websiteUrl,
          });
        }}
        role="link"
        url={websiteUrl}
      >
        <div>
          <Thumbnail size={IMAGE_SIZE.SMALL} url={imageUrl} />
        </div>
        <span className={styles.group_name}>{name}</span>
      </ExternalLink>
    </div>
  );
}
