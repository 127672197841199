import type { ReactNode } from 'react';
import { SkeletonTheme as RLSkeletonTheme } from 'react-loading-skeleton';

import styleVariables from '~/styles/variables.module.scss';

interface SkeletonTheme {
  children: ReactNode;
}

export default function SkeletonTheme({ children }: SkeletonTheme) {
  return (
    <RLSkeletonTheme
      baseColor={styleVariables.skeletonBase}
      highlightColor={styleVariables.skeletonHighlight}
    >
      {children}
    </RLSkeletonTheme>
  );
}
