import { forwardRef, useCallback, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useSetRecoilState } from 'recoil';

import API from '~/api';
import { Loading, Toaster } from '~/components';
import {
  AVAILABLE_CHAIN,
  COLLECTION_CLICKED_LOCATION,
  UNEXPECTED_ERROR_MESSAGE,
} from '~/constants';
import useContractFilterList from '~/data/useContractFilterList';
import useInfiniteHiddenContracts from '~/data/useInfiniteHiddenContracts';
import { hiddenContractsState } from '~/store/contract';
import type { AvailableChain, HiddenContract } from '~/types';

import HiddenContractItem from './HiddenContractItem';
import styles from './HiddenContractsList.module.scss';
import commonStyles from '../../SettingsModal.module.scss';

interface HiddenContractsListProps {
  handleClickContract: (
    chain: Lowercase<AvailableChain>,
    address: string
  ) => void;
}

export default function HiddenContractsList({
  handleClickContract,
}: HiddenContractsListProps) {
  const { hiddenContracts, isItemLoaded, isLoading, itemCount, loadMore } =
    useInfiniteHiddenContracts();
  const setHiddenContracts = useSetRecoilState(hiddenContractsState);
  const { unhideContract } = useContractFilterList();
  const [unhideList, setUnhideList] = useState<
    { address: string; created: string }[]
  >([]);

  const handleClick = useCallback(
    (data: HiddenContract, position: number) => {
      const { address, chain = AVAILABLE_CHAIN.ETHEREUM, name } = data;
      const params: { [key: string]: any } = {
        chain,
        contract_address: address,
        location: COLLECTION_CLICKED_LOCATION.HIDDEN_CONTRACTS,
        position,
      };
      if (!!name) {
        params['collection_name'] = name;
      }
      handleClickContract('ethereum', address);
    },
    [handleClickContract]
  );

  const handleHide = useCallback((address: string) => {
    API.hideContract(address)
      .then((res) => {
        if (res.status === 204) {
          Toaster.toast({
            description: 'This contract has been hidden.',
            type: 'success',
          });
          setUnhideList((prev) =>
            prev.filter((unhideItem) => unhideItem.address !== address)
          );
          setHiddenContracts((prev) => [...prev, address]);
        }
      })
      .catch((err: any) => {
        if (err?.response?.data?.error?.detail) {
          Toaster.toast({
            description: err.response.data.error.detail,
            type: 'error',
          });
        } else {
          Toaster.toast({
            description: UNEXPECTED_ERROR_MESSAGE,
            type: 'error',
          });
        }
      });
  }, []);

  const handleUnhide = useCallback((address: string) => {
    API.unhideContract(address)
      .then((res) => {
        if (res.status === 204) {
          Toaster.toast({
            description: 'This contract has been unhidden.',
            type: 'success',
          });
          setUnhideList((prev) => [
            ...prev,
            { address, created: new Date().toUTCString() },
          ]);
          unhideContract(address);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error?.detail) {
          Toaster.toast({
            description: err.response.data.error.detail,
            type: 'error',
          });
        } else {
          Toaster.toast({
            description: UNEXPECTED_ERROR_MESSAGE,
            type: 'error',
          });
        }
      });
  }, []);

  const renderHiddenContractItem = ({
    index,
    style,
  }: {
    index: number;
    style: any;
  }) => {
    if (!isItemLoaded(index)) {
      return (
        <div className={styles.loading_container} style={style}>
          <Loading size={32} />
        </div>
      );
    }

    const hiddenContract = hiddenContracts[index];
    const isTurnedUnhidden = unhideList.some(
      (unhideItem) => unhideItem.address === hiddenContract.address
    );

    return (
      <HiddenContractItem
        data={hiddenContract}
        handleClick={() => handleClick(hiddenContract, index + 1)}
        handleHide={handleHide}
        handleUnhide={handleUnhide}
        isTurnedUnhidden={isTurnedUnhidden}
        style={style}
      />
    );
  };

  const innerElementType = forwardRef<HTMLUListElement, any>(
    function InnerElement({ style, ...rest }, ref) {
      return <ul {...rest} ref={ref} style={{ ...style, marginBottom: 34 }} />;
    }
  );

  return (
    <section className={commonStyles.section}>
      <div className={styles.hidden_list}>
        {isLoading ? (
          <Loading size={32} />
        ) : (
          <>
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMore}
              threshold={6}
            >
              {({ onItemsRendered, ref }: any) => (
                <List
                  height={746}
                  innerElementType={innerElementType}
                  itemCount={itemCount}
                  itemSize={68}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width="100%"
                >
                  {renderHiddenContractItem}
                </List>
              )}
            </InfiniteLoader>
          </>
        )}
      </div>
    </section>
  );
}
