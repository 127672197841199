import useQuicklinks from '~/hooks/useQuicklinks';
import type { ContractQuickLink } from '~/types';

import styles from './QuicklinksExample.module.scss';

interface QuicklinksExampleButtonProps {
  platform: ContractQuickLink;
}

export default function QuicklinksExampleButton({
  platform,
}: QuicklinksExampleButtonProps) {
  const { icon } = useQuicklinks({ platform });
  return <button className={styles.example_button}>{icon}</button>;
}
