import cn from 'classnames';
import { useRouter } from 'next/router';
import {
  Fragment,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';

import { IcnAppX, IcnArrowDown, IcnWarning } from '~/assets';
import { AVAILABLE_CHAIN, COLLECTION_CLICKED_LOCATION } from '~/constants';
import { checksumContractAddressState } from '~/store/contract';
import type { SimilarContract } from '~/types';
import formatAddress from '~/utils/formatAddress';

import styles from './ScamAlertSection.module.scss';
import SimilarContractsTable from './SimilarContractsTable';
import SectionLayout from '../SectionLayout';

interface ScamAlertSectionProps {
  id: 'similar' | 'linked_x';
  similarContracts: SimilarContract[];
}

export default function ScamAlertSection({
  id,
  similarContracts,
}: ScamAlertSectionProps) {
  // const analytics = useAnalyticsContext();
  const router = useRouter();
  const address = useRecoilValue(checksumContractAddressState);
  const bodyContainerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [isCollapse, setIsCollapse] = useState(false);

  const handleToggle = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setIsCollapse(!isCollapse);
    },
    [isCollapse]
  );

  const handleClickContractName = useCallback(
    (contract: SimilarContract, position: number) => {
      const { address, name } = contract;
      const chain = AVAILABLE_CHAIN.ETHEREUM;
      const params: { [key: string]: any } = {
        chain,
        contract_address: address,
        location:
          id === 'similar'
            ? COLLECTION_CLICKED_LOCATION.SCAM_ALERT
            : COLLECTION_CLICKED_LOCATION.X_HISTORY,
        position,
      };
      if (!!name) {
        params['collection_name'] = name;
      }

      router.push(
        {
          pathname: `/collection/[chain]/[address]`,
          query: { address, chain },
        },
        undefined,
        {
          shallow: true,
        }
      );
    },
    [address, id]
  );

  const renderContractNames = useCallback(() => {
    const otherCollections = similarContracts.slice(1);
    const length = otherCollections.length;

    return otherCollections.map((contract, index) => {
      let suffix = '';

      if (length > 1) {
        if (index === length - 2) {
          suffix = ' and ';
        } else if (index < length - 1) {
          suffix = ', ';
        }
      }

      return (
        <Fragment key={`similar_contract_name_${index}`}>
          <span
            className={styles.contract_name}
            onClick={(e) => {
              e.stopPropagation();
              handleClickContractName(contract, index + 1);
            }}
          >
            {`${contract.name} (${formatAddress(contract.address)})`}
          </span>
          {suffix}
        </Fragment>
      );
    });
  }, [handleClickContractName, similarContracts]);

  const renderTitle = (isSimilar: boolean, length: number) => {
    if (isSimilar) {
      return (
        <p>
          {`${length - 1} other collection${length > 2 ? 's' : ''}( `}
          {renderContractNames()}
          {` ) with similar name${
            length > 2 ? 's' : ''
          } also minted in the last 3 days. Some, if not all, might be scam. DYOR.`}
        </p>
      );
    }

    return (
      <p>
        {`X history: X account has been linked with ${length - 1} other ${
          length > 2 ? 'projects' : 'project'
        }( `}
        {renderContractNames()}
        {` ) previously.`}
      </p>
    );
  };

  useEffect(() => {
    // initialize states if the address is changed
    setIsCollapse(false);
  }, [address]);

  useEffect(() => {
    if (bodyContainerRef.current === null || bodyRef.current === null) return;
    if (!isCollapse || bodyContainerRef.current.clientHeight > 0) {
      bodyContainerRef.current.style.height = '0';
    } else {
      bodyContainerRef.current.style.height = `${bodyRef.current.clientHeight}px`;
    }
  }, [isCollapse]);

  const isSimilar = id === 'similar';
  const length = similarContracts.length;
  return (
    <SectionLayout boundTrigger={''} enableBound={false} id={`${id}_alert`}>
      <div
        className={cn(styles.line, {
          [styles.similar]: isSimilar,
          [styles.x]: !isSimilar,
        })}
      />
      <div className={styles.alert_header} onClick={handleToggle}>
        <div className={styles.alert_title}>
          {isSimilar ? <IcnWarning /> : <IcnAppX className={styles.x_icn} />}
          {renderTitle(isSimilar, length)}
        </div>
        <div
          className={cn('btn_icon', styles.accordion_toggle_button, {
            [styles.collapsed]: isCollapse,
          })}
        >
          <IcnArrowDown />
        </div>
      </div>
      <div className={styles.alert_body_container} ref={bodyContainerRef}>
        <div className={styles.alert_body} ref={bodyRef}>
          <SimilarContractsTable
            onClick={handleClickContractName}
            similarContracts={similarContracts}
          />
        </div>
      </div>
    </SectionLayout>
  );
}
