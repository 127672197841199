import type { ChangeEvent, Dispatch, SetStateAction } from 'react';

import {
  IcnMintOptionAlphaHQ,
  IcnMintOptionBreezeAIO,
  IcnMintOptionFenixLabs,
  IcnMintOptionHangout,
  IcnMintOptionJpegAlerts,
  IcnMintOptionLucidLabs,
  IcnMintOptionMetaSniper,
  IcnMintOptionMinTech,
  IcnMintOptionNfthunder,
  IcnMintOptionNftSensei,
  IcnMintOptionTenX,
  IcnMintOptionTimith,
  IcnMintOptionWaifu,
} from '~/assets';
import { AUTOMATION_PARTNERS, MINT_OPTION } from '~/constants';
import type { AutomationPartner, MintOption } from '~/types';

import styles from './MintAutomationSection.module.scss';
import commonStyles from '../SettingsModal.module.scss';

interface MintAutomationSectionProps {
  setAutomationPartners: Dispatch<SetStateAction<AutomationPartner[]>>;
  automationPartners?: AutomationPartner[];
}

export default function MintAutomationSection({
  automationPartners,
  setAutomationPartners,
}: MintAutomationSectionProps) {
  const renderOptionIcon = (option: MintOption) =>
    ({
      [MINT_OPTION.ALPHAHQ]: <IcnMintOptionAlphaHQ />,
      [MINT_OPTION.BREEZEAIO]: <IcnMintOptionBreezeAIO />,
      [MINT_OPTION.FENIXLABS]: <IcnMintOptionFenixLabs />,
      [MINT_OPTION.HANGOUT]: <IcnMintOptionHangout />,
      [MINT_OPTION.JPEGALERTS]: <IcnMintOptionJpegAlerts />,
      [MINT_OPTION.LUCIDLABS]: <IcnMintOptionLucidLabs />,
      [MINT_OPTION.METASNIPER]: <IcnMintOptionMetaSniper />,
      [MINT_OPTION.MINTECH]: <IcnMintOptionMinTech />,
      [MINT_OPTION.NFTHUNDER]: <IcnMintOptionNfthunder />,
      [MINT_OPTION.NFTSENSEI]: <IcnMintOptionNftSensei />,
      [MINT_OPTION.TENX]: <IcnMintOptionTenX />,
      [MINT_OPTION.TIMITH]: <IcnMintOptionTimith />,
      [MINT_OPTION.WAIFU]: <IcnMintOptionWaifu />,
    }[option]);

  const changeOption = (e: ChangeEvent<HTMLInputElement>) => {
    setAutomationPartners([e.target.value as AutomationPartner]);
  };

  return (
    <section className={commonStyles.section}>
      <div className={commonStyles.section_container}>
        <div className={commonStyles.section_title_container}>
          <span className={commonStyles.section_title}>
            {'Mint Automation Settings'}
          </span>
          <p className={commonStyles.section_description}>
            {
              'If you use a mint automation service and want to set it as a default mint option, choose from below.'
            }
          </p>
        </div>
        <ul className={styles.option_list}>
          {AUTOMATION_PARTNERS?.map((vendor) => {
            const option =
              MINT_OPTION[vendor.toUpperCase() as Uppercase<AutomationPartner>];
            return (
              <li key={vendor} className={styles.option_list_item}>
                <input
                  id={vendor}
                  type="radio"
                  name="mint_option"
                  value={vendor}
                  checked={automationPartners?.[0] === vendor}
                  onChange={changeOption}
                />
                <label htmlFor={vendor}>
                  {renderOptionIcon(option)}
                  <span>{option}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
