import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import API from '~/api';
import { CHANNEL_APP_HOOKS, EVENT_GAS_PRICE } from '~/constants';
import { gasPriceKeys } from '~/constants/queryKeys';
import { useSocket } from '~/contexts/SocketContext';
import usePageVisibility from '~/hooks/usePageVisibility';

export default function useGasPrice() {
  const isPageVisible = usePageVisibility();
  const queryKey = gasPriceKeys.all;
  const [gasPrice, setGasPrice] = useState<number | null>(null);
  const result = useQuery(queryKey, () => API.getGasPrice(), {
    cacheTime: 1_000 * 30,
    onSuccess: ({ data: { gasPrice } }) => {
      setGasPrice(gasPrice);
    },
  });
  const socket = useSocket();

  useEffect(() => {
    if (isPageVisible) {
      const channel = socket?.subscribe(CHANNEL_APP_HOOKS);

      channel?.bind(EVENT_GAS_PRICE, ({ gasPrice }: { gasPrice: number }) =>
        setGasPrice(gasPrice)
      );

      return () => {
        channel?.unbind(EVENT_GAS_PRICE);
      };
    }
  }, [isPageVisible, socket]);

  return {
    ...result,
    gasPrice: gasPrice ?? '-',
  };
}
