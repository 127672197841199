import cn from 'classnames';

import { Wallet } from '~/components';
import { REDIRECT_CLICKED_LOCATION } from '~/constants';
import type { ContractHolder } from '~/types';

import styles from './WalletRow.module.scss';

interface WalletRow extends ContractHolder {
  index: number;
  deployer?: string | null;
  totalSupply?: string | number | null;
}

export default function WalletRow({
  address,
  ensName,
  index,
  quantity,
  deployer,
  totalSupply,
}: WalletRow) {
  const percentage =
    !totalSupply || (typeof totalSupply === 'string' && totalSupply.length > 19)
      ? 0
      : (quantity / +totalSupply) * 100;
  const [valueIntegerPart, valueDecimalPart] = percentage
    .toFixed(2)
    .toString()
    .split('.');

  return (
    <div
      className={cn(styles.summary, {
        [styles.even]: index % 2 === 0,
      })}
    >
      <div className={cn('number_column', styles.column, styles.rank)}>
        <span>{index}</span>
      </div>
      <div className={cn(styles.column, styles.connected_wallets)}>
        <Wallet
          address={address}
          deployer={deployer}
          ens={ensName}
          location={REDIRECT_CLICKED_LOCATION.TOP_100_HOLDERS}
        />
      </div>
      <div className={cn(styles.column, styles.quantity_container)}>
        {!!totalSupply ? (
          <>
            <span className={cn('number_column', styles.quantity)}>
              {`${quantity}`}
            </span>
            <span className={styles.percentage}>
              <p className="number_column">{`(${valueIntegerPart}`}</p>
              {valueDecimalPart && (
                <p className={styles.decimal_point}>{'.'}</p>
              )}
              <p className={styles.decimal}>{`${valueDecimalPart}%)`}</p>
            </span>
            <div className={styles.percentage_bar}>
              <div
                className={styles.filled}
                style={{
                  width: `${percentage}%`,
                }}
              />
            </div>
          </>
        ) : (
          <span className={cn('number_column', styles.quantity)}>
            {quantity}
          </span>
        )}
      </div>
    </div>
  );
}
