import cn from 'classnames';
import { CSSProperties, MouseEvent, useEffect, useRef } from 'react';

import { useNotificationsContext } from '~/contexts/NotificationsContext';
import type { Notification } from '~/types';
import formatTimeAgo from '~/utils/formatTimeAgo';

import styles from './NotificationItem.module.scss';

interface NotificationItemProps {
  index: number;
  notification: Notification;
  onClick: (
    e: MouseEvent<HTMLDivElement>,
    notification: Notification,
    position: number
  ) => void;
  style: CSSProperties;
}

export default function NotificationItem({
  index,
  notification,
  onClick,
  style,
}: NotificationItemProps) {
  const { content, created, unread, target, username, verb } = notification;
  const diff = Date.now() - new Date(created).getTime();
  const itemRef = useRef<HTMLDivElement | null>(null);
  const { setSize } = useNotificationsContext();

  useEffect(() => {
    if (itemRef.current) {
      setSize(index, itemRef.current.clientHeight);
    }
  }, []);

  return (
    <li
      className={cn(styles.container, {
        [styles.unread]: unread,
      })}
      style={{ ...style }}
    >
      <div
        ref={itemRef}
        onClick={(e) => onClick(e, notification, index + 1)}
        className={styles.inner_container}
      >
        <div className={styles.message}>
          <strong>{username}</strong>
          {` ${verb} `}
          <strong className={styles.contract_name}>{target}</strong>
        </div>
        <div className={styles.date}>{formatTimeAgo(diff)}</div>
        <div className={styles.content}>{`“${content}”`}</div>
      </div>
    </li>
  );
}
