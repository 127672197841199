import { IcnGasTracker } from '~/assets';
import { Popover } from '~/components';
import useGasPrice from '~/data/useGasPrice';

import styles from './GasTracker.module.scss';

export default function GasTracker() {
  const { gasPrice } = useGasPrice();

  return (
    <Popover
      render={() => (
        <div className="default_popover">
          <span>{'Current price per gas'}</span>
        </div>
      )}
      animation
      placement="bottom"
    >
      <div className={styles.gas_tracker}>
        <div className={styles.gas_price_container}>
          <span className={styles.gas_price}>{gasPrice}</span>
          <p className={styles.gas_price_unit}>{'gwei'}</p>
        </div>
        <IcnGasTracker />
      </div>
    </Popover>
  );
}
