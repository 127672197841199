import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { forwardRef, MouseEvent as ReactMouseEvent, useEffect } from 'react';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useRecoilValue } from 'recoil';

import { Loading, NotificationItem } from '~/components';
import { COLLECTION_CLICKED_LOCATION } from '~/constants';
import { contractsKeys } from '~/constants/queryKeys';
import { useNotificationsContext } from '~/contexts/NotificationsContext';
import useNotificationList from '~/data/useNotificationList';
import { checksumContractAddressState } from '~/store/contract';
import type { Notification } from '~/types';
import formatAddress from '~/utils/formatAddress';

import styles from './NotificationPopup.module.scss';

interface NotificationHistoryProps {
  onClose: () => void;
}

export default function NotificationHistory({
  onClose,
}: NotificationHistoryProps) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const {
    isItemLoaded,
    isLoading,
    itemCount,
    loadMore,
    notifications,
    setAllNotificationToRead,
  } = useNotificationList();
  const { getSize, listRef } = useNotificationsContext();
  const contractAddress = useRecoilValue(checksumContractAddressState);

  const onClickLink = (
    e: ReactMouseEvent<HTMLDivElement>,
    notification: Notification,
    position: number
  ) => {
    e.preventDefault();
    const regex = /^\/collection\/ethereum\/([a-zA-Z0-9]{42})\/$/;
    const match = notification.targetUrl.match(regex);

    if (match) {
      const address = match[1];
      const params: { [key: string]: any } = {
        chain: 'Ethereum',
        contract_address: address,
        location: COLLECTION_CLICKED_LOCATION.NOTIFICATION_PANEL,
        position,
      };

      if (notification.target !== formatAddress(match[1])) {
        params['collection_name'] = notification.target;
      }
    }

    const queryKey = contractsKeys.comments('ethereum', contractAddress);

    if (notification.targetUrl.endsWith(`${contractAddress}/`)) {
      queryClient.invalidateQueries({ queryKey });
    }
    router.push(notification.targetUrl, undefined, {
      scroll: false,
      shallow: true,
    });
    onClose();
  };

  const renderNotificationItem = ({
    index,
    style,
  }: {
    index: number;
    style: any;
  }) => {
    if (!isItemLoaded(index)) {
      return (
        <div className={styles.loading_container} style={style}>
          <Loading size={32} />
        </div>
      );
    }

    const notification = notifications[index];

    return (
      <NotificationItem
        index={index}
        notification={notification}
        onClick={onClickLink}
        style={style}
      />
    );
  };

  const innerElementType = forwardRef<HTMLUListElement, any>(
    function InnerElement({ style, ...rest }, ref) {
      return <ul {...rest} ref={ref} style={style} />;
    }
  );

  useEffect(() => {
    return () => {
      setAllNotificationToRead();
    };
  }, []);

  if (isLoading)
    return (
      <div className={styles.body}>
        <Loading size={24} />
      </div>
    );

  if (notifications.length < 1) {
    return <div className={styles.body}>Nothing to see here yet</div>;
  }

  return (
    <div className={styles.list}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMore}
        ref={listRef}
        threshold={10}
      >
        {({ onItemsRendered, ref }: any) => (
          <List
            height={648}
            innerElementType={innerElementType}
            itemCount={itemCount}
            itemSize={getSize}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width="100%"
          >
            {renderNotificationItem}
          </List>
        )}
      </InfiniteLoader>
    </div>
  );
}
