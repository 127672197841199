import cn from 'classnames';
import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';

import API from '~/api';
import { IcnClose } from '~/assets';
import { Modal, Toaster } from '~/components';
import useContractInfo from '~/data/useContractInfo';
import type { AvailableChain } from '~/types';

import styles from './AddDiscordLinkModal.module.scss';

interface AddDiscordLinkModalProps {
  address: string;
  chain: Lowercase<AvailableChain>;
  onClose: () => void;
}

export default function AddDiscordLinkModal({
  address,
  chain,
  onClose,
}: AddDiscordLinkModalProps) {
  const { update } = useContractInfo(chain, address);
  const [link, setLink] = useState('');

  const handleConnect = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (validateDiscordLink(link)) {
      const {
        data: { discordGuildName },
      } = await API.connectDiscordInviteUrlToContract(address, link);
      update({ discordGuildName, discordUrl: link });
      onClose();
    } else {
      Toaster.toast({
        description: 'It looks like a non-discord invite link.',
        type: 'error',
      });
    }
  };

  const handleTypeLink = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setLink(e.target.value);
  }, []);

  const validateDiscordLink = (url: string) => {
    const regex = /^https?:\/\/discord\.gg\/([A-Za-z0-9-]+)$/;
    return regex.test(url);
  };

  return (
    <Modal open={true} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <span className={styles.title}>Add invite link</span>
          <button
            className={cn('btn_icon', styles.btn_close)}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <IcnClose />
          </button>
        </div>
        <div className={styles.body_container}>
          <p>{`Enter the Discord server invite link. The link needs to be set to "never expire".`}</p>
          <input
            className={styles.input}
            value={link}
            onChange={handleTypeLink}
            placeholder="https://discord.gg/catchmintxyz"
          />
          <button className={styles.btn_connect} onClick={handleConnect}>
            Connect
          </button>
        </div>
      </div>
    </Modal>
  );
}
