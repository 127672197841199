import { HTMLInputTypeAttribute, useEffect, useState } from 'react';

import { IcnInputClear } from '~/assets';
import { useAuth } from '~/contexts/AuthContext';

import styles from './ParamInput.module.scss';

interface ParamInputProps {
  setParams: (value: string | null) => void;
  defaultValue: string | number | null;
  showPasteButton?: boolean;
  label?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
}

export default function ParamInput({
  setParams,
  defaultValue,
  showPasteButton = false,
  label,
  placeholder,
  type = 'text',
}: ParamInputProps) {
  const { user } = useAuth();
  const [value, setValue] = useState(defaultValue);

  const clearValue = () => {
    if (defaultValue === null) return setValue(null);
    return setValue('');
  };

  const pasteValue = (text: string) => {
    setValue(text);
  };

  useEffect(() => {
    if (value != null) {
      setParams(value.toString());
    } else {
      setParams(value);
    }
  }, [value]);

  return (
    <div className={styles.modal_input_container}>
      {label && (
        <label htmlFor={label} className={styles.input_label}>
          {label}
        </label>
      )}
      <div className={styles.input_container}>
        <input
          id={label}
          className={styles.input}
          onChange={(e) => setValue(e.target.value)}
          type={type}
          value={value ?? undefined}
          placeholder={placeholder}
        />
        {!(
          value === null ||
          value === undefined ||
          value.toString().length === 0
        ) && (
          <button
            className={styles.btn_clear}
            onClick={(e) => {
              e.stopPropagation();
              clearValue();
            }}
          >
            <IcnInputClear />
          </button>
        )}
        {(value === null ||
          value === undefined ||
          value.toString().length === 0) &&
          showPasteButton && (
            <button
              className={styles.btn_paste}
              onClick={(e) => {
                pasteValue(user?.address ?? '');
                e.stopPropagation();
              }}
            >
              {'Paste my address'}
            </button>
          )}
      </div>
    </div>
  );
}
