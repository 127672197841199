import cn from 'classnames';
import { useEffect, useState } from 'react';

import { TableCell } from '~/components/Table';

import styles from './SuccessfulTransactionsSection.module.scss';

interface TimeAgoCellProps {
  date: Date;
  highlightDuring?: number;
}

export default function TimeAgoCell({
  date,
  highlightDuring = 180_000,
}: TimeAgoCellProps) {
  const [diff, setDiff] = useState<number>(
    new Date().getTime() - date.getTime()
  );

  useEffect(() => {
    const secondTimer = setInterval(() => {
      setDiff(new Date().getTime() - date.getTime());
    }, 1_000);

    return () => clearInterval(secondTimer);
  }, [date]);

  const format = (diff: number) => {
    const seconds = Math.floor(diff * 0.001);

    if (seconds < 1) return [seconds < 0 ? 0 : seconds, 'secs ago'];
    if (seconds < 2) return [seconds, 'sec ago'];
    if (seconds < 60) return [seconds, 'secs ago'];
    if (seconds < 60 * 2) return ['1', 'min ago'];
    if (seconds < 60 * 60) return [Math.floor(seconds / 60), 'mins ago'];
    if (seconds < 60 * 60 * 2) return [1, 'hr ago'];
    if (seconds < 60 * 60 * 24)
      return [Math.floor(seconds / (60 * 60)), 'hrs ago'];
    if (seconds < 60 * 60 * 24 * 2) return [1, 'day ago'];
    return [Math.floor(seconds / (60 * 60 * 24)), 'days ago'];
  };

  return (
    <>
      <TableCell
        isText={false}
        className={cn(styles.cell_divided_left, {
          [styles.under_one_min]: diff < highlightDuring,
        })}
      >
        <time dateTime={date.toUTCString()}>{format(diff)[0]}</time>
      </TableCell>
      <TableCell
        isText={false}
        className={cn(styles.cell_time_unit, {
          [styles.under_one_min]: diff < highlightDuring,
        })}
      >
        {format(diff)[1]}
      </TableCell>
    </>
  );
}
