import cn from 'classnames';
import type { ReactNode } from 'react';

import styles from './DivComponent.module.scss';

interface DivListProps {
  children: ReactNode;
  className?: string;
}

export default function CustomList({ children, className }: DivListProps) {
  return <div className={cn(styles.div_view_list, className)}>{children}</div>;
}
