import { IcnMaintenance } from '~/assets';
import { ExternalLink } from '~/components';
import { APP_DISCORD_LINK, APP_X_LINK } from '~/constants';

import styles from './MaintenanceMode.module.scss';

export function MaintenanceMode() {
  return (
    <div className={styles.container}>
      <div className={styles.maintenance_container}>
        <div className={styles.maintenance_inner}>
          <IcnMaintenance />
          <h3 className={styles.maintenance_title}>We’ll be back Soon!</h3>
          <p className={styles.maintenance_description}>
            {` We’re currently down for scheduled maintenance. Thanks for your
            patience. For further details, please check our `}
          </p>
          <p className={styles.maintenance_description}>
            <ExternalLink
              allowsUtmSource={false}
              className={styles.app_link}
              label="app_discord_link"
              role="link"
              url={APP_DISCORD_LINK}
            >
              {`Discord`}
            </ExternalLink>
            {` or `}
            <ExternalLink
              allowsUtmSource={false}
              className={styles.app_link}
              label="app_x_link"
              role="link"
              url={APP_X_LINK}
            >
              {`X`}
            </ExternalLink>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default MaintenanceMode;
