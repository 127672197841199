import cn from 'classnames';
import type { ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

import { IcnExternal, IcnSmallExternal, IcnSmallHint } from '~/assets';
import { CountUp, Popover } from '~/components';
import noop from '~/utils/noop';

import styles from './Indicator.module.scss';

interface IndicatorProps {
  additional?: {
    decimals?: number;
    hint?: string;
    icon?: ReactNode;
    prefix?: string;
    suffix?: string;
    value?: number | string;
  };
  decimals?: number;
  emphasisHint?: string;
  hint?: string;
  label?: string;
  onClick?: () => void;
  prefix?: string;
  showsAlert?: JSX.Element;
  suffix?: string;
  value?: number | string;
}

export default function Indicator({
  additional,
  decimals,
  emphasisHint,
  hint,
  label,
  onClick,
  prefix,
  showsAlert,
  suffix,
  value,
}: IndicatorProps) {
  const renderCount = (
    count: number | string,
    options?: {
      prefix?: string;
      suffix?: string;
      decimals?: number;
    }
  ) => {
    if (typeof count === 'number') {
      return (
        <CountUp
          decimals={options?.decimals}
          duration={0.5}
          end={count}
          prefix={options?.prefix}
          separator=","
          suffix={options?.suffix}
        />
      );
    }
    return <span>{count.toLocaleString()}</span>;
  };

  return (
    <li className={styles.container}>
      {!!label && (
        <span className={styles.label}>
          {typeof value === 'undefined' ? <Skeleton width={80} /> : label}
        </span>
      )}
      <div
        className={cn(styles.value_container, {
          [styles.clickable]: !!onClick,
        })}
        onClick={onClick ?? noop}
      >
        {typeof value === 'undefined' ? (
          <Skeleton width={54} />
        ) : !!showsAlert || !!emphasisHint || !!hint ? (
          <Popover
            render={() =>
              showsAlert ? (
                showsAlert
              ) : emphasisHint ? (
                <div className="price_popover_container">
                  <span className="price_popover_content">{emphasisHint}</span>
                </div>
              ) : (
                <div className="default_popover">
                  <span>{hint}</span>
                </div>
              )
            }
            placement={!!showsAlert ? 'right-start' : 'top'}
            animation
          >
            <div className={styles.count_container}>
              {renderCount(value, { decimals, prefix, suffix })}
              {typeof value !== 'undefined' && !!showsAlert && (
                <div className={styles.edited}>
                  {'(edited'}
                  <div className={styles.icn_alert}>
                    <IcnSmallHint />
                  </div>
                  {')'}
                </div>
              )}
            </div>
          </Popover>
        ) : (
          renderCount(value, { decimals, prefix, suffix })
        )}
        {!!onClick && typeof value !== 'undefined' && !additional && (
          <div className={styles.icn_external}>
            <IcnExternal />
          </div>
        )}
        {!!additional &&
          (additional.hint ? (
            <Popover
              render={() => (
                <div className="default_popover">
                  <span>{additional.hint}</span>
                </div>
              )}
              placement="top"
              animation
            >
              <div className={styles.additional_indicator}>
                {additional.icon}
                {renderCount(additional.value ?? 0, {
                  decimals: additional.decimals,
                  prefix: additional.prefix,
                  suffix: additional.suffix,
                })}
              </div>
            </Popover>
          ) : (
            <div className={styles.additional_indicator}>
              {additional.icon}
              {renderCount(additional.value ?? 0, {
                decimals: additional.decimals,
                prefix: additional.prefix,
                suffix: additional.suffix,
              })}
            </div>
          ))}
        {!!onClick && typeof value !== 'undefined' && !!additional && (
          <div className={styles.icn_small_external}>
            <IcnSmallExternal />
          </div>
        )}
      </div>
    </li>
  );
}
