import cn from 'classnames';

import { IcnSmallExternal } from '~/assets';
import { ExternalLink, Popover } from '~/components';
import {
  AVAILABLE_CHAIN,
  NULL_ADDRESS,
  REDIRECT_CLICKED_TYPE,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import formatAddress from '~/utils/formatAddress';

import styles from './Wallet.module.scss';

interface WalletProps {
  address: string;
  location: string;
  deployer?: string | null;
  ens?: string;
  highlight?: boolean;
}

export default function Wallet({
  address,
  deployer,
  ens,
  location,
  highlight = false,
}: WalletProps) {
  const analytics = useAnalyticsContext();
  const name = address === NULL_ADDRESS ? 'Null Address' : ens;
  let showingAddress = name || formatAddress(address);
  if (deployer && deployer === address) showingAddress += ' (Deployer)';

  return (
    <div className={styles.wallet_container}>
      <Popover
        render={() => (
          <div className="default_popover">
            {name ? (
              <span>
                {name}
                <br />({address})
              </span>
            ) : (
              <span>{address}</span>
            )}
          </div>
        )}
        placement="top-start"
        animation
      >
        <ExternalLink
          className={cn(styles.wallet, {
            [styles.highlight_name]:
              highlight ||
              Boolean(ens) ||
              address === deployer ||
              address === NULL_ADDRESS,
          })}
          label={showingAddress}
          onClick={() => {
            analytics.track(REDIRECT_CLICKED, {
              chain: AVAILABLE_CHAIN.ETHEREUM,
              location,
              type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
              url: `https://etherscan.io/address/${address}`,
            });
          }}
          role="link"
          url={`https://etherscan.io/address/${address}`}
        >
          <p>{showingAddress}</p>
          <span className={styles.icn_external}>
            <IcnSmallExternal />
          </span>
        </ExternalLink>
      </Popover>
    </div>
  );
}
