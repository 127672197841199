import cn from 'classnames';
import { memo, VideoHTMLAttributes } from 'react';

import styles from './VideoSource.module.scss';

interface VideoSourceProps extends VideoHTMLAttributes<HTMLVideoElement> {
  extension?: string;
  radius?: boolean;
  size?: number;
  url?: string;
}

export default memo(function VideoSource(props: VideoSourceProps) {
  const {
    extension,
    height,
    poster,
    radius = false,
    size,
    url,
    width,
    ...otherProps
  } = props;
  const _width = width ?? size;
  const _height = height ?? size;

  const renderType = (extension?: string) => {
    if (extension === 'mov') return 'mp4';
    return extension;
  };

  return (
    <video
      {...otherProps}
      autoPlay
      className={cn(styles.video, {
        [styles.radius]: radius,
      })}
      height={_height}
      loop
      muted
      playsInline
      poster={poster}
      preload="none"
      style={{
        width: _width,
        height: _height,
      }}
      width={_width}
    >
      <source src={url} type={`video/${renderType(extension)}`} />
    </video>
  );
});
