import type { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';

import {
  IcnSetting,
  IcnSmallExternal,
  IcnUnbottable,
  IcnVerified,
} from '~/assets';
import {
  Copy,
  ExternalLink,
  Indicator,
  Label,
  Popover,
  Thumbnail,
  TimeAgo,
} from '~/components';
import {
  CHANNEL_PENDINGS,
  CONTRACT_QUICK_LINK,
  EVENT_PENDINGS_LATEST,
  IMAGE_SIZE,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
  TOTAL_MINTED_HINT_OLD_COLLECTIONS,
  TOTAL_MINTED_HINT_TOTAL_SUPPLY_NOT_SUPPORTED,
  UNIDENTIFIED_CONTRACT,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import { useAuth } from '~/contexts/AuthContext';
import { useSocket } from '~/contexts/SocketContext';
import useContractAnnouncements from '~/data/useContractAnnouncements';
import useContractInfo from '~/data/useContractInfo';
import useDisplay from '~/hooks/useDisplay';
import usePageVisibility from '~/hooks/usePageVisibility';
import { checksumContractAddressState } from '~/store/contract';
import type {
  Collection,
  ContractEntry,
  ContractInfo,
  PendingTransactions,
} from '~/types';
import formatAddress from '~/utils/formatAddress';
import formatTimeAgo from '~/utils/formatTimeAgo';
import getDecimalPlaceLength from '~/utils/getDecimalPlaces';
import scrollIntoViewById from '~/utils/scrollIntoViewById';

import Announcement from './Announcement';
import styles from './ContractInfoSection.module.scss';
import ContractStatus from './ContractStatus';
import Description from './Description';
import LinkButton from '../LinkButton';
import SectionLayout from '../SectionLayout';

interface ContractInfoSectionProps {
  collectionsResult: {
    collections: Collection[] | undefined;
    oldestModified: string;
  } & UseQueryResult<AxiosResponse<Collection[], any>, unknown>;
  contractInfo: ContractInfo | null;
  entryResult?: { entry: ContractEntry } & UseQueryResult<
    AxiosResponse<ContractEntry, any>,
    unknown
  >;
}

export default memo(function ContractInfoSection({
  contractInfo,
  entryResult,
}: ContractInfoSectionProps) {
  const analytics = useAnalyticsContext();
  const { user } = useAuth();
  const router = useRouter();
  const address = useRecoilValue(checksumContractAddressState);
  const [pendingTxs, setPendingTxs] = useState<{
    [address: string]: PendingTransactions;
  }>({});
  const { announcements } = useContractAnnouncements('ethereum', address);
  const contractInfoResult = useContractInfo('ethereum', address);
  const isPageVisible = usePageVisibility();
  const socket = useSocket();
  const [{ quickLinks }] = useDisplay();

  const {
    blurUrl,
    canInteractFromContract,
    chain,
    discordUrl = '',
    deployedAt,
    deployer,
    deploymentTransactionHash,
    description,
    firstMint,
    imageUrl,
    isVerified = false,
    lastMint,
    maxSupply = [],
    name,
    openseaUrl,
    standard,
    totalSupply,
    twitterUrl = '',
    uniqueWallets,
    websiteUrl = '',
  } = {
    ...contractInfoResult.contractInfo,
  };

  const lastMintedAt = contractInfo?.lastMint ?? lastMint;
  const receivedMaxSupply = contractInfo?.maxSupply ?? maxSupply;
  const showingMaxSupply =
    receivedMaxSupply.length > 0 ? receivedMaxSupply[0].supply : undefined;
  const selectedTotalSupply =
    contractInfo?.totalSupply ??
    contractInfo?.entry?.totalSupply ??
    totalSupply ??
    entryResult?.data?.data.totalSupply;
  const selectedUniqueWallets = contractInfo?.uniqueWallets ?? uniqueWallets;

  const getTotalMintedPercentage = useCallback(() => {
    if (contractInfoResult.isLoading) return undefined;
    if (
      !selectedTotalSupply ||
      !showingMaxSupply ||
      (typeof selectedTotalSupply === 'string' &&
        selectedTotalSupply.length > 19)
    )
      return undefined;

    const value = (+selectedTotalSupply / showingMaxSupply) * 100;
    const decimalPlaceLength = getDecimalPlaceLength(value);

    return {
      value,
      prefix: '(',
      suffix: '%)',
      decimals: decimalPlaceLength < 3 ? decimalPlaceLength : 2,
    };
  }, [contractInfoResult.isLoading, showingMaxSupply, selectedTotalSupply]);

  const getUniqueMintersPercentage = useCallback(() => {
    if (contractInfoResult.isLoading) return undefined;

    if (
      !selectedTotalSupply ||
      (typeof selectedTotalSupply === 'string' &&
        selectedTotalSupply.length > 19)
    ) {
      if (
        selectedUniqueWallets === null ||
        selectedUniqueWallets === undefined
      ) {
        return '?';
      }
      return selectedUniqueWallets;
    }

    return ((selectedUniqueWallets ?? 0) / +selectedTotalSupply) * 100;
  }, [
    contractInfoResult.isLoading,
    selectedTotalSupply,
    selectedUniqueWallets,
  ]);

  const getUniqueMintersValue = useCallback(() => {
    if (contractInfoResult.isLoading) return undefined;
    if (!selectedTotalSupply) return undefined;

    return {
      value: selectedUniqueWallets,
      prefix: '(',
      suffix: ')',
    };
  }, [
    contractInfoResult.isLoading,
    selectedTotalSupply,
    selectedUniqueWallets,
  ]);

  const renderMaxSupplyAlerts = useCallback(() => {
    if (receivedMaxSupply.length > 1) {
      const currentTime = Date.now();
      return (
        <div className={styles.max_supply_alerts_modal}>
          <div className={styles.column}>
            <span className={styles.column_title}>{'Max Supply'}</span>
            {receivedMaxSupply.slice(0, 10).map(({ supply }, index) => (
              <span key={index} className={styles.max_supply}>
                {supply}
              </span>
            ))}
            {receivedMaxSupply.length > 10 && (
              <span className={styles.max_supply}>{'...'}</span>
            )}
          </div>
          <div className={styles.column}>
            <span className={styles.column_title}>{'Time'}</span>
            {receivedMaxSupply.slice(0, 10).map(({ created }, index) => (
              <span key={index} className={styles.edited_time}>
                {formatTimeAgo(currentTime - new Date(created).getTime())}
              </span>
            ))}
          </div>
        </div>
      );
    }
    return;
  }, [receivedMaxSupply]);

  const getPendingCountWithoutFake = useCallback(() => {
    if (contractInfoResult.isLoading || !pendingTxs?.[address])
      return undefined;
    const { total = 0 } = { ...pendingTxs?.[address] };
    return {
      hint: 'Total pendings',
      prefix: 'out of ',
      value: total,
    };
  }, [address, contractInfoResult.isLoading, pendingTxs]);

  const showHintToTotalMinted = useCallback(
    (isTotalSupplySupported: boolean) => {
      const count = selectedTotalSupply;
      if (count === 0) return TOTAL_MINTED_HINT_OLD_COLLECTIONS;
      if (!isTotalSupplySupported)
        return TOTAL_MINTED_HINT_TOTAL_SUPPLY_NOT_SUPPORTED;
      return;
    },
    [selectedTotalSupply]
  );

  useEffect(() => {
    if (isPageVisible) {
      socket
        ?.subscribe(CHANNEL_PENDINGS)
        ?.bind(
          EVENT_PENDINGS_LATEST,
          (data: { [address: string]: PendingTransactions }) =>
            setPendingTxs(data)
        );

      return () => {
        socket?.unsubscribe(CHANNEL_PENDINGS);
      };
    }
  }, [isPageVisible, socket]);

  return (
    <SectionLayout boundTrigger={''} enableBound={false} id={'contract_info'}>
      <div className={styles.contract_info_container}>
        <div className={styles.thumbnail_container}>
          {imageUrl && (
            <>
              <div className={styles.lens_background} />
              <ExternalLink
                allowsUtmSource={false}
                className={styles.lens_thumbnail}
                label={'lens_image'}
                nofollow
                onClick={() => {
                  analytics.track(REDIRECT_CLICKED, {
                    chain,
                    location: REDIRECT_CLICKED_LOCATION.CONTRACT_INFO,
                    type: REDIRECT_CLICKED_TYPE.OTHER,
                    url: `https://lens.google.com/uploadbyurl?url=${imageUrl}`,
                  });
                }}
                role="link"
                url={`https://lens.google.com/uploadbyurl?url=${encodeURIComponent(
                  imageUrl
                )}`}
              >
                <span>Google Lens Quick Search</span>
              </ExternalLink>
            </>
          )}
          <Thumbnail
            isLoading={contractInfoResult.isLoading}
            name={name}
            size={IMAGE_SIZE.HUGE}
            url={imageUrl}
          />
        </div>
        <div className={styles.contract_info_text_container}>
          <div className={styles.contract_name_container}>
            <div className={styles.first_row}>
              {contractInfoResult.isLoading ? (
                <Skeleton width={200} height={30} />
              ) : !name || name.trim().length === 0 ? (
                <h1 className={styles.contract_name}>
                  {UNIDENTIFIED_CONTRACT}
                </h1>
              ) : (
                <Popover
                  render={() => (
                    <div className="default_popover">
                      <span>{name}</span>
                    </div>
                  )}
                  animation
                  placement="top-start"
                >
                  <h1 className={styles.contract_name}>{name}</h1>
                </Popover>
              )}
              {!contractInfoResult.isLoading && !contractInfoResult.isError && (
                <ContractStatus
                  contractInfo={contractInfoResult.contractInfo}
                />
              )}
            </div>
            <div className={styles.contract_chain_info_container}>
              {contractInfoResult.isLoading ? (
                <Skeleton width={110} />
              ) : (
                <div className={styles.contract_address_container}>
                  {formatAddress(address)}
                  <Copy content={address} />
                </div>
              )}
              <div className={styles.labels_container}>
                {!contractInfoResult.isLoading &&
                  (isVerified ? (
                    <Popover
                      render={() => (
                        <div className="default_popover">
                          <p>
                            {`Deployer has verified the contract code to be public,`}
                            <br />
                            {`but it does not mean the contract is safe or legitimate. DYOR.`}
                          </p>
                        </div>
                      )}
                      animation
                      placement="top"
                    >
                      <Label
                        text="Verified"
                        icon={<IcnVerified />}
                        highlight
                        onClick={() => {
                          scrollIntoViewById('code_snippet');
                        }}
                      />
                    </Popover>
                  ) : (
                    <Label
                      text="Unverified"
                      disabled
                      icon={<IcnVerified />}
                      onClick={undefined}
                    />
                  ))}
                {!contractInfoResult.isLoading &&
                  canInteractFromContract !== null &&
                  canInteractFromContract === false && (
                    <Popover
                      render={() => (
                        <div className="default_popover">
                          <p>
                            {`This contract has `}
                            <code className={styles.codes}>
                              {'tx.origin = msg.sender'}
                            </code>
                            {` requirement,`}
                            <br />
                            {`meaning it cannot be botted from another smart contract.`}
                          </p>
                        </div>
                      )}
                      animation
                      placement="top"
                    >
                      <Label text="Unbottable" icon={<IcnUnbottable />} />
                    </Popover>
                  )}
                {!!standard && <Label text={standard} />}
              </div>
            </div>
            <div className={styles.buttons_container}>
              {contractInfoResult.isLoading
                ? Array(quickLinks.length)
                    .fill({})
                    .map((_, index) => (
                      <Skeleton
                        key={index}
                        circle
                        width={28}
                        height={28}
                        style={{ verticalAlign: 'top' }}
                      />
                    ))
                : quickLinks.map((quickLink, index) => (
                    <LinkButton
                      key={`Quicklink ${quickLink}`}
                      address={address}
                      isLoading={contractInfoResult.isLoading}
                      platform={quickLink}
                      position={index + 1}
                      url={
                        quickLink === CONTRACT_QUICK_LINK.BLUR
                          ? blurUrl
                          : quickLink === CONTRACT_QUICK_LINK.OPENSEA
                          ? openseaUrl
                          : quickLink === CONTRACT_QUICK_LINK.X
                          ? twitterUrl
                          : quickLink === CONTRACT_QUICK_LINK.DISCORD
                          ? discordUrl
                          : quickLink === CONTRACT_QUICK_LINK.WEBSITE
                          ? websiteUrl
                          : undefined
                      }
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
      {contractInfoResult.isLoading ? (
        <Skeleton height={22} style={{ marginTop: 12 }} />
      ) : (
        description && <Description chain={chain} description={description} />
      )}
      <ul className={styles.contract_indicator_container}>
        <Indicator
          label="Total Minted"
          value={
            contractInfoResult.isLoading
              ? undefined
              : selectedTotalSupply || '?'
          }
          hint={showHintToTotalMinted(true)}
          additional={getTotalMintedPercentage()}
        />
        <Indicator
          label="Max Supply"
          showsAlert={renderMaxSupplyAlerts()}
          value={
            contractInfoResult.isLoading ? undefined : showingMaxSupply || '?'
          }
        />
        <Indicator
          label="Unique Minters"
          value={getUniqueMintersPercentage()}
          suffix={
            selectedTotalSupply && selectedUniqueWallets ? '%' : undefined
          }
          decimals={
            selectedTotalSupply && selectedUniqueWallets ? 2 : undefined
          }
          additional={getUniqueMintersValue()}
        />
        <Indicator
          hint={
            !!pendingTxs?.[address]
              ? 'Meaningful pendings that are likely to be mined within 3 blocks'
              : undefined
          }
          label="Pending Txs"
          value={
            contractInfoResult.isLoading
              ? undefined
              : !!pendingTxs?.[address]
              ? (pendingTxs?.[address].total ?? 0) -
                (pendingTxs?.[address].fake ?? 0)
              : '-'
          }
          additional={getPendingCountWithoutFake()}
          onClick={() => {
            const url = `https://etherscan.io/txsPending?a=${address}&m=hf`;
            analytics.track(REDIRECT_CLICKED, {
              chain,
              location: REDIRECT_CLICKED_LOCATION.CONTRACT_INFO,
              type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
              url,
            });
            window.open(url, '_blank');
          }}
        />
      </ul>
      <div className={styles.contract_info_footer}>
        <div
          className={cn(styles.contract_info_deploy_container, {
            [styles.show_deploy]:
              contractInfoResult.isLoading ||
              !!deployer ||
              !!firstMint ||
              !!lastMint,
          })}
        >
          {contractInfoResult.isLoading ? (
            <Skeleton width={250} />
          ) : (
            !!deployer && (
              <p>
                Deployed By
                <ExternalLink
                  className={styles.deployer}
                  url={`https://etherscan.io/address/${deployer}`}
                  label={'deploy_contract'}
                  role="button"
                  onClick={() => {
                    analytics.track(REDIRECT_CLICKED, {
                      chain,
                      location: REDIRECT_CLICKED_LOCATION.CONTRACT_INFO,
                      type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
                      url: `https://etherscan.io/address/${deployer}`,
                    });
                  }}
                >
                  {formatAddress(deployer)}
                  <span className={styles.icn_external}>
                    <IcnSmallExternal />
                  </span>
                </ExternalLink>
                {!!deployedAt &&
                  (!!deploymentTransactionHash ? (
                    <ExternalLink
                      className={cn(styles.deployment_tx_hash, styles.time)}
                      label={'deployment_transaction_hash'}
                      onClick={() => {
                        analytics.track(REDIRECT_CLICKED, {
                          chain,
                          location: REDIRECT_CLICKED_LOCATION.CONTRACT_INFO,
                          type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
                          url: `https://etherscan.io/tx/${deploymentTransactionHash}`,
                        });
                      }}
                      role="button"
                      url={`https://etherscan.io/tx/${deploymentTransactionHash}`}
                    >
                      <TimeAgo date={new Date(deployedAt)} />
                      <span className={styles.icn_external}>
                        <IcnSmallExternal />
                      </span>
                    </ExternalLink>
                  ) : (
                    <span className={styles.time}>
                      <TimeAgo date={new Date(deployedAt)} />
                    </span>
                  ))}
              </p>
            )
          )}
          {contractInfoResult.isLoading ? (
            <Skeleton width={280} />
          ) : (
            <p>
              {!!firstMint && (
                <>
                  First minted &nbsp;
                  <span className={styles.time}>
                    <TimeAgo date={new Date(firstMint)} />
                  </span>
                </>
              )}
              {!!lastMintedAt && (
                <>
                  {!!firstMint && `, `}
                  Last minted &nbsp;
                  <span className={styles.time}>
                    <TimeAgo date={new Date(lastMintedAt)} highlight />
                  </span>
                </>
              )}
            </p>
          )}
        </div>
        {!contractInfoResult.isLoading &&
          typeof deployer === 'string' &&
          deployer === user?.address && (
            <div className={styles.etc_container}>
              <Popover
                render={() => (
                  <div className="default_popover">
                    <span>{`Customize`}</span>
                  </div>
                )}
                animation
                placement="top"
              >
                <button
                  className={cn('btn_icon', styles.btn_customize)}
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push(
                      {
                        pathname: `/collection/[chain]/[address]/customize`,
                        query: { address, chain: chain?.toLowerCase() },
                      },
                      undefined,
                      {
                        shallow: true,
                      }
                    );
                  }}
                >
                  <IcnSetting />
                </button>
              </Popover>
            </div>
          )}
      </div>
      {!contractInfoResult.isLoading && announcements.length > 0 && (
        <div className={styles.announcement_container}>
          <Announcement announcements={announcements} />
        </div>
      )}
    </SectionLayout>
  );
});
