import cn from 'classnames';
import type { MouseEvent } from 'react';

import { IcnChainEthereum, IcnSetting } from '~/assets';
import { Popover, Thumbnail } from '~/components';
import { AVAILABLE_CHAIN, IMAGE_SIZE } from '~/constants';
import type { MyContract } from '~/types';

import styles from './MyContractItem.module.scss';

interface MyCollectionItemProps extends MyContract {
  onClick: (e: MouseEvent<HTMLLIElement>) => void;
  onClickCustomize: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function MyCollectionItem({
  address,
  imageUrl,
  name,
  onClick,
  onClickCustomize,
}: MyCollectionItemProps) {
  return (
    <li className={styles.container} key={address} onClick={onClick}>
      <div className={styles.thumbnail_container}>
        <Thumbnail size={IMAGE_SIZE.HUGE} url={imageUrl} />
      </div>
      <div className={styles.info_container}>
        <div className={styles.name_container}>
          <div className={styles.chain}>
            <Popover
              render={() => (
                <div className="default_popover">
                  <span>{AVAILABLE_CHAIN.ETHEREUM}</span>
                </div>
              )}
              animation
              placement="top"
            >
              <div className={styles.chain_ethereum}>
                <IcnChainEthereum />
              </div>
            </Popover>
          </div>
          <span className={styles.name}>{name}</span>
        </div>
        <span className={styles.address}>{address}</span>
        <div className={styles.etc_container}>
          <Popover
            render={() => (
              <div className="default_popover">
                <span>{`Customize`}</span>
              </div>
            )}
            animation
            placement="top"
          >
            <button
              className={cn('btn_icon', styles.btn_customize)}
              onClick={onClickCustomize}
            >
              <IcnSetting />
            </button>
          </Popover>
        </div>
      </div>
    </li>
  );
}
