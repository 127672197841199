import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { FILTER_CLEAR_ALL } from '~/constants';
import type { SelectItem } from '~/types';

import styles from './SelectButton.module.scss';

import SelectButton from './index';

interface SelectButtonGroupProps<T> {
  items: SelectItem<T>[];
  onItemClicked: (
    selectedItems: SelectItem<T>[],
    selectedItem?: SelectItem<T>
  ) => void;
  clickable?: boolean;
  flat?: boolean;
  multi?: boolean;
  resetClear?: boolean;
  showClear?: boolean;
}

export default function SelectButtonGroup<T>({
  items,
  onItemClicked,
  clickable = true,
  flat = false,
  multi = false,
  resetClear = false,
  showClear = false,
}: SelectButtonGroupProps<T>) {
  const [selectedItems, setSelectedItems] = useState<SelectItem<T>[]>(
    items.filter(({ selected = false }) => selected)
  );

  const handleClearSelect = () => {
    onItemClicked([]);
    setSelectedItems([]);
  };

  const handleSelect = (item: SelectItem<T>) => {
    if (!clickable) return;
    if (multi) {
      setSelectedItems((prev) => {
        let nextSelectedItems: SelectItem<T>[];

        if (prev.some((prevItem) => prevItem.key === item.key)) {
          nextSelectedItems = prev.filter(
            (prevItem) => prevItem.key !== item.key
          );
        } else {
          nextSelectedItems = [...prev, item];
        }
        onItemClicked(nextSelectedItems, item);

        return nextSelectedItems;
      });
    } else {
      setSelectedItems([item]);
      onItemClicked([item], item);
    }
  };

  const isItemSelected = useCallback(
    (item: SelectItem<T>) =>
      selectedItems.some((selectedItem) => selectedItem.key === item.key),
    [selectedItems]
  );

  useEffect(() => {
    if (resetClear) {
      setSelectedItems([]);
    }
  }, [resetClear]);

  return (
    <ul
      className={cn(styles.select_button_group, {
        [styles.flat]: flat,
        [styles.multi]: multi,
      })}
    >
      {items.map(
        (item) =>
          !item.hide && (
            <SelectButton
              key={item.key}
              active={isItemSelected(item)}
              onClick={() => handleSelect(item)}
              popover={item.popover}
            >
              {item.label}
            </SelectButton>
          )
      )}
      {showClear && (
        <li>
          <button
            className={styles.clear_button}
            onClick={(e) => {
              e.stopPropagation();
              handleClearSelect();
            }}
            disabled={selectedItems.length === 0}
          >
            {FILTER_CLEAR_ALL}
          </button>
        </li>
      )}
    </ul>
  );
}
