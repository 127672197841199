import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { IcnHint } from '~/assets';
import { Popover } from '~/components';
import { DETAIL_LAYOUT, RENDERING_BOUND_TIME } from '~/constants';
import type { ContractEntry } from '~/types';
import formatEthPrice from '~/utils/formatEthPrice';
import noExponents from '~/utils/noExponents';

import styles from './AverageEntryPointSection.module.scss';
import SectionLayout from './SectionLayout';

interface AverageEntryPointSectionProps {
  entry: ContractEntry;
  isFetched: boolean;
  isLoading: boolean;
}

export default memo(function AverageEntryPointSection({
  entry,
  isFetched,
  isLoading,
}: AverageEntryPointSectionProps) {
  const { averageEntry, averageTransactionFee, averageValue, totalSupply } = {
    ...entry,
  };
  const [enableBound, setEnableBound] = useState(false);

  useEffect(() => {
    const boundTimer = () =>
      setTimeout(() => {
        setEnableBound(true);
      }, RENDERING_BOUND_TIME);

    if (isFetched) {
      boundTimer();
    } else {
      clearTimeout(boundTimer());
      setEnableBound(false);
    }
  }, [isFetched]);

  return (
    <SectionLayout
      boundTrigger={''}
      enableBound={enableBound}
      id={'average_entry_point'}
    >
      <div className={styles.section_title_container}>
        <span className={styles.section_title}>
          {isLoading ? (
            <Skeleton width={198} />
          ) : (
            DETAIL_LAYOUT.AVERAGE_ENTRY_POINT
          )}
        </span>
        {isFetched && (
          <Popover
            render={() => (
              <div className="hint_popover">
                <span>{'Numbers are rounded up to 4 decimal places.'}</span>
              </div>
            )}
            animation
            placement="top"
            showArrow
          >
            <div className="center">
              <IcnHint />
            </div>
          </Popover>
        )}
      </div>
      {isLoading ? (
        <Skeleton height={106} />
      ) : (
        <div className={styles.contents_container}>
          {(averageEntry ?? '0').length > 6 ? (
            <Popover
              render={() => (
                <div className="price_popover_container">
                  <span className="price_popover_content">
                    {`Ξ ${noExponents(+(averageEntry ?? '0'))}`}
                  </span>
                </div>
              )}
              delay={{ open: 1, close: 1 }}
              placement="right-end"
            >
              <span className={styles.total_cost}>
                {`≈ ${formatEthPrice(+(averageEntry ?? '0'))} (Ξ)`}
              </span>
            </Popover>
          ) : (
            <span className={styles.total_cost}>
              {`${formatEthPrice(+(averageEntry ?? '0'))} (Ξ)`}
            </span>
          )}
          <span className={styles.cost_info}>
            {`Mint: ${noExponents(+(averageValue ?? '0'))} + Gas: ${noExponents(
              +(averageTransactionFee ?? '0')
            )}`}
          </span>
          {totalSupply && (
            <span className={styles.total_minted}>
              {`each for ${totalSupply.toLocaleString()} minted`}
            </span>
          )}
        </div>
      )}
    </SectionLayout>
  );
});
