import { Ranger, useRanger } from '@tanstack/react-ranger';
import cn from 'classnames';
import { useEffect, useRef } from 'react';

import { IcnSmallDraggable } from '~/assets';
import styleVariables from '~/styles/variables.module.scss';

import styles from './RangeSlider.module.scss';

interface RangeSliderProps {
  disabled: boolean;
  max: number;
  min: number;
  setValue: (value: number) => void;
  stepSize?: number;
  value: number;
}

export default function RangeSlider({
  disabled,
  max,
  min,
  setValue,
  stepSize = 1,
  value,
}: RangeSliderProps) {
  const rangerRef = useRef<HTMLDivElement>(null);

  const rangerInstance = useRanger<HTMLDivElement>({
    getRangerElement: () => rangerRef.current,
    max,
    min,
    onDrag: (instance: Ranger<HTMLDivElement>) =>
      setValue(Math.max(instance.sortedValues[0], 1)),
    stepSize,
    values: [value],
  });

  const handler = rangerInstance.handles()[0];
  const { isActive } = handler;

  useEffect(() => {
    if (isActive) {
      const custom = document.createElement('style');
      custom.innerHTML = `*{cursor: grabbing!important}`;
      custom.id = 'cursor-style';
      document.head.appendChild(custom);

      return () => {
        custom.remove();
      };
    }
  }, [isActive]);

  return (
    <div
      ref={rangerRef}
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '100%',
        userSelect: 'none',
        height: '4px',
        borderRadius: '2px',
      }}
    >
      {rangerInstance.getSteps().map(({ left, width }, i) => (
        <div
          key={i}
          style={{
            position: `absolute`,
            left: `${left}%`,
            width: `${width}%`,
            height: `100%`,
            backgroundColor: disabled
              ? styleVariables.gray500
              : i === 0
              ? styleVariables.brand500
              : styleVariables.gray800,
            borderRadius: `2px`,
            opacity: i === 0 ? 1 : 0.38,
          }}
        />
      ))}
      <button
        className={cn(styles.btn_sweep, {
          [styles.dragging]: !disabled && isActive,
          [styles.disabled]: disabled,
        })}
        disabled={disabled}
        onKeyDown={handler.onKeyDownHandler}
        onMouseDown={handler.onMouseDownHandler}
        onTouchStart={handler.onTouchStart}
        role="slider"
        aria-valuemin={rangerInstance.options.min}
        aria-valuemax={rangerInstance.options.max}
        aria-valuenow={handler.value}
        style={{
          position: 'absolute',
          top: '50%',
          left: `${rangerInstance.getPercentageForValue(handler.value)}%`,
          transform: 'translate(-50%, -50%)',
        }}
      >
        <IcnSmallDraggable />
      </button>
    </div>
  );
}
