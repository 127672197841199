import cn from 'classnames';

import { IcnRemove } from '~/assets';
import { Loading } from '~/components';
import styleVariables from '~/styles/variables.module.scss';
import type { StepComponent } from '~/types';

import styles from '../NotificationsSection.module.scss';

interface RegisteredProps extends StepComponent {
  isDeleting: boolean;
  setIsDeleting: (isDeleting: boolean) => void;
  registeredUrl?: string;
}

export default function Registered({
  isDeleting,
  isLoading,
  onNext,
  registeredUrl,
  setIsDeleting,
}: RegisteredProps) {
  return (
    <div className={styles.provider_setting}>
      <div className={cn(styles.input_container, styles.input_disabled)}>
        <input className={styles.input} disabled value={registeredUrl} />
        <div className={styles.input_buttons_container}>
          {isDeleting ? (
            <>
              <button
                className={styles.btn_cancel}
                onClick={() => setIsDeleting(false)}
              >
                {'Cancel'}
              </button>
              <button className={styles.btn_delete} onClick={onNext}>
                {isLoading ? (
                  <Loading color={styleVariables.white} size={16} />
                ) : (
                  'Delete'
                )}
              </button>
            </>
          ) : (
            <button
              className={styles.btn_icn}
              onClick={() => setIsDeleting(true)}
            >
              <IcnRemove />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
