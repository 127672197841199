import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  IcnAppSettings,
  IcnLogo,
  IcnNotificationRead,
  IcnNotificationUnread,
} from '~/assets';
import {
  Account,
  GasTracker,
  NotificationPopup,
  Popover,
  SearchBar,
} from '~/components';
import config from '~/config';
import { useAuth } from '~/contexts/AuthContext';
import {
  appNotificationModalState,
  appNotificationState,
  appSettingModalState,
} from '~/store/app';

import styles from './Navbar.module.scss';
import NavItem from './NavItem';

interface NavbarProps {}

export default memo(function Navbar({}: NavbarProps) {
  const { isAuthenticated, user } = useAuth();
  const [appNotificationModal, setAppNotificationModal] = useRecoilState(
    appNotificationModalState
  );
  const { pathname } = useRouter();
  const setAppSettingModal = useSetRecoilState(appSettingModalState);
  const appNotification = useRecoilValue(appNotificationState);

  useEffect(() => {
    if (!!user) {
      const { unreadNotificationCount } = user;
      const isUnread = unreadNotificationCount > 0;

      setAppNotificationModal((appNotification) => ({
        ...appNotification,
        isUnread,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (!!appNotification && Notification.permission === 'granted') {
      const { data, notification } = appNotification;
      const { body, image, title = 'CatchMint' } = { ...notification };
      const notify = new Notification(title, {
        body,
        icon: image,
        data,
      });
      notify.addEventListener('click', () => {
        if (data?.targetUrl) {
          window
            .open(`${config.APP_HOST}/${data.targetUrl}`, '_blank')
            ?.focus();
        }
        notify.close();
      });
    }
  }, [appNotification]);

  return (
    <>
      <nav className={styles.container}>
        <div className={styles.left_container}>
          <Link className={styles.logo} href="/">
            <IcnLogo />
          </Link>
          <div className={styles.nav_container}>
            <NavItem
              isActive={/^\/comments(\/(\[[a-z]{1,}\]|[0-9a-zA-Z]{1,}))?$/.test(
                pathname
              )}
              name="Comments"
              shallow
              to="/comments/new"
            />
            <NavItem
              isActive={/^\/groups(\/(\[[a-z]{1,}\]|[0-9a-zA-Z]{1,}))?$/.test(
                pathname
              )}
              name="Groups"
              shallow
              to="/groups"
            />
          </div>
        </div>
        {config.MAINTENANCE_MODE === 'false' && (
          <div className={styles.center_container}>
            <SearchBar />
          </div>
        )}
        <div className={styles.right_container}>
          {config.MAINTENANCE_MODE === 'false' && (
            <>
              <GasTracker />
              {isAuthenticated && (
                <div className={styles.nav_button_container}>
                  <Popover
                    render={() => (
                      <div className="default_popover">
                        <span>{'Notifications'}</span>
                      </div>
                    )}
                    animation
                    placement="bottom"
                  >
                    <button
                      className={cn('btn_icon', styles.nav_button)}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAppNotificationModal((appNotification) => ({
                          ...appNotification,
                          isOpened: !appNotification.isOpened,
                        }));
                      }}
                    >
                      {appNotificationModal.isUnread ? (
                        <IcnNotificationUnread />
                      ) : (
                        <IcnNotificationRead />
                      )}
                    </button>
                  </Popover>
                  {appNotificationModal.isOpened && <NotificationPopup />}
                </div>
              )}
              <Popover
                render={() => (
                  <div className="default_popover">
                    <span>{'Settings'}</span>
                  </div>
                )}
                animation
                placement="bottom"
              >
                <button
                  className={cn('btn_icon', styles.nav_button)}
                  onClick={(e) => {
                    setAppSettingModal({ history: [0], isOpened: true });
                    e.stopPropagation();
                  }}
                >
                  <IcnAppSettings />
                </button>
              </Popover>
              <Account />
            </>
          )}
        </div>
      </nav>
    </>
  );
});
