import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { IcnVerified } from '~/assets';
import { CountUp, Thumbnail } from '~/components';
import {
  COLLECTION_CLICKED_LOCATION,
  RENDERING_BOUND_TIME,
  TRANSITION_TIME,
  UNIDENTIFIED_CONTRACT,
} from '~/constants';
import { usePrevious } from '~/hooks/usePrevious';
import type { MintsOverviewData, MintsOverviewFilterTimeOption } from '~/types';

import styles from './MintsOverviewItem.module.scss';

interface MintsOverviewItemProps {
  data: MintsOverviewData;
  enableBound: boolean;
  filter: MintsOverviewFilterTimeOption;
  onClickItem: () => void;
  rank: number;
  blur?: boolean;
}

export default function MintsOverviewItem({
  data: {
    address,
    chain,
    counts,
    name,
    imageUrl,
    isVerified,
    maxSupply,
    simulationPassed,
    totalCounts,
    totalSupply,
  },
  enableBound,
  filter,
  onClickItem,
  rank,
  blur = false,
  // For using react-flip-toolkit
  ...rest
}: MintsOverviewItemProps) {
  const highestCount = counts ? Math.max(...counts) : 0;
  const boundTimeoutRef = useRef<undefined | number>(undefined);
  const noTransitionTimeoutRef = useRef<undefined | number>(undefined);
  const prevTotalCounts = usePrevious(totalCounts) ?? 0;
  const [isBound, setIsBound] = useState(enableBound);
  const [noTransition, setNoTransition] = useState(!enableBound);
  const isMintable = maxSupply !== totalSupply && simulationPassed;

  const onClick = () => {
    if (enableBound) {
      const params: { [key: string]: any } = {
        chain,
        contract_address: address,
        filter: `${+filter / 60}m`,
        location: COLLECTION_CLICKED_LOCATION.MINTS_OVERVIEW,
        position: rank,
      };
      if (!!name) {
        params['collection_name'] = name;
      }
      onClickItem();
    }
  };

  useEffect(() => {
    if (enableBound && prevTotalCounts !== totalCounts && totalCounts > 0) {
      setNoTransition(false);
    }
  }, [enableBound, prevTotalCounts, totalCounts]);

  useEffect(() => {
    if (!noTransition) {
      setIsBound(true);
    }
  }, [noTransition]);

  useEffect(() => {
    if (isBound) {
      boundTimeoutRef.current = window.setTimeout(
        () => setIsBound(false),
        RENDERING_BOUND_TIME
      );
      return () => window.clearTimeout(boundTimeoutRef.current);
    } else {
      noTransitionTimeoutRef.current = window.setTimeout(
        () => setNoTransition(true),
        TRANSITION_TIME - RENDERING_BOUND_TIME
      );
      return () => window.clearTimeout(noTransitionTimeoutRef.current);
    }
  }, [isBound]);

  return (
    <li
      {...rest}
      className={cn(styles.container, {
        [styles.bound]: !blur && isBound,
        [styles.data_received]: enableBound,
        [styles.no_transition]: noTransition,
      })}
      onClick={onClick}
    >
      <div className={styles.information_container}>
        <span className={styles.rank}>{totalCounts ? rank : <Skeleton />}</span>
        <div className={styles.thumbnail_container}>
          <Thumbnail
            isBlurred={blur}
            isLoading={!enableBound}
            name={name?.trim().length > 0 ? name : UNIDENTIFIED_CONTRACT}
            url={imageUrl}
          />
        </div>
        <div
          className={cn(styles.text_information_container, {
            [styles.blur]: blur,
          })}
        >
          <div className={styles.contract_name_container}>
            {address ? (
              <span className={styles.contract_name}>
                {name?.trim().length > 0 ? name : UNIDENTIFIED_CONTRACT}
              </span>
            ) : (
              <Skeleton width={110} />
            )}
          </div>
          <div className={styles.etc_info_container}>
            <p className={styles.mints_amount}>
              {totalCounts ? (
                blur ? (
                  `${totalCounts.toLocaleString()} mints`
                ) : (
                  <CountUp
                    end={totalCounts}
                    duration={0.5}
                    separator=","
                    suffix=" mints"
                  />
                )
              ) : (
                <Skeleton width={60} />
              )}
            </p>
            {isMintable && (
              <>
                <div className={styles.extra_divider} />
                <span className={styles.mintable}>Mintable</span>
              </>
            )}
            {isVerified && (
              <>
                <div className={styles.extra_divider} />
                <div className={styles.verified_container}>
                  <IcnVerified />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {!blur && (
        <div className={styles.graph_container}>
          {counts ? (
            counts.map((count, index) => (
              <div
                key={index}
                className={styles.bar}
                style={{
                  height: `${+(count / highestCount).toFixed(2) * 26}px`,
                }}
              />
            ))
          ) : (
            <Skeleton width={39} height={26} />
          )}
        </div>
      )}
    </li>
  );
}
