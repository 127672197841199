import { Loading } from '~/components';
import styleVariables from '~/styles/variables.module.scss';
import type { StepComponent } from '~/types';

import styles from '../NotificationsSection.module.scss';

interface RegisterUrlProps extends StepComponent {
  canSubmit: boolean;
  setUrl: (username: string) => void;
  url: string;
}

export default function RegisterUrl({
  canSubmit,
  isLoading,
  onNext,
  setUrl,
  url,
}: RegisterUrlProps) {
  return (
    <div className={styles.provider_setting}>
      <div className={styles.input_container}>
        <input
          className={styles.input}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="https://discord.com/api/webhooks/..."
          value={url}
        />
        <div className={styles.input_buttons_container}>
          <button
            className={styles.btn_submit}
            disabled={!canSubmit}
            onClick={onNext}
          >
            {isLoading ? (
              <Loading color={styleVariables.gray800} size={16} />
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
