import { useContext } from 'react';

import { SocketContext } from './context';

export const useSocket = () => {
  const context = useContext(SocketContext);
  return context;
};

export default useSocket;
