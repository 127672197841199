const config = {
  ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY as string,
  ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
  API_HOST: process.env.NEXT_PUBLIC_API_HOST as string,
  APP_ENV: process.env.NEXT_PUBLIC_APP_ENV as string,
  APP_HOST: process.env.NEXT_PUBLIC_APP_HOST as string,
  APP_IMAGE_HOST: process.env.NEXT_PUBLIC_APP_IMAGE_HOST as string,
  ASSETS_HOST: process.env.NEXT_PUBLIC_ASSETS_HOST as string,
  FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY as string,
  FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
  FIREBASE_SENDER_ID: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID as string,
  FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID as string,
  FIREBASE_VAPID_KEY: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY as string,
  MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE as string,
  SEGMENT_CDN_URL: process.env.NEXT_PUBLIC_SEGMENT_CDN_URL as string,
  SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string,
  SOCKETI_HOST: process.env.NEXT_PUBLIC_SOCKETI_HOST as string,
  SOCKETI_KEY: process.env.NEXT_PUBLIC_SOCKETI_KEY as string,
  WALLET_CONNECT_PROJECT_ID: process.env
    .NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID as string,
};

export default config;
