import { Reorder } from 'framer-motion';
import _isEqual from 'lodash/isEqual';
import { type Dispatch, type SetStateAction, useCallback } from 'react';

import { DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT } from '~/constants';
import type { ContractQuickLink, LayoutSetting } from '~/types';

import QuicklinkOrder from './QuicklinkOrder';
import QuicklinksExample from './QuicklinksExample';
import styles from './QuicklinksSection.module.scss';
import commonStyles from '../SettingsModal.module.scss';

interface QuicklinksSectionProps {
  quickLinksLayout: LayoutSetting<ContractQuickLink>[];
  setQuickLinksLayout: Dispatch<
    SetStateAction<LayoutSetting<ContractQuickLink>[]>
  >;
}

export default function QuicklinksSection({
  quickLinksLayout,
  setQuickLinksLayout,
}: QuicklinksSectionProps) {
  const toggleVisibility = useCallback((index: number) => {
    setQuickLinksLayout((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        visible: !updated[index].visible,
      };
      return updated;
    });
  }, []);

  const resetToDefault = () => {
    setQuickLinksLayout(DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT);
  };

  return (
    <section className={commonStyles.section}>
      <div className={commonStyles.section_container}>
        <div className={commonStyles.section_header}>
          <div className={commonStyles.section_title_container}>
            <div className={commonStyles.section_title}>
              <span>{'Quicklinks Ordering'}</span>
              <button
                className={commonStyles.btn_reset}
                onClick={resetToDefault}
                disabled={_isEqual(
                  quickLinksLayout,
                  DEFAULT_CONTRACT_QUICK_LINKS_LAYOUT
                )}
              >
                {`Reset to Default`}
              </button>
            </div>
            <p className={commonStyles.section_description}>
              {`Re-order quicklinks on the collection page.`}
            </p>
            <QuicklinksExample quickLinks={quickLinksLayout} />
            <p className={commonStyles.section_description}>
              {`Drag the components left & right for ordering. Click on the component to activate/deactivate.`}
            </p>
          </div>
        </div>
        <Reorder.Group
          id="quicklinks"
          axis="x"
          className={styles.layout_order_container}
          onReorder={setQuickLinksLayout}
          values={quickLinksLayout}
        >
          {quickLinksLayout.map((quickLink, index) => (
            <QuicklinkOrder
              key={quickLink.name}
              index={index}
              item={quickLink}
              toggleVisibility={toggleVisibility}
            />
          ))}
        </Reorder.Group>
      </div>
    </section>
  );
}
