import { forwardRef, MouseEvent, useCallback, useState } from 'react';

import { CommentItem } from '~/components';
import {
  DEFAULT_FOLDED_FLAG_SCORE,
  DEFAULT_SHOWING_COMMENTS,
} from '~/constants';
import { useAuth } from '~/contexts/AuthContext';
import type { Comment, FlagParams, VoteParams } from '~/types';
import scrollIntoViewById from '~/utils/scrollIntoViewById';

import styles from './CommentList.module.scss';

interface CommentListProps {
  comments: Comment[];
  handleAddReply: (
    text: string,
    parentId: string,
    close: () => void
  ) => Promise<boolean>;
  handleDelete: (uuid: string, close: () => void) => Promise<boolean>;
  handleDownvote: (uuid: string, close: () => void) => Promise<boolean>;
  handleEdit: (
    uuid: string,
    text: string,
    close: () => void
  ) => Promise<boolean>;
  handleFlag: (flagParams: FlagParams, close: () => void) => Promise<boolean>;
  handlePin: (uuid: string, close: () => void) => Promise<boolean>;
  handleUnflag: (flagParams: FlagParams, close: () => void) => Promise<boolean>;
  handleUnpin: (uuid: string, close: () => void) => Promise<boolean>;
  handleUnvote: (voteParams: VoteParams, close: () => void) => Promise<boolean>;
  handleUnvouch: (uuid: string, close: () => void) => Promise<boolean>;
  handleUpvote: (uuid: string, close: () => void) => Promise<boolean>;
  handleVouch: (uuid: string, close: () => void) => Promise<boolean>;
  isUserDeployer: boolean;
  totalCount: number;
  hash?: string;
}

export default forwardRef<HTMLButtonElement, CommentListProps>(
  function CommentList(
    {
      comments,
      handleAddReply,
      handleDelete,
      handleDownvote,
      handleEdit,
      handleFlag,
      handlePin,
      handleUnflag,
      handleUnpin,
      handleUnvote,
      handleUnvouch,
      handleUpvote,
      handleVouch,
      hash,
      isUserDeployer,
      totalCount,
    },
    ref
  ) {
    const { isAuthenticated, user } = useAuth();
    const [foldedPathIds, setFoldedPathIds] = useState<string[]>(
      comments
        .filter((comment) => comment.flagScore <= DEFAULT_FOLDED_FLAG_SCORE)
        .map((flaggedComment) => `comment_${flaggedComment.sortPath.join('_')}`)
    );
    const [showAllComments, setShowAllComments] = useState(!!hash);

    const getNextPathId: (
      standardIndex: number,
      standardDepth: number
    ) => [string | undefined, number] = useCallback(
      (standardIndex: number, standardDepth: number) => {
        let i = standardIndex + 1;
        let pathId;
        for (i; i < comments.length; i++) {
          const { sortPath } = comments[i];
          const depth = sortPath.length;
          if (depth === standardDepth || depth < standardDepth) {
            pathId = sortPath.join('_');
            break;
          }
        }
        return [pathId, i - standardIndex];
      },
      [comments]
    );

    const handleFold = useCallback((pathId: string) => {
      setFoldedPathIds((prev) => [...prev, pathId]);
    }, []);

    const toggleComments = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setShowAllComments((prev) => !prev);
      if (showAllComments) {
        scrollIntoViewById('discussions');
      }
    };

    const handleUnfold = useCallback((pathId: string) => {
      setFoldedPathIds((prev) =>
        prev.filter((prevPathId) => prevPathId !== pathId)
      );
    }, []);

    const isInFolded = (sortPath: number[]) => {
      const depth = sortPath.length;
      if (depth === 1) return false;
      for (let i = depth; i > 1; i--) {
        const pathId = `comment_${sortPath.slice(0, i - 1).join('_')}`;
        if (foldedPathIds.includes(pathId)) {
          return true;
        }
      }
      return false;
    };

    const renderComment = (comment: Comment, index: number) => {
      const depth = comment.sortPath.length;
      const isFolded = isInFolded(comment.sortPath);
      const isMine = isAuthenticated && comment.username === user?.username;
      const pathId = `comment_${comment.sortPath.join('_')}`;
      const isFoldedRoot = foldedPathIds.includes(pathId);
      const hasReply =
        index + 1 < comments.length &&
        depth === comments[index + 1].sortPath.length - 1;
      const isLast =
        !comments.map((comment) => comment.sortPath[0]).includes(2) ||
        index === comments.length - 1;
      const [, childrenCount] = isLast
        ? [undefined, 1]
        : getNextPathId(index, depth);
      const highlight = hash === comment.uuid;

      return (
        <CommentItem
          key={comment.uuid}
          childrenCount={childrenCount}
          comment={comment}
          hasReply={hasReply}
          highlight={highlight}
          isFolded={isFolded}
          isFoldedRoot={isFoldedRoot}
          isMine={isMine}
          isUserDeployer={isUserDeployer}
          myKarma={user?.karma ?? 0}
          onAddReply={handleAddReply}
          onDelete={handleDelete}
          onDownvote={handleDownvote}
          onEdit={handleEdit}
          onFlag={handleFlag}
          onFold={handleFold}
          onPin={handlePin}
          onUnflag={handleUnflag}
          onUnfold={handleUnfold}
          onUnpin={handleUnpin}
          onUnvote={handleUnvote}
          onUnvouch={handleUnvouch}
          onUpvote={handleUpvote}
          onVouch={handleVouch}
          pathId={pathId}
        />
      );
    };

    return (
      <>
        <ul className={styles.container}>
          {(showAllComments
            ? comments
            : comments.slice(0, DEFAULT_SHOWING_COMMENTS)
          ).map((comment, index) => renderComment(comment, index))}
        </ul>
        {totalCount > 3 &&
          (showAllComments ? (
            <button
              className={styles.btn_read_more}
              onClick={toggleComments}
              ref={ref}
            >
              {`Read less`}
            </button>
          ) : (
            <button className={styles.btn_read_more} onClick={toggleComments}>
              {`Read other ${totalCount - DEFAULT_SHOWING_COMMENTS} comment${
                totalCount - DEFAULT_SHOWING_COMMENTS > 1 ? 's' : ''
              }`}
            </button>
          ))}
      </>
    );
  }
);
