import cn from 'classnames';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import API from '~/api';
import { IcnHide, IcnSmallCopied } from '~/assets';
import { Loading, Toaster } from '~/components';
import { UNEXPECTED_ERROR_MESSAGE } from '~/constants';
import useSearch from '~/hooks/useSearch';

import styles from './UsernameSearchBar.module.scss';

interface UsernameSearchBarProps {
  username: string;
  setUsername: Dispatch<
    SetStateAction<{
      username: string;
    }>
  >;
  setError: Dispatch<SetStateAction<string>>;
}

export default function UsernameSearchBar({
  username,
  setUsername,
  setError,
}: UsernameSearchBarProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [canSetUsername, setCanSetUsername] = useState(false);
  const searchUsername = useCallback(
    (name: string) => {
      setError('');
      setIsLoading(true);
      setUsername((prev) => ({ ...prev, username: name }));
      if (name === username) {
        setCanSetUsername(false);
        setIsFetched(false);
        setIsLoading(false);
        return;
      }
      if (name.length < 5) {
        setCanSetUsername(false);
        setError('Must be at least 5 characters');
        setIsLoading(false);
        return;
      }
      if (!usernameValidator(name)) {
        setCanSetUsername(false);
        setError(
          'Required. 15 characters or fewer. Letters, digits and -/_ only.'
        );
        setIsLoading(false);
        return;
      }
      API.checkUsername(name)
        .then(() => {
          setCanSetUsername(false);
          setError(`'${name}' is already taken.`);
        })
        .catch((err: any) => {
          if (err?.response?.status) {
            if (err.response.status === 404) {
              setCanSetUsername(true);
              setUsername((prev) => ({ ...prev, username: name }));
            }
          } else if (err?.response?.data?.error?.detail) {
            Toaster.toast({
              description: err.response.data.error.detail,
              type: 'error',
            });
          } else {
            Toaster.toast({
              description: UNEXPECTED_ERROR_MESSAGE,
              type: 'error',
            });
          }
        })
        .finally(() => {
          setIsFetched(true);
          setIsLoading(false);
        });
    },
    [username]
  );

  const usernameValidator: (username: string) => boolean = (
    username: string
  ) => {
    const pattern = /^[a-zA-Z0-9_]{5,15}$/;

    return pattern.test(username);
  };

  const { keyword, onBlur, onChange, onKeyDown, ref } = useSearch({
    callback: searchUsername,
    defaultValue: username,
  });

  return (
    <div className={styles.username_search_bar_container}>
      <input
        className={styles.username_search_bar_input}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={ref}
        maxLength={32}
      />
      {isFetched && (
        <div
          className={cn(styles.search_status_container, {
            [styles.possible]:
              keyword.length > 0 && !isLoading && canSetUsername,
            [styles.impossible]:
              keyword.length > 0 && !isLoading && !canSetUsername,
          })}
        >
          {isLoading && <Loading />}
          {keyword.length > 0 && !isLoading && canSetUsername && (
            <IcnSmallCopied />
          )}
          {keyword.length > 0 && !isLoading && !canSetUsername && <IcnHide />}
        </div>
      )}
    </div>
  );
}
