import _isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { APP_SETTINGS_MODIFIED } from '~/constants';
import { groupActivityFilterState } from '~/store/app';
import type { NotableGroupActivityFilter } from '~/types';

export default function useNotableGroupActivityFilter() {
  const [filter, setFilter] = useRecoilState(groupActivityFilterState);

  const updateFilter = useCallback(
    (updatedFilter: Partial<NotableGroupActivityFilter>) => {
      setFilter((prev) => {
        if (_isEqual(prev, updatedFilter)) {
          return prev;
        }
        const timestamp = new Date().getTime().toString();
        localStorage.setItem(APP_SETTINGS_MODIFIED, timestamp);

        return {
          ...prev,
          ...updatedFilter,
        };
      });
    },
    []
  );

  return [filter, updateFilter] as const;
}
