import _isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { APP_SETTINGS_MODIFIED } from '~/constants';
import { settingsState } from '~/store/app';
import type { Settings } from '~/types';

export default function useSettings() {
  const [settings, setSettings] = useRecoilState(settingsState);

  const updateSettings = useCallback(
    (updatedSettings: Partial<Settings>, shouldSetModified = true) => {
      setSettings((prev) => {
        if (_isEqual(prev, updatedSettings)) {
          return prev;
        }
        if (shouldSetModified) {
          const timestamp = new Date().getTime().toString();
          localStorage.setItem(APP_SETTINGS_MODIFIED, timestamp);
        }

        return {
          ...prev,
          ...updatedSettings,
        };
      });
    },
    []
  );

  return [settings, updateSettings] as const;
}
