import cn from 'classnames';
import { CSSProperties, MouseEvent, ReactNode, useEffect } from 'react';

import { Portal } from '~/components';

import styles from './Modal.module.scss';

interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  open: boolean;
  full?: boolean;
  maskClosable?: boolean;
  style?: CSSProperties;
}

export default function Modal({
  children,
  onClose,
  open,
  full = false,
  maskClosable = true,
  style,
}: ModalProps) {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (['Escape', 'Esc'].includes(e.key)) {
        onClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onClose]);

  const onMaskClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    onClose();
  };

  return (
    <Portal elementId={'modal-root'}>
      <div
        className={cn(styles.container, {
          [styles.open]: open,
        })}
      >
        <div
          className={styles.inner}
          onMouseDown={(e: MouseEvent<HTMLDivElement>) =>
            maskClosable ? onMaskClick(e) : undefined
          }
        >
          <div
            className={cn(styles.contents_container, {
              [styles.full]: full,
            })}
            style={style}
          >
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
}
