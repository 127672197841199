import cn from 'classnames';
import { Reorder } from 'framer-motion';
import { useState } from 'react';

import { IcnInvisible, IcnVisible } from '~/assets';
import Popover from '~/components/Popover';
import useQuicklinks from '~/hooks/useQuicklinks';
import type { ContractQuickLink, LayoutSetting } from '~/types';

import styles from './QuicklinksSection.module.scss';

interface QuicklinkOrderProps {
  index: number;
  item: LayoutSetting<ContractQuickLink>;
  toggleVisibility: (index: number) => void;
}

export default function QuicklinkOrder({
  index,
  item,
  toggleVisibility,
}: QuicklinkOrderProps) {
  const { icon } = useQuicklinks({ platform: item.name });
  const [isDragging, setIsDragging] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <Reorder.Item
      className={cn(styles.layout_component, {
        [styles.isDragging]: isDragging,
      })}
      onDragEnd={() => setIsDragging(false)}
      onDragStart={() => setIsDragging(true)}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      value={item}
    >
      <Popover
        render={() => (
          <div className="default_popover">
            <span>{item.name}</span>
          </div>
        )}
        animation
        placement="top"
      >
        <div className={styles.quicklink_order}>
          <div
            className={cn(styles.icn_platform, {
              [styles.invisible]: !item.visible || isMouseOver,
            })}
          >
            {icon}
          </div>
          {!item.visible && (
            <button
              className={styles.btn_visible}
              onClick={(e) => {
                e.stopPropagation();
                toggleVisibility(index);
              }}
            >
              <IcnInvisible />
            </button>
          )}
          {item.visible && isMouseOver && (
            <button
              className={styles.btn_visible}
              onClick={(e) => {
                e.stopPropagation();
                toggleVisibility(index);
              }}
            >
              <IcnVisible />
            </button>
          )}
        </div>
      </Popover>
    </Reorder.Item>
  );
}
