import { useQuery } from '@tanstack/react-query';
import { useLayoutEffect, useState } from 'react';

import API from '~/api';
import { APP_NEW_FEATURES_LAST_ID, VERSION_NEW_FEATURES_ID } from '~/constants';
import { newFeaturesKeys } from '~/constants/queryKeys';
import { NewFeature } from '~/types';

export default function useNewFeatures() {
  const [newFeatures, setNewFeatures] = useState<NewFeature[]>([]);

  const queryKey = newFeaturesKeys.all;
  const { data } = useQuery(queryKey, () => API.getNewFeatures(), {
    cacheTime: 1_000 * 60 * 60 * 6,
    // stopped showing feature updates starting in version 0.64.2
    enabled: false,
    staleTime: 1_000 * 60 * 60 * 6,
  });

  const setNewFeaturesLastId = () => {
    localStorage.setItem(APP_NEW_FEATURES_LAST_ID, VERSION_NEW_FEATURES_ID);
  };

  useLayoutEffect(() => {
    if (data) {
      const newFeaturesLastId = localStorage.getItem(APP_NEW_FEATURES_LAST_ID);
      const filteredNewFeatures = data.data.filter((newFeature) =>
        newFeaturesLastId ? newFeature.id > Number(newFeaturesLastId) : true
      );
      setNewFeatures(filteredNewFeatures.reverse());
    }
  }, [data]);

  return {
    setNewFeaturesLastId,
    newFeatures,
  };
}
