import cn from 'classnames';
import { Reorder, useDragControls } from 'framer-motion';
import { ReactNode, useState } from 'react';

import { IcnDraggable } from '~/assets';
import { MAIN_LAYOUT } from '~/constants';
import type { LayoutSetting, MainLayout } from '~/types';

import styles from './LayoutComponent.module.scss';

interface ContractDetailComponentProps {
  item: LayoutSetting<MainLayout>;
  children?: ReactNode;
}

export default function LayoutComponent({
  item,
  children,
}: ContractDetailComponentProps) {
  const dragControls = useDragControls();
  const [isMouseOverToDrag, setIsMouseOverToDrag] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  return (
    <Reorder.Item
      value={item}
      className={cn(styles.layout_component, {
        [styles.contract_detail_layout]:
          item.name === MAIN_LAYOUT.CONTRACT_DETAIL,
        [styles.highlighting]: isMouseOverToDrag || isDragging,
        [styles.dragging]: isDragging,
      })}
      dragListener={false}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      dragControls={dragControls}
    >
      <div
        className={cn(styles.layout_title_container, {
          [styles.dragging]: isDragging,
        })}
      >
        <span>{item.name}</span>
        <button
          className={cn({
            [styles.dragging]: isDragging,
          })}
          onMouseEnter={() => setIsMouseOverToDrag(true)}
          onMouseLeave={() => setIsMouseOverToDrag(false)}
          onPointerDown={(event) => dragControls.start(event)}
        >
          <IcnDraggable />
        </button>
      </div>
      {children}
    </Reorder.Item>
  );
}
