import { IcnSearch } from '~/assets';
import useNotableGroupsMintsVolume from '~/data/useNotableGroupsMintsVolume';
import useSearch from '~/hooks/useSearch';

import styles from './MintsVolumePanel.module.scss';
import NotableGroupMintsVolumeList from './NotableGroupMintsVolumeList';

export default function MintsVolumePanel() {
  const { keyword, onBlur, onChange, onKeyDown, ref } = useSearch({
    defaultValue: '',
    delay: 0,
  });

  const {
    flipKey,
    isLoading,
    notSelectedNotableGroups,
    resetSelectedNotableGroupIds,
    selectedNotableGroups,
    selectedNotableGroupIds,
  } = useNotableGroupsMintsVolume({
    searchKeyword: keyword,
  });

  return (
    <section className={styles.container}>
      <div className={styles.header_container}>
        <span className={styles.header_title}>Notable Groups</span>
        <button
          className={styles.btn_reset}
          disabled={selectedNotableGroupIds.length === 0}
          onClick={resetSelectedNotableGroupIds}
        >
          <span>{'Reset'}</span>
        </button>
      </div>
      <div className={styles.group_search_container}>
        <div className={styles.group_search_input_container}>
          <IcnSearch />
          <input
            className={styles.group_search_input}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder="Search groups"
            ref={ref}
          />
        </div>
      </div>
      <div className={styles.groups_head}>
        <span>Notable Group</span>
        <span>1D Mint Volume</span>
      </div>
      <NotableGroupMintsVolumeList
        flipKey={flipKey}
        isLoading={isLoading}
        notSelectedItems={notSelectedNotableGroups}
        selectedItems={selectedNotableGroups}
      />
    </section>
  );
}
