import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  Side,
  useDismiss,
  useFloating,
  useHover,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  cloneElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import styles from './Popover.module.scss';

interface PopoverProps {
  children: JSX.Element;
  render: (data: {
    close: () => void;
    labelId: string;
    descriptionId: string;
  }) => ReactNode;
  animation?: boolean;
  delay?: number | { open: number; close: number };
  placement?: Placement;
  showArrow?: boolean;
}

export default function Popover({
  children,
  render,
  animation = false,
  delay = 0,
  placement = 'top',
  showArrow = false,
}: PopoverProps) {
  const arrowRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const middleware = [flip(), offset(4), shift()];
  if (showArrow) middleware.push(arrow({ element: arrowRef }));
  const {
    context,
    floating,
    reference,
    refs,
    strategy,
    update,
    x,
    y,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    middleware,
    onOpenChange: setOpen,
    open,
    placement,
    whileElementsMounted: autoUpdate,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context),
    useHover(context, {
      delay,
    }),
    useRole(context),
  ]);
  const arrowCallback = useCallback(
    (node: HTMLDivElement) => {
      arrowRef.current = node;
      update();
    },
    [update]
  );

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0] as Side];

  const id = useId();
  const labelId = `${id}-label`;
  const descriptionId = `${id}-description`;

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [open, update, refs.reference, refs.floating]);

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props })
      )}
      <FloatingPortal id="tooltip">
        {open && (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, scale: 0.85 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0 }}
              transition={
                animation
                  ? // When in "grouped phase", make the transition faster
                    typeof delay === 'object' && delay.open === 1
                    ? { duration: 0.1 }
                    : { type: 'spring', damping: 20, stiffness: 300 }
                  : { duration: 0 }
              }
              {...getFloatingProps({
                className: 'popover',
                ref: floating,
                style: {
                  position: strategy,
                  top: y ? (showArrow ? y - 6 : y) : '',
                  left: x ?? '',
                  zIndex: 1000,
                },
                'aria-labelledby': labelId,
                'aria-describedby': descriptionId,
              })}
            >
              {render({
                labelId,
                descriptionId,
                close: () => {
                  setOpen(false);
                },
              })}
              {showArrow && (
                <div
                  style={{
                    left: arrowX ? arrowX - 4 : '',
                    top: arrowY ?? '',
                    right: '',
                    bottom: '',
                    [staticSide]: '-14px',
                    zIndex: 1000,
                  }}
                  className={cn(styles.arrow)}
                  ref={arrowCallback}
                />
              )}
            </motion.div>
          </AnimatePresence>
        )}
      </FloatingPortal>
    </>
  );
}
