import cn from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';

import { Modal } from '~/components';

import styles from './DefaultModal.module.scss';

interface DefaultModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  titleAlign?: 'left' | 'center';
  buttonText?: ReactNode;
}

export default function DefaultModal({
  children,
  isOpen,
  onClose,
  title,
  titleAlign = 'left',
  buttonText = 'Close',
}: DefaultModalProps) {
  const modalBodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose} style={{ width: 640 }}>
      <div className={styles.default_modal_container}>
        <div
          className={cn(styles.default_modal_title_container, {
            [styles.center]: titleAlign === 'center',
          })}
        >
          <h1 className={styles.default_modal_title}>{title}</h1>
        </div>
        <div className={styles.default_modal_body} ref={modalBodyRef}>
          {children}
        </div>
        <div className={styles.buttons_container}>
          <button className={styles.close_button} onClick={onClose}>
            {buttonText}
          </button>
        </div>
      </div>
    </Modal>
  );
}
