import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import API from '~/api';
import { clusteredKeys } from '~/constants/queryKeys';

export default function useContractHoldersNetwork(addresses: string[]) {
  const queryKey = clusteredKeys.wallets('ethereum', addresses);
  const result = useQuery(
    queryKey,
    () => API.getContractHoldersNetwork(addresses),
    {
      enabled: addresses.length > 0,
    }
  );

  const wallets = useMemo(
    () => [
      ...new Set(
        (result.data?.data ?? []).reduce<string[]>((acc, curr) => {
          acc.push(curr.fromAddress);
          acc.push(curr.toAddress);
          return acc;
        }, [])
      ),
    ],
    [result.data?.data]
  );

  return {
    ...result,
    networkLinks: result.data?.data ?? [],
    wallets,
  };
}
