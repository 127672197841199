import { configureScope } from '@sentry/nextjs';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isSupported } from 'firebase/messaging';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import { ThemeProvider } from 'next-themes';
import { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { RecoilRoot } from 'recoil';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public';
import 'simplebar-react/dist/simplebar.min.css';

import { PrivateRoute, SkeletonTheme, Toaster } from '~/components';
import config from '~/config';
import { APP_THEME, firebaseConfig } from '~/constants';
import { AnalyticsProvider } from '~/contexts/AnalyticsContext';
import { AuthProvider } from '~/contexts/AuthContext';
import { SocketProvider } from '~/contexts/SocketContext';
import '~/styles/globals.scss';

const { publicRuntimeConfig } = getConfig();
const { chains, provider, webSocketProvider } = configureChains(
  [mainnet],
  [publicProvider()]
);

const client = createClient({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        shimDisconnect: false,
      },
    }),
    new MetaMaskConnector({
      chains,
      options: {
        shimDisconnect: false,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        metadata: {
          description: 'Discover the best new NFTs, every day',
          icons: [`${config.APP_HOST}/android-chrome-256x256.png`],
          name: 'CatchMint',
          url: config.APP_HOST,
        },
        projectId: config.WALLET_CONNECT_PROJECT_ID,
        // @ts-ignore
        qrModalOptions: {
          themeVariables: {
            ['--wcm-z-index']: '999',
            ['--wcm-overlay-background-color']: `var(--modal-shadow)`,
          },
        },
        showQrModal: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

function CatchMintApp({ Component, pageProps }: AppProps) {
  dayjs.extend(relativeTime, {
    thresholds: [
      { l: 's', r: 1 },
      { l: 'm', r: 1 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 1 },
      { l: 'hh', r: 23, d: 'hour' },
      { l: 'd', r: 1 },
      { l: 'dd', r: 29, d: 'day' },
      { l: 'M', r: 1 },
      { l: 'MM', r: 11, d: 'month' },
      { l: 'y' },
      { l: 'yy', d: 'year' },
    ],
  });
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: 0,
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  const protectedRoutes = ['/collections'];

  configureScope((scope) => {
    scope.setTag('version', publicRuntimeConfig.version);
  });

  useEffect(() => {
    const registerInit = async () => {
      try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
          navigator.serviceWorker
            // set queries for initializing by environment
            ?.register(`/firebase-messaging-sw.js?${firebaseConfig}`)
            .then((res) => {
              console.log('Service worker registered: ', res.scope);
            })
            .catch((err) => {
              console.error('Service worker registration failed: ', err);
            });
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (document.readyState === 'complete') {
      registerInit();
    } else {
      window.addEventListener('load', registerInit);
      return () => window.removeEventListener('load', registerInit);
    }
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1.0,maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      <AnalyticsProvider writeKey={config.SEGMENT_WRITE_KEY}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <RecoilRoot>
              <ThemeProvider disableTransitionOnChange storageKey={APP_THEME}>
                <SkeletonTheme>
                  <WagmiConfig client={client}>
                    <AuthProvider>
                      <PrivateRoute protectedRoutes={protectedRoutes}>
                        <ReCaptchaProvider useEnterprise>
                          <SocketProvider>
                            <Component {...pageProps} />
                          </SocketProvider>
                        </ReCaptchaProvider>
                      </PrivateRoute>
                      <Toaster />
                    </AuthProvider>
                  </WagmiConfig>
                </SkeletonTheme>
              </ThemeProvider>
            </RecoilRoot>
          </Hydrate>
        </QueryClientProvider>
      </AnalyticsProvider>
    </>
  );
}

export default CatchMintApp;
