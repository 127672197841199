import cn from 'classnames';
import Link from 'next/link';
import type { ReactElement } from 'react';

import styles from './NavItem.module.scss';

interface NavItemProps {
  name: string;
  to: string;
  children?: ReactElement;
  isActive?: boolean;
  isNew?: boolean;
  shallow?: boolean;
  style?: 'border';
}

export default function NavItem({
  children,
  isActive,
  isNew = false,
  name,
  shallow = false,
  style,
  to,
}: NavItemProps) {
  return (
    <div className={styles.container}>
      <Link href={to} passHref shallow={shallow}>
        <div
          className={cn(styles.inner_container, {
            [styles.border]: style === 'border',
            [styles.active]: isActive,
          })}
        >
          {children}
          <div className={styles.name}>{name}</div>
          {isNew && <div className={styles.new}>New</div>}
        </div>
      </Link>
    </div>
  );
}
