import { Flipped, Flipper } from 'react-flip-toolkit';

import { Loading } from '~/components';
import useNotableGroupMintsVolumeList from '~/data/useNotableGroupMintsVolumeList';
import { NotableGroupWithMintsVolume } from '~/types';

import NotableGroupMintsVolumeItem from './NotableGroupMintsVolumeItem';
import styles from './NotableGroupMintsVolumeList.module.scss';

interface NotableGroupMintsVolumeListProps {
  flipKey: string;
  isLoading: boolean;
  notSelectedItems: NotableGroupWithMintsVolume[];
  selectedItems: NotableGroupWithMintsVolume[];
}

export default function NotableGroupMintsVolumeList({
  flipKey,
  isLoading,
  notSelectedItems,
  selectedItems,
}: NotableGroupMintsVolumeListProps) {
  const { containerRef, handleClickItem, totalSize, virtualItems } =
    useNotableGroupMintsVolumeList({
      notSelectedItems,
      selectedItems,
    });

  if (isLoading) {
    return (
      <div className={styles.empty_container}>
        <Loading size={32} />
      </div>
    );
  }

  if (virtualItems.length === 0) {
    return <div className={styles.empty_container}>{'No data found'}</div>;
  }

  return (
    <Flipper
      className={styles.container}
      flipKey={flipKey}
      spring="noWobble"
      staggerConfig={{
        default: {
          speed: 15,
        },
      }}
    >
      <div className={styles.list_container} ref={containerRef}>
        <ol
          style={{
            position: 'relative',
            width: '100%',
            height: `${totalSize}px`,
          }}
        >
          {virtualItems.map(({ index, key, size, start }) => {
            const isSelected = index < selectedItems.length;
            const group = isSelected
              ? selectedItems[index]
              : notSelectedItems[index - selectedItems.length];

            return (
              <Flipped key={group.name} flipId={group.name} translate>
                <li
                  key={key}
                  data-index={index}
                  style={{
                    position: 'absolute',
                    top: `${start}px`,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                  }}
                >
                  <NotableGroupMintsVolumeItem
                    {...group}
                    onClick={() => handleClickItem(group.id, isSelected)}
                    selected={isSelected}
                  />
                </li>
              </Flipped>
            );
          })}
        </ol>
      </div>
    </Flipper>
  );
}
