import { ReactNode, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
  elementId: string;
}

export default function Portal({ children, elementId }: PortalProps) {
  const [mounted, setMounted] = useState(false);
  const portalElement = useMemo(() => {
    if (typeof window === 'undefined') {
      return null;
    }

    return document.getElementById(elementId);
  }, [elementId]);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return mounted && portalElement
    ? createPortal(children, portalElement)
    : null;
}
