import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import API from '~/api';
import { commentsKeys } from '~/constants/queryKeys';
import type {
  GlobalCommentsFilter,
  GlobalCommentsFilterVoteOption,
} from '~/types';
import getParamFromUrl from '~/utils/getParamFromUrl';

export default function useGlobalComments(
  sort: GlobalCommentsFilter,
  duration?: GlobalCommentsFilterVoteOption
) {
  const queryClient = useQueryClient();
  const queryKey = !!duration
    ? commentsKeys.duration(duration)
    : commentsKeys.sort(sort);

  const getGlobalCommentsWithCursor = async (cursor: string | null) => {
    const {
      data: { next, results },
    } = await API.getComments(cursor, sort, duration);

    return {
      results,
      next: getParamFromUrl(next, 'cursor'),
      isLast: !next,
    };
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetched,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => getGlobalCommentsWithCursor(pageParam),
    {
      cacheTime: 5_000,
      enabled: !!sort,
      getNextPageParam: ({ next }) => next,
      refetchOnMount: false,
      staleTime: 5_000,
      keepPreviousData: true,
    }
  );

  const globalComments = useMemo(
    () => data?.pages.flatMap((page) => page.results) ?? [],
    [data?.pages]
  );

  const refresh = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    globalComments,
    hasNextPage,
    isError,
    isFetched,
    isFetchingNextPage,
    isLoading,
    itemCount: globalComments.length,
    loadMore: fetchNextPage,
    refresh,
  };
}
