import _isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { APP_SETTINGS_MODIFIED } from '~/constants';
import { displayState } from '~/store/app';
import type { Display } from '~/types';

export default function useDisplay() {
  const [display, setDisplay] = useRecoilState(displayState);

  const updateDisplay = useCallback(
    (updatedDisplay: Partial<Display>, shouldSetModified = true) => {
      setDisplay((prev) => {
        if (_isEqual(prev, updatedDisplay)) {
          return prev;
        }
        if (shouldSetModified) {
          const timestamp = new Date().getTime().toString();
          localStorage.setItem(APP_SETTINGS_MODIFIED, timestamp);
        }
        return {
          ...prev,
          ...updatedDisplay,
        };
      });
    },
    []
  );

  return [display, updateDisplay] as const;
}
