import cn from 'classnames';
import { useEffect, useState } from 'react';

import { IcnAirdrop, IcnVerified } from '~/assets';
import { PRICE_MAX_VALUE, PRICE_MIN_VALUE } from '~/constants';
import useNotableGroupActivityFilter from '~/hooks/useNotableGroupActivityFilter';

import styles from './NotableGroupActivityFilter.module.scss';

export default function NotableGroupActivityFilter() {
  const [filter, setFilter] = useNotableGroupActivityFilter();
  const [tempMaxPrice, setTempMaxPrice] = useState<string | number>(0);
  const [tempMinPrice, setTempMinPrice] = useState<string | number>(0);

  const switchExcludeAirdrop = () => {
    setFilter({
      excludeAirdrop: !filter.excludeAirdrop,
    });
  };

  const switchOnlyFree = () => {
    setFilter({
      onlyFree: !filter.onlyFree,
    });
  };

  const switchOnlyMintable = () => {
    setFilter({
      onlyMintable: !filter.onlyMintable,
    });
  };

  const switchOnlyVerified = () => {
    setFilter({
      onlyVerified: !filter.onlyVerified,
    });
  };

  const setMaxPrice = (tempPrice: number) => {
    let priceRangeMax = +tempPrice.toFixed(2);
    const priceRangeMin = filter.priceRangeMin;

    if (priceRangeMax > PRICE_MAX_VALUE) {
      priceRangeMax = PRICE_MAX_VALUE;
    } else if (priceRangeMax < priceRangeMin) {
      priceRangeMax = filter.priceRangeMin;
    }

    setTempMaxPrice(priceRangeMax);
    setFilter({
      onlyFree: false,
      priceRangeMax,
    });
  };

  const setMinPrice = (tempPrice: number) => {
    let priceRangeMin = +tempPrice.toFixed(2);
    const priceRangeMax = filter.priceRangeMax;

    if (priceRangeMin > priceRangeMax) {
      priceRangeMin = priceRangeMax;
    }

    setTempMinPrice(priceRangeMin);
    setFilter({
      onlyFree: false,
      priceRangeMin,
    });
  };

  useEffect(() => {
    setTempMaxPrice(filter.priceRangeMax);
    setTempMinPrice(filter.priceRangeMin);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.price_input_container}>
        <span className={styles.filter_label}>{'Min'}</span>
        <input
          className={cn(styles.price_range_input, {
            [styles.filter_active]:
              !filter.onlyFree && filter.priceRangeMin > PRICE_MIN_VALUE,
          })}
          inputMode="decimal"
          min={PRICE_MIN_VALUE}
          name="minPrice"
          onBlur={() => setMinPrice(+tempMinPrice)}
          onChange={(e) => {
            const { value } = e.target;
            if (value.startsWith('-')) return;
            if (value.startsWith('0') && !value.startsWith('0.')) {
              setTempMinPrice(+value);
            } else {
              setTempMinPrice(value);
            }
          }}
          onKeyDown={(e) => {
            const blacklist = ['Minus'].includes(e.code);
            if (blacklist) {
              e.preventDefault();
            }
            return blacklist;
          }}
          placeholder={PRICE_MIN_VALUE.toString()}
          step={0.01}
          type="number"
          value={tempMinPrice.toString()}
        />
      </div>
      <div className={styles.price_input_container}>
        <span className={styles.filter_label}>{'Max'}</span>
        <input
          className={cn(styles.price_range_input, {
            [styles.filter_active]:
              !filter.onlyFree && filter.priceRangeMax < PRICE_MAX_VALUE,
          })}
          inputMode="decimal"
          min={PRICE_MIN_VALUE}
          name="maxPrice"
          onBlur={() => setMaxPrice(+tempMaxPrice)}
          onChange={(e) => {
            const { value } = e.target;
            if (value.startsWith('-')) return;
            if (value.startsWith('0') && !value.startsWith('0.')) {
              setTempMaxPrice(+value);
            } else {
              setTempMaxPrice(value);
            }
          }}
          onKeyDown={(e) => {
            const blacklist = ['Minus'].includes(e.code);
            if (blacklist) {
              e.preventDefault();
            }
            return blacklist;
          }}
          placeholder={PRICE_MAX_VALUE.toString()}
          step={0.01}
          type="number"
          value={tempMaxPrice.toString()}
        />
      </div>
      <button
        className={cn(styles.btn_filter, {
          [styles.filter_active]: filter.onlyFree,
        })}
        onClick={switchOnlyFree}
      >
        <span>{'Free'}</span>
      </button>
      <button
        className={cn(styles.btn_filter, {
          [styles.filter_active]: filter.onlyVerified,
        })}
        disabled={false}
        onClick={switchOnlyVerified}
      >
        <span>{'Only'}</span>
        <div className={styles.icn_filter}>
          <IcnVerified />
        </div>
      </button>
      <button
        className={cn(styles.btn_filter, {
          [styles.filter_active]: filter.excludeAirdrop,
        })}
        onClick={switchExcludeAirdrop}
      >
        <span>{'Exclude'}</span>
        <div className={styles.icn_filter}>
          <IcnAirdrop />
        </div>
      </button>
      <button
        className={cn(styles.btn_filter, {
          [styles.filter_active]: filter.onlyMintable,
        })}
        onClick={switchOnlyMintable}
      >
        <span>{'Mintable'}</span>
      </button>
    </div>
  );
}
