import styles from './GoogleReCAPTCHAGuide.module.scss';

export default function GoogleReCAPTCHAGuide() {
  return (
    <span className={styles.container}>
      {`This site is protected by reCAPTCHA and the Google `}
      <a
        className="google-re-captcha-link"
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>{' '}
      {`and `}
      <a
        className="google-re-captcha-link"
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>{' '}
      apply.
    </span>
  );
}
