var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"_Hjx1xGuvUXeWbgQPXJVV"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import type { ErrorEvent, EventHint } from '@sentry/types';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const ENV = process.env.NEXT_PUBLIC_APP_ENV;
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const VERSION = publicRuntimeConfig.buildId;

const ignoreErrors = [
  'Attempt to connect to relay via `transportOpen` has stalled. Retrying...',
  `Cannot read properties of undefined (reading 'enterprise')`,
  `can't redefine non-configurable property "ethereum"`,
  `can't redefine non-configurable property "solana"`,
  `Connection request reset. Please try again.`,
  `failed to get recent blockhash: TypeError: Failed to fetch`,
  'Internal JSON-RPC error',
  `install_metamask`,
  `NetFunnel is not defined`,
  `No injected ethereum object.`,
  `Transaction has been reverted by the EVM`,
  `trap returned falsish for property 'request'`,
  'timeout of 9000ms exceeded',
  'Permission denied to access property "__SENTRY_LOADER__"',
  'Request aborted',
  'ResizeObserver loop completed with undelivered notifications.',
  'To use QR modal, please install @walletconnect/modal package',
  `User rejected request`,
];

const ignoreRegex = [
  /^Cannot redefine property/,
  /^WebSocket connection failed for URL: wss:\/\/relay\.walletconnect\.com/,
];

if (ENV === 'production') {
  Sentry.init({
    beforeSend: (event: ErrorEvent, hint: EventHint) => {
      // filter out UnhandledRejection errors that have no information
      if (event?.exception?.values?.length == 1) {
        const e = event.exception.values[0];
        if (
          e.type === 'UnhandledRejection' &&
          e.value?.startsWith(
            'Non-Error promise rejection captured with value:'
          )
        ) {
          return null;
        }

        if (
          ignoreErrors.some((err) =>
            // @ts-ignore
            hint.originalException?.message?.includes(err)
          ) ||
          ignoreRegex.some((regex) =>
            // @ts-ignore
            regex.test(hint.originalException?.message)
          ) ||
          event.exception.values.some((val) =>
            ignoreErrors.some((err) => val.value?.includes(err))
          )
        ) {
          return null;
        }
      }

      if (event.exception?.values) {
        for (const value of event.exception.values) {
          if (
            value.stacktrace?.frames?.some((frame) =>
              frame.filename?.includes('wagmi')
            )
          ) {
            return null;
          }
        }
      }

      // https://github.com/mytonwalletorg/mytonwallet/issues/155
      if (event.breadcrumbs) {
        for (const breadcrumb of event.breadcrumbs) {
          if (breadcrumb.category === 'console' && breadcrumb.data?.arguments) {
            const log = breadcrumb.data.arguments[0];
            if (
              log?.stack?.includes(
                'chrome-extension://fldfpgipfncgndfolcbkdeeknbbbnhcc'
              )
            ) {
              return null;
            }
          }
        }
      }

      return event;
    },
    dsn: SENTRY_DSN,
    environment: ENV,
    release: VERSION,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors,
  });
}
