import cn from 'classnames';

import { IcnArrowUp, IcnSmallClustered } from '~/assets';
import { Popover, Wallet } from '~/components';
import { REDIRECT_CLICKED_LOCATION } from '~/constants';
import type { ContractLinkedHolder } from '~/types';
import noop from '~/utils/noop';

import styles from './ClusterRow.module.scss';
import ClusterDetail from '../ClusterDetail';

interface ClusterRow extends ContractLinkedHolder {
  handleCollapse: () => void;
  index: number;
  isCollapsed: boolean;
  deployer?: string | null;
  totalSupply?: string | number | null;
}

export default function ClusterRow({
  addresses,
  handleCollapse,
  index,
  isCollapsed,
  quantity,
  deployer,
  totalSupply,
}: ClusterRow) {
  const clustered = addresses.length > 1;
  const representative =
    addresses.find((address) => !!address.ens) || addresses[0];
  const suffix = clustered
    ? ` and ${addresses.length - 1} wallet${addresses.length > 2 ? 's' : ''}`
    : '';
  const percentage =
    !totalSupply || (typeof totalSupply === 'string' && totalSupply.length > 19)
      ? 0
      : (quantity / +totalSupply) * 100;
  const [valueIntegerPart, valueDecimalPart] = percentage
    .toFixed(2)
    .toString()
    .split('.');

  return (
    <li className={styles.container}>
      <div
        className={cn(styles.summary, {
          [styles.not_collapsed]: !isCollapsed,
          [styles.clustered]: clustered,
        })}
        onClick={clustered ? handleCollapse : noop}
      >
        <div className={cn('number_column', styles.rank)}>
          <span>{index}</span>
          {clustered && (
            <Popover
              render={() => (
                <div className="default_popover">
                  <span>{'Cluster'}</span>
                </div>
              )}
              placement="top"
            >
              <div className={styles.icn_cluster}>
                <IcnSmallClustered />
              </div>
            </Popover>
          )}
        </div>
        <div
          className={cn(styles.connected_wallets, {
            [styles.highlight]: clustered,
          })}
        >
          <Wallet
            address={representative.address}
            deployer={deployer}
            ens={representative.ens}
            location={REDIRECT_CLICKED_LOCATION.TOP_100_HOLDERS}
          />
          {suffix}
          {clustered && (
            <button
              className={cn(styles.btn_toggle, {
                [styles.btn_collapsed]: isCollapsed,
              })}
            >
              <IcnArrowUp />
            </button>
          )}
        </div>
        <div className={styles.quantity_container}>
          {!!totalSupply ? (
            <>
              <span className={cn('number_column', styles.quantity)}>
                {`${quantity}`}
              </span>
              <span className={styles.percentage}>
                <p className="number_column">{`(${valueIntegerPart}`}</p>
                {valueDecimalPart && (
                  <p className={styles.decimal_point}>{'.'}</p>
                )}
                <p className={styles.decimal}>{`${valueDecimalPart}%)`}</p>
              </span>
              <div className={styles.percentage_bar}>
                <div
                  className={styles.filled}
                  style={{
                    width: `${percentage}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <span className={cn('number_column', styles.quantity)}>
              {quantity}
            </span>
          )}
        </div>
      </div>
      {addresses.length > 1 && !isCollapsed && (
        <ClusterDetail addresses={addresses} deployer={deployer} />
      )}
    </li>
  );
}
