import { useQueryClient } from '@tanstack/react-query';
import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

import API from '~/api';
import { API_DEBOUNCE_DELAY, MINIMUM_LOADING_TIME } from '~/constants';
import { notificationsKeys } from '~/constants/queryKeys';
import showErrorToast from '~/utils/showErrorToast';

interface UseDiscordWebhookSettingProps {
  registeredUrl?: string;
}

export default function useDiscordWebhookSetting({
  registeredUrl = '',
}: UseDiscordWebhookSettingProps) {
  const queryClient = useQueryClient();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [receivedUrl, setReceivedUrl] = useState(registeredUrl);
  const [step, setStep] = useState<'RegisterUrl' | 'Registered'>(
    !!registeredUrl ? 'Registered' : 'RegisterUrl'
  );
  const queryKey = notificationsKeys.social();

  const checkUrlValidation = useCallback((url: string) => {
    const regex = new RegExp(
      /^https:\/\/discord\.com\/api\/webhooks\/\d+\/([A-Za-z0-9-_]+)$/
    );
    return regex.test(url);
  }, []);

  const initializeStates = useCallback(() => {
    setIsDeleting(false);
    setIsLoading(false);
    setReceivedUrl('');
  }, []);

  const registerDiscordWebhook = useCallback(
    _debounce(async () => {
      try {
        setIsLoading(true);
        const startTime = Date.now();
        await API.registerDiscordWebhookNotification(receivedUrl);
        queryClient.invalidateQueries({ queryKey });
        const elapsedTime = Date.now() - startTime;
        const remainingTime = MINIMUM_LOADING_TIME - elapsedTime;

        if (remainingTime > 0) {
          setTimeout(() => {
            setIsLoading(false);
            setStep('Registered');
          }, remainingTime);
        } else {
          setIsLoading(false);
          setStep('Registered');
        }
      } catch (err) {
        setIsLoading(false);
        showErrorToast(err);
      }
    }, API_DEBOUNCE_DELAY),
    [receivedUrl]
  );

  const removeDiscordWebhookNotification = useCallback(
    _debounce(async () => {
      try {
        setIsLoading(true);
        const startTime = Date.now();
        await API.removeDiscordWebhookNotification();
        queryClient.invalidateQueries({ queryKey });
        const elapsedTime = Date.now() - startTime;
        const remainingTime = MINIMUM_LOADING_TIME - elapsedTime;

        if (remainingTime > 0) {
          setTimeout(() => {
            initializeStates();
            setStep('RegisterUrl');
          }, remainingTime);
        } else {
          initializeStates();
          setStep('RegisterUrl');
        }
      } catch (err) {
        setIsLoading(false);
        showErrorToast(err);
      }
    }, API_DEBOUNCE_DELAY),
    []
  );

  useEffect(() => {
    if (!!registeredUrl) {
      setReceivedUrl(registeredUrl);
      setStep('Registered');
    }
  }, [registeredUrl]);

  return {
    checkUrlValidation,
    isDeleting,
    isLoading,
    registerDiscordWebhook,
    removeDiscordWebhookNotification,
    setIsDeleting,
    setUrl: setReceivedUrl,
    step,
    url: receivedUrl,
  };
}
