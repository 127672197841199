import cn from 'classnames';
import _debounce from 'lodash/debounce';
import { ChangeEvent, FocusEvent, useRef, useState } from 'react';

import { GoogleReCAPTCHAGuide } from '~/components';
import { HUMAN_MEDIAN_REACTION_TIME } from '~/constants';

import styles from './CommentInput.module.scss';

interface CommentInputProps {
  isLoading: boolean;
  submit: (text: string) => Promise<boolean>;
  comment?: string;
  isDead?: boolean;
  isEditing?: boolean;
}

export default function CommentInput({
  comment = '',
  isDead = false,
  isEditing = false,
  isLoading,
  submit,
}: CommentInputProps) {
  const isSubmitting = useRef(false);
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState(comment);
  const isDisabled =
    isLoading || isSubmitting.current || text.trim().length === 0;

  const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    if (event.relatedTarget?.className === 'google-re-captcha-link') {
      event.preventDefault();
    } else {
      setIsFocused(false);
    }
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    setText(value);
  };

  const onSubmit = _debounce(async () => {
    if (isSubmitting.current) return;
    isSubmitting.current = true;
    const result = await submit(text);
    if (result) setText('');
    isSubmitting.current = false;
  }, HUMAN_MEDIAN_REACTION_TIME);

  return (
    <div className={styles.container}>
      <textarea
        value={text}
        className={cn(styles.textarea, {
          [styles.active]: isFocused || text.length > 0,
        })}
        placeholder="Leave a comment"
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={onBlur}
        disabled={isDead}
      />
      {(isFocused || text.length > 0) && (
        <>
          <GoogleReCAPTCHAGuide />
          <button
            className={styles.btn_add_comment}
            onClick={onSubmit}
            disabled={isDisabled || isDead}
          >
            {isEditing ? 'update' : 'Add comment'}
          </button>
        </>
      )}
    </div>
  );
}
