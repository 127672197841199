import { memo, useEffect, useRef } from 'react';
import ReactCountUp, { CountUpProps } from 'react-countup';

export default memo(function CountUp(props: CountUpProps) {
  const { end, prefix = '', start = 0, suffix = '' } = props;
  const prevValueRef = useRef(start);

  useEffect(() => {
    prevValueRef.current = end;
  });

  return (
    <ReactCountUp
      {...props}
      start={prevValueRef.current}
      end={end}
      prefix={prefix}
      suffix={suffix}
    />
  );
});
