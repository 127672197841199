import cn from 'classnames';
import Link from 'next/link';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTimeout } from 'usehooks-ts';

import {
  IcnAirdrop,
  IcnConfirmed,
  IcnMintable,
  IcnSmallChainEthereum,
  IcnSmallExternal,
} from '~/assets';
import {
  ExternalLink,
  Loading,
  Popover,
  Thumbnail,
  TimeAgo,
  Wallet,
} from '~/components';
import {
  AVAILABLE_CHAIN,
  IMAGE_SIZE,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
  RENDERING_BOUND_TIME,
  TRANSITION_TIME,
  UNIDENTIFIED_CONTRACT,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import styleVariables from '~/styles/variables.module.scss';
import type { NotableGroup, NotableGroupActivity } from '~/types';
import formatEthPrice from '~/utils/formatEthPrice';

import styles from './NotableGroupActivityItem.module.scss';

interface NotableGroupActivityItemProps extends NotableGroupActivity {
  clicked: boolean;
  enableBound: boolean;
  groupsInfo: NotableGroup[];
  index: number;
  onClick: () => void;
}

export default function NotableGroupActivityItem({
  address,
  clicked,
  confirmations,
  enableBound,
  ensName,
  explorerUrl,
  functionName,
  gasPrice,
  groupsInfo,
  imageUrl,
  index,
  isAirdrop,
  maxSupply,
  minter,
  mintQuantity,
  name,
  onClick,
  simulationPassed,
  timestamp,
  totalSupply,
  value,
}: NotableGroupActivityItemProps) {
  const analytics = useAnalyticsContext();
  const [isBound, setIsBound] = useState(enableBound);
  const [noTransition, setNoTransition] = useState(false);

  const mintable = maxSupply !== totalSupply && simulationPassed;

  const onClickExplorerUrl = () => {
    analytics.track(REDIRECT_CLICKED, {
      chain: AVAILABLE_CHAIN.ETHEREUM,
      location: REDIRECT_CLICKED_LOCATION.NOTABLE_GROUP_ACTIVITY,
      position: index,
      type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
      url: explorerUrl,
    });
  };

  useTimeout(() => {
    setIsBound(false);
  }, RENDERING_BOUND_TIME);

  useTimeout(() => {
    setNoTransition(true);
  }, TRANSITION_TIME);

  return (
    <div
      className={cn(styles.container, {
        [styles.bound]: isBound,
        [styles.clicked]: clicked,
        [styles.no_transition]: noTransition,
      })}
      onClick={onClick}
    >
      <div className={styles.collection_info_container}>
        <Link
          className={styles.collection_info}
          href={`/collection/ethereum/${address}`}
        >
          <div className={styles.thumbnail_container}>
            <Thumbnail name={name} url={imageUrl} />
          </div>
          <div className={styles.collection_name_container}>
            {address ? (
              <span className={styles.collection_name}>
                {name.trim().length > 0 ? name : UNIDENTIFIED_CONTRACT}
              </span>
            ) : (
              <Skeleton width={200} />
            )}
            {(mintQuantity?.length > 19
              ? BigInt(mintQuantity)
              : +mintQuantity) > 1 && (
              <div className={styles.quantity_container}>
                <span className={styles.quantity}>{`x${mintQuantity}`}</span>
              </div>
            )}
          </div>
        </Link>
      </div>
      <div className={styles.price_container}>
        {isAirdrop ? (
          <>
            <div className={styles.airdrop_container}>
              <IcnAirdrop />
            </div>
          </>
        ) : (
          value === '0' && <span>{'-'}</span>
        )}
        {value !== '0' && (
          <div className={styles.price}>
            <span>{formatEthPrice(+(value ?? '0'))}</span>
            <div className={styles.icn_container}>
              <IcnSmallChainEthereum />
            </div>
          </div>
        )}
      </div>
      <div className={styles.gas_price_container}>
        <span>{`${Math.ceil(+gasPrice)} Gwei`}</span>
      </div>
      <div className={styles.mint_function_container}>
        {mintable && (
          <div className={styles.icn_container}>
            <IcnMintable />
          </div>
        )}
        <span
          className={cn(styles.function_name, {
            [styles.mintable]: mintable,
          })}
        >
          {functionName || '-'}
        </span>
      </div>
      <div className={styles.groups_container}>
        {groupsInfo
          .slice(0, groupsInfo.length > 5 ? 5 : groupsInfo.length)
          .map((group) => (
            <Popover
              key={`group_${group.imageUrl}`}
              render={() => (
                <div className="default_popover">
                  <span>{group.name}</span>
                </div>
              )}
              placement="top"
            >
              <div className={styles.avatars_item}>
                <div
                  className={styles.avatars_image}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Thumbnail url={group.imageUrl} size={IMAGE_SIZE.SMALL} />
                </div>
              </div>
            </Popover>
          ))}
        {groupsInfo.length > 5 && (
          <Popover
            render={() => (
              <div className="default_popover">
                {groupsInfo.slice(5).map(({ name }) => (
                  <span key={name}>
                    {name}
                    <br />
                  </span>
                ))}
              </div>
            )}
            placement="top"
          >
            <div className={styles.avatars_item}>
              <div
                className={styles.remaining_avatars}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {`+${groupsInfo.length - 5}`}
              </div>
            </div>
          </Popover>
        )}
      </div>
      <div className={styles.minter_container}>
        <Wallet
          address={minter}
          ens={ensName}
          highlight
          location={REDIRECT_CLICKED_LOCATION.NOTABLE_GROUP_ACTIVITY}
        />
      </div>
      <div className={styles.time_container}>
        {address ? (
          <ExternalLink
            className={styles.transaction_link}
            disabled={explorerUrl.length === 0}
            label="View on Etherscan"
            nofollow
            onClick={onClickExplorerUrl}
            role="link"
            url={explorerUrl}
          >
            <TimeAgo date={new Date(timestamp)} />
            <div
              className={cn(
                styles.icn_container,
                styles.icn_small_external_container
              )}
            >
              <IcnSmallExternal />
            </div>
            <div className={styles.confirmation_container}>
              {confirmations === 0 ? (
                <Loading color={styleVariables.orange500} size={12} />
              ) : (
                <div className={styles.icn_confirmed_container}>
                  <IcnConfirmed />
                </div>
              )}
            </div>
          </ExternalLink>
        ) : (
          <Skeleton width={120} />
        )}
      </div>
    </div>
  );
}
