import _debounce from 'lodash/debounce';
import {
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useAccount } from 'wagmi';

import { IcnHint, IcnProfile, IcnSmallWallet } from '~/assets';
import {
  Indicator,
  Loading,
  NotableGroup,
  Popover,
  SelectButton,
} from '~/components';
import Table, {
  TableBody,
  TableCell,
  TableHeader,
  TableNoData,
  TableRow,
} from '~/components/Table';
import {
  AGGREGATE_AIRDROPS,
  AGGREGATE_MINTS,
  AGGREGATE_WALLETS,
  DETAIL_LAYOUT,
  NOTABLE_GROUPS_AGGREGATES,
  RENDERING_BOUND_TIME,
} from '~/constants';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import { useAuth } from '~/contexts/AuthContext';
import useFollowedNotableGroups from '~/data/useFollowedNotableGroups';
import useMintingAggregates from '~/data/useMintingAggregates';
import useNotableGroups from '~/data/useNotableGroups';
import { checksumContractAddressState } from '~/store/contract';
import type { NotableGroupMinted, SelectItem } from '~/types';

import NotableGroupsModal from './NotableGroupsModal';
import styles from './NotableGroupsSection.module.scss';
import SectionLayout from '../SectionLayout';

interface NotableGroupsSectionProps {
  isFetched: boolean;
  isFirstFetched: boolean;
  isLoading: boolean;
  notableGroupsMinted: NotableGroupMinted[];
  tags: string[];
}

export default memo(function NotableGroupsSection({
  isFetched,
  isFirstFetched,
  isLoading,
  notableGroupsMinted,
  tags,
}: NotableGroupsSectionProps) {
  const analytics = useAnalyticsContext();

  const { address: connectedWalletAddress } = useAccount();
  const { isAuthenticated, signIn } = useAuth();
  const address = useRecoilValue(checksumContractAddressState);
  const { hasNew, notableGroups } = useNotableGroups();
  const { followingIds } = useFollowedNotableGroups();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [enableBound, setEnableBound] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [mintingAggregatesParams, setMintingAggregatesParams] = useState<{
    address: string;
    ids: number[];
  }>({ address, ids: [] });
  const [showLoadingToMintingAggregates, setShowLoadingToMintingAggregates] =
    useState(true);
  const mintingAggregatesResult = useMintingAggregates(mintingAggregatesParams);

  const { mintingAggregates } = mintingAggregatesResult;

  const filteredNotableGroupsMinted = useMemo(
    () =>
      selectedTags.length > 0
        ? notableGroupsMinted.filter((group) =>
            group.tags.some((tag) => selectedTags.includes(tag))
          )
        : notableGroupsMinted,
    [notableGroupsMinted, selectedTags]
  );

  const followingNotableGroups = useMemo(
    () => notableGroups.filter((group) => followingIds.includes(group.id)),
    [followingIds, notableGroups]
  );

  const handleFollowButton = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsOpenModal(true);
    },
    [analytics]
  );

  const renderFollowGroupsPopover = useCallback(() => {
    let sentence = 'You are following ';
    if (followingNotableGroups.length === 1)
      sentence += followingNotableGroups[0].name;
    if (followingNotableGroups.length === 2)
      sentence += `${followingNotableGroups[0].name} and ${followingNotableGroups[1].name}`;
    if (followingNotableGroups.length > 2)
      sentence += `${followingNotableGroups[0].name}, ${
        followingNotableGroups[1].name
      } and ${followingNotableGroups.length - 2} other notable groups`;

    return sentence;
  }, [followingNotableGroups]);

  const renderTableRow = (group: NotableGroupMinted) => {
    return (
      <TableRow key={group.name}>
        <TableCell isText={true}>
          <NotableGroup {...group} />
        </TableCell>
        <TableCell isText={true}>
          <div className={styles.tags_container}>
            {group.tags.map((tag) => (
              <span className={styles.tag} key={`${group.name}_tag_${tag}`}>
                {tag}
              </span>
            ))}
          </div>
        </TableCell>
        <TableCell isText={false}>
          {group.mintCount ? group.mintCount.toLocaleString() : ''}
        </TableCell>
        <TableCell isText={false}>
          {group.mintWallets ? group.mintWallets.toLocaleString() : ''}
        </TableCell>
        <TableCell isText={false}>
          {group.airdropCount ? group.airdropCount.toLocaleString() : ''}
        </TableCell>
        <TableCell isText={false}>
          {group.airdropWallets ? group.airdropWallets.toLocaleString() : ''}
        </TableCell>
      </TableRow>
    );
  };

  const debounced = useCallback(
    _debounce((selectedItems: SelectItem<string>[]) => {
      setSelectedTags(selectedItems.map((selectedItem) => selectedItem.key));
    }, 500),
    []
  );

  useEffect(() => {
    // initialize selected tags
    setMintingAggregatesParams({
      address,
      ids: [],
    });
    setSelectedTags([]);
    setShowLoadingToMintingAggregates(true);
  }, [address]);

  useEffect(() => {
    const boundTimer = () =>
      setTimeout(() => {
        setEnableBound(true);
      }, RENDERING_BOUND_TIME);

    if (isFetched) {
      boundTimer();
    } else {
      clearTimeout(boundTimer());
      setEnableBound(false);
    }
  }, [isFetched]);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsOpenModal(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      selectedTags.length > 0 &&
      !selectedTags.some((selectedTag) => tags.includes(selectedTag))
    ) {
      setSelectedTags([]);
    }
  }, [selectedTags, tags]);

  useDeepCompareEffect(() => {
    const filteredIds = filteredNotableGroupsMinted.map((group) => group.id);
    setMintingAggregatesParams((prev) => ({ ...prev, ids: filteredIds }));
  }, [filteredNotableGroupsMinted]);

  useEffect(() => {
    if (!!mintingAggregates) {
      setShowLoadingToMintingAggregates(false);
    }
  }, [mintingAggregates]);

  return (
    <SectionLayout
      boundTrigger={''}
      enableBound={enableBound}
      id={'notable_groups_minted'}
    >
      <div className={styles.container}>
        <div className={styles.section_title_container}>
          <div className={styles.section_title_left_container}>
            <span className={styles.section_title}>
              {isLoading ? (
                <Skeleton width={232} />
              ) : (
                DETAIL_LAYOUT.NOTABLE_GROUPS_MINTED
              )}
            </span>
            {isAuthenticated &&
              !isLoading &&
              followingNotableGroups.length > 0 && (
                <Popover
                  render={() => (
                    <div className="default_popover">
                      <span>{renderFollowGroupsPopover()}</span>
                    </div>
                  )}
                  animation
                  placement="top"
                >
                  <button
                    className={styles.btn_following}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpenModal(true);
                    }}
                  >
                    <span>{followingNotableGroups.length}</span>
                    {` following`}
                    {hasNew && <label className={styles.new}>New</label>}
                  </button>
                </Popover>
              )}
          </div>
          {isAuthenticated &&
            !isLoading &&
            tags.length > 0 &&
            !isFirstFetched && (
              <div className={styles.tags_filter_container}>
                <SelectButton.Group
                  items={tags.map((tag) => ({
                    key: tag,
                    label: tag,
                    selected: selectedTags.includes(tag),
                    value: tag,
                  }))}
                  multi
                  onItemClicked={(selectedItems) => {
                    debounced(selectedItems);
                  }}
                  resetClear={selectedTags.length === 0}
                  showClear={tags.length > 1}
                />
              </div>
            )}
        </div>
        {isAuthenticated && notableGroupsMinted.length > 0 && (
          <div className={styles.minting_aggregates_container}>
            <div className={styles.minting_aggregates}>
              {isLoading ? (
                <Skeleton width={145} />
              ) : (
                <>
                  <Popover
                    render={() => (
                      <div className="default_popover">
                        <span>{NOTABLE_GROUPS_AGGREGATES}</span>
                      </div>
                    )}
                    placement="top"
                    animation
                  >
                    <span className={styles.aggregate_label}>
                      {'Unique Total Mints'}
                    </span>
                  </Popover>
                  <span className={styles.aggregate_data}>
                    {showLoadingToMintingAggregates ? (
                      <Loading />
                    ) : (
                      <Indicator
                        hint={AGGREGATE_MINTS}
                        value={mintingAggregates?.uniqueMintCount ?? 0}
                        additional={{
                          hint: AGGREGATE_WALLETS,
                          icon: (
                            <>
                              {'('}
                              <div className={styles.wallet_icon}>
                                <IcnSmallWallet />
                              </div>
                            </>
                          ),
                          suffix: ')',
                          value: mintingAggregates?.uniqueMintWallets ?? 0,
                        }}
                      />
                    )}
                  </span>
                </>
              )}
            </div>
            <div className={styles.minting_aggregates}>
              {isLoading ? (
                <Skeleton width={169} />
              ) : (
                <>
                  <Popover
                    render={() => (
                      <div className="default_popover">
                        <span>{NOTABLE_GROUPS_AGGREGATES}</span>
                      </div>
                    )}
                    placement="top"
                    animation
                  >
                    <span className={styles.aggregate_label}>
                      {'Unique Total Airdrops'}
                    </span>
                  </Popover>
                  <span className={styles.aggregate_data}>
                    {showLoadingToMintingAggregates ? (
                      <Loading />
                    ) : (
                      <Indicator
                        hint={AGGREGATE_AIRDROPS}
                        value={mintingAggregates?.uniqueAirdropCount ?? 0}
                        additional={{
                          hint: AGGREGATE_WALLETS,
                          icon: (
                            <>
                              {'('}
                              <div className={styles.wallet_icon}>
                                <IcnSmallWallet />
                              </div>
                            </>
                          ),
                          suffix: ')',
                          value: mintingAggregates?.uniqueAirdropWallets ?? 0,
                        }}
                      />
                    )}
                  </span>
                </>
              )}
            </div>
          </div>
        )}
        {isLoading ? (
          <Skeleton height={164} />
        ) : !isAuthenticated ? (
          <div className={styles.no_notable_groups_container}>
            <div className={styles.no_notable_groups_title_container}>
              <div>
                <IcnHint />
              </div>
              <span className={styles.no_notable_groups_title}>
                {`${
                  connectedWalletAddress ? 'Sign in' : 'Connect and Sign in'
                } to see which notable groups have minted this collection.`}
              </span>
            </div>
            <button
              className={styles.report_another_group_button}
              onClick={signIn}
            >
              <IcnProfile />
              {`${connectedWalletAddress ? 'Sign in' : 'Connect and Sign in'}`}
            </button>
          </div>
        ) : notableGroupsMinted.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell
                  isHeader={true}
                  isText={true}
                  width={108}
                  rowSpan={2}
                >
                  {'Name'}
                </TableCell>
                <TableCell isHeader={true} isText={true} width={74} rowSpan={2}>
                  {'Tags'}
                </TableCell>
                <TableCell
                  isHeader={true}
                  isText={false}
                  width={74}
                  colSpan={2}
                >
                  {'Mints'}
                </TableCell>
                <TableCell
                  isHeader={true}
                  isText={false}
                  width={74}
                  colSpan={2}
                >
                  {'Airdrops'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell isHeader={true} isText={false} width={74}>
                  {'Count'}
                </TableCell>
                <TableCell isHeader={true} isText={false} width={74}>
                  {'Wallets'}
                </TableCell>
                <TableCell isHeader={true} isText={false} width={74}>
                  {'Count'}
                </TableCell>
                <TableCell isHeader={true} isText={false} width={74}>
                  {'Wallets'}
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredNotableGroupsMinted.length > 0 ? (
                filteredNotableGroupsMinted.map((group) =>
                  renderTableRow(group)
                )
              ) : (
                <TableNoData colSpan={6} />
              )}
            </TableBody>
          </Table>
        ) : (
          <div className={styles.no_notable_groups_container}>
            <div className={styles.no_notable_groups_title_container}>
              <div>
                <IcnHint />
              </div>
              <span className={styles.no_notable_groups_title}>
                {followingIds.length > 0
                  ? 'No one from the notable groups you are following has minted this collection yet.'
                  : 'You are not following any notable groups.'}
              </span>
            </div>
            <span className={styles.no_notable_groups_curated}>
              {'Follow notable groups such as BAYC, CryptoPunks and Moonbirds.'}
            </span>
            <button
              className={styles.report_another_group_button}
              onClick={handleFollowButton}
            >
              {'Choose notable groups to follow'}
            </button>
          </div>
        )}
      </div>
      {isOpenModal && (
        <NotableGroupsModal
          isOpen={isOpenModal}
          onClose={() => {
            setIsOpenModal(false);
          }}
        />
      )}
    </SectionLayout>
  );
});
