import { useConnect } from 'wagmi';

import { ExternalLink, Modal } from '~/components';
import {
  APP_PRIVACY_LINK,
  APP_TERMS_LINK,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';

import ConnectButton from './ConnectButton';
import styles from './ConnectModal.module.scss';

interface ConnectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ConnectModal({ isOpen, onClose }: ConnectModalProps) {
  const analytics = useAnalyticsContext();
  const { connectors } = useConnect();
  const defaultConnector = connectors[0].ready
    ? connectors[0].name === 'MetaMask' || connectors[0].name === 'Rabby'
      ? connectors[0]
      : connectors[1]
    : connectors[1];
  const walletConnectConnector = connectors[2];

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.connect_container}>
        <h2 className={styles.connect_title}>{'Connect your wallet'}</h2>
        <p className={styles.connect_description}>
          {`By connecting your wallet and using CatchMint, you agree to our `}
          <ExternalLink
            allowsUtmSource={false}
            className={styles.external_link}
            label="terms_of_service"
            onClick={() => {
              analytics.track(REDIRECT_CLICKED, {
                location: REDIRECT_CLICKED_LOCATION.OTHER,
                type: REDIRECT_CLICKED_TYPE.OTHER,
                url: APP_TERMS_LINK,
              });
            }}
            role="link"
            url={APP_TERMS_LINK}
          >
            {`Terms of Service`}
          </ExternalLink>
          {` and `}
          <ExternalLink
            allowsUtmSource={false}
            className={styles.external_link}
            label="privacy_policy"
            onClick={() => {
              analytics.track(REDIRECT_CLICKED, {
                location: REDIRECT_CLICKED_LOCATION.OTHER,
                type: REDIRECT_CLICKED_TYPE.OTHER,
                url: APP_PRIVACY_LINK,
              });
            }}
            role="link"
            url={APP_PRIVACY_LINK}
          >
            {`Privacy Policy`}
          </ExternalLink>
        </p>
        <div className={styles.connect_connect_buttons_container}>
          <ConnectButton
            key={defaultConnector.id}
            connector={defaultConnector}
            name={'MetaMask'}
            onClose={onClose}
          />
          <ConnectButton
            key={walletConnectConnector.id}
            connector={walletConnectConnector}
            onClose={onClose}
          />
        </div>
      </div>
    </Modal>
  );
}
