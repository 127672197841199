import {
  observeWindowOffset,
  useWindowVirtualizer,
} from '@tanstack/react-virtual';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import { IcnScrollToTop } from '~/assets';
import { Loading } from '~/components';
import { AVAILABLE_CHAIN, COLLECTION_CLICKED_LOCATION } from '~/constants';
import { useAuth } from '~/contexts/AuthContext';
import useGlobalComments from '~/data/useGlobalComments';
import type {
  GlobalCommentsFilter,
  GlobalCommentsFilterVoteOption,
} from '~/types';

import GlobalCommentItem from './GlobalCommentItem';
import styles from './GlobalCommentList.module.scss';

interface GlobalCommentListProps {
  restorationKey: string;
  sort: GlobalCommentsFilter;
  duration?: GlobalCommentsFilterVoteOption;
}

export default function GlobalCommentList({
  restorationKey,
  duration,
  sort,
}: GlobalCommentListProps) {
  const { isAuthenticated, user } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerOffsetRef = useRef(0);
  const {
    globalComments,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isLoading,
    itemCount,
    loadMore,
    refresh,
  } = useGlobalComments(sort, duration);

  const virtualizer = useWindowVirtualizer({
    count: itemCount,
    estimateSize: () => 72,
    initialOffset: (() =>
      typeof sessionStorage !== 'undefined'
        ? parseInt(sessionStorage.getItem(restorationKey) || '0', 10) || 0
        : 0)(),
    observeElementOffset: (instance, cb) =>
      observeWindowOffset(instance, (offset) => {
        sessionStorage?.setItem(restorationKey, offset.toString());
        cb(offset);
      }),
    overscan: 5,
    scrollMargin: containerOffsetRef.current,
  });

  const items = virtualizer.getVirtualItems();
  const lastItem = useMemo(
    () => (items.length > 0 ? items[items.length - 1] : undefined),
    [items]
  );

  const onClickCollection = useCallback(
    ({
      address,
      name,
      position,
    }: {
      address: string;
      name: string;
      position: number;
    }) => {
      const params: { [key: string]: any } = {
        chain: AVAILABLE_CHAIN.ETHEREUM,
        contract_address: address,
        location: COLLECTION_CLICKED_LOCATION.COMMENTS,
        position,
      };
      if (!!name) {
        params['collection_name'] = name;
      }
    },
    []
  );

  const scrollToTop = useCallback(() => {
    virtualizer.scrollToOffset(0);
  }, []);

  useEffect(() => {
    refresh();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!lastItem) return;
    if (lastItem.index >= itemCount - 1 && hasNextPage && !isFetchingNextPage) {
      loadMore();
    }
  }, [hasNextPage, isFetchingNextPage, itemCount, lastItem, loadMore]);

  useLayoutEffect(() => {
    containerOffsetRef.current = containerRef.current?.offsetTop ?? 0;
  }, []);

  if (isLoading) {
    return (
      <div className={styles.etc_container}>
        <Loading size={32} />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={styles.etc_container}>
        <p>{'probably nothing'}</p>
      </div>
    );
  }

  if (itemCount === 0) {
    return (
      <div className={styles.etc_container}>
        <p>{`No comments yet ${duration}.`}</p>
      </div>
    );
  }

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        <div
          style={{
            height: virtualizer.getTotalSize(),
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${
                items.length > 0
                  ? items[0].start - virtualizer.options.scrollMargin
                  : 0
              }px)`,
            }}
          >
            {items.map(({ key, index }) => {
              const comment = globalComments[index];
              const isMine = user?.username === comment.username;

              return (
                <GlobalCommentItem
                  key={key}
                  index={index}
                  onClickCollection={onClickCollection}
                  ref={virtualizer.measureElement}
                  isMine={isMine}
                  comment={comment}
                />
              );
            })}
            {isFetchingNextPage && (
              <div className={styles.loading_container}>
                <Loading size={32} />
              </div>
            )}
          </div>
        </div>
      </div>
      <button className={styles.btn_scroll_top} onClick={scrollToTop}>
        <IcnScrollToTop />
      </button>
    </>
  );
}
