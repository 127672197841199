import cn from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { IcnHint } from '~/assets';
import { Indicator, Loading, Popover, SelectButton } from '~/components';
import Table, {
  TableBody,
  TableCell,
  TableHeader,
  TableNoData,
  TableRow,
} from '~/components/Table';
import { DETAIL_LAYOUT, FAILED_TRANSACTIONS_GROUP_BY } from '~/constants';
import useFailedTransactionsFilter from '~/hooks/useFailedTransactionsFilter';
import type {
  ContractFailedTransaction,
  FailedTransactionsGroupBy,
  MintableFunctions,
} from '~/types';
import noExponents from '~/utils/noExponents';

import styles from './FailedTransactions.module.scss';
import SectionLayout from '../SectionLayout';

interface FailedTransactionsSectionProps {
  failedTransactions: ContractFailedTransaction[];
  isLoading: boolean;
  mintFunctions: MintableFunctions;
}

export default function FailedTransactionsSection({
  failedTransactions,
  isLoading,
  mintFunctions,
}: FailedTransactionsSectionProps) {
  const [failedTransactionsFilter, setFailedTransactionsFilter] =
    useFailedTransactionsFilter();
  const failedTransactionsGroupBy =
    failedTransactionsFilter?.groupBy || FAILED_TRANSACTIONS_GROUP_BY.METHOD;

  const mintFailedTransactions = useMemo(
    () =>
      failedTransactions
        .filter((tx) => !!mintFunctions[tx.functionSignature])
        .sort((prev, curr) => {
          const { name: prevMethodName = 'unknown' } =
            mintFunctions[prev.functionSignature];
          const { name: currMethodName = 'unknown' } =
            mintFunctions[curr.functionSignature];

          if (
            failedTransactionsGroupBy === FAILED_TRANSACTIONS_GROUP_BY.METHOD
          ) {
            return prevMethodName === currMethodName
              ? curr.quantity - prev.quantity
              : prevMethodName
                  .toLowerCase()
                  .localeCompare(currMethodName.toLowerCase());
          } else {
            return prev.error === curr.error
              ? curr.quantity - prev.quantity
              : prev.error.localeCompare(curr.error);
          }
        }),
    [failedTransactions, failedTransactionsGroupBy, mintFunctions]
  );
  const lastFailed = mintFailedTransactions.reduce(
    (acc, curr) => (acc < curr.lastFailed ? curr.lastFailed : acc),
    ''
  );
  const totalCount = mintFailedTransactions.reduce(
    (acc, curr) => acc + curr.quantity,
    0
  );
  const totalWasted = mintFailedTransactions.reduce(
    (acc, curr) => acc + +curr.averageTxFeeSpent * curr.quantity,
    0
  );

  const handleChangeView = (groupBy: FailedTransactionsGroupBy) => {
    setFailedTransactionsFilter({
      groupBy,
    });
  };

  const renderTableRow = (
    index: number,
    currTx: ContractFailedTransaction,
    prevTx?: ContractFailedTransaction
  ) => {
    const methodName =
      failedTransactionsGroupBy === FAILED_TRANSACTIONS_GROUP_BY.METHOD
        ? !!prevTx
          ? prevTx.functionSignature === currTx.functionSignature
            ? ''
            : mintFunctions[currTx.functionSignature].name
          : mintFunctions[currTx.functionSignature].name
        : mintFunctions[currTx.functionSignature].name;
    const errorName =
      failedTransactionsGroupBy === FAILED_TRANSACTIONS_GROUP_BY.TYPE
        ? !!prevTx
          ? prevTx.error === currTx.error
            ? ''
            : currTx.error
          : currTx.error
        : currTx.error;
    return (
      <TableRow key={`failed_tx_row_${index}`}>
        {failedTransactionsGroupBy === FAILED_TRANSACTIONS_GROUP_BY.METHOD ? (
          <>
            <TableCell isText={true} divClassName={styles.method_name}>
              {methodName}
            </TableCell>
            <TableCell isText={true} divClassName={cn(styles.error_name)}>
              {errorName}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell isText={true} divClassName={cn(styles.error_name)}>
              {errorName}
            </TableCell>
            <TableCell divClassName={styles.method_name} isText={true}>
              {methodName}
            </TableCell>
          </>
        )}
        <TableCell isText={false}>{currTx.quantity.toLocaleString()}</TableCell>
        <TableCell isText={false}>
          {currTx.averageGasUsed.toLocaleString()}
        </TableCell>
        <TableCell isText={false}>
          {currTx.averageTxFeeSpent.length > 6 ? (
            <Popover
              render={() => (
                <div className="price_popover_container">
                  <span className="price_popover_content">
                    {`Ξ ${noExponents(+(currTx.averageTxFeeSpent ?? '0'))}`}
                  </span>
                </div>
              )}
              delay={{ open: 1, close: 1 }}
              placement="right-end"
            >
              <span>
                {`≈ ${(
                  Math.ceil(+currTx.averageTxFeeSpent * 10000) * 0.0001
                ).toFixed(4)}`}
              </span>
            </Popover>
          ) : (
            <p>{`${noExponents(+(currTx.averageTxFeeSpent ?? '0'))}`}</p>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <SectionLayout
      boundTrigger={''}
      enableBound={false}
      id={'average_entry_point'}
    >
      <div className={styles.section_header_container}>
        <div className={styles.section_title_container}>
          <div className={styles.section_title_left_container}>
            <span className={styles.section_title}>
              {isLoading ? (
                <Skeleton width={198} />
              ) : (
                DETAIL_LAYOUT.FAILED_TRANSACTIONS
              )}
            </span>
            {!isLoading && (
              <Popover
                render={() => (
                  <div className="hint_popover">
                    <span>{'Updated every 5 minutes.'}</span>
                  </div>
                )}
                animation
                placement="top"
                showArrow
              >
                <div className={cn('center', styles.hint_container)}>
                  <IcnHint />
                </div>
              </Popover>
            )}
            {!isLoading && mintFailedTransactions.length > 0 && (
              <SelectButton.Group<FailedTransactionsGroupBy>
                items={[
                  {
                    key: FAILED_TRANSACTIONS_GROUP_BY.METHOD,
                    label: FAILED_TRANSACTIONS_GROUP_BY.METHOD,
                    selected:
                      failedTransactionsGroupBy ===
                      FAILED_TRANSACTIONS_GROUP_BY.METHOD,
                    value: FAILED_TRANSACTIONS_GROUP_BY.METHOD,
                  },
                  {
                    key: FAILED_TRANSACTIONS_GROUP_BY.TYPE,
                    label: FAILED_TRANSACTIONS_GROUP_BY.TYPE,
                    selected:
                      failedTransactionsGroupBy ===
                      FAILED_TRANSACTIONS_GROUP_BY.TYPE,
                    value: FAILED_TRANSACTIONS_GROUP_BY.TYPE,
                  },
                ]}
                onItemClicked={(_, clickedItem) => {
                  if (clickedItem) {
                    handleChangeView(clickedItem.value);
                  }
                }}
              />
            )}
          </div>
          {!isLoading && lastFailed && (
            <span className={styles.last_failed}>
              {`Last failed ${dayjs().to(dayjs(lastFailed))}`}
            </span>
          )}
        </div>
      </div>
      {isLoading ? (
        <Skeleton height={75} />
      ) : (
        <div className={styles.table_container}>
          {mintFailedTransactions.length > 0 && (
            <div className={styles.total_indicator_container}>
              <div className={styles.total_indicator}>
                {isLoading ? (
                  <Skeleton width={145} />
                ) : (
                  <>
                    <span className={styles.total_label}>
                      {'Total Failed Transactions'}
                    </span>
                    <span className={styles.total_data}>
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <Indicator value={totalCount} />
                      )}
                    </span>
                  </>
                )}
              </div>
              <div className={styles.total_indicator}>
                {isLoading ? (
                  <Skeleton width={169} />
                ) : (
                  <>
                    <span className={styles.total_label}>
                      {'Total Tx Fee Spent'}
                    </span>
                    <span className={styles.total_data}>
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <Indicator
                          value={
                            +(Math.ceil(+totalWasted * 10000) * 0.0001).toFixed(
                              4
                            )
                          }
                          decimals={4}
                          emphasisHint={`Ξ ${totalWasted}`}
                          prefix={'≈ '}
                          suffix={' (Ξ)'}
                        />
                      )}
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
          <Table useCustomContainer={true}>
            <TableHeader>
              <TableRow>
                <TableCell isText={true} isHeader={true}>
                  {failedTransactionsGroupBy ===
                  FAILED_TRANSACTIONS_GROUP_BY.METHOD
                    ? 'Method'
                    : 'Type'}
                </TableCell>
                <TableCell isText={true} isHeader={true}>
                  {failedTransactionsGroupBy ===
                  FAILED_TRANSACTIONS_GROUP_BY.METHOD
                    ? 'Type'
                    : 'Method'}
                </TableCell>
                <TableCell isText={false} isHeader={true} width={76}>
                  {'Quantity'}
                </TableCell>
                <TableCell isText={false} isHeader={true} width={120}>
                  <p>
                    <span className={styles.average_prefix}>{'Avg.'}</span>
                    {'Gas Used'}
                  </p>
                </TableCell>
                <TableCell isText={false} isHeader={true} width={154}>
                  <p>
                    <span className={styles.average_prefix}>{'Avg.'}</span>
                    {'Tx Fee Spent (Ξ)'}
                  </p>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {mintFailedTransactions.length > 0 ? (
                mintFailedTransactions.map((tx, index) =>
                  renderTableRow(
                    index,
                    tx,
                    index > 0 ? mintFailedTransactions[index - 1] : undefined
                  )
                )
              ) : (
                <TableNoData colSpan={5} text="No Failed Transactions" />
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </SectionLayout>
  );
}
