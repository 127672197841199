import { useEffect, useState } from 'react';

import { CHANNEL_APP_HOOKS, EVENT_GAS_SUGGESTION } from '~/constants';
import { useSocket } from '~/contexts/SocketContext';
import usePageVisibility from '~/hooks/usePageVisibility';
import type { GasSuggestionResource } from '~/types';

export default function useGasSuggestion() {
  const isPageVisible = usePageVisibility();
  const [gasSuggestion, setGasSuggestion] =
    useState<GasSuggestionResource | null>(null);
  const socket = useSocket();

  useEffect(() => {
    if (isPageVisible) {
      const channel = socket?.subscribe(CHANNEL_APP_HOOKS);

      channel?.bind(
        EVENT_GAS_SUGGESTION,
        (gasSuggestion: GasSuggestionResource) => {
          setGasSuggestion(gasSuggestion);
        }
      );

      return () => {
        channel?.unbind(EVENT_GAS_SUGGESTION);
      };
    }
  }, [isPageVisible, socket]);

  return gasSuggestion;
}
