import { MouseEvent, useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import API from '~/api';
import { IcnKarma, IcnX } from '~/assets';
import { Loading, SocialProfile, Toaster } from '~/components';
import { X_LOGIN_URL } from '~/constants';
import { useAuth } from '~/contexts/AuthContext';
import useSocialAccounts from '~/data/useSocialAccounts';
import { locationConnectXClickedState } from '~/store/user';
import showErrorToast from '~/utils/showErrorToast';

import styles from './ProfileSection.module.scss';
import UsernameSearchBar from './UsernameSearchBar';
import commonStyles from '../SettingsModal.module.scss';

interface ProfileSectionProps {}

export default function ProfileSection({}: ProfileSectionProps) {
  const { setUserInfo, user } = useAuth();
  const { disconnect, isLoading, xAccount } = useSocialAccounts();
  const setLocationConnectXClicked = useSetRecoilState(
    locationConnectXClickedState
  );

  const [tempModifiedUserInfo, setTempModifiedUserInfo] = useState<{
    username: string;
  }>({
    username: user?.username ?? '',
  });
  const [usernameError, setUsernameError] = useState('');

  const handleConnectX = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLocationConnectXClicked('profile');
    window.location.replace(X_LOGIN_URL);
  };

  const handleDisconnectX = async () => {
    if (xAccount) {
      const result = await disconnect(xAccount.id);
      if (result) {
        Toaster.toast({
          description: 'X disconnected successfully.',
          type: 'success',
        });
      }
    }
  };

  const setProfile = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (user) {
        API.setUsername(tempModifiedUserInfo.username)
          .then(({ data: { username } }) => {
            setUserInfo({
              ...user,
              username,
            });
            Toaster.toast({
              description: 'Your profile is saved.',
              type: 'success',
            });
          })
          .catch((err) => {
            showErrorToast(err);
          });
      }
    },
    [setUserInfo, tempModifiedUserInfo, user]
  );

  return (
    <section className={commonStyles.section}>
      <div className={commonStyles.section_container}>
        <div className={commonStyles.section_title_container}>
          <div className={commonStyles.section_title}>
            <span>{'Profile Details'}</span>
          </div>
          <p className={commonStyles.section_description}>
            {
              'You can add an ENS name in our next update, which will validate ENS ownership.'
            }
          </p>
        </div>
        {user ? (
          <>
            <div className={styles.profile_details_container}>
              <div className={styles.detail_container}>
                <span className={styles.profile_label}>{'Username'}</span>
                <UsernameSearchBar
                  username={user?.username ?? ''}
                  setUsername={setTempModifiedUserInfo}
                  setError={setUsernameError}
                />
                <p className={styles.error_msg}>{usernameError}</p>
              </div>
              <div className={styles.detail_container}>
                <span className={styles.profile_label}>{'Karma'}</span>
                <div className={styles.karma_container}>
                  <IcnKarma />
                  <p className={styles.karma}>
                    {(user?.karma ?? 0).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className={styles.detail_container}>
                <span className={styles.profile_label}>
                  {'Social Connections'}
                </span>
                <div className={styles.social_connection_container}>
                  <div className={styles.social_container}>
                    <IcnX />
                    <span className={styles.social_profile_label}>{'X'}</span>
                  </div>
                  {isLoading ? (
                    <Loading size={24} />
                  ) : !!xAccount ? (
                    <div className={styles.social_profile_container}>
                      <SocialProfile
                        {...xAccount}
                        onDisconnect={handleDisconnectX}
                      />
                    </div>
                  ) : (
                    <button
                      aria-label="Connect X Button"
                      className={styles.btn_connect}
                      onClick={handleConnectX}
                      type="button"
                    >
                      Connect
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.profile_details_container}>
            <Loading size={32} />
          </div>
        )}
      </div>
      <div className={commonStyles.fixed_buttons_container}>
        <button className={commonStyles.btn_save} onClick={setProfile}>
          {'Save'}
        </button>
      </div>
    </section>
  );
}
