import { MouseEvent, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';

import { ContractArtItem } from '~/components';
import {
  DETAIL_LAYOUT,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import useContractArt from '~/data/useContractArt';
import { checksumContractAddressState } from '~/store/contract';

import styles from './ArtSection.module.scss';
import SectionLayout from '../SectionLayout';

interface ArtSectionProps {}

export default function ArtSection({}: ArtSectionProps) {
  const analytics = useAnalyticsContext();
  const contractAddress = useRecoilValue(checksumContractAddressState);
  const { arts, isError, isLoading } = useContractArt(
    'ethereum',
    contractAddress
  );

  const onClickArtItem = useCallback((url: string, position: number) => {
    analytics.track(REDIRECT_CLICKED, {
      chain: 'Ethereum',
      location: REDIRECT_CLICKED_LOCATION.ART,
      position,
      type: REDIRECT_CLICKED_TYPE.MARKETPLACE,
      url,
    });
  }, []);

  const onClickGoogleLens = useCallback(
    (e: MouseEvent<HTMLButtonElement>, url: string, position: number) => {
      e.preventDefault();
      analytics.track(REDIRECT_CLICKED, {
        chain: 'Ethereum',
        location: REDIRECT_CLICKED_LOCATION.ART,
        position,
        type: REDIRECT_CLICKED_TYPE.OTHER,
        url: `https://lens.google.com/uploadbyurl?url=${url}`,
      });
      window.open(
        `https://lens.google.com/uploadbyurl?url=${encodeURIComponent(url)}`,
        '_blank'
      );
    },
    []
  );

  return (
    <SectionLayout boundTrigger="" enableBound={false} id={'art'}>
      <div className={styles.section_title_container}>
        <h2 className={styles.section_title}>
          {isLoading ? <Skeleton width={32} height={24} /> : DETAIL_LAYOUT.ART}
        </h2>
      </div>
      {isError || arts?.length === 0 ? (
        <span className={styles.no_data}>
          {`We couldn't find any arts to display`}
        </span>
      ) : (
        <div className={styles.inner_container}>
          {isLoading || !arts
            ? Array.from({ length: 5 }).map((_, index) => (
                <div
                  className={styles.skeleton_container}
                  key={`art_skeleton_${index}`}
                >
                  <div className={styles.skeleton_item}>
                    <Skeleton width={250} height={250} />
                  </div>
                  <Skeleton width={120} height={12} />
                </div>
              ))
            : arts.map((art, index) => {
                const key = `${contractAddress}_${art.timestamp}_${index}`;
                return (
                  <ContractArtItem
                    key={key}
                    address={contractAddress}
                    art={art}
                    onClick={onClickArtItem}
                    onClickGoogleLens={onClickGoogleLens}
                    position={index + 1}
                  />
                );
              })}
        </div>
      )}
    </SectionLayout>
  );
}
