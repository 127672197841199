import { ComponentProps, Suspense } from 'react';

import useIsMounted from '~/hooks/useIsMounted';

export default function SSRSafeSuspense(
  props: ComponentProps<typeof Suspense>
) {
  const isMounted = useIsMounted();

  if (isMounted) {
    return <Suspense {...props} />;
  }
  return <>{props.fallback}</>;
}
