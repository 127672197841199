import cn from 'classnames';

import { IcnSmallBell, IcnSmallLock } from '~/assets';
import { ToggleSwitch } from '~/components';
import useWebPushSetting from '~/data/useWebPushSetting';

import styles from '../NotificationsSection.module.scss';

interface WebPushSettingProps {
  notificationEnabled: boolean;
  toggleNotification: () => void;
}

export default function WebPushSetting({
  notificationEnabled,
  toggleNotification,
}: WebPushSettingProps) {
  const { notificationPermission } = useWebPushSetting();

  return (
    <div className={styles.provider_container}>
      <span className={styles.provider_title}>Web Push</span>
      <div className={styles.provider_setting_container}>
        <div className={styles.toggle_switch_container}>
          <ToggleSwitch
            checked={
              notificationEnabled && notificationPermission === 'granted'
            }
            disabled={notificationPermission !== 'granted'}
            name="Web Push Notification Toggle Switch"
            onChange={toggleNotification}
          />
        </div>
        <span
          className={cn(styles.notification_label, {
            [styles.label_disabled]: notificationPermission !== 'granted',
          })}
        >
          {'Enable Browser Notifications'}
        </span>
      </div>
      {notificationPermission !== 'granted' && (
        <div className={styles.information_container}>
          <span className={styles.information_title}>
            {`Turn on CatchMint Notifications`}
          </span>
          <div className={styles.information}>
            {`1. Choose `}
            <div className={styles.information_icon}>
              <IcnSmallLock />
            </div>
            {` in your browser's address bar.`}
          </div>
          <div className={styles.information}>
            {`2. Locate `}
            <div className={styles.information_icon}>
              <IcnSmallBell />
            </div>
            {`Notifications and select`}
            <span>{`"Allow"`}</span>
            {`.`}
          </div>
        </div>
      )}
    </div>
  );
}
