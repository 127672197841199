import {
  AVAILABLE_IMAGE_EXTENSION,
  AVAILABLE_VIDEO_EXTENSION,
  FILE_UPLOAD_SIZE_MAX_LIMIT,
} from '~/constants';
import type { AvailableImageExtension, AvailableVideoExtension } from '~/types';

import getExtension from './getExtension';

const validateFileExtension = (fileName: string) => {
  const extension = getExtension(fileName);
  if (
    !extension ||
    !(
      Object.values(AVAILABLE_IMAGE_EXTENSION).includes(
        extension as AvailableImageExtension
      ) ||
      Object.values(AVAILABLE_VIDEO_EXTENSION).includes(
        extension as AvailableVideoExtension
      )
    )
  ) {
    return false;
  }

  return true;
};

const validateFileUpload = (target: EventTarget & HTMLInputElement) => {
  const files = (target.files as FileList)[0];

  if (files === undefined) {
    return;
  }

  if (!validateFileExtension(files.name)) {
    target.value = '';
    return;
  }

  if (files.size > FILE_UPLOAD_SIZE_MAX_LIMIT) {
    target.value = '';
    return;
  }

  return files;
};

export default validateFileUpload;
