import cn from 'classnames';
import Linkify from 'linkify-react';
import { useEffect, useRef, useState } from 'react';

import {
  IcnDead,
  IcnDeployer,
  IcnDownvote,
  IcnDownvoted,
  IcnFlagged,
  IcnFoldComments,
  IcnPinnedComment,
  IcnUnfoldComments,
  IcnUpvote,
  IcnUpvoted,
} from '~/assets';
import { CommentInput, Popover } from '~/components';
import {
  DEAD_SCORE_COUNT,
  ENABLE_TO_DOWNVOTE_KARMA,
  TOWARDS_DEATH_FIRST_SCORE_COUNT,
  TOWARDS_DEATH_SECOND_SCORE_COUNT,
  TOWARDS_DEATH_THIRD_SCORE_COUNT,
} from '~/constants';
import type { Comment, FlagParams, VoteParams } from '~/types';
import formatTimeAgo from '~/utils/formatTimeAgo';
import scrollIntoViewById from '~/utils/scrollIntoViewById';

import styles from './CommentItem.module.scss';

interface CommentItemProps {
  childrenCount: number;
  comment: Comment;
  hasReply: boolean;
  highlight: boolean;
  isFolded: boolean;
  isFoldedRoot: boolean;
  isMine: boolean;
  isUserDeployer: boolean;
  myKarma: number;
  onAddReply: (
    text: string,
    parentId: string,
    callback: () => void
  ) => Promise<boolean>;
  onDelete: (uuid: string, callback: () => void) => Promise<boolean>;
  onDownvote: (uuid: string, callback: () => void) => Promise<boolean>;
  onEdit: (
    uuid: string,
    text: string,
    callback: () => void
  ) => Promise<boolean>;
  onFlag: (params: FlagParams, callback: () => void) => Promise<boolean>;
  onFold: (uuid: string) => void;
  onPin: (uuid: string, callback: () => void) => void;
  onUnflag: (params: FlagParams, callback: () => void) => Promise<boolean>;
  onUnfold: (uuid: string) => void;
  onUnpin: (uuid: string, callback: () => void) => void;
  onUnvote: (VoteParams: VoteParams, callback: () => void) => Promise<boolean>;
  onUnvouch: (uuid: string, callback: () => void) => Promise<boolean>;
  onUpvote: (uuid: string, callback: () => void) => Promise<boolean>;
  onVouch: (uuid: string, callback: () => void) => Promise<boolean>;
  pathId: string;
}

const LEFT_SPACING = 32;

export default function CommentItem({
  childrenCount,
  comment: {
    comment,
    created,
    flagScore,
    isDeployer,
    isDownvote,
    isFlagged,
    isFlaggedItem,
    isPinned,
    isUpvote,
    isVouched,
    modified,
    sortPath,
    totalScore,
    username,
    uuid,
  },
  hasReply,
  highlight,
  isFolded,
  isFoldedRoot,
  isMine,
  isUserDeployer,
  myKarma,
  onAddReply,
  onDelete,
  onDownvote,
  onEdit,
  onFlag,
  onFold,
  onPin,
  onUnflag,
  onUnfold,
  onUnpin,
  onUnvote,
  onUnvouch,
  onUpvote,
  onVouch,
  pathId,
}: CommentItemProps) {
  const depth = sortPath.length - 1;
  const diff = Date.now() - new Date(created).getTime();
  const isEnableToPatch = !hasReply && diff < 2 * 60 * 60 * 1_000;
  const isDead = totalScore < DEAD_SCORE_COUNT;
  const paddingLeft = depth * LEFT_SPACING;

  const isRequesting = useRef(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const onDeleteClick = () => {
    isRequesting.current = true;
    onDelete(uuid, () => {
      isRequesting.current = false;
    });
  };

  const onDownvoteClick = () => {
    isRequesting.current = true;
    onDownvote(uuid, () => {
      isRequesting.current = false;
    });
  };

  const onFlagClicked = () => {
    isRequesting.current = true;
    onFlag({ flagScore, uuid }, () => {
      isRequesting.current = false;
    });
  };

  const onPinClicked = () => {
    isRequesting.current = true;
    onPin(uuid, () => {
      isRequesting.current = false;
    });
  };

  const onReply = async (text: string) => {
    isRequesting.current = true;
    const isSuccess = await onAddReply(text, uuid, () => {
      setIsShow(false);
      isRequesting.current = false;
    });
    return isSuccess;
  };

  const onToggle = () => {
    setIsShow((isShow: boolean) => !isShow);
  };

  const onUnflagClicked = () => {
    isRequesting.current = true;
    onUnflag({ flagScore, uuid }, () => {
      isRequesting.current = false;
    });
  };

  const onUnpinClicked = () => {
    isRequesting.current = true;
    onUnpin(uuid, () => {
      isRequesting.current = false;
    });
  };

  const onUnvoteClick = () => {
    isRequesting.current = true;
    onUnvote({ isDownvote, isUpvote, uuid }, () => {
      isRequesting.current = false;
    });
  };

  const onUnvouchClick = () => {
    isRequesting.current = true;
    onUnvouch(uuid, () => {
      isRequesting.current = false;
    });
  };

  const onUpdate = async (text: string) => {
    isRequesting.current = true;
    const isSuccess = await onEdit(uuid, text, () => {
      setIsEditing(false);
      setIsShow(false);
      isRequesting.current = false;
    });
    return isSuccess;
  };

  const onUpvoteClick = () => {
    isRequesting.current = true;
    onUpvote(uuid, () => {
      isRequesting.current = false;
    });
  };

  const onVouchClick = () => {
    isRequesting.current = true;
    onVouch(uuid, () => {
      isRequesting.current = false;
    });
  };

  useEffect(() => {
    if (highlight) {
      scrollIntoViewById(pathId);
    }
  }, []);

  return (
    <li
      style={{
        marginTop: depth > 0 ? '0px' : undefined,
      }}
      className={cn(styles.container, {
        [styles.no_show]: isFolded,
        [styles.pinned]: isPinned,
        [styles.highlight]: highlight,
      })}
      id={pathId}
    >
      <div
        style={{
          paddingLeft: `${paddingLeft}px`,
        }}
        className={styles.inner_container}
      >
        <div className={styles.text_area}>
          <div className={styles.info}>
            <div
              className={cn(styles.username, {
                [styles.me]: isMine,
                [styles.pinned_username]: isPinned,
              })}
            >
              {username ?? 'unknown'}
              {isDeployer && (
                <span className={styles.deployer_container}>
                  {`(`}
                  <div className={styles.icn_deployer}>
                    <IcnDeployer />
                  </div>
                  {`deployer)`}
                </span>
              )}
            </div>
            <div
              className={cn(styles.info_side, {
                [styles.pinned_info_side]: isPinned,
              })}
            >
              <div className={styles.info_side_data}>
                {formatTimeAgo(diff)}
                {created !== modified && (
                  <span className={styles.edited}>{`(edited)`}</span>
                )}
              </div>
              {(isDead || isFlaggedItem) && (
                <div className={styles.status_container}>
                  {isFlaggedItem && (
                    <Popover
                      render={() => (
                        <div className="default_popover">
                          <span>{'flagged'}</span>
                        </div>
                      )}
                      animation
                      placement="top"
                    >
                      <div className="center">
                        <IcnFlagged />
                      </div>
                    </Popover>
                  )}
                  {isDead && (
                    <Popover
                      render={() => (
                        <div className="default_popover">
                          <span>{'dead'}</span>
                        </div>
                      )}
                      animation
                      placement="top"
                    >
                      <div className="center">
                        <IcnDead />
                      </div>
                    </Popover>
                  )}
                </div>
              )}
              {!isMine && myKarma > 30 && !isFlagged && !isVouched && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.info_side_data}>
                    <button
                      className={styles.action}
                      disabled={isRequesting.current}
                      onClick={onFlagClicked}
                    >
                      flag
                    </button>
                  </div>
                </>
              )}
              {!isMine && isFlagged && !isVouched && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.info_side_data}>
                    <button
                      className={styles.action}
                      disabled={isRequesting.current}
                      onClick={onUnflagClicked}
                    >
                      unflag
                    </button>
                  </div>
                </>
              )}
              {!isMine &&
                myKarma > 30 &&
                isFlaggedItem &&
                !isFlagged &&
                !isVouched && (
                  <>
                    <div className={styles.divider} />
                    <div className={styles.info_side_data}>
                      <button
                        className={styles.action}
                        disabled={isRequesting.current}
                        onClick={onVouchClick}
                      >
                        {'vouch'}
                      </button>
                    </div>
                  </>
                )}
              {!isMine && isVouched && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.info_side_data}>
                    <button
                      className={styles.action}
                      disabled={isRequesting.current}
                      onClick={onUnvouchClick}
                    >
                      {'unvouch'}
                    </button>
                  </div>
                </>
              )}
              {depth === 0 &&
                isUserDeployer &&
                (isPinned ? (
                  <>
                    <div className={styles.divider} />
                    <div className={styles.info_side_data}>
                      <button
                        className={styles.action}
                        disabled={isRequesting.current}
                        onClick={onUnpinClicked}
                      >
                        unpin
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.divider} />
                    <div className={styles.info_side_data}>
                      <button
                        className={styles.action}
                        disabled={isRequesting.current}
                        onClick={onPinClicked}
                      >
                        pin
                      </button>
                    </div>
                  </>
                ))}
              {isMine && isEnableToPatch && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.info_side_data}>
                    <button
                      className={styles.action}
                      disabled={isRequesting.current}
                      onClick={() => setIsEditing((prev) => !prev)}
                    >
                      {isEditing ? 'cancel edit' : 'edit'}
                    </button>
                  </div>
                  <div className={styles.divider} />
                  <div className={styles.info_side_data}>
                    <button
                      className={styles.action}
                      disabled={isRequesting.current}
                      onClick={onDeleteClick}
                    >
                      delete
                    </button>
                  </div>
                </>
              )}
              {isFoldedRoot ? (
                <div className={styles.info_side_data}>
                  <button
                    className={styles.action}
                    disabled={isRequesting.current}
                    onClick={() => onUnfold(pathId)}
                  >
                    <IcnUnfoldComments />
                    {`${childrenCount} more`}
                  </button>
                </div>
              ) : (
                <div className={styles.info_side_data}>
                  <button
                    className={styles.action}
                    disabled={isRequesting.current}
                    onClick={() => onFold(pathId)}
                  >
                    <IcnFoldComments />
                  </button>
                </div>
              )}
            </div>
            {isPinned && (
              <Popover
                render={() => (
                  <div className="default_popover">
                    <span>{'Pinned by deployer'}</span>
                  </div>
                )}
                animation
                placement="top"
              >
                <div className={styles.icn_pinned}>
                  <IcnPinnedComment />
                </div>
              </Popover>
            )}
          </div>
          {isEditing ? (
            <CommentInput
              comment={comment}
              isEditing={isEditing}
              isLoading={isRequesting.current}
              submit={onUpdate}
            />
          ) : (
            <div
              className={cn(styles.contents_container, {
                [styles.no_show]: isFoldedRoot,
              })}
            >
              <p
                className={cn(styles.text, {
                  ['dead']: isDead,
                  ['towards_death_first']:
                    !isDead &&
                    totalScore <= TOWARDS_DEATH_FIRST_SCORE_COUNT &&
                    totalScore > TOWARDS_DEATH_SECOND_SCORE_COUNT,
                  ['towards_death_second']:
                    !isDead &&
                    totalScore <= TOWARDS_DEATH_SECOND_SCORE_COUNT &&
                    totalScore > TOWARDS_DEATH_THIRD_SCORE_COUNT,
                  ['towards_death_third']:
                    !isDead &&
                    totalScore <= TOWARDS_DEATH_THIRD_SCORE_COUNT &&
                    totalScore > DEAD_SCORE_COUNT,
                })}
              >
                <Linkify
                  options={{
                    className: styles.link,
                    defaultProtocol: 'https',
                    rel: 'noopener noreferrer',
                    target: '_blank',
                  }}
                >
                  {comment.trim()}
                </Linkify>
              </p>
              {!isDead && (
                <div className={styles.footer}>
                  {!isFoldedRoot && !isMine && (
                    <div className={styles.vote}>
                      {isUpvote ? (
                        <Popover
                          render={() => (
                            <div className="default_popover">
                              <span>{'Unvote'}</span>
                            </div>
                          )}
                          animation
                          placement="top"
                        >
                          <button
                            className={cn('btn_icon', styles.btn_vote)}
                            disabled={isRequesting.current}
                            onClick={onUnvoteClick}
                          >
                            <IcnUpvoted />
                          </button>
                        </Popover>
                      ) : (
                        <Popover
                          render={() => (
                            <div className="default_popover">
                              <span>{'Upvote'}</span>
                            </div>
                          )}
                          animation
                          placement="top"
                        >
                          <button
                            className={cn('btn_icon', styles.btn_vote)}
                            disabled={isRequesting.current}
                            onClick={onUpvoteClick}
                          >
                            <IcnUpvote />
                          </button>
                        </Popover>
                      )}
                      {myKarma > ENABLE_TO_DOWNVOTE_KARMA &&
                        (isDownvote ? (
                          <Popover
                            render={() => (
                              <div className="default_popover">
                                <span>{'Unvote'}</span>
                              </div>
                            )}
                            animation
                            placement="top"
                          >
                            <button
                              className={cn('btn_icon', styles.btn_vote)}
                              disabled={isRequesting.current}
                              onClick={onUnvoteClick}
                            >
                              <IcnDownvoted />
                            </button>
                          </Popover>
                        ) : (
                          <Popover
                            render={() => (
                              <div className="default_popover">
                                <span>{'Downvote'}</span>
                              </div>
                            )}
                            animation
                            placement="top"
                          >
                            <button
                              className={cn('btn_icon', styles.btn_vote)}
                              disabled={isRequesting.current}
                              onClick={onDownvoteClick}
                            >
                              <IcnDownvote />
                            </button>
                          </Popover>
                        ))}
                    </div>
                  )}
                  <div className={styles.reply_btn_box}>
                    <button
                      className={cn(styles.reply_btn, styles.action)}
                      onClick={onToggle}
                    >
                      reply
                    </button>
                  </div>
                </div>
              )}
              {isShow && (
                <CommentInput
                  isDead={isDead}
                  isLoading={isRequesting.current}
                  submit={onReply}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </li>
  );
}
