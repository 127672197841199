import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { IcnNew } from '~/assets';
import { ExternalLink, Popover } from '~/components';
import { AVAILABLE_CHAIN, REDIRECT_CLICKED_LOCATION } from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import useQuicklinks from '~/hooks/useQuicklinks';
import type { AvailableChain, ContractQuickLink } from '~/types';

import styles from './LinkButton.module.scss';

interface LinkButtonProps {
  isLoading: boolean;
  platform: ContractQuickLink;
  position: number;
  address: string;
  chain?: AvailableChain;
  disabled?: boolean;
  isNew?: boolean;
  nofollow?: boolean;
  url?: string;
}

export default function LinkButton({
  address,
  chain = AVAILABLE_CHAIN.ETHEREUM,
  disabled = false,
  isLoading,
  isNew = false,
  nofollow = false,
  platform,
  position,
  url,
}: LinkButtonProps) {
  const analytics = useAnalyticsContext();
  const { getUrl, icon, type } = useQuicklinks({ platform });
  const receivedUrl = getUrl(address, url);

  if (isLoading) {
    return (
      <Skeleton
        circle
        width={28}
        height={28}
        style={{ verticalAlign: 'top' }}
      />
    );
  }

  if (!receivedUrl) return null;

  if (disabled) {
    return (
      <ExternalLink
        allowsUtmSource={false}
        className={cn(styles.button, styles.btn_disabled)}
        label={platform.toLowerCase()}
        role="button"
      >
        {icon}
      </ExternalLink>
    );
  }

  return (
    <Popover
      render={() => (
        <div className="default_popover">
          <span>{platform}</span>
        </div>
      )}
      animation
      placement="top"
    >
      <ExternalLink
        className={cn(styles.button, {
          [styles.btn_normal]: !isNew,
        })}
        label={platform.toLowerCase()}
        nofollow={nofollow}
        onClick={() => {
          analytics.track(REDIRECT_CLICKED, {
            chain,
            location: REDIRECT_CLICKED_LOCATION.CONTRACT_INFO,
            url: receivedUrl,
            position,
            type,
          });
        }}
        role="link"
        url={receivedUrl}
      >
        {icon}
        {isNew && (
          <div className={styles.new_badge}>
            <IcnNew />
          </div>
        )}
      </ExternalLink>
    </Popover>
  );
}
