import { Markdown, TimeAgo } from '~/components';
import { REDIRECT_CLICKED_LOCATION } from '~/constants';
import type { AvailableChain, ContractAnnouncement } from '~/types';

import styles from './AnnouncementItem.module.scss';

interface AnnouncementItemProps extends ContractAnnouncement {
  chain?: AvailableChain;
}

export default function AnnouncementItem({
  chain = 'Ethereum',
  created,
  description,
  title,
}: AnnouncementItemProps) {
  return (
    <li className={styles.container}>
      <span className={styles.time}>
        <TimeAgo date={new Date(created)} highlight />
      </span>
      <span className={styles.title}>{title}</span>
      <div className={styles.description}>
        <Markdown
          chain={chain}
          location={REDIRECT_CLICKED_LOCATION.ANNOUNCEMENTS}
        >
          {description}
        </Markdown>
      </div>
    </li>
  );
}
