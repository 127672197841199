import { ReactNode, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  IcnAlphaHQ,
  IcnBreezeAIO,
  IcnFenixLabs,
  IcnHangout,
  IcnJpegAlerts,
  IcnLucidLabs,
  IcnMetaSniper,
  IcnMinTech,
  IcnNfthunder,
  IcnNftSensei,
  IcnTenX,
  IcnTimith,
  IcnWaifu,
} from '~/assets';
import { Toaster } from '~/components';
import {
  ALPHAHQ_QT_URL,
  BREEZEAIO_QT_URL,
  FENIXLABS_QT_URL,
  HANGOUT_QT_URL,
  JPEGALERTS_QT_URL,
  LUCIDLABS_QT_URL,
  METASNIPER_QT_URL,
  MINTECH_QT_URL,
  NFTHUNDER_QT_URL,
  NFTSENSEI_QT_URL,
  TENX_QT_URL,
  TIMITH_QT_URL,
  WAIFU_QT_URL,
} from '~/constants';
import { useAuth } from '~/contexts/AuthContext';
import {
  automationModalState,
  checksumContractAddressState,
} from '~/store/contract';
import type {
  AutomationPartner,
  ContractSuccessfulTransaction,
  MintableFunction,
} from '~/types';
import getAlignedParams from '~/utils/getAlignedParams';
import setUtmSource from '~/utils/setUtmSource';

interface UseAutomationQuickTaskReturnTypes {
  icon: ReactNode;
  isMintable: (
    tx: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => boolean;
  mint: (
    tx: ContractSuccessfulTransaction,
    mintFunction: MintableFunction,
    deployer?: string | null
  ) => void;
}

export default function useAutomationQuickTask(
  option: AutomationPartner | null
): UseAutomationQuickTaskReturnTypes {
  const address = useRecoilValue(checksumContractAddressState);
  const setAutomationModal = useSetRecoilState(automationModalState);
  const { user } = useAuth();

  const isMintable = (
    option: AutomationPartner,
    {
      data,
      decodedData,
      fromAddress,
      gasUsed,
      maxFeePerGas,
      maxPriorityFeePerGas,
      transactionHash,
    }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ): boolean =>
    ({
      ['alphahq']: !!decodedData && !!gasUsed && !!mintFunction.name,
      ['breezeaio']: !!data && !!mintFunction.name,
      ['fenixlabs']: !!data && !!mintFunction.name && !!transactionHash,
      ['hangout']:
        !!data &&
        !!maxFeePerGas &&
        !!maxPriorityFeePerGas &&
        !!mintFunction.name,
      ['jpegalerts']: !!data && !!mintFunction.name,
      ['lucidlabs']: !!mintFunction.inputs && !!mintFunction.name,
      ['metasniper']: !!data && !!mintFunction.name,
      ['mintech']: !!data && !!mintFunction.name,
      ['nfthunder']: !!data && !!mintFunction.name,
      ['nftsensei']: !!mintFunction.name,
      ['tenx']: !!mintFunction.inputs && !!mintFunction.name,
      ['timith']:
        !!data &&
        !!decodedData &&
        !!gasUsed &&
        !!maxFeePerGas &&
        !!maxPriorityFeePerGas &&
        !!mintFunction.name &&
        !!transactionHash,
      ['waifu']: !!fromAddress && !!mintFunction.name && !!transactionHash,
    }[option]);

  const quickTaskAlphaHQ = (
    {
      decodedData,
      gasUsed,
      maxFeePerGas,
      maxPriorityFeePerGas,
      value,
    }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (!(mintFunction.name && user)) return;

    const params: { [key: string]: any } = new URLSearchParams({
      contract_address: address,
      mint_function: mintFunction.name,
      mint_inputs: JSON.stringify([{ ...decodedData, mintValue: value }]),
      gas_limit: gasUsed.toString(),
      origin: 'catchmint',
      minter_address: user.address,
    });
    if (maxFeePerGas) params['max_fee'] = maxFeePerGas;
    if (maxPriorityFeePerGas) params['max_prio'] = maxPriorityFeePerGas;
    const qtURL = `${ALPHAHQ_QT_URL}?${params.toString()}`;

    window.open(setUtmSource(qtURL), 'alphahq');
    return;
  };

  const quickTaskBreezeAIO = (
    { data, value }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (!data || !mintFunction.name || !user) return;

    const params: { [key: string]: any } = {
      address,
      cost: value,
      customHex: data,
      minterAddress: user.address,
    };
    const urlSearchParams = new URLSearchParams(params);
    const qtURL = `${BREEZEAIO_QT_URL}?${urlSearchParams.toString()}`;

    window.open(setUtmSource(qtURL), 'breezeaio');
    return;
  };

  const quickTaskFenixLabs = (
    {
      data,
      maxFeePerGas,
      maxPriorityFeePerGas,
      transactionHash,
      value,
    }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (!data || !mintFunction.name || !transactionHash || !user) return;

    const params: { [key: string]: any } = {
      contractAddress: address,
      hexData: data,
      minterAddress: user.address,
      price: value,
      txHash: transactionHash,
    };
    if (maxFeePerGas) params['gasMaxFeePerGas'] = maxFeePerGas;
    if (maxPriorityFeePerGas)
      params['gasMaxPriorityPerGas'] = maxPriorityFeePerGas;
    const urlSearchParams = new URLSearchParams(params);
    const qtURL = `${FENIXLABS_QT_URL}?${urlSearchParams.toString()}`;

    window.open(setUtmSource(qtURL), 'fenixlabs');
    return;
  };

  const quickTaskHangout = (
    {
      data,
      fromAddress,
      maxFeePerGas,
      maxPriorityFeePerGas,
      value,
    }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (
      !data ||
      !fromAddress ||
      !maxFeePerGas ||
      !maxPriorityFeePerGas ||
      !mintFunction.name ||
      !user
    )
      return;

    const params: { [key: string]: any } = {
      contractAddress: address,
      from: fromAddress,
      hexData: data,
      maxFeePerGas,
      maxPriorityPerGas: maxPriorityFeePerGas,
      minterAddress: user.address,
      origin: 'catchmint',
      price: value,
    };
    const qtURL = `${HANGOUT_QT_URL}`;

    fetch(qtURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
      redirect: 'follow',
    })
      .then((res) => {
        if (res.status === 400) {
          Toaster.toast({
            description: 'It seems that the app is not running.',
            type: 'error',
          });
        }
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          Toaster.toast({
            description: 'Your automation app needs to be up and running.',
            type: 'error',
          });
        }
      });
    return;
  };

  const quickTaskJpegAlerts = (
    { data, value }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (!data || !mintFunction.name || !user) return;

    const params: { [key: string]: any } = {
      contractAddress: address,
      hexData: data,
      minterAddress: user.address,
      price: value,
    };
    const qtURL = `${JPEGALERTS_QT_URL}`;

    fetch(qtURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
      redirect: 'follow',
    })
      .then((res) => {
        if (res.status === 400) {
          Toaster.toast({
            description: 'It seems that the app is not running.',
            type: 'error',
          });
        }
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          Toaster.toast({
            description: 'Your automation app needs to be up and running.',
            type: 'error',
          });
        }
      });
    return;
  };

  const quickTaskLucidLabs = (
    successfulTx: ContractSuccessfulTransaction,
    mintFunction: MintableFunction,
    deployer?: string | null
  ) => {
    setAutomationModal({
      deployer,
      isOpen: true,
      mintFunction,
      successfulTx,
      execute: (urlSearchParams: URLSearchParams) => {
        const qtURL = `${LUCIDLABS_QT_URL}?${urlSearchParams.toString()}`;

        fetch(qtURL, {
          method: 'GET',
        })
          .then((res) => {
            if (res.status === 400) {
              Toaster.toast({
                description: 'It seems that the app is not running.',
                type: 'error',
              });
            }
          })
          .catch((err) => {
            if (err.message === 'Failed to fetch') {
              Toaster.toast({
                description: 'Your automation app needs to be up and running.',
                type: 'error',
              });
            }
          });
        return;
      },
    });
  };

  const quickTaskMetaSniper = (
    { data, value }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (!data || !mintFunction.name || !user) return;

    const params: { [key: string]: any } = {
      contractAddress: address,
      minterAddress: user.address,
      mintData: data,
      mintPrice: value,
      origin: 'catchmint',
    };
    const urlSearchParams = new URLSearchParams(params);
    const qtURL = `${METASNIPER_QT_URL}?${urlSearchParams.toString()}`;

    fetch(qtURL, {
      method: 'GET',
    })
      .then((res) => {
        if (res.status === 400) {
          Toaster.toast({
            description: 'It seems that the app is not running.',
            type: 'error',
          });
        }
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          Toaster.toast({
            description: 'Your automation app needs to be up and running.',
            type: 'error',
          });
        }
      });
    return;
  };

  const quickTaskMintech = (
    { data, value }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (!data || !mintFunction.name || !user) return;

    const params: { [key: string]: any } = {
      contract_address: address,
      group_name: 1,
      hexData: data,
      minterAddress: user.address,
      mint_price: value,
      origin: 'catchmint',
    };
    const qtURL = `${MINTECH_QT_URL}`;

    fetch(qtURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then((res) => {
        if (res.status === 400) {
          Toaster.toast({
            description: 'It seems that the app is not running.',
            type: 'error',
          });
        }
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          Toaster.toast({
            description: 'Your automation app needs to be up and running.',
            type: 'error',
          });
        }
      });
    return;
  };

  const quickTaskNfthunder = (
    { data, value }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (!data || !mintFunction.name || !user) return;

    const params: { [key: string]: any } = {
      contract: address,
      function: data,
      minterAddress: user.address,
      origin: 'catchmint',
      price: value,
    };
    const urlSearchParams = new URLSearchParams(params);
    const qtURL = `${NFTHUNDER_QT_URL}?${urlSearchParams.toString()}`;

    fetch(qtURL, {
      method: 'GET',
    })
      .then((res) => {
        if (res.status === 400) {
          Toaster.toast({
            description: 'It seems that the app is not running.',
            type: 'error',
          });
        }
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          Toaster.toast({
            description: 'Your automation app needs to be up and running.',
            type: 'error',
          });
        }
      });
    return;
  };

  const quickTaskNftSensei = (
    { transactionHash, value }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (!mintFunction.name || !user) return;

    const type = mintFunction.inputs
      ?.map((input) => `${input.type}`, '')
      .join(',');

    const functionName = `${mintFunction.name}(${type})`;

    const params = new URLSearchParams({
      contract: address,
      functionName,
      hash: transactionHash,
      minterAddress: user.address,
      value,
    });
    const qtURL = `${NFTSENSEI_QT_URL}&${params.toString()}`;

    window.open(setUtmSource(qtURL), 'nftsensei');
    return;
  };

  const quickTaskTenx = (
    successfulTx: ContractSuccessfulTransaction,
    mintFunction: MintableFunction,
    deployer?: string | null
  ) => {
    setAutomationModal({
      deployer,
      isOpen: true,
      mintFunction,
      successfulTx,
      execute: (urlSearchParams: URLSearchParams) => {
        const qtURL = `${TENX_QT_URL}?${urlSearchParams.toString()}`;

        fetch(qtURL, {
          method: 'GET',
        })
          .then((res) => {
            if (res.status === 400) {
              Toaster.toast({
                description: 'It seems that the app is not running.',
                type: 'error',
              });
            }
          })
          .catch((err) => {
            if (err.message === 'Failed to fetch') {
              Toaster.toast({
                description: 'Your automation app needs to be up and running.',
                type: 'error',
              });
            }
          });
        return;
      },
    });
  };

  const quickTaskTimith = (
    {
      data,
      decodedData,
      gasUsed,
      maxFeePerGas,
      maxPriorityFeePerGas,
      transactionHash,
      value,
    }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (
      !data ||
      !decodedData ||
      !gasUsed ||
      !maxFeePerGas ||
      !maxPriorityFeePerGas ||
      !mintFunction.name ||
      !transactionHash ||
      !user
    )
      return;

    const alignedParams =
      getAlignedParams(decodedData, mintFunction.inputs) ?? {};

    const type = mintFunction.inputs
      ?.map((input) => `${input.type}`, '')
      .join(',');

    const functionName = `${mintFunction.name}(${type})`;

    const params: { [key: string]: any } = {
      contractAddress: address,
      data,
      functionName,
      functionArgs: Object.values(alignedParams),
      gasLimit: gasUsed.toString(),
      hash: transactionHash,
      maxFeePerGas,
      maxPriorityFeePerGas,
      minterAddress: user.address,
      value,
    };
    const qtURL = TIMITH_QT_URL;

    fetch(qtURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then((res) => {
        if (res.status === 400) {
          Toaster.toast({
            description: 'It seems that the app is not running.',
            type: 'error',
          });
        }
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          Toaster.toast({
            description: 'Your automation app needs to be up and running.',
            type: 'error',
          });
        }
      });
    return;
  };

  const quickTaskWaifu = (
    { fromAddress, transactionHash }: ContractSuccessfulTransaction,
    mintFunction: MintableFunction
  ) => {
    if (!fromAddress || !mintFunction.name || !transactionHash || !user) return;

    const params: { [key: string]: any } = {
      service: 'tasks_quickTask',
      data: {
        rawHex: transactionHash,
        sender: fromAddress,
        minterAddress: user.address,
        origin: 'catchmint',
      },
    };
    const qtURL = WAIFU_QT_URL;

    fetch(qtURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then((res) => {
        if (res.status === 400) {
          Toaster.toast({
            description: 'It seems that the app is not running.',
            type: 'error',
          });
        }
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          Toaster.toast({
            description: 'Your automation app needs to be up and running.',
            type: 'error',
          });
        }
      });
    return;
  };

  const renderIcon = useCallback(
    (option: AutomationPartner) =>
      ({
        ['alphahq']: <IcnAlphaHQ />,
        ['breezeaio']: <IcnBreezeAIO />,
        ['fenixlabs']: <IcnFenixLabs />,
        ['hangout']: <IcnHangout />,
        ['jpegalerts']: <IcnJpegAlerts />,
        ['lucidlabs']: <IcnLucidLabs />,
        ['metasniper']: <IcnMetaSniper />,
        ['mintech']: <IcnMinTech />,
        ['nfthunder']: <IcnNfthunder />,
        ['nftsensei']: <IcnNftSensei />,
        ['tenx']: <IcnTenX />,
        ['timith']: <IcnTimith />,
        ['waifu']: <IcnWaifu />,
      }[option]),
    []
  );

  const setQuickTask = (
    option: AutomationPartner,
    tx: ContractSuccessfulTransaction,
    mintFunction: MintableFunction,
    deployer?: string | null
  ) => {
    if (option === 'alphahq') return quickTaskAlphaHQ(tx, mintFunction);
    if (option === 'breezeaio') return quickTaskBreezeAIO(tx, mintFunction);
    if (option === 'fenixlabs') return quickTaskFenixLabs(tx, mintFunction);
    if (option === 'hangout') return quickTaskHangout(tx, mintFunction);
    if (option === 'jpegalerts') return quickTaskJpegAlerts(tx, mintFunction);
    if (option === 'lucidlabs')
      return quickTaskLucidLabs(tx, mintFunction, deployer);
    if (option === 'metasniper') return quickTaskMetaSniper(tx, mintFunction);
    if (option === 'mintech') return quickTaskMintech(tx, mintFunction);
    if (option === 'nfthunder') return quickTaskNfthunder(tx, mintFunction);
    if (option === 'nftsensei') return quickTaskNftSensei(tx, mintFunction);
    if (option === 'tenx') return quickTaskTenx(tx, mintFunction, deployer);
    if (option === 'timith') return quickTaskTimith(tx, mintFunction);
    if (option === 'waifu') return quickTaskWaifu(tx, mintFunction);
  };

  return {
    icon: option ? renderIcon(option) : null,
    isMintable: (
      tx: ContractSuccessfulTransaction,
      mintFunction: MintableFunction
    ) => (option ? isMintable(option, tx, mintFunction) : false),
    mint: (
      tx: ContractSuccessfulTransaction,
      mintFunction: MintableFunction,
      deployer?: string | null
    ) => (option ? setQuickTask(option, tx, mintFunction, deployer) : false),
  };
}
