import { useQueries } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useDeepCompareEffect from 'use-deep-compare-effect';

import API from '~/api';
import { DEFAULT_TOP_100_HOLDERS, TOP_100_HOLDERS_VIEW } from '~/constants';
import { contractsKeys } from '~/constants/queryKeys';
import useStateCallback from '~/hooks/useStateCallback';
import { top100HoldersFilterState } from '~/store/app';
import type {
  ContractSuccessfulTransaction,
  NotableGroupMinted,
  SimilarContract,
} from '~/types';

import useContractFilterList from './useContractFilterList';

export default function useContractDetail(
  address: string,
  followingIds: number[]
) {
  const { view: top100HoldersView } =
    useRecoilValue(top100HoldersFilterState) || DEFAULT_TOP_100_HOLDERS;

  const { contractFilterList } = useContractFilterList();
  const isHidden = contractFilterList.some(
    (hiddenContract) => hiddenContract === address
  );
  const [
    isFirstFetchedNotableGroupsMinted,
    setIsFirstFetchedNotableGroupsMinted,
  ] = useState(true);
  const [isFirstFetchedSimilarContracts, setIsFirstFetchedSimilarContracts] =
    useState(true);
  const [isFirstFetchedLinkedXContracts, setIsFirstFetchedLinkedXContracts] =
    useState(true);
  const [
    isFirstFetchedSuccessfulTransactions,
    setIsFirstFetchedSuccessfulTransactions,
  ] = useState(true);
  const [linkedXContracts, setLinkedXContracts] = useStateCallback<
    SimilarContract[]
  >([]);
  const [notableGroupsMinted, setNotableGroupsMinted] = useStateCallback<
    NotableGroupMinted[]
  >([]);
  const [similarContracts, setSimilarContracts] = useStateCallback<
    SimilarContract[]
  >([]);
  const [successfulTransactions, setSuccessfulTransactions] = useStateCallback<
    ContractSuccessfulTransaction[]
  >([]);
  const [
    entryResult,
    failedTransactionsResult,
    holdersResult,
    linkedHoldersResult,
    linkedXContractsResult,
    mintsResult,
    notableGroupsMintedResult,
    similarContractsResult,
    successfulTransactionsResult,
  ] = useQueries({
    queries: [
      {
        queryKey: contractsKeys.entry('ethereum', address),
        queryFn: () => API.getContractEntry(address),
        enabled: !!address && !isHidden,
      },
      {
        queryKey: contractsKeys.failedTxs('ethereum', address),
        queryFn: () => API.getContractFailedTransactions(address),
        enabled: !!address && !isHidden,
      },
      {
        queryKey: contractsKeys.holders('ethereum', address),
        queryFn: () => API.getContractHolders(address),
        enabled:
          !!address &&
          !isHidden &&
          top100HoldersView === TOP_100_HOLDERS_VIEW.WALLETS,
      },
      {
        queryKey: contractsKeys.linkedHolders('ethereum', address),
        queryFn: () => API.getContractLinkedHolders(address),
        enabled:
          !!address &&
          !isHidden &&
          top100HoldersView === TOP_100_HOLDERS_VIEW.CLUSTERED,
      },
      {
        queryKey: contractsKeys.linkedXContracts('ethereum', address),
        queryFn: () => API.getLinkedXContracts(address),
        enabled: !!address && !isHidden,
      },
      {
        queryKey: contractsKeys.mints('ethereum', address),
        queryFn: () => API.getMintInfo(address),
        enabled: !!address && !isHidden,
      },
      {
        queryKey: contractsKeys.notableGroupsFollowing(
          'ethereum',
          address,
          followingIds
        ),
        queryFn: () =>
          API.getNotableGroupsMinted(address, followingIds.toString()),
        enabled: !!address && followingIds.length > 0 && !isHidden,
      },
      {
        queryKey: contractsKeys.similar('ethereum', address),
        queryFn: () => API.getSimilarContracts(address),
        enabled: !!address && !isHidden,
      },
      {
        queryKey: contractsKeys.successfulTxs('ethereum', address),
        queryFn: () => API.getContractSuccessfulTxs(address),
        enabled: !!address && !isHidden,
      },
    ],
  });

  const sortNotableGroupsMinted = (groups: NotableGroupMinted[]) => {
    return groups.sort((prev, curr) =>
      prev.mintCount === curr.mintCount
        ? prev.mintWallets === curr.mintWallets
          ? prev.airdropCount === curr.airdropCount
            ? prev.airdropWallets === curr.airdropWallets
              ? prev.name.toLowerCase().localeCompare(curr.name.toLowerCase())
              : curr.airdropWallets - prev.airdropWallets
            : curr.airdropCount - prev.airdropCount
          : curr.mintWallets - prev.mintWallets
        : curr.mintCount - prev.mintCount
    );
  };

  useEffect(() => {
    if (notableGroupsMintedResult.data && notableGroupsMintedResult.isFetched) {
      setNotableGroupsMinted(
        sortNotableGroupsMinted(notableGroupsMintedResult.data.data),
        () => {
          setIsFirstFetchedNotableGroupsMinted(false);
        }
      );
    }
  }, [notableGroupsMintedResult.data, notableGroupsMintedResult.isFetched]);

  useEffect(() => {
    if (similarContractsResult.data && similarContractsResult.isFetched) {
      setSimilarContracts(similarContractsResult.data.data, () => {
        setIsFirstFetchedSimilarContracts(false);
      });
    }
  }, [similarContractsResult.data, similarContractsResult.isFetched]);

  useEffect(() => {
    if (linkedXContractsResult.data && linkedXContractsResult.isFetched) {
      setLinkedXContracts(linkedXContractsResult.data.data, () => {
        setIsFirstFetchedLinkedXContracts(false);
      });
    }
  }, [linkedXContractsResult.data, linkedXContractsResult.isFetched]);

  useEffect(() => {
    if (
      successfulTransactionsResult.data &&
      successfulTransactionsResult.isFetched
    ) {
      setSuccessfulTransactions(successfulTransactionsResult.data.data, () => {
        setIsFirstFetchedSuccessfulTransactions(false);
      });
    }
  }, [
    successfulTransactionsResult.data,
    successfulTransactionsResult.isFetched,
  ]);

  useEffect(() => {
    // initialize if the address is changed
    setIsFirstFetchedNotableGroupsMinted(true);
    setIsFirstFetchedSuccessfulTransactions(true);
    setLinkedXContracts([]);
    setNotableGroupsMinted([]);
    setSimilarContracts([]);
    setSuccessfulTransactions([]);
  }, [address]);

  useDeepCompareEffect(() => {
    if (followingIds.length === 0) {
      setNotableGroupsMinted([], () => {
        setIsFirstFetchedNotableGroupsMinted(false);
      });
    }
  }, [followingIds]);

  return {
    entryResult: {
      ...entryResult,
      entry: entryResult.data?.data ?? {
        averageEntry: '0',
        averageTransactionFee: '0',
        averageValue: '0',
        totalSupply: 0,
      },
    },
    failedTransactionsResult: {
      ...failedTransactionsResult,
      failedTransactions: failedTransactionsResult.data?.data,
    },
    holdersResult: {
      ...holdersResult,
      holders:
        holdersResult.data?.data.sort((prev, curr) =>
          curr.quantity - prev.quantity
            ? 0
            : prev.ensName
            ? curr.ensName
              ? prev.ensName.localeCompare(curr.ensName)
              : -1
            : 0
        ) ?? [],
    },
    linkedHoldersResult: {
      ...linkedHoldersResult,
      linkedHolders:
        linkedHoldersResult.data?.data.sort(
          (prev, curr) => curr.quantity - prev.quantity
        ) ?? [],
    },
    linkedXContractsResult: {
      ...linkedXContractsResult,
      isFirstFetched: isFirstFetchedLinkedXContracts,
      linkedXContracts: [
        ...new Set([
          linkedXContracts.find((contract) => contract.address === address),
          ...linkedXContracts,
        ]),
      ].filter(Boolean) as SimilarContract[],
    },
    mintsResult: {
      ...mintsResult,
      mintInfo:
        mintsResult.data?.data.filter(
          (info) => info.simulationPassed && !!info.price
        ) ?? [],
    },
    notableGroupsMintedResult: {
      ...notableGroupsMintedResult,
      isFirstFetched: isFirstFetchedNotableGroupsMinted,
      notableGroupsMinted,
      tags: [
        ...new Set([...notableGroupsMinted.flatMap((group) => group.tags)]),
      ].sort((prev, curr) => prev.localeCompare(curr)),
    },
    similarContractsResult: {
      ...similarContractsResult,
      isFirstFetched: isFirstFetchedSimilarContracts,
      similarContracts: [
        ...new Set([
          similarContracts.find((contract) => contract.address === address),
          ...similarContracts,
        ]),
      ].filter(Boolean) as SimilarContract[],
    },
    successfulTransactionsResult: {
      ...successfulTransactionsResult,
      isFirstFetched: isFirstFetchedSuccessfulTransactions,
      successfulTransactions,
    },
  };
}
