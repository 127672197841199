import cn from 'classnames';
import { forwardRef, ReactNode } from 'react';

import styles from './Label.module.scss';

interface LabelProps {
  text: string;
  disabled?: boolean;
  highlight?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}

export default forwardRef<HTMLDivElement, LabelProps>(function Label(
  { text, disabled = false, highlight = false, icon, onClick },
  ref
) {
  return (
    <div
      className={cn(styles.label, {
        [styles.disabled]: disabled,
        [styles.clickable]: !!onClick,
        [styles.highlight]: highlight,
      })}
      onClick={onClick}
      ref={ref}
    >
      {icon && (
        <div
          className={cn(styles.icon_container, {
            [styles.disabled]: disabled,
            [styles.highlight]: highlight,
          })}
        >
          {icon}
        </div>
      )}
      <span>{text}</span>
    </div>
  );
});
