import cn from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import type { SendTransactionResult } from 'wagmi/actions';

import API from '~/api';
import { ExternalLink, Modal, Toaster } from '~/components';
import {
  APP_DISCLAIMER,
  AVAILABLE_CHAIN,
  HUMAN_MEDIAN_REACTION_TIME,
  MANIFOLD_CONTRACT_ADDRESSES,
  MANIFOLD_CREATOR_CONTRACT_ADDRESS_ARGUMENT,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
  UNEXPECTED_ERROR_MESSAGE,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import { useAuth } from '~/contexts/AuthContext';
import useScrollWithShadow from '~/hooks/useScrollWithShadow';
import type {
  GasSuggestionResource,
  MintInformation,
  TxGasOption,
} from '~/types';
import toChecksumAddress from '~/utils/toChecksumAddress';

import styles from './MintModal.module.scss';
import ParamAddressInput from './ParamAddressInput';
import ParamInput from './ParamInput';
import WriteButton from './WriteButton';

interface MintModalProps {
  address: string;
  gasSuggestion: GasSuggestionResource | null;
  isOpen: boolean;
  mintInfo: MintInformation;
  onClose: () => void;
  shouldClearAddresses: boolean;
}

export default memo(function MintModal({
  address,
  gasSuggestion,
  isOpen,
  mintInfo: { mintableFunction, params, price, transactionTo },
  onClose,
  shouldClearAddresses,
}: MintModalProps) {
  const analytics = useAnalyticsContext();
  const { isAuthenticated, user } = useAuth();
  const { bottomOpacity, onScrollHandler, topOpacity } = useScrollWithShadow();
  const modalBodyRef = useRef<HTMLDivElement | null>(null);
  const hasScroll = modalBodyRef.current
    ? modalBodyRef.current.scrollHeight - modalBodyRef.current.clientHeight > 0
    : false;
  const {
    inputs,
    name: methodName = '',
    stateMutability,
  } = {
    ...mintableFunction,
  };
  const [value, setValue] = useState<string>(price);
  const [txGasOptions, setTxGasOptions] = useState<Array<TxGasOption | null>>(
    []
  );
  const [inputParams, setInputParams] = useState<{
    [name: string]: string;
  } | null>(_isEmpty(params) ? {} : null);
  const argsOrder =
    (inputs
      ?.map((input) => input.name)
      .filter((input) => !!input) as NonNullable<string>[]) ?? [];

  const writeMintOption = useDebounce(
    {
      from: user?.address,
      to: transactionTo,
      abiItem: mintableFunction,
      methodName,
      params: inputParams,
      value,
      enabled:
        isAuthenticated && inputParams !== null
          ? Object.values(inputParams).every((paramValue) => !!paramValue)
          : false,
    },
    HUMAN_MEDIAN_REACTION_TIME
  );

  const convertParamToType = (param: string | null, type?: string) => {
    try {
      if (type === 'tuple') {
        if (param === null || param.length === 0) return null;
        return JSON.parse(param);
      }
      if (type?.startsWith('address')) {
        if (param === null) return 0;
        return toChecksumAddress(param);
      }
      if (type?.slice(-1) === ']') {
        if (param === null || param.length === 0) return null;
        return JSON.parse(param);
      }
      if (type?.startsWith('bytes') && (param === null || param.length === 0))
        return '0x';
    } catch (e) {
      return param;
    }
    return param;
  };

  const onSubmit = useCallback(
    (sendTx: () => Promise<SendTransactionResult>) => {
      sendTx()
        .then(async (sendTxResult) => {
          sendTxResult
            .wait()
            .then((receipt) => {
              API.updateMintConfirmation(receipt.transactionHash, receipt)
                .then(() => {
                  Toaster.toast({
                    title: 'Successfully Minted',
                    description: (
                      <ExternalLink
                        className={styles.tx_link}
                        label={receipt.transactionHash}
                        nofollow
                        onClick={() => {
                          analytics.track(REDIRECT_CLICKED, {
                            chain: AVAILABLE_CHAIN.ETHEREUM,
                            location: REDIRECT_CLICKED_LOCATION.OTHER,
                            type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
                            url: `https://etherscan.io/tx/${receipt.transactionHash}`,
                          });
                        }}
                        role="link"
                        url={`https://etherscan.io/tx/${receipt.transactionHash}`}
                      >
                        {receipt.transactionHash}
                      </ExternalLink>
                    ),
                    type: 'success',
                  });
                  onClose();
                })
                .catch(() => {
                  API.sendMintConfirmation(
                    receipt.transactionHash,
                    receipt
                  ).then(() => {
                    Toaster.toast({
                      title: 'Successfully Minted',
                      description: (
                        <ExternalLink
                          className={styles.tx_link}
                          label={receipt.transactionHash}
                          nofollow
                          onClick={() => {
                            analytics.track(REDIRECT_CLICKED, {
                              chain: AVAILABLE_CHAIN.ETHEREUM,
                              location: REDIRECT_CLICKED_LOCATION.OTHER,
                              type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
                              url: `https://etherscan.io/tx/${receipt.transactionHash}`,
                            });
                          }}
                          role="link"
                          url={`https://etherscan.io/tx/${receipt.transactionHash}`}
                        >
                          {receipt.transactionHash}
                        </ExternalLink>
                      ),
                      type: 'success',
                    });
                    onClose();
                  });
                });
            })
            .catch((err) => {
              if (err?.response?.data?.error?.detail) {
                Toaster.toast({
                  description: err.response.data.error.detail,
                  type: 'error',
                });
              } else {
                Toaster.toast({
                  description: UNEXPECTED_ERROR_MESSAGE,
                  type: 'error',
                });
              }
            });
        })
        .catch((err) => {
          if (err?.message) {
            Toaster.toast({
              description: err.message,
              type: 'error',
            });
          } else if (err?.response?.data?.error?.detail) {
            Toaster.toast({
              description: err.response.data.error.detail,
              type: 'error',
            });
          } else {
            Toaster.toast({
              description: UNEXPECTED_ERROR_MESSAGE,
              type: 'error',
            });
          }
        });
    },
    [address, analytics, onClose, price, transactionTo, isAuthenticated]
  );

  const setDefaultValue: (
    name: string,
    param: any,
    type: string
  ) => string | null = (name: string, param: any, type: string) => {
    if (param === null) return null;
    if (type === 'tuple') return JSON.stringify(param);
    if (type.startsWith('address')) {
      if (
        MANIFOLD_CONTRACT_ADDRESSES.includes(transactionTo) &&
        name === MANIFOLD_CREATOR_CONTRACT_ADDRESS_ARGUMENT
      ) {
        return address;
      }
      if (!shouldClearAddresses) return param;
      return '';
    }
    if (type.slice(-1) === ']') return JSON.stringify(param);
    return param;
  };

  const setParamValue = useCallback(
    (name: string, type: string, value: string | null) => {
      setInputParams((prev: any) => ({
        ...prev,
        [name]: convertParamToType(value, type),
      }));
    },
    []
  );

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
  }, [isOpen]);

  useEffect(() => {
    const txGasOptions = gasSuggestion
      ? [
          {
            ...gasSuggestion.nextBlock,
            confidence: 'next block',
          },
          {
            ...gasSuggestion.threeBlocks,
            confidence: 'next 2-3 blocks',
          },
          null,
        ]
      : [null];

    setTxGasOptions(txGasOptions);
  }, [gasSuggestion]);

  if (!user) return <></>;

  let txParams = params ? [...new Set(inputs)] : [];
  const addressArgs = txParams.filter((arg) => arg.type === 'address');
  txParams = addressArgs.concat(
    txParams.filter((arg) => arg.type !== 'address')
  );

  return (
    <Modal open={isOpen} onClose={onClose} style={{ width: 640 }}>
      <div className={styles.mint_modal_container}>
        <div className={styles.mint_modal_title_container}>
          <h1 className={styles.mint_modal_title}>{methodName}</h1>
        </div>
        {hasScroll && (
          <>
            <div
              className={cn(styles.shadow, styles.shadow_bottom)}
              style={{ opacity: bottomOpacity }}
            />
            <div
              className={cn(styles.shadow, styles.shadow_top)}
              style={{ opacity: topOpacity }}
            />
          </>
        )}
        <div
          className={styles.mint_modal_body}
          ref={modalBodyRef}
          onScroll={onScrollHandler}
        >
          {stateMutability === 'payable' && (
            <ParamInput
              label={'payableAmount (ether)'}
              defaultValue={price}
              placeholder={'payableAmount (ether)'}
              setParams={(value) => setValue(value ?? '0')}
            />
          )}
          {params &&
            txParams.map((txParam) => {
              const { name, type } = txParam;
              if (!name || !type) {
                return <></>;
              }

              const combineProperties = `${name} (${type})`;
              const isAddress = type.startsWith('address');
              const defaultValue = setDefaultValue(name, params[name], type);

              if (isAddress) {
                return (
                  <ParamAddressInput
                    key={name}
                    contractAddress={address}
                    defaultValue={defaultValue}
                    label={combineProperties}
                    myAddress={user.address}
                    placeholder={combineProperties}
                    resource={params[name]}
                    setParams={(value) => setParamValue(name, type, value)}
                  />
                );
              }

              return (
                <ParamInput
                  key={name}
                  label={combineProperties}
                  defaultValue={defaultValue}
                  placeholder={combineProperties}
                  setParams={(value) => setParamValue(name, type, value)}
                />
              );
            })}
          <div className={styles.alert_text_container}>
            <span className={styles.alert_text}>
              {`Please use with caution. It may be unstable, and you may lose gas fee unless you know what you’re doing. By clicking on one of the three buttons right below, you agree to our `}
              <ExternalLink
                allowsUtmSource={false}
                className={styles.disclaimer_link}
                label="catchmint_disclaimer"
                onClick={() => {
                  analytics.track(REDIRECT_CLICKED, {
                    chain: AVAILABLE_CHAIN.ETHEREUM,
                    location: REDIRECT_CLICKED_LOCATION.OTHER,
                    type: REDIRECT_CLICKED_TYPE.OTHER,
                    url: APP_DISCLAIMER,
                  });
                }}
                role="link"
                url={APP_DISCLAIMER}
              >
                {'Disclaimer'}
              </ExternalLink>
              {`.`}
            </span>
          </div>
        </div>
        <div className={styles.buttons_container}>
          <div className={styles.submit_button_container}>
            {txGasOptions.map((txGasOption, i) => (
              <WriteButton
                key={`write_option_${i}`}
                argsOrder={argsOrder}
                isAuthenticated={isAuthenticated}
                txGasOption={txGasOption}
                writeMintOption={writeMintOption}
                onSubmit={onSubmit}
              />
            ))}
          </div>
          <button className={styles.cancel_button} onClick={onClose}>
            {'Cancel'}
          </button>
        </div>
      </div>
    </Modal>
  );
});
