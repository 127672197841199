import { useTheme } from 'next-themes';
import { useSetRecoilState } from 'recoil';

import { APP_SETTINGS_MODIFIED, THEME } from '~/constants';
import useIsMounted from '~/hooks/useIsMounted';
import { themeState } from '~/store/app';
import type { Theme } from '~/types';

import styles from './ThemeSwitch.module.scss';

export default function ThemeSwitch() {
  const isMounted = useIsMounted();
  const setStoredTheme = useSetRecoilState(themeState);
  const { theme, setTheme, systemTheme } = useTheme();

  const changeTheme = (theme: Theme) => {
    const timestamp = new Date().getTime().toString();
    localStorage.setItem(APP_SETTINGS_MODIFIED, timestamp);

    setStoredTheme(theme);
    setTheme(theme);
  };

  if (!isMounted) return null;

  return (
    <div className={styles.theme_switch_container}>
      <input
        type="checkbox"
        id="theme--switch"
        className={styles['theme--switch']}
        onChange={() => {
          changeTheme(
            theme === THEME.SYSTEM
              ? systemTheme === THEME.DARK
                ? THEME.LIGHT
                : THEME.DARK
              : theme === THEME.DARK
              ? THEME.LIGHT
              : THEME.DARK
          );
        }}
        checked={
          theme === THEME.SYSTEM
            ? systemTheme === THEME.DARK
            : theme === THEME.DARK
        }
      />
      <label htmlFor="theme--switch" className={styles['switch--label']}>
        <span className={styles['switch--label-background']}></span>
      </label>
    </div>
  );
}
