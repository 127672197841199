import type { FollowingNotableGroupIds, NotableGroup } from '~/types';

import base from './base';

const Groups = {
  getNotableGroups() {
    return base.get<NotableGroup[]>(`/groups/notable/`);
  },
  updateFollowingNotableGroups(ids: number[]) {
    return base.put<FollowingNotableGroupIds>(`/groups/notable/follow/`, {
      following: ids,
    });
  },
};

export default Groups;
