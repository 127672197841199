import type { TransactionRequest } from '@ethersproject/abstract-provider';
import { formatUnits } from '@ethersproject/units';
import type { SendTransactionResult } from '@wagmi/core';
import cn from 'classnames';
import { BigNumber } from 'ethers';
import { hexZeroPad } from 'ethers/lib/utils.js';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTransaction, useWaitForTransaction } from 'wagmi';
import {
  prepareSendTransaction,
  prepareWriteContract,
  sendTransaction,
  writeContract,
} from 'wagmi/actions';
import { mainnet } from 'wagmi/chains';

import API from '~/api';
import {
  IcnAddressNeeded,
  IcnArrowDown,
  IcnByteNeeded,
  IcnParameters,
  IcnSmallChainEthereum,
  IcnSmallHint,
  IcnStepDown,
  IcnStepUp,
  IcnSweep,
} from '~/assets';
import {
  DropdownMenu,
  ExternalLink,
  Loading,
  Popover,
  Toaster,
} from '~/components';
import {
  AMOUNT_SEPARATOR,
  AVAILABLE_CHAIN,
  FROM_SEPARATOR,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
  WRITE_INFORMATION_FROM,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import { useAuth } from '~/contexts/AuthContext';
import {
  checksumContractAddressState,
  writeInformationState,
} from '~/store/contract';
import styleVariables from '~/styles/variables.module.scss';
import type { MintInfo } from '~/types';
import formatEthPrice from '~/utils/formatEthPrice';
import showErrorToast from '~/utils/showErrorToast';

import styles from './GlobalMintButton.module.scss';
import RangeSlider from './RangeSlider';

interface GlobalMintButtonProps {
  mintInfo: MintInfo[];
  isLoading: boolean;
  isMintedOut: boolean;
  getDeployer: () => Promise<string | null>;
}

export default function GlobalMintButton({
  mintInfo,
  isLoading,
  isMintedOut,
  getDeployer,
}: GlobalMintButtonProps) {
  const analytics = useAnalyticsContext();
  const { signIn, user } = useAuth();
  const contractAddress = useRecoilValue(checksumContractAddressState);
  const setWriteInformation = useSetRecoilState(writeInformationState);
  const [transactionResult, setTransactionResult] =
    useState<SendTransactionResult | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedMintInfo, setSelectedMintInfo] = useState<MintInfo | null>(
    null
  );
  const max = selectedMintInfo?.maxMints ?? 1;
  const min = max === 1 ? 0 : 1;
  const isSelectedFunctionAddressNeeded = selectedMintInfo?.abi?.inputs?.some(
    (param) => param?.type?.startsWith('address')
  );
  const isSelectedFunctionByteNeeded = selectedMintInfo?.abi?.inputs?.some(
    (param) => param?.type?.startsWith('byte')
  );
  const pricePerToken =
    selectedMintInfo && !!selectedMintInfo.price
      ? BigNumber.from(selectedMintInfo.price)
      : BigNumber.from(0);
  const totalPrice = pricePerToken.mul(quantity);
  const totalPriceInEth = formatUnits(totalPrice);
  const totalPriceInFormattedEth =
    totalPriceInEth.length > 6
      ? formatEthPrice(totalPriceInEth).toString()
      : totalPriceInEth;

  const paramsInfo = selectedMintInfo?.abi?.inputs?.map((input, index) => {
    let param = selectedMintInfo.defaultParams[index];
    if (param === AMOUNT_SEPARATOR) param = quantity;
    if (param === FROM_SEPARATOR) param = user?.address ?? '';
    if (param instanceof Array) {
      param = JSON.stringify(
        param.map((value) => {
          if (value?.type === 'BigNumber') {
            return BigNumber.from(value).toString();
          }
          return value;
        })
      );
    } else if (param?.type === 'BigNumber') {
      param = BigNumber.from(param).toString();
    } else {
      param = JSON.stringify(param);
    }
    return [input, param];
  });

  const showMintModal = async () => {
    if (!selectedMintInfo) return;
    if (!user) return signIn();
    const { abi, defaultParams, signature, targetAddress } = {
      ...selectedMintInfo,
    };

    if (!abi || !targetAddress) return;

    const deployer = await getDeployer();

    const args = defaultParams.map((param) => {
      if (param === AMOUNT_SEPARATOR) return quantity;
      if (param === FROM_SEPARATOR) return user.address;
      if (param instanceof Array) {
        param = param.map((value) => {
          if (value?.type === 'BigNumber') {
            return BigNumber.from(value).toString();
          }
          return value;
        });
      } else if (param?.type === 'BigNumber') {
        param = BigNumber.from(param).toString();
      }
      return param;
    });

    const alignedParams =
      abi.inputs?.reduce<{
        [key: string]: any;
      }>((prev, input, index) => {
        if (input.name)
          return Object.assign(prev, {
            [input.name]: args[index],
          });
        return prev;
      }, {}) || null;

    setWriteInformation({
      address: contractAddress,
      from: WRITE_INFORMATION_FROM.GLOBAL,
      mintInfo: {
        deployer,
        mintableFunction: {
          ...abi,
          signature,
        },
        params: alignedParams,
        price: totalPriceInEth === '0.0' ? '0' : totalPriceInEth,
        transactionTo: targetAddress,
      },
    });
  };

  const handleQuantity = useCallback((quantity: number) => {
    setQuantity(quantity);
  }, []);

  const handleMint = async () => {
    if (!selectedMintInfo) return;
    if (!user) return signIn();
    const { abi, data, defaultParams, functionName, price, targetAddress } = {
      ...selectedMintInfo,
    };

    try {
      if (abi) {
        const overrides: { [key: string]: string | BigNumber } = {
          from: user.address as `0x${string}`,
        };
        if (price!.length > 0) {
          overrides['value'] = totalPrice;
        }

        const args = defaultParams.map((param) => {
          if (param === AMOUNT_SEPARATOR) return quantity;
          if (param === FROM_SEPARATOR) return user.address;
          return param;
        });

        const config = await prepareWriteContract({
          abi: [abi],
          address: targetAddress,
          args,
          chainId: mainnet.id,
          functionName,
          overrides,
        });

        const transactionResult = await writeContract({
          ...config,
          request: {
            ...config.request,
            gasLimit: config.request.gasLimit.add(
              config.request.gasLimit.div(5)
            ),
          },
        });

        setTransactionResult(transactionResult);
      }
      if (data) {
        const amountHex = BigNumber.from(quantity).toHexString();
        const paddedAmountHex = hexZeroPad(amountHex, 32)
          .slice(2)
          .toLowerCase();
        const customData = data
          .replace(AMOUNT_SEPARATOR, paddedAmountHex)
          .replace(FROM_SEPARATOR, user.address.slice(2).toLowerCase());
        const request: TransactionRequest & { to: string } = {
          from: user.address,
          to: targetAddress,
          data: customData,
        };
        if (price!.length > 0) {
          request['value'] = totalPrice;
        }
        const config = await prepareSendTransaction({
          chainId: mainnet.id,
          request,
        });
        const requestParams = {
          ...config.request,
        };
        if (config.request.gasLimit instanceof BigNumber) {
          requestParams.gasLimit = config.request.gasLimit.add(
            config.request.gasLimit.div(5)
          );
        }
        const transactionResult = await sendTransaction({
          ...config,
          request: requestParams,
        });

        setTransactionResult(transactionResult);
      }
    } catch (e) {
      showErrorToast(e);
    }
  };

  const { isLoading: isConfirming } = useWaitForTransaction({
    chainId: mainnet.id,
    hash: transactionResult?.hash,
    enabled: !!transactionResult,
  });

  const { data: txResponse } = useTransaction({
    chainId: mainnet.id,
    hash: transactionResult?.hash,
    enabled: !!transactionResult,
  });

  useEffect(() => {
    if (contractAddress) {
      setQuantity(1);
      setSelectedMintInfo(null);
      setTransactionResult(null);
    }
  }, [contractAddress]);

  useEffect(() => {
    if (mintInfo.length > 0) {
      setSelectedMintInfo(mintInfo[0]);
    }
  }, [mintInfo]);

  useEffect(() => {
    if (txResponse) {
      const sendMintConfirmation = async () => {
        try {
          await API.sendMintConfirmation(txResponse.hash, txResponse);
          const receipt = await txResponse.wait();
          if (receipt !== null) {
            const hash = receipt.transactionHash;
            await API.updateMintConfirmation(hash, receipt);

            Toaster.toast({
              title: 'Successfully Minted',
              description: (
                <ExternalLink
                  className={styles.tx_link}
                  label={hash}
                  nofollow
                  onClick={() => {
                    analytics.track(REDIRECT_CLICKED, {
                      chain: AVAILABLE_CHAIN.ETHEREUM,
                      location: REDIRECT_CLICKED_LOCATION.OTHER,
                      type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
                      url: `https://etherscan.io/tx/${hash}`,
                    });
                  }}
                  role="link"
                  url={`https://etherscan.io/tx/${hash}`}
                >
                  {hash}
                </ExternalLink>
              ),
              type: 'success',
            });
          }
        } catch (err) {
          showErrorToast(err);
        } finally {
          setTransactionResult(null);
        }
      };

      sendMintConfirmation();
    }
  }, [txResponse]);

  if (isMintedOut) {
    return (
      <div className={styles.minted_out_container}>
        <span className={styles.minted_out}>Minted Out</span>
      </div>
    );
  }

  if (isLoading || mintInfo.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_inner}>
        <Popover
          render={() => (
            <div className="default_popover">
              <p>
                {`Mint function logic varies across different contracts.`}
                <br />
                {`We're aiming to improve the handling of edge cases.`}
              </p>
            </div>
          )}
          animation
          placement="top"
        >
          <div className={styles.beta_container}>
            <span>Beta</span>
            <IcnSmallHint />
          </div>
        </Popover>
        <div className={styles.function_info_container}>
          <DropdownMenu.Menu
            className={styles.selected_function_container}
            label={
              <button className={styles.selected_function}>
                {isSelectedFunctionAddressNeeded && (
                  <div
                    className={cn(styles.icn_container, styles.address_param)}
                  >
                    <IcnAddressNeeded />
                  </div>
                )}
                {isSelectedFunctionByteNeeded && (
                  <div className={cn(styles.icn_container, styles.byte_param)}>
                    <IcnByteNeeded />
                  </div>
                )}
                <span>
                  {selectedMintInfo?.functionName ||
                    selectedMintInfo?.signature}
                </span>
                <div className={cn(styles.icn_container, styles.arrow_down)}>
                  <IcnArrowDown />
                </div>
              </button>
            }
            placement="top-start"
          >
            {mintInfo.map((info) => {
              const isFunctionAddressNeeded = info?.abi?.inputs?.some((param) =>
                param?.type?.startsWith('address')
              );
              const isFunctionBytesNeeded = info?.abi?.inputs?.some((param) =>
                param?.type?.startsWith('byte')
              );

              return (
                <DropdownMenu.MenuItem
                  key={info.signature}
                  label={
                    <button className={styles.selectable_function}>
                      {isFunctionAddressNeeded && (
                        <div
                          className={cn(
                            styles.icn_container,
                            styles.address_param
                          )}
                        >
                          <IcnAddressNeeded />
                        </div>
                      )}
                      {isFunctionBytesNeeded && (
                        <div
                          className={cn(
                            styles.icn_container,
                            styles.byte_param
                          )}
                        >
                          <IcnByteNeeded />
                        </div>
                      )}
                      <span>{info.functionName || info.signature}</span>
                    </button>
                  }
                  onClick={() => {
                    setQuantity(1);
                    setSelectedMintInfo(info);
                  }}
                />
              );
            })}
          </DropdownMenu.Menu>
          {selectedMintInfo?.abi && (
            <Popover
              render={() => (
                <div className={styles.params_popover}>
                  {selectedMintInfo?.abi?.stateMutability === 'payable' && (
                    <div className={styles.param_container}>
                      <span className={styles.param_name}>
                        {`payableAmount (ether)`}
                      </span>
                      <span className={styles.param}>
                        {totalPriceInEth === '0.0' ? '0' : totalPriceInEth}
                      </span>
                    </div>
                  )}
                  {!!paramsInfo && paramsInfo.length > 0 ? (
                    paramsInfo?.map((pair, index) => (
                      <div key={index} className={styles.param_container}>
                        <span className={styles.param_name}>
                          {`${pair[0].name} (${pair[0].type})`}
                        </span>
                        <span className={styles.param}>{pair[1]}</span>
                      </div>
                    ))
                  ) : (
                    <div className={styles.param_container}>
                      <span className={styles.param}>{`No parameters`}</span>
                    </div>
                  )}
                </div>
              )}
              animation
              placement="top-start"
            >
              <button
                className={styles.selected_function_parameters}
                onClick={showMintModal}
              >
                <IcnParameters />
              </button>
            </Popover>
          )}
        </div>
        <div className={styles.mint_container}>
          <div className={styles.sweep_container}>
            <div className={styles.icn_sweep_container}>
              <IcnSweep />
            </div>
            <div className={styles.slider_container}>
              <RangeSlider
                disabled={!selectedMintInfo}
                max={max}
                min={min}
                setValue={handleQuantity}
                value={quantity}
              />
            </div>
            <div className={styles.input_container}>
              <input
                max={max}
                min={1}
                onChange={(event) => {
                  let changedValue = +event.target.value;
                  if (changedValue > max) changedValue = max;
                  if (changedValue < 1) changedValue = 1;
                  handleQuantity(changedValue);
                }}
                step={1}
                type="number"
                value={quantity.toString()}
              />
              <button
                className={styles.btn_max}
                onClick={() => handleQuantity(max)}
              >
                {`Max ${max}`}
              </button>
              <div className={styles.btn_steps_container}>
                <div
                  className={styles.btn_step}
                  onClick={() => handleQuantity(Math.min(quantity + 1, max))}
                >
                  <IcnStepUp />
                </div>
                <div
                  className={styles.btn_step}
                  onClick={() => handleQuantity(Math.max(quantity - 1, 1))}
                >
                  <IcnStepDown />
                </div>
              </div>
            </div>
          </div>
          <button
            className={styles.btn_mint}
            disabled={!selectedMintInfo || quantity === 0}
            onClick={handleMint}
          >
            {isConfirming ? (
              <div className={styles.loading_container}>
                <Loading color={styleVariables.white} size={20} />
              </div>
            ) : (
              <>
                <div className={styles.amount_container}>
                  <span className={styles.amount}>
                    {`Mint${quantity > 0 ? ` ${quantity}` : ''}`}
                  </span>
                  {quantity > 0 && (
                    <span className={styles.suffix}>
                      {`item${quantity > 1 ? 's' : ''}`}
                    </span>
                  )}
                </div>
                <div className={styles.price_container}>
                  <span className={styles.price}>
                    {totalPriceInFormattedEth === '0.0'
                      ? '0'
                      : totalPriceInFormattedEth}
                  </span>
                  <div className={styles.icn_search}>
                    <IcnSmallChainEthereum />
                  </div>
                </div>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
