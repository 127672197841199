import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { IcnClustered, IcnHint, IcnSmallWallet } from '~/assets';
import { Popover, SelectButton } from '~/components';
import {
  DETAIL_LAYOUT,
  RENDERING_BOUND_TIME,
  TOP_100_HOLDERS_VIEW,
} from '~/constants';
import useTop100HoldersFilter from '~/hooks/useTop100HoldersFilter';
import type {
  ContractHolder,
  ContractLinkedHolder,
  Top100HoldersView,
} from '~/types';

import ClusteredView from './ClusteredView';
import styles from './Top100HoldersSection.module.scss';
import WalletsView from './WalletsView';
import SectionLayout from '../SectionLayout';

interface Top100HoldersSectionProps {
  holders: ContractHolder[];
  isFetched: boolean;
  isLoading: boolean;
  isOtherOptionFetched: boolean;
  linkedHolders: ContractLinkedHolder[];
  deployer?: string | null;
  totalSupply?: string | number | null;
  uniqueWallets?: number;
}

export default function Top100HoldersSection({
  holders,
  isFetched,
  isLoading,
  isOtherOptionFetched,
  linkedHolders,
  deployer,
  totalSupply,
  uniqueWallets,
}: Top100HoldersSectionProps) {
  const [top100HoldersFilter, setTop100HoldersFilter] =
    useTop100HoldersFilter();
  const top100HoldersView =
    top100HoldersFilter?.view || TOP_100_HOLDERS_VIEW.WALLETS;
  const [enableBound, setEnableBound] = useState(false);

  const handleChangeView = (view: Top100HoldersView) => {
    setTop100HoldersFilter({
      view,
    });
  };

  useEffect(() => {
    const boundTimer = () =>
      setTimeout(() => {
        setEnableBound(true);
      }, RENDERING_BOUND_TIME);

    if (isFetched) {
      boundTimer();
    } else {
      clearTimeout(boundTimer());
      setEnableBound(false);
    }
  }, [isFetched]);

  return (
    <SectionLayout
      boundTrigger={''}
      enableBound={enableBound}
      id={'top_100_holders'}
    >
      <div className={styles.container}>
        <div className={styles.section_title_container}>
          <div className={styles.section_title_left_container}>
            <span className={styles.section_title}>
              {isLoading && !isOtherOptionFetched ? (
                <Skeleton width={160} />
              ) : (
                DETAIL_LAYOUT.TOP_100_HOLDERS
              )}
            </span>
            {(!isLoading || isOtherOptionFetched) && (
              <>
                <Popover
                  render={() => (
                    <div className="hint_popover">
                      <span>{`Wallet relations are analyzed from on-chain data only.`}</span>
                      <br />
                      <span>{`Linked wallets data from CatchMint's "Manage Wallets" is not used.`}</span>
                    </div>
                  )}
                  animation
                  placement="top"
                  showArrow
                >
                  <div className="center">
                    <IcnHint />
                  </div>
                </Popover>
                <SelectButton.Group<Top100HoldersView>
                  items={[
                    {
                      key: TOP_100_HOLDERS_VIEW.WALLETS,
                      label: (
                        <div className={styles.option_container}>
                          <IcnSmallWallet />
                        </div>
                      ),
                      popover: TOP_100_HOLDERS_VIEW.WALLETS,
                      selected:
                        top100HoldersView === TOP_100_HOLDERS_VIEW.WALLETS,
                      value: TOP_100_HOLDERS_VIEW.WALLETS,
                    },
                    {
                      key: TOP_100_HOLDERS_VIEW.CLUSTERED,
                      label: (
                        <div className={styles.option_container}>
                          <IcnClustered />
                        </div>
                      ),
                      popover: `${TOP_100_HOLDERS_VIEW.CLUSTERED} - updated every 5 minutes for collections with 30K holders or less`,
                      selected:
                        top100HoldersView === TOP_100_HOLDERS_VIEW.CLUSTERED,
                      value: TOP_100_HOLDERS_VIEW.CLUSTERED,
                    },
                  ]}
                  onItemClicked={(_, clickedItem) => {
                    if (clickedItem) {
                      handleChangeView(clickedItem.value);
                    }
                  }}
                />
              </>
            )}
          </div>
          {(!isLoading || isOtherOptionFetched) && !!uniqueWallets && (
            <span className={styles.total_holders}>
              {`${uniqueWallets.toLocaleString()} total wallets`}
            </span>
          )}
        </div>
        {isLoading ? (
          <Skeleton height={430} />
        ) : top100HoldersView === TOP_100_HOLDERS_VIEW.WALLETS ? (
          <WalletsView
            deployer={deployer}
            holders={holders}
            totalSupply={totalSupply}
          />
        ) : (
          <ClusteredView
            deployer={deployer}
            linkedHolders={linkedHolders}
            totalSupply={totalSupply}
          />
        )}
      </div>
    </SectionLayout>
  );
}
