import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import API from '~/api';
import { EVENT_ART } from '~/constants';
import { contractsKeys } from '~/constants/queryKeys';
import { useSocket } from '~/contexts/SocketContext';
import usePageVisibility from '~/hooks/usePageVisibility';
import type { AvailableChain, ContractArt } from '~/types';

import useContractFilterList from './useContractFilterList';

export default function useContractArt(
  chain: Lowercase<AvailableChain>,
  address: string
) {
  const isPageVisible = usePageVisibility();
  const [arts, setArts] = useState<ContractArt[] | null>(null);
  const queryKey = contractsKeys.art(chain, address);
  const { contractFilterList } = useContractFilterList();
  const isHidden = contractFilterList.some(
    (hiddenContract) => hiddenContract === address
  );
  const result = useQuery(queryKey, () => API.getContractArt(address), {
    enabled: !!address && !isHidden,
    onSuccess: async ({ data }) => {
      const filtered = data.filter((art) => !!art.imageUrl);
      setArts(filtered);
    },
  });
  const socket = useSocket();

  useEffect(() => {
    setArts(null);
  }, [address]);

  useEffect(() => {
    if (isPageVisible && address.startsWith('0x') && address.length === 42) {
      const channel = socket?.subscribe(address);

      channel?.bind(EVENT_ART, (data: ContractArt[]) => {
        const filtered = data.filter((art) => !!art.imageUrl);
        setArts((prev) =>
          prev
            ? filtered
                .concat(prev)
                .filter(
                  (art, index, self) =>
                    index ===
                    self.findIndex((selfArt) => selfArt.tokenId === art.tokenId)
                )
                .slice(0, 5)
            : filtered
        );
      });

      return () => {
        channel?.unbind(EVENT_ART);
      };
    }
  }, [address, isPageVisible, socket]);

  return {
    ...result,
    isError: result.isFetched && result.isError,
    arts,
  };
}
