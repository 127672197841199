import cn from 'classnames';
import Linkify from 'linkify-react';
import Link from 'next/link';
import { forwardRef } from 'react';

import { IcnComments, IcnDeployer, IcnPinnedComment } from '~/assets';
import { Popover, Thumbnail } from '~/components';
import {
  DEAD_SCORE_COUNT,
  IMAGE_SIZE,
  TOWARDS_DEATH_FIRST_SCORE_COUNT,
  TOWARDS_DEATH_SECOND_SCORE_COUNT,
  TOWARDS_DEATH_THIRD_SCORE_COUNT,
  UNIDENTIFIED_CONTRACT,
} from '~/constants';
import type { GlobalComment } from '~/types';
import formatAddress from '~/utils/formatAddress';
import formatTimeAgo from '~/utils/formatTimeAgo';

import styles from './GlobalCommentItem.module.scss';

interface GlobalCommentItemProps {
  comment: GlobalComment;
  index: number;
  isMine: boolean;
  onClickCollection: (params: {
    address: string;
    name: string;
    position: number;
  }) => void;
}

export default forwardRef<HTMLDivElement, GlobalCommentItemProps>(
  function GlobalCommentItem(
    {
      comment: {
        comment,
        contentObject,
        created,
        isDeployer,
        isPinned,
        modified,
        subComments,
        totalScore,
        username,
        uuid,
      },
      index,
      isMine,
      onClickCollection,
    },
    ref
  ) {
    const isDead = totalScore < DEAD_SCORE_COUNT;
    const diff = Date.now() - new Date(created).getTime();

    return (
      <div className={styles.container} data-index={index} ref={ref}>
        <div className={styles.inner_container}>
          <div className={styles.head}>
            <div
              className={cn(styles.username, {
                [styles.me]: isMine,
                [styles.pinned_username]: isPinned,
              })}
            >
              {username ?? 'unknown'}
              {isDeployer && (
                <span className={styles.deployer_container}>
                  {`(`}
                  <div className={styles.icn_deployer}>
                    <IcnDeployer />
                  </div>
                  {`deployer)`}
                </span>
              )}
            </div>
            <div className={styles.date}>
              {formatTimeAgo(diff)}
              {created !== modified && (
                <span className={styles.edited}>{`(edited)`}</span>
              )}
            </div>
            <Link
              className={styles.collection_info}
              href={`/collection/ethereum/${contentObject.address}`}
              onClick={() =>
                onClickCollection({
                  address: contentObject.address,
                  name: contentObject.name,
                  position: index + 1,
                })
              }
            >
              <div className={styles.collection_thumbnail}>
                <Thumbnail
                  size={IMAGE_SIZE.SMALL}
                  url={contentObject.imageUrl}
                />
              </div>
              <span className={styles.collection_name}>
                {`${
                  contentObject.name || UNIDENTIFIED_CONTRACT
                }(${formatAddress(contentObject.address)})`}
              </span>
              {isPinned && (
                <Popover
                  render={() => (
                    <div className="default_popover">
                      <span>{'Pinned by deployer'}</span>
                    </div>
                  )}
                  animation
                  placement="top"
                >
                  <div className={styles.icn_pinned}>
                    <IcnPinnedComment />
                  </div>
                </Popover>
              )}
            </Link>
          </div>
          <div className={styles.body}>
            <p
              className={cn(styles.text, {
                ['dead']: isDead,
                ['towards_death_first']:
                  !isDead &&
                  totalScore <= TOWARDS_DEATH_FIRST_SCORE_COUNT &&
                  totalScore > TOWARDS_DEATH_SECOND_SCORE_COUNT,
                ['towards_death_second']:
                  !isDead &&
                  totalScore <= TOWARDS_DEATH_SECOND_SCORE_COUNT &&
                  totalScore > TOWARDS_DEATH_THIRD_SCORE_COUNT,
                ['towards_death_third']:
                  !isDead &&
                  totalScore <= TOWARDS_DEATH_THIRD_SCORE_COUNT &&
                  totalScore > DEAD_SCORE_COUNT,
              })}
            >
              <Linkify
                options={{
                  className: styles.link,
                  defaultProtocol: 'https',
                  rel: 'noopener noreferrer',
                  target: '_blank',
                }}
              >
                {comment.trim()}
              </Linkify>
            </p>
            <Link
              className={styles.num_of_comment}
              href={`/collection/ethereum/${contentObject.address}#${uuid}`}
              onClick={() =>
                onClickCollection({
                  address: contentObject.address,
                  name: contentObject.name,
                  position: index,
                })
              }
              scroll={false}
            >
              <IcnComments />
              {subComments || 0}
            </Link>
          </div>
        </div>
      </div>
    );
  }
);
