import {
  FloatingContext,
  FloatingFocusManager,
  Strategy,
  useId,
} from '@floating-ui/react';
import type { HTMLProps } from 'react';

import { IcnSmallExternal } from '~/assets';
import { ExternalLink, Wallet } from '~/components';
import {
  AVAILABLE_CHAIN,
  REDIRECT_CLICKED_LOCATION,
  REDIRECT_CLICKED_TYPE,
} from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import type {
  ContractHolderNetworkLink,
  HoldersCluster,
  SimNode,
} from '~/types';
import formatEthPrice from '~/utils/formatEthPrice';

import styles from './WalletSummary.module.scss';

interface WalletSummaryProps {
  context: FloatingContext;
  floating: (node: HTMLElement | null) => void;
  getFloatingProps: (
    userProps?: HTMLProps<HTMLElement> | undefined
  ) => Record<string, unknown>;
  links: ContractHolderNetworkLink[];
  nodesInfo: {
    [address: string]: SimNode<HoldersCluster>;
  };
  strategy: Strategy;
  wallet: string;
  x: number | null;
  y: number | null;
  deployer?: string | null;
}

export default function WalletSummary({
  context,
  floating,
  getFloatingProps,
  links,
  nodesInfo,
  strategy,
  wallet,
  x,
  y,
  deployer,
}: WalletSummaryProps) {
  const analytics = useAnalyticsContext();
  const headingId = useId();
  const target = nodesInfo[wallet].data;
  const informationByWallet = links.reduce<{
    [address: string]: {
      to: { count: number | null; value: string };
      from: { count: number | null; value: string };
    };
  }>((acc, curr) => {
    if (curr.fromAddress === wallet) {
      acc[curr.toAddress] = {
        ...acc[curr.toAddress],
        to: {
          count: curr.count,
          value: curr.value,
        },
      };
    }
    if (curr.toAddress === wallet) {
      acc[curr.fromAddress] = {
        ...acc[curr.fromAddress],
        from: {
          count: curr.count,
          value: curr.value,
        },
      };
    }
    return acc;
  }, {});

  const getIntegerAndDecimalParts = (value: string) => {
    let valueIntegerPart = '';
    let valueDecimalPart = '';

    if (value.length > 6) {
      [valueIntegerPart, valueDecimalPart] = formatEthPrice(value)
        .toString()
        .split('.');
    } else {
      [valueIntegerPart, valueDecimalPart] = value.split('.');
    }

    return (
      <>
        <p className={styles.integer_part}>{valueIntegerPart}</p>
        <p className={styles.decimal_part}>
          {valueDecimalPart && `.${valueDecimalPart}`}
          <span className={styles.icn_external}>
            <IcnSmallExternal />
          </span>
        </p>
      </>
    );
  };

  return (
    <FloatingFocusManager context={context} modal={false}>
      <div
        className={styles.wallet_summary}
        ref={floating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
        aria-labelledby={headingId}
        {...getFloatingProps()}
      >
        <div className={styles.wallet_summary_title_container}>
          <Wallet
            address={target.address}
            ens={target.ens}
            location={REDIRECT_CLICKED_LOCATION.TOP_100_HOLDERS}
          />
          <div className={styles.value_columns}>
            <div className={styles.to_column}>
              <span>{'+ Received'}</span>
            </div>
            <div className={styles.from_column}>
              <span>{'- Sent'}</span>
            </div>
          </div>
        </div>
        <ul className={styles.summary_list}>
          {Object.entries(informationByWallet).map(
            ([linkedWallet, { from, to }]) => {
              const { address, ens } = nodesInfo[linkedWallet].data;
              return (
                <li
                  className={styles.summary_item}
                  key={`summary_with_${address}`}
                >
                  <Wallet
                    address={address}
                    ens={ens}
                    deployer={deployer}
                    location={REDIRECT_CLICKED_LOCATION.TOP_100_HOLDERS}
                  />
                  <div className={styles.value_columns}>
                    {from && (
                      <div className={styles.from_container}>
                        <ExternalLink
                          label={`etherscan_from_${address}_to_${wallet}`}
                          onClick={() => {
                            analytics.track(REDIRECT_CLICKED, {
                              chain: AVAILABLE_CHAIN.ETHEREUM,
                              location:
                                REDIRECT_CLICKED_LOCATION.TOP_100_HOLDERS,
                              type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
                              url: `https://etherscan.io/address/${wallet}?fromaddress=${address}`,
                            });
                          }}
                          role="link"
                          url={`https://etherscan.io/address/${wallet}?fromaddress=${address}`}
                        >
                          <span className={styles.value}>
                            {getIntegerAndDecimalParts(from.value)}
                          </span>
                        </ExternalLink>
                      </div>
                    )}
                    {to && (
                      <div className={styles.to_container}>
                        <ExternalLink
                          label={`etherscan_from_${wallet}_to_${address}`}
                          onClick={() => {
                            analytics.track(REDIRECT_CLICKED, {
                              chain: AVAILABLE_CHAIN.ETHEREUM,
                              location:
                                REDIRECT_CLICKED_LOCATION.TOP_100_HOLDERS,
                              type: REDIRECT_CLICKED_TYPE.BLOCK_EXPLORER,
                              url: `https://etherscan.io/address/${wallet}?toaddress=${address}`,
                            });
                          }}
                          role="link"
                          url={`https://etherscan.io/address/${wallet}?toaddress=${address}`}
                        >
                          <span className={styles.value}>
                            {getIntegerAndDecimalParts(to.value)}
                          </span>
                        </ExternalLink>
                      </div>
                    )}
                  </div>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </FloatingFocusManager>
  );
}
