import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cn from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { Loading, Wallet } from '~/components';
import { REDIRECT_CLICKED_LOCATION } from '~/constants';
import useContractHoldersNetwork from '~/data/useContractHoldersNetwork';
import type { HoldersCluster, LinkedHolder, SimNode } from '~/types';

import styles from './ClusterDetail.module.scss';
import ForceGraph from './ForceGraph';
import WalletSummary from './WalletSummary';

interface ClusterDetailProps {
  addresses: LinkedHolder[];
  deployer?: string | null;
}

export default function ClusterDetail({
  addresses,
  deployer,
}: ClusterDetailProps) {
  const uniqueQuantities = [
    ...new Set(addresses.map((address) => address.quantity)),
  ];

  const { isError, isLoading, networkLinks, wallets } =
    useContractHoldersNetwork(addresses.map((address) => address.address));
  const [focusedWallet, setFocusedWallet] = useState<string | null>(null);
  const [hoveredWallet, setHoveredWallet] = useState<string | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const { context, floating, reference, strategy, x, y } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      if (!open) {
        setFocusedWallet(null);
      }
      setIsOpen(open);
    },
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
  });

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  const linkInfo = useMemo(
    () =>
      networkLinks.reduce<{
        [address: string]: {
          linkedNodes: string[];
        };
      }>((acc, curr) => {
        acc[curr.toAddress] = {
          linkedNodes: !!acc[curr.toAddress]?.linkedNodes
            ? [...acc[curr.toAddress].linkedNodes, curr.fromAddress]
            : [curr.fromAddress],
        };
        return acc;
      }, {}),
    [networkLinks]
  );

  const nodesInfo = useMemo(
    () =>
      wallets.reduce<{
        [address: string]: SimNode<HoldersCluster>;
      }>((acc, curr) => {
        const matched = addresses.find((wallet) => wallet.address === curr);
        acc[curr] = {
          id: curr,
          data: {
            address: curr,
            ens: matched?.ens,
            isHolder: !!matched,
            linkedNodes: linkInfo[curr].linkedNodes,
            weight: matched?.quantity || 0,
          },
        };
        return acc;
      }, {}),
    [addresses, linkInfo, wallets]
  );

  const links = networkLinks.map((link) => ({
    source: link.fromAddress,
    target: link.toAddress,
  }));

  const handleFocusNode = useCallback((e: any, address: string) => {
    e.stopPropagation();

    reference(e.target);
    setIsOpen(true);
    setFocusedWallet((prev) => (prev == address ? null : address));
  }, []);

  const handleMouseOutNode = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setHoveredWallet(null);
  };

  const handleMouseOverNode = useCallback(
    (
      e: MouseEvent<HTMLLIElement | SVGCircleElement>,
      address: string | null
    ) => {
      e.stopPropagation();
      setHoveredWallet(address);
    },
    []
  );

  return (
    <>
      <div className={styles.details}>
        <div className={styles.chart_container}>
          {isLoading ? (
            <Loading size={32} />
          ) : isError ? (
            <span className={styles.chart_error}>{'probably nothing'}</span>
          ) : (
            <ForceGraph
              focusedNode={focusedWallet}
              hoveredNode={hoveredWallet}
              links={links}
              nodes={Object.values(nodesInfo)}
              onClickNode={handleFocusNode}
              onHoverNode={handleMouseOverNode}
              uniqueQuantities={uniqueQuantities}
            />
          )}
        </div>
        <ul className={styles.clustered_addresses}>
          {addresses.map((wallet, index) => (
            <li
              key={`cluster_inner_${index}`}
              className={cn(styles.connected_wallet_item, {
                [styles.focused]: wallet.address === focusedWallet,
                [styles.hovered]:
                  wallet.address !== focusedWallet &&
                  wallet.address === hoveredWallet,
                [styles.focused_hovered]:
                  wallet.address === focusedWallet &&
                  wallet.address === hoveredWallet,
                [styles.linked]:
                  focusedWallet &&
                  !_isEmpty(nodesInfo) &&
                  nodesInfo[focusedWallet].data.linkedNodes.includes(
                    wallet.address
                  ),
              })}
              onClick={(e) => handleFocusNode(e, wallet.address)}
              onMouseOver={(e) => handleMouseOverNode(e, wallet.address)}
              onMouseOut={handleMouseOutNode}
            >
              <Wallet
                address={wallet.address}
                deployer={deployer}
                ens={wallet.ens}
                location={REDIRECT_CLICKED_LOCATION.TOP_100_HOLDERS}
              />
              <span className={styles.quantity}>{wallet.quantity}</span>
            </li>
          ))}
        </ul>
      </div>
      <FloatingPortal>
        {isOpen && focusedWallet && !_isEmpty(nodesInfo) && (
          <WalletSummary
            context={context}
            deployer={deployer}
            floating={floating}
            getFloatingProps={getFloatingProps}
            links={networkLinks}
            nodesInfo={nodesInfo}
            strategy={strategy}
            wallet={focusedWallet}
            x={x}
            y={y}
          />
        )}
      </FloatingPortal>
    </>
  );
}
