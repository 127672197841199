import { of } from 'rxjs';
import { bufferTime, concatMap, filter } from 'rxjs/operators';

import type { MintsOverviewData, MintsOverviewFilterTimeOption } from '~/types';

import ResettableSubject from './ResettableSubject';

export class MintsOverviewService {
  private txSubject = new ResettableSubject<{
    [key in MintsOverviewFilterTimeOption]: MintsOverviewData[];
  }>();
  private tx = this.txSubject.asObservable();
  loadTxs = this.tx
    .pipe(bufferTime(3_000))
    .pipe(filter((data) => data.length > 0))
    .pipe(
      concatMap((data) => {
        const mintsOverviewData = {};
        data.map((datum) => Object.assign(mintsOverviewData, datum));
        return of(mintsOverviewData);
      })
    );

  constructor() {
    this.reset = this.reset.bind(this);
    this.set = this.set.bind(this);
  }

  reset(): void {
    this.txSubject.reset();
  }

  set(data: {
    [key in MintsOverviewFilterTimeOption]: MintsOverviewData[];
  }): void {
    this.txSubject.next(data);
  }
}

export default MintsOverviewService;
