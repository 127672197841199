import type { ReactNode } from 'react';
import { useRecoilState } from 'recoil';

import {
  ConnectModal,
  LinkWalletModal,
  Loading,
  MaintenanceMode,
  Navbar,
  NewFeaturesModal,
  SettingsModal,
  UsernameRequiredModal,
} from '~/components';
import config from '~/config';
import useNewFeatures from '~/data/useNewFeatures';
import {
  appConnectModalState,
  appNewFeaturesModalState,
  appSettingModalState,
} from '~/store/app';
import { linkWalletModalState, usernameRequiredModalState } from '~/store/user';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: ReactNode;
  isLoading?: boolean;
}

export default function Layout({ children, isLoading = false }: LayoutProps) {
  const [appConnectModal, setAppConnectModal] =
    useRecoilState(appConnectModalState);
  const [appNewFeaturesModal, setAppNewFeaturesModal] = useRecoilState(
    appNewFeaturesModalState
  );
  const [appSettingModal, setAppSettingModal] =
    useRecoilState(appSettingModalState);
  const [linkWalletModal, setLinkWalletModal] =
    useRecoilState(linkWalletModalState);
  const [usernameRequiredModal, setUsernameRequiredModal] = useRecoilState(
    usernameRequiredModalState
  );

  const { newFeatures, setNewFeaturesLastId } = useNewFeatures();

  const onCloseConnectModal = () => {
    setAppConnectModal({ isOpened: false });
  };

  const onCloseNewFeaturesModal = () => {
    setAppNewFeaturesModal({ isOpened: false });
    setNewFeaturesLastId();
  };

  const onCloseSettingsModal = () => {
    setAppSettingModal({ history: [], isOpened: false });
  };

  const onCloseLinkWalletModal = () => {
    setLinkWalletModal({ isOpened: false });
  };

  const onCloseUsernameRequiredModal = () => {
    setUsernameRequiredModal({ isOpened: false });
  };

  return (
    <>
      <Navbar />
      {isLoading ? (
        <div className={styles.loading_container}>
          <Loading size={48} />
        </div>
      ) : config.MAINTENANCE_MODE === 'true' ? (
        <MaintenanceMode />
      ) : (
        <>{children}</>
      )}
      {appConnectModal.isOpened && (
        <ConnectModal
          isOpen={appConnectModal.isOpened}
          onClose={onCloseConnectModal}
        />
      )}
      {appSettingModal.isOpened && (
        <SettingsModal
          isOpen={appSettingModal.isOpened}
          onClose={onCloseSettingsModal}
        />
      )}
      {linkWalletModal.isOpened && (
        <LinkWalletModal
          isOpen={linkWalletModal.isOpened}
          onClose={onCloseLinkWalletModal}
        />
      )}
      {usernameRequiredModal.isOpened && (
        <UsernameRequiredModal
          isOpen={usernameRequiredModal.isOpened}
          onClose={onCloseUsernameRequiredModal}
        />
      )}
      {newFeatures.length > 0 && appNewFeaturesModal.isOpened && (
        <NewFeaturesModal
          isOpen={appNewFeaturesModal.isOpened}
          newFeatures={newFeatures}
          onClose={onCloseNewFeaturesModal}
        />
      )}
    </>
  );
}
