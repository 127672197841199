import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import API from '~/api';
import { contractsKeys } from '~/constants/queryKeys';
import { MintInfo, NotableGroupActivity } from '~/types';

interface UseGroupActivityMintInfoProps {
  groupActivity: NotableGroupActivity | null;
}

export default function useGroupActivityMintInfo({
  groupActivity,
}: UseGroupActivityMintInfoProps) {
  const [isMintedOut, setIsMintedOut] = useState(false);
  const [mintInfo, setMintInfo] = useState<MintInfo[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const {
    address = '',
    maxSupply = null,
    simulationPassed = false,
    totalSupply = null,
  } = { ...groupActivity };
  const mintable = simulationPassed && maxSupply !== totalSupply;
  const queryKey = contractsKeys.mints('ethereum', address);
  const result = useQuery(queryKey, () => API.getMintInfo(address), {
    enabled: mintable,
  });

  const getDeployer = async () => {
    try {
      const response = await API.getContractDetail(address);
      const { deployer } = response.data;

      return deployer;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (selectedAddress === address || (mintable && result.isLoading)) {
      return;
    }

    const isMintedOut = maxSupply === totalSupply;
    const data = mintable && result.data ? result.data.data : [];
    const mintInfo = data
      .filter((info) => info.simulationPassed && !!info.price)
      .sort((a, b) => {
        if (a.signature === groupActivity?.functionSignature) return -1;
        if (b.signature === groupActivity?.functionSignature) return 1;
        return 0;
      });
    setIsMintedOut(isMintedOut);
    setMintInfo(mintInfo);
    setSelectedAddress(address);
  }, [address, result]);

  return {
    isLoading: result.isLoading,
    isMintedOut,
    mintInfo,
    selectedAddress,
    getDeployer,
  };
}
