import type {
  CursorBasedPagination,
  MintData,
  MintsOverviewData,
  MintsVolume,
  NotableGroupActivity,
  NotableGroupActivityArgs,
} from '~/types';

import base from './base';

const Transactions = {
  getMintsOverview(time: string) {
    const query = `?window=${time}&chains=ethereum`;
    return base.get<MintsOverviewData[]>(`/timeseries/mints/overview/${query}`);
  },
  getNotableGroupsMints(
    params: NotableGroupActivityArgs,
    cursor: string | null
  ) {
    const query = `${
      params.ids.length > 0 ? `ids=${params.ids.toString()}&` : ''
    }excludeAirdrop=${params.excludeAirdrop}&maxValue=${
      params.maxValue
    }&minValue=${params.minValue}&onlyMintable=${
      params.onlyMintable
    }&onlyVerified=${params.onlyVerified}`;
    const url = `/timeseries/mints/notable/?page_size=30&${
      cursor ? `cursor=${cursor}&${query}` : `${query}`
    }`;
    return base.get<CursorBasedPagination<NotableGroupActivity>>(url);
  },
  getNotableGroupsMintsVolume() {
    return base.get<MintsVolume[]>(`/timeseries/mints/notable/volume/`);
  },
  getLiveMints() {
    return base.get<MintData[]>('/timeseries/mints/live/');
  },
};

export default Transactions;
