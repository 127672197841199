import cn from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';

import { Modal } from '~/components';

import styles from './SubmitModal.module.scss';

interface SubmitModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  titleAlign?: 'left' | 'center';
  buttonText?: ReactNode;
  filter?: ReactNode;
}

export default function SubmitModal({
  children,
  isOpen,
  onClose,
  onSubmit,
  title,
  titleAlign = 'left',
  buttonText = 'Submit',
  filter,
}: SubmitModalProps) {
  const modalBodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollIntoView();
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose} style={{ width: 640 }}>
      <div className={styles.submit_modal_container}>
        <div
          className={cn(styles.submit_modal_title_container, {
            [styles.center]: titleAlign === 'center',
          })}
        >
          <h1 className={styles.submit_modal_title}>{title}</h1>
          {filter}
        </div>
        <div className={styles.submit_modal_body} ref={modalBodyRef}>
          {children}
        </div>
        <div className={styles.buttons_container}>
          <button className={styles.submit_button} onClick={onSubmit}>
            {buttonText}
          </button>
          <button className={styles.cancel_button} onClick={onClose}>
            {'Cancel'}
          </button>
        </div>
      </div>
    </Modal>
  );
}
