import cn from 'classnames';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useBalance } from 'wagmi';
import { mainnet } from 'wagmi/chains';

import {
  IcnAddressNeeded,
  IcnAirdrop,
  IcnByteNeeded,
  IcnSmallChainEthereum,
  IcnSmallHint,
} from '~/assets';
import { Popover, SelectButton, Toaster } from '~/components';
import Table, {
  TableBody,
  TableCell,
  TableHeader,
  TableNoData,
  TableRow,
} from '~/components/Table';
import {
  DETAIL_LAYOUT,
  RENDERING_BOUND_TIME,
  WRITE_INFORMATION_FROM,
} from '~/constants';
import { useAuth } from '~/contexts/AuthContext';
import useAutomationQuickTask from '~/hooks/useAutomationQuickTask';
import useAutomations from '~/hooks/useAutomations';
import { appSettingModalState } from '~/store/app';
import {
  checksumContractAddressState,
  writeInformationState,
} from '~/store/contract';
import type {
  ContractSuccessfulTransaction,
  MintableFunction,
  MintableFunctions,
} from '~/types';
import formatEthPrice from '~/utils/formatEthPrice';
import getAlignedParams from '~/utils/getAlignedParams';
import noExponents from '~/utils/noExponents';
import noop from '~/utils/noop';

import styles from './SuccessfulTransactionsSection.module.scss';
import TimeAgoCell from './TimeAgoCell';
import SectionLayout from '../SectionLayout';

interface SuccessfulTransactionsSectionProps {
  isFetched: boolean;
  isFirstFetched: boolean;
  isLoading: boolean;
  isMintedOut: boolean;
  mintFunctions: MintableFunctions;
  successfulTransactions: ContractSuccessfulTransaction[];
  deployer?: string | null;
}

export default memo(function SuccessfulTransactionsSection({
  isFetched,
  isFirstFetched,
  isLoading,
  isMintedOut,
  mintFunctions = {},
  successfulTransactions,
  deployer,
}: SuccessfulTransactionsSectionProps) {
  const { isAuthenticated, signIn, user } = useAuth();
  const { data: balanceData } = useBalance({
    address: user?.address as `0x${string}`,
    chainId: mainnet.id,
  });
  const address = useRecoilValue(checksumContractAddressState);
  const [automations, setAutomations] = useAutomations();
  const setAppSettingsModal = useSetRecoilState(appSettingModalState);
  const setWriteInformation = useSetRecoilState(writeInformationState);
  const existedSelectedPartner = automations.partners.length > 0;
  const isAutomationActive = !!existedSelectedPartner && !!automations.default;
  const selectedPartner = existedSelectedPartner
    ? automations.partners[0]
    : null;
  const { icon, isMintable, mint } = useAutomationQuickTask(selectedPartner);
  const [enableBound, setEnableBound] = useState(false);
  const [selectedFunctionSignatures, setSelectedFunctionSignatures] = useState<
    string[]
  >([]);

  const handleGoToMintAutomation = useCallback(() => {
    if (!isAuthenticated) {
      signIn();
      return;
    }
    setAppSettingsModal({ isOpened: true, history: [2] });
    return;
  }, [isAuthenticated, signIn]);

  const showingFunctionSignatures: MintableFunction[] = useMemo(
    () =>
      successfulTransactions.length > 0 && Object.keys(mintFunctions).length > 0
        ? [...new Set(successfulTransactions.map((tx) => tx.functionSignature))]
            .filter((signature): signature is string => signature != null)
            .map((signature) => mintFunctions[signature])
            .filter(Boolean)
            .sort((prev, curr) =>
              prev.name
                ? curr.name
                  ? prev.name?.localeCompare(curr.name)
                  : 0
                : 0
            )
        : [],
    [mintFunctions, successfulTransactions]
  );

  const filteredTransactions = useMemo(
    () =>
      selectedFunctionSignatures.length > 0
        ? successfulTransactions.filter(
            (tx) =>
              tx.functionSignature != null &&
              selectedFunctionSignatures.includes(tx.functionSignature)
          )
        : successfulTransactions,
    [successfulTransactions, selectedFunctionSignatures]
  );

  const isAllTransactionsFree = useMemo(
    () => successfulTransactions.every((tx) => tx.value === '0'),
    [successfulTransactions]
  );

  const renderSwitchMintVendor = useCallback(() => {
    if (!icon || !isAuthenticated) {
      return (
        <button
          className={cn('btn_icon', styles.automation_container, {
            [styles.automation_active]: isAutomationActive,
          })}
          onClick={handleGoToMintAutomation}
        >
          <span className={styles.quick_task}>QT</span>
        </button>
      );
    }

    return (
      <Popover
        render={() => (
          <div className="default_popover">
            <span>
              {isAutomationActive
                ? 'Deactivate automation'
                : 'Activate automation'}
            </span>
          </div>
        )}
        placement="top"
      >
        <button
          className={cn('btn_icon', styles.automation_container, {
            [styles.automation_active]: isAutomationActive,
          })}
          onClick={(e) => {
            e.stopPropagation();
            setAutomations({
              default: isAutomationActive ? null : automations.partners[0],
            });
          }}
        >
          {icon}
        </button>
      </Popover>
    );
  }, [
    automations,
    handleGoToMintAutomation,
    icon,
    isAutomationActive,
    isAuthenticated,
    setAutomations,
  ]);

  const onClickMint = (
    mintFunction: MintableFunction,
    price: string,
    params: any,
    toAddress: string
  ) => {
    const alignedParams = getAlignedParams(params, mintFunction?.inputs);

    setWriteInformation({
      address,
      from: WRITE_INFORMATION_FROM.SUCCESSFUL_TX,
      mintInfo: {
        deployer,
        mintableFunction: mintFunction,
        params: alignedParams,
        price,
        transactionTo: toAddress,
      },
    });
  };

  const renderMintButton = (tx: ContractSuccessfulTransaction) => {
    const { decodedData, functionSignature, toAddress, value } = tx;
    const price = value;
    const mintFunction = functionSignature
      ? mintFunctions[functionSignature]
      : null;

    const isSameParametersLength =
      decodedData &&
      mintFunction &&
      Object.keys(decodedData).length === (mintFunction.inputs ?? []).length;

    if (
      !!icon &&
      isAuthenticated &&
      !isMintedOut &&
      isAutomationActive &&
      selectedPartner
    ) {
      const isActivated =
        mintFunction && isMintable(tx, mintFunction) && isSameParametersLength;

      return (
        <button
          className={cn(styles.mint_bot, {
            [styles.inactive_qt]: !isActivated,
          })}
          onClick={(e) => {
            e.stopPropagation();
            if (isActivated) {
              return mint(tx, mintFunction, deployer);
            }
            return noop();
          }}
        >
          {icon}
          <span>{'QT'}</span>
        </button>
      );
    }

    const isActivated =
      !!mintFunction?.name &&
      isSameParametersLength &&
      toAddress &&
      !isMintedOut;

    return (
      <button
        className={cn(styles.mint_button, {
          [styles.active]: isActivated,
          [styles.minted_out_button]: isMintedOut,
        })}
        onClick={(e) => {
          e.stopPropagation();
          if (isActivated) {
            if (isAuthenticated) {
              if (balanceData ? balanceData.formatted > price : true) {
                return onClickMint(mintFunction, price, decodedData, toAddress);
              }

              Toaster.toast({
                title: 'Insufficient funds',
                type: 'error',
              });
            } else {
              signIn();
            }
          }
          return noop();
        }}
      >
        {isMintedOut ? 'Minted Out' : 'Mint'}
      </button>
    );
  };

  const renderTableRow = (
    tx: ContractSuccessfulTransaction,
    isAllValueFree: boolean
  ) => {
    const {
      count,
      functionSignature,
      gasPrice,
      gasUsed,
      isAirdrop,
      maxFeePerGas,
      maxPriorityFeePerGas,
      mintQuantity,
      timestamp,
      transactionFee,
      transactionType,
      value,
    } = tx;
    const mintFunction = functionSignature
      ? mintFunctions[functionSignature]
      : null;
    const isAddressNeeded = !!mintFunction?.inputs?.some((param) =>
      param?.type?.startsWith('address')
    );
    const isByteNeeded = !!mintFunction?.inputs?.some((param) =>
      param?.type?.startsWith('byte')
    );

    let valueIntegerPart = '';
    let valueDecimalPart = '';

    if (!isAllValueFree) {
      if (value.length > 6) {
        [valueIntegerPart, valueDecimalPart] = formatEthPrice(value)
          .toString()
          .split('.');
      } else {
        [valueIntegerPart, valueDecimalPart] = value.split('.');
      }
    }

    const renderMintQuantity = (mintQuantity: string) => {
      if (!mintQuantity) return '-';
      if (mintQuantity.length > 9) return `> 1B`;
      if (mintQuantity.length > 6) return `> ${mintQuantity.slice(0, -6)}M`;
      return mintQuantity;
    };

    return (
      <TableRow key={mintQuantity}>
        <TableCell
          isText={false}
          className={styles.table_body_absolute_left_cell}
          divClassName={styles.table_body_absolute_cell}
        >
          {renderMintQuantity(mintQuantity)}
          {isAirdrop && value === '0' && (
            <Popover
              render={() => (
                <div className="default_popover">
                  <span>{'Airdrop'}</span>
                </div>
              )}
              animation
              placement="top"
            >
              <div className={styles.airdrop_icon}>
                <IcnAirdrop />
              </div>
            </Popover>
          )}
        </TableCell>
        <TableCell isText={false}>{count}</TableCell>
        {timestamp ? (
          <TimeAgoCell date={new Date(timestamp)} />
        ) : (
          <TableCell isText={false}>{'-'}</TableCell>
        )}
        {isAllValueFree ? (
          <TableCell isText={false} colSpan={3}>
            {'0'}
          </TableCell>
        ) : value === '0' ? (
          <>
            <TableCell isText={false} className={styles.value_integer}>
              <p>{'0'}</p>
            </TableCell>
            <TableCell className={styles.value_empty}>{''}</TableCell>
            <TableCell className={styles.value_empty}>{''}</TableCell>
          </>
        ) : (
          <>
            <TableCell isText={false} className={styles.value_integer}>
              {value.length > 6 ? (
                <Popover
                  render={() => (
                    <div className="price_popover_container">
                      <span className="price_popover_content">
                        <IcnSmallChainEthereum />
                        <span className="price_content">
                          {noExponents(+(value ?? '0'))}
                        </span>
                      </span>
                    </div>
                  )}
                  delay={{ open: 1, close: 1 }}
                  placement="top"
                >
                  <p>{`≈ ${valueIntegerPart}`}</p>
                </Popover>
              ) : (
                <p>{valueIntegerPart}</p>
              )}
            </TableCell>
            {valueDecimalPart ? (
              <TableCell isText={false} className={styles.value_decimal_point}>
                {value.length > 6 ? (
                  <Popover
                    render={() => (
                      <div className="price_popover_container">
                        <span className="price_popover_content">
                          <IcnSmallChainEthereum />
                          <span className="price_content">
                            {noExponents(+(value ?? '0'))}
                          </span>
                        </span>
                      </div>
                    )}
                    delay={{ open: 1, close: 1 }}
                    placement="top"
                  >
                    <p>{'.'}</p>
                  </Popover>
                ) : (
                  <p>{'.'}</p>
                )}
              </TableCell>
            ) : (
              <TableCell isText={false} className={styles.value_empty}>
                {''}
              </TableCell>
            )}
            <TableCell isText={true} className={styles.value_decimal}>
              {value.length > 6 ? (
                <Popover
                  render={() => (
                    <div className="price_popover_container">
                      <span className="price_popover_content">
                        <IcnSmallChainEthereum />
                        <span className="price_content">
                          {noExponents(+(value ?? '0'))}
                        </span>
                      </span>
                    </div>
                  )}
                  delay={{ open: 1, close: 1 }}
                  placement="top"
                >
                  <span>{valueDecimalPart}</span>
                </Popover>
              ) : (
                <p>{valueDecimalPart}</p>
              )}
            </TableCell>
          </>
        )}
        <TableCell isText={false}>{gasUsed.toLocaleString() ?? '-'}</TableCell>
        {transactionType === 2 ? (
          <>
            <TableCell isText={false} className={styles.cell_divided_left}>
              <Popover
                render={() => (
                  <div className="price_popover_container">
                    <span className="price_popover_content">
                      {`${maxFeePerGas ? +maxFeePerGas : '-'}  |  ${
                        maxPriorityFeePerGas ? +maxPriorityFeePerGas : '-'
                      }`}
                    </span>
                  </div>
                )}
                delay={{ open: 1, close: 1 }}
                placement="right-end"
              >
                <span>
                  {maxFeePerGas ? `${Math.ceil(+maxFeePerGas)}` : '-'}
                </span>
              </Popover>
            </TableCell>
            <TableCell className={styles.cell_divider}>{'|'}</TableCell>
            <TableCell isText={false} className={styles.cell_divided_right}>
              <Popover
                render={() => (
                  <div className="price_popover_container">
                    <span className="price_popover_content">
                      {`${maxFeePerGas ? +maxFeePerGas : '-'}  |  ${
                        maxPriorityFeePerGas ? +maxPriorityFeePerGas : '-'
                      }`}
                    </span>
                  </div>
                )}
                delay={{ open: 1, close: 1 }}
                placement="right-end"
              >
                <span>
                  {maxPriorityFeePerGas
                    ? `${Math.ceil(+maxPriorityFeePerGas)}`
                    : '-'}
                </span>
              </Popover>
            </TableCell>
            <TableCell isText={true} className={styles.cell_divided_unit}>
              {'Gwei'}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              isText={false}
              className={styles.cell_divided_right}
              colSpan={3}
            >
              <Popover
                render={() => (
                  <div className="price_popover_container">
                    <span className="price_popover_content">
                      {gasPrice ?? '-'}
                    </span>
                  </div>
                )}
                delay={{ open: 1, close: 1 }}
                placement="right-end"
              >
                <span>{gasPrice ? `${Math.ceil(+gasPrice)}` : '-'}</span>
              </Popover>
            </TableCell>
            <TableCell isText={true} className={styles.cell_divided_unit}>
              {'Gwei'}
            </TableCell>
          </>
        )}
        <TableCell isText={false}>
          {transactionFee.length > 6 ? (
            <Popover
              render={() => (
                <div className="price_popover_container">
                  <span className="price_popover_content">
                    <IcnSmallChainEthereum />
                    <span className="price_content">
                      {noExponents(+(transactionFee ?? '0'))}
                    </span>
                  </span>
                </div>
              )}
              delay={{ open: 1, close: 1 }}
              placement="right-end"
            >
              <span>{`≈ ${(Math.ceil(+transactionFee * 10000) * 0.0001).toFixed(
                4
              )}`}</span>
            </Popover>
          ) : (
            <p>{`${noExponents(+(transactionFee ?? '0'))}`}</p>
          )}
        </TableCell>
        <TableCell>
          <div className={styles.method_container}>
            {isAddressNeeded && (
              <Popover
                render={() => (
                  <div className="default_popover">
                    <span>{`This method has address field(s). You should be careful because you might end up minting NFTs to someone else's address.`}</span>
                  </div>
                )}
                animation
                placement="top"
              >
                <div
                  className={cn(
                    styles.param_needed_container,
                    styles.address_param
                  )}
                >
                  <IcnAddressNeeded />
                </div>
              </Popover>
            )}
            {isByteNeeded && (
              <Popover
                render={() => (
                  <div className="default_popover">
                    <span>{`This method has bytes field(s). They are typically used for allowlists.`}</span>
                  </div>
                )}
                animation
                placement="top"
              >
                <div
                  className={cn(
                    styles.param_needed_container,
                    styles.byte_param
                  )}
                >
                  <IcnByteNeeded />
                </div>
              </Popover>
            )}
            <p>{mintFunction?.name ?? '-'}</p>
          </div>
        </TableCell>
        <TableCell
          className={cn(
            styles.table_body_absolute_right_cell,
            styles.table_body_absolute_button,
            { [styles.minted_out_cell]: isMintedOut }
          )}
        >
          {renderMintButton(tx)}
        </TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    // initialize selected functionNames
    setSelectedFunctionSignatures([]);
  }, [address]);

  useEffect(() => {
    const boundTimer = () =>
      setTimeout(() => {
        setEnableBound(true);
      }, RENDERING_BOUND_TIME);

    if (isFetched) {
      boundTimer();
    } else {
      clearTimeout(boundTimer());
      setEnableBound(false);
    }
  }, [isFetched]);

  return (
    <SectionLayout
      boundTrigger={''}
      enableBound={enableBound}
      id={'successful_transaction'}
    >
      <div className={styles.section_header_container}>
        <div className={styles.section_title_container}>
          <span className={styles.section_title}>
            {isLoading ? (
              <Skeleton width={244} />
            ) : (
              DETAIL_LAYOUT.SUCCESSFUL_TRANSACTIONS
            )}
          </span>
          <span className={styles.section_help}>
            {isLoading ? <Skeleton width={128} /> : 'Latest per quantity'}
          </span>
        </div>
        {!isFirstFetched &&
          !isLoading &&
          showingFunctionSignatures.length > 0 && (
            <div className={styles.method_names_filter}>
              <SelectButton.Group
                items={showingFunctionSignatures.map((mintFunction, index) => {
                  const isAddressNeeded = !!mintFunction?.inputs?.some(
                    (param) => param?.type?.startsWith('address')
                  );
                  const isByteNeeded = !!mintFunction?.inputs?.some((param) =>
                    param?.type?.startsWith('byte')
                  );
                  const key =
                    mintFunction.signature ?? `filter_method_${index}`;

                  return {
                    key,
                    label: (
                      <span className={styles.method_container}>
                        {isAddressNeeded && (
                          <div
                            className={cn(
                              styles.param_needed_container,
                              styles.address_param
                            )}
                          >
                            <IcnAddressNeeded />
                          </div>
                        )}
                        {isByteNeeded && (
                          <div
                            className={cn(
                              styles.param_needed_container,
                              styles.byte_param
                            )}
                          >
                            <IcnByteNeeded />
                          </div>
                        )}
                        {mintFunction.name}
                      </span>
                    ),
                    selected: selectedFunctionSignatures.includes(
                      mintFunction.signature!
                    ),
                    value: mintFunction.signature!,
                  };
                })}
                multi
                onItemClicked={(selectedItems) => {
                  setSelectedFunctionSignatures(
                    selectedItems.map((selectedItem) => selectedItem.key)
                  );
                }}
                showClear={Object.keys(mintFunctions).length > 1}
              />
            </div>
          )}
      </div>
      {isLoading ? (
        <Skeleton height={75} />
      ) : (
        <div className={styles.table_container}>
          <div
            className={cn(styles.table_inner_container, {
              [styles.minted_out]: isMintedOut,
              [styles.empty]: filteredTransactions.length === 0,
            })}
          >
            <Table
              useCustomContainer={true}
              tableClassName={styles.table_custom_style}
            >
              <TableHeader className={styles.table_header_custom_style}>
                <TableRow>
                  <TableCell
                    isText={false}
                    isHeader={true}
                    width={76}
                    className={styles.table_header_absolute_left_cell}
                    divClassName={styles.table_header_absolute_cell}
                  >
                    {'Quantity'}
                  </TableCell>
                  <TableCell isText={false} isHeader={true}>
                    <div className={styles.table_header_cell}>
                      {'Tx Count'}
                      <Popover
                        render={() => (
                          <div className="hint_popover">
                            <span>{'Number of successful transactions.'}</span>
                          </div>
                        )}
                        animation
                        placement="top"
                        showArrow
                      >
                        <div className="center">
                          <IcnSmallHint />
                        </div>
                      </Popover>
                    </div>
                  </TableCell>
                  <TableCell
                    isText={true}
                    isHeader={true}
                    width={40}
                    colSpan={2}
                  >
                    {'Time'}
                  </TableCell>
                  <TableCell isText={false} isHeader={true} colSpan={3}>
                    <div className={styles.table_header_cell}>
                      {`Value`}
                      <IcnSmallChainEthereum />
                    </div>
                  </TableCell>
                  <TableCell isText={false} isHeader={true}>
                    {'Gas Used'}
                  </TableCell>
                  <TableCell isText={false} isHeader={true} colSpan={4}>
                    <div className={styles.table_header_cell}>
                      {'Gas Price'}
                      <Popover
                        render={() => (
                          <div className="hint_popover">
                            <span>
                              {
                                'For EIP 1559 tx, Max Fee Per Gas and Max priority Fee Per Gas are displayed.'
                              }
                            </span>
                          </div>
                        )}
                        animation
                        placement="top"
                        showArrow
                      >
                        <div className="center">
                          <IcnSmallHint />
                        </div>
                      </Popover>
                    </div>
                  </TableCell>
                  <TableCell isText={false} isHeader={true}>
                    <div className={styles.table_header_cell}>
                      {'Tx Fee'}
                      <IcnSmallChainEthereum />
                    </div>
                  </TableCell>
                  <TableCell isText={true} isHeader={true}>
                    <div className={styles.table_header_cell}>
                      {'Method'}
                      <Popover
                        render={() => (
                          <div className="hint_popover">
                            <span>
                              {'Function executed based on decoded input data.'}
                            </span>
                          </div>
                        )}
                        animation
                        placement="top"
                        showArrow
                      >
                        <div className="center">
                          <IcnSmallHint />
                        </div>
                      </Popover>
                    </div>
                  </TableCell>
                  <TableCell
                    isText={true}
                    isHeader={true}
                    width={84}
                    className={cn(
                      { [styles.minted_out_cell]: isMintedOut },
                      styles.table_header_absolute_right_cell
                    )}
                  >
                    {renderSwitchMintVendor()}
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredTransactions.length > 0 ? (
                  filteredTransactions.map((tx) =>
                    renderTableRow(tx, isAllTransactionsFree)
                  )
                ) : (
                  <TableNoData colSpan={6} />
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
    </SectionLayout>
  );
});
