import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { isSupported, onMessage } from 'firebase/messaging';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import API from '~/api';
import { notificationsKeys } from '~/constants/queryKeys';
import { FirebaseService } from '~/services';
import { appNotificationModalState, appNotificationState } from '~/store/app';
import { Notification } from '~/types';

const messagingService = new FirebaseService();

export default function usePushNotification() {
  const queryClient = useQueryClient();
  const setAppNotification = useSetRecoilState(appNotificationState);
  const setAppNotificationModal = useSetRecoilState(appNotificationModalState);

  const getMessage = useCallback((messagingService: FirebaseService) => {
    if (messagingService.firebaseMessaging) {
      const queryKey = notificationsKeys.all;

      onMessage(
        messagingService.firebaseMessaging,
        ({ data, notification }) => {
          if (data?.showBadge === 'true') {
            queryClient.setQueriesData<
              | InfiniteData<{
                  results: Notification[];
                  next: string | null;
                  isLast: boolean;
                }>
              | undefined
            >({ exact: true, queryKey }, (prev) => {
              if (!!prev && !!data) {
                const lastPage = prev.pages[0];
                const newLastPage = {
                  ...lastPage,
                  results: [
                    { ...data, unread: true },
                    ...lastPage.results,
                  ] as Notification[],
                };
                prev.pages = [
                  newLastPage,
                  ...prev.pages.slice(1, prev.pages.length - 1),
                ];
              }
              return prev;
            });
            setAppNotificationModal((prev) => ({ ...prev, isUnread: true }));
          }

          if (notification) {
            setAppNotification({
              data,
              notification,
            });
          }
        }
      );
    }
  }, []);

  const setFcmToken = useCallback(async () => {
    try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        const token = await messagingService.init();
        if (token) {
          await API.setPushToken(token);
          getMessage(messagingService);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [getMessage]);

  async function removeToken() {
    return await messagingService.clear();
  }

  async function removeServiceWorker() {
    try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        const registrations = await navigator.serviceWorker?.getRegistrations();
        for (const registration of registrations) {
          registration.unregister();
        }
        return await removeToken();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return {
    removeServiceWorker,
    removeToken,
    setFcmToken,
  };
}
