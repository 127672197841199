import cn from 'classnames';
import {
  ImgHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';

import { IcnImgFailed } from '~/assets';

import styles from './ImageSource.module.scss';

interface ImageSourceProps extends ImgHTMLAttributes<HTMLImageElement> {
  url: string;
  radius?: boolean;
  size?: number;
}

export default memo(function ImageSource(props: ImageSourceProps) {
  const {
    alt,
    height,
    radius = false,
    loading = 'lazy',
    size,
    style,
    url,
    width,
    ...otherProps
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const handleError = useCallback(() => setIsFailed(true), []);
  const handleLoad = useCallback(() => setIsLoaded(true), []);
  const _width = width ?? size;
  const _height = height ?? size;

  useEffect(() => {
    if (url) {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setIsLoaded(true);
      };
      img.onerror = () => {
        setIsFailed(true);
      };
    } else {
      setIsFailed(true);
    }
  }, [url]);

  return (
    <>
      {isFailed ? (
        <div className={styles.failed}>
          <IcnImgFailed />
        </div>
      ) : isLoaded ? (
        <picture>
          <source srcSet={url} />
          <img
            {...otherProps}
            alt={alt}
            className={cn(styles.img, {
              [styles.radius]: radius,
            })}
            height={_height}
            loading={loading}
            onError={handleError}
            onLoad={handleLoad}
            src={url}
            style={{
              ...style,
              width: _width,
              height: _height,
            }}
            width={_width}
          />
        </picture>
      ) : (
        <div className={styles.skeleton_container}>
          <Skeleton
            height="100%"
            width="100%"
            borderRadius={radius ? '50%' : undefined}
            style={{
              zIndex: 'unset',
            }}
          />
        </div>
      )}
    </>
  );
});
