import { noop } from 'lodash';

import { IcnVerified } from '~/assets';
import { Copy, Label, Thumbnail } from '~/components';
import { IMAGE_SIZE, QUICKLINKS_ORDERING_EXAMPLE_INFO } from '~/constants';
import type { ContractQuickLink, LayoutSetting } from '~/types';
import formatAddress from '~/utils/formatAddress';

import styles from './QuicklinksExample.module.scss';
import QuicklinksExampleButton from './QuicklinksExampleButton';

interface QuicklinksExampleProps {
  quickLinks: LayoutSetting<ContractQuickLink>[];
}

export default function QuicklinksExample({
  quickLinks,
}: QuicklinksExampleProps) {
  return (
    <div className={styles.container}>
      <div className={styles.thumbnail_container}>
        <Thumbnail
          name={'Reorder quicklinks example thumbnail'}
          size={IMAGE_SIZE.HUGE}
          url={QUICKLINKS_ORDERING_EXAMPLE_INFO.imageUrl}
        />
      </div>
      <div className={styles.info_container}>
        <div className={styles.contract_name_container}>
          <span className={styles.contract_name}>
            {QUICKLINKS_ORDERING_EXAMPLE_INFO.name}
          </span>
        </div>
        <div className={styles.contract_chain_info_container}>
          <div className={styles.address_container}>
            {formatAddress(QUICKLINKS_ORDERING_EXAMPLE_INFO.address)}
            <Copy content={QUICKLINKS_ORDERING_EXAMPLE_INFO.address} />
          </div>
          <div className={styles.labels_container}>
            <Label
              text="Verified"
              icon={<IcnVerified />}
              highlight
              onClick={noop}
            />
            <Label text={'ERC721'} />
          </div>
        </div>
        <div className={styles.buttons_container}>
          {quickLinks
            .filter(({ visible }) => visible)
            .map(({ name }) => (
              <QuicklinksExampleButton key={name} platform={name} />
            ))}
        </div>
      </div>
      <div className={styles.row}></div>
    </div>
  );
}
