import cn from 'classnames';
import { ChangeEvent, MouseEvent, useState } from 'react';

import { IcnSmallHint } from '~/assets';
import Popover from '~/components/Popover';
import {
  ANNOUNCEMENT_DESCRIPTION_CHARACTER_LIMITS,
  ANNOUNCEMENT_TITLE_CHARACTER_LIMITS,
} from '~/constants';
import type { ContractAnnouncement } from '~/types';

import styles from './AnnouncementInputForm.module.scss';

interface AnnouncementInputFormProps {
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmit: (
    announcement: Pick<ContractAnnouncement, 'description' | 'title'>
  ) => void;
}

export default function AddAnnouncement({
  onClose,
  onSubmit,
}: AnnouncementInputFormProps) {
  const [announcement, setAnnouncement] = useState<
    Pick<ContractAnnouncement, 'description' | 'title'>
  >({
    description: '',
    title: '',
  });

  const descriptionLength = announcement.description.trim().length;
  const titleLength = announcement.title.trim().length;

  const handleChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.stopPropagation();
    setAnnouncement((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setAnnouncement((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit({
      description: announcement.description.trim(),
      title: announcement.title.trim(),
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.add_announcement_container}>
        <div className={styles.announcement_field_container}>
          <div className={styles.label_container}>
            <label className={styles.label} htmlFor="announcement_title">
              Title *
            </label>
            <div className={styles.character_limits_guide}>
              <span
                className={cn({
                  [styles.character_limits_exceeded]:
                    titleLength === 0 ||
                    titleLength > ANNOUNCEMENT_TITLE_CHARACTER_LIMITS,
                })}
              >
                {titleLength}
              </span>
              {` / ${ANNOUNCEMENT_TITLE_CHARACTER_LIMITS}`}
            </div>
          </div>
          <input
            className={cn(styles.input, {
              [styles.input_warning]:
                titleLength > ANNOUNCEMENT_TITLE_CHARACTER_LIMITS,
            })}
            id="announcement_title"
            onChange={handleChangeTitle}
            value={announcement.title}
          />
        </div>
        <div className={styles.announcement_field_container}>
          <div className={styles.label_container}>
            <label
              className={styles.field_name}
              htmlFor="announcement_description"
            >
              Description *
              <Popover
                render={() => (
                  <div className={styles.hint_container}>
                    <span>{'Markdown syntax is supported'}</span>
                  </div>
                )}
                animation
                placement="top"
              >
                <div className="center">
                  <IcnSmallHint />
                </div>
              </Popover>
            </label>
            <div className={styles.character_limits_guide}>
              <span
                className={cn({
                  [styles.character_limits_exceeded]:
                    descriptionLength === 0 ||
                    descriptionLength >
                      ANNOUNCEMENT_DESCRIPTION_CHARACTER_LIMITS,
                })}
              >
                {descriptionLength}
              </span>
              {` / ${ANNOUNCEMENT_DESCRIPTION_CHARACTER_LIMITS}`}
            </div>
          </div>
          <textarea
            className={cn(styles.textarea, {
              [styles.textarea_warning]:
                descriptionLength > ANNOUNCEMENT_DESCRIPTION_CHARACTER_LIMITS,
            })}
            id="announcement_description"
            onChange={handleChangeDescription}
            value={announcement.description}
          />
        </div>
      </div>
      <div className={styles.buttons_container}>
        <button
          className={styles.btn_announce}
          onClick={handleSubmit}
          disabled={
            descriptionLength === 0 ||
            descriptionLength > ANNOUNCEMENT_DESCRIPTION_CHARACTER_LIMITS ||
            titleLength === 0 ||
            titleLength > ANNOUNCEMENT_TITLE_CHARACTER_LIMITS
          }
        >
          Announce
        </button>
        <button className={styles.btn_cancel} onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
}
