import cn from 'classnames';
import type { ReactNode } from 'react';

import styles from './TableData.module.scss';

interface TableCellProps {
  children: ReactNode;
  className?: string;
  colSpan?: number;
  divClassName?: string;
  isDefaultStyle?: boolean;
  isHeader?: boolean;
  isText?: boolean;
  rowSpan?: number;
  width?: string | number;
}

export default function TableCell({
  children,
  isText = undefined,
  className,
  colSpan = 1,
  divClassName,
  isDefaultStyle = false,
  isHeader = false,
  rowSpan = 1,
  width,
}: TableCellProps) {
  return (
    <>
      {isHeader ? (
        <th
          className={cn(
            { '': isText === undefined },
            { [styles.number_column]: isText === false },
            { [styles.text_column]: isText === true },
            className
          )}
          colSpan={colSpan}
          rowSpan={rowSpan}
          style={{
            width: width,
          }}
        >
          <div
            className={cn(
              { [styles.table_element_container]: isDefaultStyle },
              { [styles.table_default_container]: !isDefaultStyle },
              divClassName
            )}
          >
            {children}
          </div>
        </th>
      ) : (
        <td
          className={cn(
            { '': isText === undefined },
            { [styles.number_column]: isText === false },
            { [styles.text_column]: isText === true },
            className
          )}
          rowSpan={rowSpan}
          colSpan={colSpan}
        >
          <div
            className={cn(
              { [styles.table_element_container]: isDefaultStyle },
              { [styles.table_default_container]: !isDefaultStyle },
              divClassName
            )}
          >
            {children}
          </div>
        </td>
      )}
    </>
  );
}
