import { UIEvent, useRef, useState } from 'react';

export default function useScrollWithShadow() {
  const container = useRef<HTMLDivElement>(null);

  const [offsetHeight, setOffsetHeight] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const currentScroll = scrollTop / (scrollHeight - offsetHeight);

  const onScrollHandler = (event: UIEvent<HTMLDivElement>) => {
    const { offsetHeight, scrollTop, scrollHeight } = event.currentTarget;
    setOffsetHeight(offsetHeight);
    setScrollHeight(scrollHeight);
    setScrollTop(scrollTop);
  };

  return {
    ref: container,
    onScrollHandler,
    topOpacity: currentScroll,
    bottomOpacity: 1 - currentScroll,
  };
}
