import { Loading } from '~/components';
import useNotificationSettings from '~/data/useNotificationSettings';
import styleVariables from '~/styles/variables.module.scss';

import DiscordWebhookSetting from './DiscordWebhookSetting';
import NotificationTriggerSetting from './NotificationTriggerSetting';
import WebPushSetting from './WebPushSetting';
import commonStyles from '../SettingsModal.module.scss';

interface NotificationsSectionProps {}

export default function NotificationsSection({}: NotificationsSectionProps) {
  const {
    canSave,
    discordWebhook,
    isLoading,
    notification,
    saveSettings,
    setTriggerSettings,
    toggleDiscordWebhookNotification,
    toggleWebPushNotification,
    triggerSettings,
  } = useNotificationSettings();
  const { DiscordWebhook = false, WebPush = true } = { ...notification };

  return (
    <section className={commonStyles.section}>
      <div className={commonStyles.section_container}>
        <DiscordWebhookSetting
          notificationEnabled={DiscordWebhook}
          provider={discordWebhook}
          toggleNotification={toggleDiscordWebhookNotification}
        />
        <WebPushSetting
          notificationEnabled={WebPush}
          toggleNotification={toggleWebPushNotification}
        />
        <NotificationTriggerSetting
          setTriggerSettings={setTriggerSettings}
          triggerSettings={triggerSettings}
        />
      </div>
      <div className={commonStyles.fixed_buttons_container}>
        <button
          className={commonStyles.btn_save}
          disabled={!canSave || isLoading}
          onClick={saveSettings}
        >
          {isLoading ? (
            <Loading color={styleVariables.gray800} size={24} />
          ) : (
            'Save'
          )}
        </button>
      </div>
    </section>
  );
}
