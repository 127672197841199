import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import API from '~/api';
import { usersKeys } from '~/constants/queryKeys';
import { useAuth } from '~/contexts/AuthContext';
import getParamFromUrl from '~/utils/getParamFromUrl';

export default function useInfiniteHiddenContracts() {
  const queryKey = usersKeys.hiddenContracts();
  const { isAuthenticated } = useAuth();

  const getHiddenContractsWithCursor = async (cursor: string | null) => {
    const {
      data: { next, results },
    } = await API.getHiddenContracts(cursor);

    return {
      results,
      next: getParamFromUrl(next, 'cursor'),
      isLast: !next,
    };
  };

  const { data, isFetched, isLoading, fetchNextPage } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => getHiddenContractsWithCursor(pageParam),
    {
      enabled: isAuthenticated,
      getNextPageParam: ({ next }) => next,
      keepPreviousData: true,
    }
  );

  const hiddenContracts = useMemo(
    () => data?.pages.flatMap((page) => page.results) ?? [],
    [data?.pages]
  );

  const isItemLoaded: (index: number) => boolean = (index) =>
    data?.pages[data.pages.length - 1].isLast || index < hiddenContracts.length;

  return {
    hiddenContracts,
    isFetched,
    isItemLoaded,
    isLoading,
    itemCount: data?.pages[data.pages.length - 1].isLast
      ? hiddenContracts.length
      : hiddenContracts.length + 1,
    loadMore: () => {
      fetchNextPage();
    },
  };
}
