import type { MouseEventHandler, ReactNode } from 'react';

interface TableRowProps {
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
}

export default function TableRow({
  children,
  className,
  onClick,
}: TableRowProps) {
  return (
    <tr className={className} onClick={!!onClick ? onClick : undefined}>
      {children}
    </tr>
  );
}
