import type { MouseEvent } from 'react';
import { Connector } from 'wagmi';

import { IcnMetaMask, IcnWalletConnect } from '~/assets';
import { REDIRECT_CLICKED_LOCATION, REDIRECT_CLICKED_TYPE } from '~/constants';
import { REDIRECT_CLICKED } from '~/constants/segment';
import { useAnalyticsContext } from '~/contexts/AnalyticsContext';
import { useAuth } from '~/contexts/AuthContext';
import setUtmSource from '~/utils/setUtmSource';

import styles from './ConnectButton.module.scss';

interface ConnectButtonProps {
  connector: Connector;
  onClose: () => void;
  name?: string;
}

export default function ConnectButton({
  connector,
  name,
  onClose,
}: ConnectButtonProps) {
  const analytics = useAnalyticsContext();
  const { connectWallet } = useAuth();

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const isRedirectToMetaMask =
      connector.id === 'metaMask' && !connector.ready;
    if (isRedirectToMetaMask) {
      analytics.track(REDIRECT_CLICKED, {
        location: REDIRECT_CLICKED_LOCATION.OTHER,
        type: REDIRECT_CLICKED_TYPE.OTHER,
        url: 'https://metamask.io/',
      });

      window.open(setUtmSource('https://metamask.io/'), '_blank');
    } else {
      connectWallet(connector);
    }

    onClose();
  };

  const renderIcon = (name: string) => {
    switch (name) {
      case 'WalletConnect':
        return <IcnWalletConnect />;
      case 'MetaMask':
      default:
        return <IcnMetaMask />;
    }
  };

  const renderText = (id: string, name: string) => {
    if (id === 'metaMask' && !connector.ready) {
      return `Install ${name}`;
    }
    return name;
  };

  return (
    <button
      className={styles.sign_in_connect_button}
      onClick={onClick}
      disabled={connector.name === 'MetaMask' ? false : !connector.ready}
    >
      {renderIcon(connector.name)}
      <span className={styles.sign_in_connect_button_text}>
        {renderText(connector.id, name ?? connector.name)}
      </span>
    </button>
  );
}
