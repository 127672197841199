/**
 * https://stackoverflow.com/questions/18719775/parsing-and-converting-exponential-values-to-decimal-in-javascript/18719988#18719988
 */
export default function noExponents(value: number) {
  const data = String(value).split(/[eE]/);
  if (data.length == 1) return data[0];

  let z = '';
  const sign = value < 0 ? '-' : '',
    str = data[0].replace('.', '');
  let mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
}
