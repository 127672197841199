import type {
  TransactionReceipt,
  TransactionResponse,
} from '@ethersproject/abstract-provider';

import base from './base';

const Mints = {
  sendMintConfirmation(
    transactionHash: string,
    payload: TransactionResponse | TransactionReceipt
  ) {
    return base.post<{
      transactionHash: string;
      payload: TransactionResponse | TransactionReceipt;
    }>(`/mints/confirm/`, { transactionHash, payload });
  },
  updateMintConfirmation(
    transactionHash: string,
    receipt?: TransactionReceipt,
    error?: any
  ) {
    const params: { [key: string]: any } = {};
    if (receipt) params['receipt'] = receipt;
    if (error) params['error'] = error;
    return base.patch(`/mints/confirm/${transactionHash}/`, params);
  },
};

export default Mints;
